import {
  Badge,
  ButtonProps,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Notification } from "components/Notification";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { MdOutlineNotifications } from "react-icons/md";

import { useNotifications } from "state/react/useNotifications";
import { fontSizes } from "theme/typography";

interface Props extends ButtonProps {
  notifications?: number;
  onClick?: () => void;
}

export const NavbarNotifications: React.FC<Props> = ({ onClick, ...rest }) => {
  const { notifications } = useNotifications();
  const { lgUp } = useCustomBreakpoints();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const hasNotifications = notifications.length > 0;

  return (
    <Popover
      isOpen={isOpen}
      boundary="scrollParent"
      placement={!lgUp ? "bottom" : "bottom-end"}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <IconButton
          aria-label="Notifications"
          icon={
            <HStack mx={3}>
              <MdOutlineNotifications size={fontSizes["2xl"]} />
              {hasNotifications && (
                <Badge variant="solid">{notifications.length}</Badge>
              )}
            </HStack>
          }
          variant="ghost"
          isRound
          onClick={onClick}
          color="whiteAlpha.500"
          {...rest}
        />
      </PopoverTrigger>
      <PopoverContent
        variants={
          !lgUp
            ? {
                exit: {
                  opacity: 0,
                  scale: 0.95,
                  transition: {
                    duration: 0.1,
                    ease: [0.4, 0, 1, 1],
                  },
                  top: "100vh",
                },
                enter: {
                  scale: 1,
                  opacity: 1,
                  transition: {
                    duration: 0.15,
                    ease: [0, 0, 0.2, 1],
                  },
                  top: `-64px`,
                },
              }
            : undefined
        }
        border="none"
        bg="gray.700"
        borderRadius={!lgUp ? 0 : 12}
        maxHeight={!lgUp ? "100vh" : "calc(100vh - 84px)"}
        width={!lgUp ? "100vw" : "auto"}
        height={!lgUp ? "100vh" : "auto"}
        overflowX="hidden"
        overflowY={hasNotifications ? "scroll" : "hidden"}
        bottom="0px"
      >
        <PopoverHeader border="none" color="whiteAlpha.700" fontSize="sm" p={4}>
          <Flex alignItems="center" justifyContent="space-between">
            NOTIFICATIONS {!lgUp && <PopoverCloseButton position="relative" />}
          </Flex>
        </PopoverHeader>

        <PopoverBody>
          {hasNotifications ? (
            <Stack spacing={2}>
              {notifications.map(({ id, ...notification }) => (
                <Notification
                  key={id}
                  {...notification}
                  onNotificationClick={onClose}
                />
              ))}
            </Stack>
          ) : (
            <Text as="div" p={14} textAlign="center">
              All caught up. 🎉
            </Text>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
