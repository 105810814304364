import { AuctionHouseProgram } from "@metaplex-foundation/mpl-auction-house";
import { BidReceipt } from "@metaplex-foundation/mpl-auction-house/dist/src/generated/accounts";
import { AnyPublicKey, StringPublicKey } from "@metaplex-foundation/mpl-core";
import { Connection } from "@solana/web3.js";
import base58 from "bs58";
import { findAccountsAndDeserialize } from "sdk/share";

const discriminator = base58.encode(
  Uint8Array.from([186, 150, 141, 135, 59, 122, 39, 99])
);

export const getBids = async (
  auctionHouse: StringPublicKey,
  connection: Connection
) => {
  const bidsAndKeys = await findAccountsAndDeserialize(
    connection,
    AuctionHouseProgram,
    BidReceipt,
    [
      { offset: 0, bytes: discriminator },
      { offset: 8 + 32 * 2, bytes: auctionHouse },
    ]
  );

  return Array.from(bidsAndKeys).map(([pubkey, bid]) =>
    combineBid(bid, pubkey)
  );
};

const combineBid = (data: BidReceipt, pubkey: AnyPublicKey) => ({
  ...data,
  bidId: pubkey.toString(),
  tradeState: data.tradeState.toString(),
  bookkeeper: data.bookkeeper.toString(),
  auctionHouse: data.auctionHouse.toString(),
  buyer: data.buyer.toString(),
  metadata: data.metadata.toString(),
  tokenAccount: data.tokenAccount?.toString(),
  purchaseReceipt: data.purchaseReceipt?.toString(),
});

export const loadBids = async ({
  auctionHouse,
  connection,
}: {
  auctionHouse: StringPublicKey;
  connection: Connection;
}) => {
  try {
    return await getBids(auctionHouse, connection);
  } catch {
    return null;
  }
};
