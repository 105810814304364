import { Button, ModalHeader } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { ENftProgress } from "sdk/nft/mintArveaweNFT";
import { FlowStatus } from "components/modals/FlowStatus";
import { HaloedIcon } from "components/HaloedIcon";
import { IArt } from "state/artworks";
import { IFormData } from "components/forms/NftCreate";
import { IProgressStep } from "components/ProgressSteps";
import { ListingProgress } from "components/modals/ListingProgress";
import { MdErrorOutline } from "react-icons/md";
import { ModalTemplate } from "components/modals/template";
import { getContent } from "./NftCreationView.state";
import { useFileReader } from "hooks/useFileReader";

interface Props {
  formData: IFormData;
  step: ENftProgress | null;
  isOpen: boolean;
  error: string | null;
  cancelHandling?: () => void;
}

export const ProgressModal: React.FC<Props> = ({
  formData: { file, preview, title, desc },
  step,
  isOpen,
  error,
  cancelHandling,
}) => {
  const [contentUrl] = useFileReader(preview ?? file);

  const steps: IProgressStep[] | null = useMemo(() => {
    if (!step) return null;
    return Object.values(ENftProgress)
      .filter((step): step is ENftProgress => typeof step === "number")
      .map((key) => ({
        title: getContent(key).title,
        isActive: step === key,
        isComplete: step > key,
      }));
  }, [step]);

  return (
    <ModalTemplate
      bodyProps={{ bgColor: "whiteAlpha.50", borderRadius: 12, p: 0 }}
      contentProps={{ bgColor: "gray.800", borderRadius: 12 }}
      header={<ModalHeader fontSize="md">Minting your item</ModalHeader>}
      isOpen={isOpen}
      onClose={() => {}}
      size="lg"
    >
      {error ? (
        <FlowStatus
          title={error}
          statusIcon={<HaloedIcon icon={MdErrorOutline} />}
          actions={
            <Button variant="primary" size="lg" onClick={cancelHandling}>
              Dismiss
            </Button>
          }
        />
      ) : (
        steps && (
          <ListingProgress
            bgColor="none"
            steps={steps}
            artwork={
              {
                image: contentUrl,
                type: "Master",
                title,
                description: desc,
              } as IArt
            }
          />
        )
      )}
    </ModalTemplate>
  );
};
