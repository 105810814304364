import { AuctionHouseProgram } from "@metaplex-foundation/mpl-auction-house";
import {
  AuctionHouse,
  createWithdrawInstruction,
} from "@metaplex-foundation/mpl-auction-house/dist/src/generated";
import { Connection, PublicKey } from "@solana/web3.js";

import { loadAccountAndDeserialize } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";

export interface CreateWithdrawTranscationProps {
  amount?: number;
  auctionHouseId: PublicKey;
  connection: Connection;
  wallet: PublicKey;
}

export const createWithdrawTransaction = async ({
  amount = 0,
  auctionHouseId,
  connection,
  wallet,
}: CreateWithdrawTranscationProps) => {
  const auctionHouse = await loadAccountAndDeserialize(
    connection,
    AuctionHouse,
    auctionHouseId
  );

  const [escrowPaymentAccount, escrowPaymentBump] =
    await AuctionHouseProgram.findEscrowPaymentAccountAddress(
      auctionHouseId,
      wallet
    );

  const withdrawIx = createWithdrawInstruction(
    {
      auctionHouse: auctionHouseId,
      auctionHouseFeeAccount: auctionHouse.auctionHouseFeeAccount,
      authority: auctionHouse.authority,
      escrowPaymentAccount,
      receiptAccount: wallet,
      treasuryMint: auctionHouse.treasuryMint,
      wallet,
    },
    {
      amount,
      escrowPaymentBump,
    }
  );

  const tx = new TransactionsBatch({
    instructions: [withdrawIx],
  });

  return { tx };
};
