import { IStore } from "../store";
import { IArt } from "./types";

export function isImageFormat(artwork: IArt | null): boolean {
  return artwork?.format === "image";
}

export function isVideoFormat(artwork: IArt | null): boolean {
  return artwork?.format === "video";
}

export function isAudioFormat(artwork: IArt | null): boolean {
  return artwork?.format === "audio";
}

export function isVrFormat(artwork: IArt | null): boolean {
  return artwork?.format === "vr";
}

type Response = (art: IArt) => boolean;

export const getArtworksOwnershipFilterFn =
  ({ owner, whitelistedCollections }: IStore): Response =>
  ({ creators, collection, id }) => {
    const artCreators = creators || [];
    const isArtCreatedByStoreOwner = artCreators[0]?.address === owner;

    const isArtCollectionMatch = whitelistedCollections.length
      ? whitelistedCollections?.includes(collection?.address || "") ||
        whitelistedCollections?.includes(id)
      : false;

    return isArtCollectionMatch || isArtCreatedByStoreOwner;
  };
