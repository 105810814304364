import React from "react";
import { Image } from "@chakra-ui/image";
import { Box } from "@chakra-ui/react";

interface ISquarePictureProps {
  imageSrc: string;
  size?: number;
}

export const SquarePicture: React.FC<ISquarePictureProps> = ({
  imageSrc,
  size = 100,
}: ISquarePictureProps) => {
  return (
    <Box width={size} height={size}>
      <Box
        position="relative"
        borderRadius={8}
        paddingTop="100%"
        overflow="hidden"
      >
        <Image
          position="absolute"
          top={0}
          right={0}
          left={0}
          bottom={0}
          width="100%"
          objectFit="cover"
          src={imageSrc}
        />
      </Box>
    </Box>
  );
};
