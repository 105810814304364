import { attach, createEffect, StoreValue } from "effector";
import { initSale as initAhSale } from "sdk/auction-house";
import { IArt } from "../artworks";
import { $connection } from "../connection";
import { $store } from "../store";
import { $wallet } from "../wallet";
import { initMarket } from "sdk/fps/initMarket";
import { MarketSettings } from "sdk/fps/actions/createMarketTransaction";
import { SaleType } from "views/CreateSaleView/SaleDetailsForm/SaleTypeSelector";
import { ETransactionProgress } from "sdk/share";

interface Params {
  artwork: IArt;
  settings: MarketSettings & { type: SaleType };
  updateProgress: (state: ETransactionProgress) => ETransactionProgress | null;
}

export const createSaleFx = attach({
  effect: createEffect(
    ({
      artwork,
      store,
      wallet,
      connection,
      settings,
      updateProgress,
    }: Params & {
      connection: StoreValue<typeof $connection>;
      wallet: StoreValue<typeof $wallet>;
      store: StoreValue<typeof $store>;
    }) => {
      if (!store) {
        throw new Error("Empty store");
      }

      if (!wallet) {
        throw new Error("Empty wallet");
      }

      if (settings.type === SaleType.InstantSale) {
        return initAhSale({
          wallet,
          connection,
          auctionHouse: store.storeAhId,
          resourceMint: artwork.mint,
          price: settings.price as number,
          progressChange: updateProgress,
        });
      }

      if (settings.type === SaleType.FixedPrice) {
        let maxSupply = null;

        if (artwork.prints?.maxSupply !== undefined) {
          maxSupply = artwork.prints.maxSupply - (artwork.prints.supply ?? 0);
        }

        return initMarket({
          store: store.storeFpsId,
          resourceToken: artwork.token,
          resourceMint: artwork.mint,
          maxSupply,
          wallet,
          connection,
          marketSettings: settings,
          progressChange: updateProgress,
        });
      }

      throw new Error("Not supported type");
    }
  ),
  source: {
    connection: $connection,
    wallet: $wallet,
    store: $store,
  },
  mapParams(data: Params, source) {
    return { ...data, ...source };
  },
});
