import { sample } from "effector";
import { closeMarketAndWithdrawFx } from "views/AdminItems/effects/closeMarketAndWithdrawFx";
import { endSaleFx } from "./AH/endSaleFx";
import { loadSaleFx } from "./loadSaleFx";

sample({
  clock: closeMarketAndWithdrawFx.done,
  source: [],
  fn: (_, { params }) => ({ saleId: params.market }),
  target: loadSaleFx,
});

sample({
  clock: endSaleFx.done,
  source: [],
  fn: (_, { params: { sale } }) => ({ saleId: sale.id }),
  target: loadSaleFx,
});
