/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC } from "react";
import { Flex, HStack } from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { heroContent } from "views/StorePage/constants/heroContent";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface ImageStepperProps {
  onPrevClick?: () => void;
  onNextClick?: () => void;
  activeIdx?: number;
}

export const arrowButtonStyles = {
  width: "40px",
  height: "40px",
  padding: "12px",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(0, 0, 0, 0.5)",
  borderRadius: "50%",
  cursor: "pointer",
};

const dotsStyles = {
  width: "8px",
  height: "8px",
  borderRadius: "50%",
};

export const ImageStepper: FC<ImageStepperProps> = ({
  onPrevClick,
  onNextClick,
  activeIdx,
}) => {
  const { mdUp } = useCustomBreakpoints();

  return (
    <HStack
      position="absolute"
      width={mdUp ? "calc(100% - 32px)" : "100%"}
      h={mdUp ? "calc(100% - 48px)" : "calc(100% - 64px)"}
      top={mdUp ? 6 : 0}
      left={mdUp ? 4 : 0}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex style={arrowButtonStyles} onClick={onPrevClick}>
        <MdArrowBack />
      </Flex>

      {mdUp && (
        <Flex w={5} h={2} justifyContent="space-between" alignSelf="flex-end">
          {[...new Array(heroContent.length)].map((item, idx) => (
            <div
              style={{
                ...dotsStyles,
                background: idx === activeIdx ? "#FFFFFF" : "whiteAlpha.500",
              }}
            />
          ))}
        </Flex>
      )}

      <Flex
        transform="rotate(180deg)"
        style={arrowButtonStyles}
        onClick={onNextClick}
      >
        <MdArrowBack />
      </Flex>
    </HStack>
  );
};
