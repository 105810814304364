import { Divider, Stack, VStack, StackProps, Flex } from "@chakra-ui/react";

import { SolUsdDisplay } from "components/SolUsdDisplay/SolUsdDisplay";
import { TitledBlock } from "components/TitledBlock";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { useBalance } from "state/react/useBalance";

interface WalletTransactionProps extends StackProps {
  sol?: number;
  title?: string;
}

export const WalletTransaction: React.FC<WalletTransactionProps> = ({
  sol,
  title = "Price",
  children,
  ...rest
}) => {
  const { balance } = useBalance();
  const { mdUp } = useCustomBreakpoints();

  return (
    <VStack
      align="stretch"
      borderRadius={12}
      bgColor="whiteAlpha.100"
      p={6}
      spacing={4}
      {...rest}
    >
      <TitledBlock
        title="YOUR BALANCE"
        titleProps={{ mb: 1, color: "whiteAlpha.700" }}
      >
        <SolUsdDisplay sol={balance?.sol ?? 0} variant="big-price" />
      </TitledBlock>

      <Divider />

      <Stack
        alignItems="center"
        direction={mdUp ? "row" : "column"}
        spacing={4}
      >
        <TitledBlock title={title} size="sm" w="full">
          <SolUsdDisplay sol={sol} variant="big-price" />
        </TitledBlock>

        <Flex
          display={mdUp ? "flex" : "grid"}
          mt={mdUp ? 0 : 2}
          width={mdUp ? "unset" : "100%"}
        >
          {children}
        </Flex>
      </Stack>
    </VStack>
  );
};
