import { Box, Image } from "@chakra-ui/react";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { FC } from "react";

interface Props {
  logo?: string | null;
}

export const HeroLogo: FC<Props> = ({ logo }) => {
  const { mdUp } = useCustomBreakpoints();

  if (logo === null) return null;

  const ImageElement = logo ? Image : Box;

  return (
    <ImageElement
      src={logo}
      w="128px"
      h="128px"
      padding="14px"
      objectFit="contain"
      borderRadius="12px"
      position={mdUp ? "static" : "absolute"}
      bgColor={logo ? undefined : "whiteAlpha.100"}
      bottom={mdUp ? "unset" : 0}
      boxShadow={mdUp ? "unset" : "0 0 0 4px rgba(255, 255, 255, 0.1)"}
    />
  );
};
