import { useEffect } from "react";
import { AddressRow } from "components/forms/NftCreate";
import { useIsTotalActive } from "./useIsTotalActive";

interface UseUpdateTotalParams {
  secondarySplits: Array<AddressRow>;
  royalty: string;
  id: number;
  onUpdate?: (
    id: `secondarySplits.${number}.total` | `secondarySplits.${number}.value`,
    state: number
  ) => void;
}

export const useUpdateTotal = ({
  onUpdate,
  secondarySplits,
  royalty,
  id,
}: UseUpdateTotalParams) => {
  const isTotalActive = useIsTotalActive(id);

  const percentOfTotal = Number(
    ((secondarySplits[id]?.value * Number(royalty)) / 100).toFixed(2)
  );
  const percentage = Number(
    (((secondarySplits[id]?.total || 0) * 100) / Number(royalty)).toFixed(2)
  );

  useEffect(() => {
    if (!onUpdate || !secondarySplits[id]) {
      return;
    }

    if (!isTotalActive) {
      onUpdate(
        `secondarySplits.${id}.total`,
        secondarySplits[id]?.value && royalty ? percentOfTotal : 0
      );
    }

    if (royalty && isTotalActive) {
      onUpdate(
        `secondarySplits.${id}.value`,
        secondarySplits[id]?.total ? percentage : 0
      );
    }
  }, [
    royalty,
    onUpdate,
    id,
    secondarySplits,
    isTotalActive,
    percentOfTotal,
    percentage,
  ]);
};
