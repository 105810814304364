import { Box, BoxProps, Heading, Text, VStack } from "@chakra-ui/react";
import { EarningsList, EarningsRow } from ".";
import React, { useMemo } from "react";

import { IArt } from "state/artworks";

export interface EarningsProps extends BoxProps {
  artwork?: IArt;
}

export const Earnings: React.FC<EarningsProps> = ({ artwork, ...props }) => {
  const earnings = useMemo(() => {
    if (artwork && artwork.creators.length > 0) {
      const royalties = artwork.sellerFeeBasisPoints / 100;
      const owner = 100 - royalties;

      return {
        list: artwork.creators.map(({ share, ...creator }) => ({
          ...creator,
          share: +(royalties * (share / 100)).toFixed(2),
        })),
        owner,
        royalties,
      };
    }

    return null;
  }, [artwork]);

  return earnings ? (
    <Box {...props}>
      <Heading variant="h4">Secondary Sales</Heading>

      <Text color="gray.200" fontSize="xs" mt={2}>
        Secondary sales earnings are distributed between the&nbsp;individual
        selling an NFT, and an NFT’s royalty settings.
      </Text>

      <VStack mt={2} spacing={0}>
        <EarningsRow value={earnings.owner}>NFT SELLER</EarningsRow>
        <EarningsRow value={earnings.royalties}>ROYALTIES</EarningsRow>
      </VStack>

      <EarningsList data={earnings.list} mt={6} />
    </Box>
  ) : null;
};
