import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";

import { CreatorSplitPreview } from "../CreatorSplitPreview";
import { IFormData } from "components/forms/NftCreate";
import { Image } from "@chakra-ui/image";
import { PageHead } from "../PageHead";
import { Price } from "views/ItemView/types";
import { PropertyCard } from "../PropertyCard";
import React, { useMemo } from "react";
import { StackDivider } from "@chakra-ui/layout";
import { TitledBlock } from "../TitledBlock";
import { WalletTransaction } from "../WalletTransaction";
import { smartButtonProps } from "utils/smartButtonProps";
import { useFileReader } from "hooks/useFileReader";
import { getSupplyType } from "utils/getSupplyType";
import { SupplyTypeToText } from "../EditionSelector";

export interface Props {
  preview: IFormData;
  price: Price | null;
  onBack: () => void;
  onMint: () => void;
  isLoading: boolean;
}

export const PreviewNft: React.FC<Props> = ({
  preview: {
    desc,
    file,
    preview,
    properties,
    royalty,
    splits,
    supply,
    title,
    symbol,
  },
  price,
  onMint,
  onBack,
  isLoading,
}) => {
  const [contentUrl] = useFileReader(preview ? preview : file);

  const supplyType = useMemo(() => getSupplyType(supply), [supply]);

  return (
    <Box>
      <PageHead title="Preview your NFT" />

      <VStack
        spacing={6}
        divider={<StackDivider borderColor="whiteAlpha.100" />}
        align="stretch"
      >
        <Box>
          <Box layerStyle="base" p={8}>
            <Image w="full" borderRadius="xl" src={contentUrl ?? undefined} />
          </Box>
          <TitledBlock title="name" mt={12} titleProps={{ mb: 2 }}>
            <Text variant="button">{title}</Text>
          </TitledBlock>
        </Box>

        {symbol !== "" && (
          <TitledBlock title="symbol" titleProps={{ mb: 2 }}>
            <Text variant="button">{symbol}</Text>
          </TitledBlock>
        )}

        {desc !== "" && (
          <TitledBlock title="description" titleProps={{ mb: 2 }}>
            <Text variant="button">{desc}</Text>
          </TitledBlock>
        )}
        <TitledBlock title="Supply" titleProps={{ mb: 2 }}>
          <Box>
            <Text textTransform="capitalize" variant="button">
              {SupplyTypeToText[supplyType]}
            </Text>
            {supply > 1 && <Text variant="body">{supply} Editions</Text>}
          </Box>
        </TitledBlock>

        {properties.length > 0 && (
          <TitledBlock title="properties" titleProps={{ mb: 2 }}>
            <HStack>
              {properties.map(({ key, value }) => (
                <PropertyCard key={key} title={key} description={value} />
              ))}
            </HStack>
          </TitledBlock>
        )}

        <TitledBlock
          title="ROYALTY PERCENTAGE FOR SECONDARY SALES"
          titleProps={{ mb: 2 }}
        >
          <Text variant="button">{royalty}%</Text>
        </TitledBlock>

        <VStack
          pt={6}
          spacing={12}
          alignItems="stretch"
          divider={<StackDivider borderColor="whiteAlpha.100" />}
        >
          {splits.length > 0 && (
            <TitledBlock title="CREATOR SPLITS">
              <VStack spacing={4} alignItems="stretch">
                {splits.map(({ key, value }) => (
                  <CreatorSplitPreview
                    key={key}
                    address={key}
                    percentage={value}
                    totalPercentage={value * (Number(royalty) / 100)}
                  />
                ))}
              </VStack>
            </TitledBlock>
          )}

          <WalletTransaction sol={price?.sol} title="COST TO MINT">
            <Button
              ml="auto"
              variant="primary"
              size="lg"
              px="30px"
              isLoading={isLoading}
              {...smartButtonProps(onMint)}
            >
              Create NFT
            </Button>
          </WalletTransaction>
        </VStack>
      </VStack>
      <Button variant="tertiary" mt={12} px={12} onClick={onBack}>
        Back
      </Button>
    </Box>
  );
};
