import { IArt } from "state/artworks";
import {
  ISale,
  isFixedPrice,
  isSale,
  SaleState,
  UnixTimestamp,
} from "state/sales";

interface GetArtworkActionsPropsResponse {
  artwork: IArt;
  isSoldOut: boolean;
  state: SaleState;
  endDate?: UnixTimestamp;
  sale?: ISale;
}

const getArtworkActionsProps = (
  item: ISale | IArt
): GetArtworkActionsPropsResponse => {
  const artwork = isSale(item) ? item.artwork : item;

  const isSoldOut =
    !!artwork && artwork.prints?.supply === artwork.prints?.maxSupply;
  const endDate =
    (isSale(item) && isFixedPrice(item) && item?.endDate) || undefined;
  const state = (isSale(item) && item.state) || SaleState.Uninitialized;

  return {
    artwork,
    isSoldOut,
    state,
    endDate,
    ...(isSale(item) && { sale: item }),
  };
};

export default getArtworkActionsProps;
