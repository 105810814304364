import React, { ReactNode } from "react";
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  StyleProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  LimitedEditionIcon,
  OneEditionIcon,
  UnlimitedEditionIcon,
} from "./EditionSelector.icons";
import { MdChevronLeft } from "react-icons/md";
import { EditionSelectorItem } from "./EditionSelectorItem";

export enum SupplyType {
  Single,
  Limited,
  Unlimited,
}

const TypeToIcon: Record<SupplyType, ReactNode> = {
  [SupplyType.Single]: <OneEditionIcon />,
  [SupplyType.Limited]: <LimitedEditionIcon />,
  [SupplyType.Unlimited]: <UnlimitedEditionIcon />,
};

export const SupplyTypeToText: Record<SupplyType, string> = {
  [SupplyType.Single]: "One of One",
  [SupplyType.Limited]: "Limited Edition",
  [SupplyType.Unlimited]: "Unlimited Edition",
};

interface Props extends StyleProps {
  value: SupplyType;
  onChange: (value: SupplyType) => void;
}

export const EditionSelector: React.FC<Props> = ({
  value,
  onChange,
  ...props
}) => {
  const itemClickHandler = (value: SupplyType) => {
    onChange(value);
  };

  return (
    <VStack alignItems="flex-start" spacing={2} {...props}>
      <Text variant="label-bold" color="whiteAlpha.700">
        EDITIONS
      </Text>

      <Menu>
        <MenuButton type="button" w="full" cursor="pointer" layerStyle="base">
          <Flex alignItems="center">
            {TypeToIcon[value]}
            <Text ml={4} mr="auto" variant="body-bold">
              {SupplyTypeToText[value]}
            </Text>
            <MdChevronLeft
              size="24px"
              style={{ transform: "rotate(-90deg)" }}
            />
          </Flex>
        </MenuButton>
        <MenuList
          px={2}
          py={4}
          borderRadius="xl"
          as={VStack}
          spacing={3}
          alignItems="stretch"
        >
          <EditionSelectorItem
            isActive={value === SupplyType.Single}
            icon={<OneEditionIcon w="20px" h="20px" />}
            title="One of One"
            subtitle="Only one will ever exist"
            onClick={() => itemClickHandler(SupplyType.Single)}
          />
          <EditionSelectorItem
            isActive={value === SupplyType.Limited}
            icon={<LimitedEditionIcon w="20px" h="20px" />}
            title="Limited Edition"
            subtitle="Allow a fixed number of prints"
            onClick={() => itemClickHandler(SupplyType.Limited)}
          />
          <EditionSelectorItem
            isActive={value === SupplyType.Unlimited}
            icon={<UnlimitedEditionIcon w="24px" h="24px" />}
            title="Unlimited Edition"
            subtitle="Allow an unlimited amount of prints"
            onClick={() => itemClickHandler(SupplyType.Unlimited)}
          />
        </MenuList>
      </Menu>
    </VStack>
  );
};
