import { Connection } from "@solana/web3.js";
import { createEffect, restore } from "effector";
import { loadArtworksByOwner } from "sdk/loadArtworks";

export const getApproveNotificationsFx = createEffect(
  async ({
    storeOwner,
    walletAddress,
    connection,
  }: {
    storeOwner?: string;
    walletAddress: string | null;
    connection: Connection;
  }) => {
    if (storeOwner === undefined || walletAddress === null) {
      return [];
    }

    const artworks = await loadArtworksByOwner({
      connection,
      owner: storeOwner,
    });

    const artworksNeedApproving = artworks.filter((art) => {
      return (
        art.creators &&
        art.creators.find((c) => c.address === walletAddress && !c.verified)
      );
    });

    return artworksNeedApproving;
  }
);

export const $artworkNeedApproving = restore(
  getApproveNotificationsFx.doneData,
  []
);

// forward({
//   from: combine(
//     $storeOwner,
//     $walletAddress,
//     $connection,
//     (storeOwner, walletAddress, connection) => ({
//       storeOwner,
//       walletAddress,
//       connection,
//     })
//   ),
//   to: getApproveNotificationsFx,
// });
