// @ts-nocheck
import { AuctionHouseProgram } from "@metaplex-foundation/mpl-auction-house";
import {
  AuctionHouse,
  createExecuteSaleInstruction,
  createPrintPurchaseReceiptInstruction,
} from "@metaplex-foundation/mpl-auction-house/dist/src/generated";
import { AccountLayout } from "@solana/spl-token";
import {
  Connection,
  PublicKey,
  SYSVAR_INSTRUCTIONS_PUBKEY,
} from "@solana/web3.js";
import { toPubkey } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";
import { IInstantSale } from "state/sales";
import { excludesFalsy } from "utils/excludeFalsy";
import { solToLamports } from "utils/lamportsToSol";
import { topupFeeAccount } from "./topupFeeAccount";

export interface ExecuteProps {
  connection: Connection;
  auctionHouse: AuctionHouse;
  auctionHouseKey: PublicKey;
  sale: IInstantSale;
  buyer: PublicKey;
  buyerTradeState: PublicKey;
  sellerTradeState: PublicKey;
  bidReceipt: PublicKey;
  listingReceipt: PublicKey;
  tokenSize?: number;
}

export const createExecuteTransaction = async ({
  connection,
  auctionHouse,
  auctionHouseKey,
  sale,
  buyer,
  buyerTradeState,
  sellerTradeState,
  bidReceipt,
  listingReceipt,
  tokenSize = 1,
}: ExecuteProps) => {
  const {
    treasuryMint,
    authority,
    auctionHouseTreasury,
    auctionHouseFeeAccount,
  } = auctionHouse;

  const tokenAccount = toPubkey(sale.artwork.token);
  const tokenMint = toPubkey(sale.artwork.mint);
  const metadata = toPubkey(sale.artwork.accountAddress);
  const buyerPrice = solToLamports(sale.price);
  const { creators } = sale.artwork;
  const seller = toPubkey(sale.refs.seller);

  const [escrowPaymentAccount, escrowPaymentBump] =
    await AuctionHouseProgram.findEscrowPaymentAccountAddress(
      auctionHouseKey,
      buyer
    );

  const [freeTradeState, freeTradeStateBump] =
    await AuctionHouseProgram.findTradeStateAddress(
      seller,
      auctionHouseKey,
      tokenAccount,
      treasuryMint,
      tokenMint,
      0,
      tokenSize
    );

  const [programAsSigner, programAsSignerBump] =
    await AuctionHouseProgram.findAuctionHouseProgramAsSignerAddress();

  const [buyerReceiptTokenAccount] =
    await AuctionHouseProgram.findAssociatedTokenAccountAddress(
      tokenMint,
      buyer
    );

  const executeAmount = await connection.getMinimumBalanceForRentExemption(
    AccountLayout.span
  );
  const topupFeeIx = buyer.equals(authority)
    ? await topupFeeAccount({
        connection,
        payer: buyer,
        auctionHouseFeeAccount,
        amount: executeAmount,
      })
    : undefined;

  const execIx = createExecuteSaleInstruction(
    {
      buyer,
      seller,
      tokenAccount,
      tokenMint,
      metadata,
      treasuryMint,
      escrowPaymentAccount,
      sellerPaymentReceiptAccount: seller,
      buyerReceiptTokenAccount,
      authority,
      auctionHouse: auctionHouseKey,
      auctionHouseFeeAccount,
      auctionHouseTreasury,
      buyerTradeState,
      sellerTradeState,
      freeTradeState,
      programAsSigner,
    },
    {
      escrowPaymentBump,
      freeTradeStateBump,
      programAsSignerBump,
      buyerPrice,
      tokenSize,
    }
  );

  creators?.map((creator) =>
    execIx.keys.push({
      isSigner: false,
      isWritable: true,
      pubkey: new PublicKey(creator.address),
    })
  );

  const [purchaseReceipt, purchaseReceiptBump] =
    await AuctionHouseProgram.findPurchaseReceiptAddress(
      sellerTradeState,
      buyerTradeState
    );

  const receiptIx = createPrintPurchaseReceiptInstruction(
    {
      purchaseReceipt,
      listingReceipt,
      bidReceipt,
      bookkeeper: buyer,
      instruction: SYSVAR_INSTRUCTIONS_PUBKEY,
    },
    { purchaseReceiptBump }
  );

  const tx = new TransactionsBatch({
    instructions: [topupFeeIx, execIx, receiptIx].filter(excludesFalsy),
  });

  return { purchaseReceipt, tx };
};
