import React from "react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  VStack,
  Accordion as AccordionChakra,
  AccordionProps as AccordionChakraProps,
} from "@chakra-ui/react";

interface AccordionProps extends AccordionChakraProps {
  children: React.ReactNode | ((isExpanded: boolean) => React.ReactNode);
  title: string;
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  ...props
}) => {
  return (
    <AccordionChakra allowToggle {...props}>
      <AccordionItem css={{ border: "none", padding: 0 }}>
        {({ isExpanded }) => (
          <>
            <AccordionButton css={{ padding: 0 }} _focus={{ outline: "none" }}>
              <Heading flex="1" textAlign="left">
                {title}
              </Heading>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel
              as={VStack}
              p={0}
              mt={8}
              spacing={12}
              w="full"
              alignItems="flex-start"
            >
              {typeof children === "function" ? children(isExpanded) : children}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </AccordionChakra>
  );
};
