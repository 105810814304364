import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface Props {
  coverImg?: string;
}

export const MobileBackgroundImage: FC<Props> = ({ coverImg }) => {
  const { mdUp } = useCustomBreakpoints();

  if (mdUp) return null;

  return (
    <Box
      zIndex={-1}
      position="absolute"
      top="72px"
      left={0}
      right={0}
      height="355px"
      bgImage={coverImg}
      bgPos="top"
      bgRepeat="no-repeat"
      bgSize="cover"
      bgAttachment="scroll"
      filter="brightness(0.5)"
      willChange="transform"
      style={{ transform: "translateZ(0)" }}
    />
  );
};
