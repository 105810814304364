import { ButtonProps, Button, Link } from "@chakra-ui/react";
import { FC } from "react";
import { MdLanguage } from "react-icons/md";
import { fontSizes } from "theme/typography";

interface Props {
  url?: string;
}

export const ExternalSiteButton: FC<Props & ButtonProps> = ({
  url,
  ...props //
}) =>
  url ? (
    <Button
      aria-label="Open external URL"
      px="sm"
      {...props}
      as={Link}
      href={url}
      isExternal
    >
      <MdLanguage size={fontSizes.xl} />
    </Button>
  ) : null;
