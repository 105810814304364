import { AuctionHouseProgram } from "@metaplex-foundation/mpl-auction-house";
import { createCloseEscrowAccountInstruction } from "@metaplex-foundation/mpl-auction-house/dist/src/generated";
import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import { Connection } from "@solana/web3.js";
import { loadAccount, toPubkey } from "sdk/share";
import { sendTransactions, TransactionsBatch } from "sdk/transactions";
import { Wallet } from "wallet";

export async function loadEscrowBalance(
  connection: Connection,
  auctionHouse: StringPublicKey,
  wallet: Wallet
) {
  const [escrowPaymentAccount] =
    await AuctionHouseProgram.findEscrowPaymentAccountAddress(
      toPubkey(auctionHouse),
      wallet.publicKey
    );

  const escrow = await loadAccount(connection, escrowPaymentAccount, true);
  return escrow?.lamports || null;
}

export async function closeEscrowBalance(
  connection: Connection,
  auctionHouse: StringPublicKey,
  wallet: Wallet
) {
  const auctionHouseKey = toPubkey(auctionHouse);

  const [escrowPaymentAccount, escrowPaymentBump] =
    await AuctionHouseProgram.findEscrowPaymentAccountAddress(
      auctionHouseKey,
      wallet.publicKey
    );

  const ix = createCloseEscrowAccountInstruction(
    {
      wallet: wallet.publicKey,
      escrowPaymentAccount,
      auctionHouse: auctionHouseKey,
    },
    { escrowPaymentBump }
  );
  const tx = new TransactionsBatch({ instructions: [ix] });

  await sendTransactions(connection, wallet, [tx]);
}
