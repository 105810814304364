import { AnyPublicKey } from "@metaplex-foundation/mpl-core";
import { AccountLayout, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Connection } from "@solana/web3.js";
import { findMetadataAddress } from "sdk/nft";
import { parseBN } from "utils/parseBN";
import { toPubkey } from "../share";
import { loadArtworks } from "./loadArtworks";

export const loadArtworksByOwner = async ({
  connection,
  owner,
}: {
  connection: Connection;
  owner: AnyPublicKey;
}) => {
  const accounts = await connection.getTokenAccountsByOwner(toPubkey(owner), {
    programId: TOKEN_PROGRAM_ID,
  });

  const PDAs = await Promise.all(
    accounts.value.map(async ({ pubkey, account }) => {
      const data = AccountLayout.decode(account.data);
      const token = { address: pubkey, ...data };
      const [pda] = await findMetadataAddress(data.mint);
      return [pda, { token }] as const;
    })
  );

  return loadArtworks(
    connection,
    PDAs.filter(([, { token }]) => parseBN(token.amount) === 1)
  );
};
