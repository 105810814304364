import { Box, Flex, FlexProps } from "@chakra-ui/react";
import { FC } from "react";

import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { useLayoutContentPadding } from "./useLayoutContentPadding";

export type LayoutContentVariant = "wide" | "medium" | "narrow" | "full";
export const layoutVariantToWidth = {
  wide: "1600px",
  medium: "7xl",
  narrow: "3xl",
  full: "100vw",
};

interface Props {
  variant?: LayoutContentVariant;
  gutterBottom?: boolean;
  noPadding?: boolean;
  props?: FlexProps;
}

export const LayoutContent: FC<Props> = ({
  variant = "wide",
  gutterBottom,
  children,
  noPadding,
  props,
}) => {
  const { mdUp } = useCustomBreakpoints();
  const { px, pt, pb } = useLayoutContentPadding({ gutterBottom, noPadding });

  const maxWidth = layoutVariantToWidth[variant];

  return (
    <Box flexGrow={1} position="relative" maxWidth="100vw" {...props}>
      <Flex
        direction="column"
        maxW={noPadding || mdUp ? maxWidth : "full"}
        w={noPadding || mdUp ? undefined : "390px"}
        marginX="auto"
        h="full"
        flexGrow={1}
        px={px}
        pt={pt}
        pb={pb}
      >
        {children}
      </Flex>
    </Box>
  );
};
