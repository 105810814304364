import { IconButton, IconButtonProps } from "@chakra-ui/react";

import { MdMenu } from "react-icons/md";

export const Hamburger: React.FC<IconButtonProps> = (props) => {
  return (
    <IconButton variant="ghost" isRound px={3} {...props}>
      <MdMenu size={24} />
    </IconButton>
  );
};
