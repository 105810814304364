import { Box, HStack, StyleProps } from "@chakra-ui/react";
import React from "react";

const toggleValues = [
  { label: "Off", value: false },
  { label: "On", value: true },
];

interface ToggleProps extends StyleProps {
  onChange: (value: boolean) => void;
  value: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({
  onChange,
  value: inputValue,
  ...props
}: ToggleProps) => {
  return (
    <HStack
      p="1"
      borderRadius="xl"
      bg="gray.800"
      width="fit-content"
      cursor="pointer"
      {...props}
    >
      {toggleValues.map(({ value, label }) => (
        <Box
          h={8}
          lineHeight={8}
          borderRadius="xl"
          key={label}
          px={4}
          color={value === inputValue ? "black" : "white"}
          fontWeight="bold"
          bgColor={value === inputValue ? "green.500" : "transparent"}
          marginRight="5px"
          _last={{ marginRight: 0 }}
          onClick={() => onChange(value)}
        >
          {label}
        </Box>
      ))}
    </HStack>
  );
};
