import { AuctionHouse } from "@metaplex-foundation/mpl-auction-house/dist/src/generated";
import { Connection, PublicKey } from "@solana/web3.js";
import { loadAccountAndDeserialize, toPubkey } from "sdk/share";
import { IInstantSale } from "state/sales";
import { createBuyTransaction } from "./createBuyTransaction";
import { createExecuteTransaction } from "./createExecuteTransaction";

interface BuyProps {
  connection: Connection;
  storeId: PublicKey;
  buyer: PublicKey;
  sale: IInstantSale;
}
export const createBuy = async ({
  connection,
  sale,
  buyer,
  storeId,
}: BuyProps) => {
  const auctionHouse = await loadAccountAndDeserialize(
    connection,
    AuctionHouse,
    storeId
  );

  const {
    tradeState,
    receipt: bidReceipt,
    tx: buyTx,
  } = await createBuyTransaction({
    connection,
    auctionHouse,
    auctionHouseKey: storeId,
    buyer,
    sale,
  });

  const { purchaseReceipt, tx: execTx } = await createExecuteTransaction({
    connection,
    auctionHouse,
    auctionHouseKey: storeId,
    buyer,
    sale,
    buyerTradeState: tradeState,
    sellerTradeState: toPubkey(sale.refs.tradeState),
    bidReceipt,
    listingReceipt: toPubkey(sale.id),
  });

  return { txs: [buyTx, execTx], bidReceipt, purchaseReceipt };
};
