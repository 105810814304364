// @ts-ignore
import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { usePaths } from "routes";
import { IArt } from "state/artworks";
import { fetchStoreSalesFx, ISale, isSale } from "state/sales";
import { smartButtonProps } from "utils/smartButtonProps";

import { ArtworkListItem } from "components/Artwork";
import { ArtworkListCreateButton } from "components/ArtworksList";
import { ArtworksList } from "components/ArtworksList/ArtworksList";
import { PageHead } from "components/PageHead";
import { InfiniteProgress } from "components/modals/InfiniteProgress";
import { ArtworkActions } from "components/Artwork/ArtworkListItem/ArtworkActions";

import getArtworkActionsProps from "components/Artwork/ArtworkListItem/utils/getArtworkActionsProps";

import { useLocalState } from "./AdminItems.state";
import { ErrorModal } from "./ErrorModal";

export const AdminItems: FC = () => {
  const {
    handleSell,
    isLoading,
    sales,
    progress,
    tabs,
    onCloseMarket,
    error,
    resetError,
  } = useLocalState();
  const navigate = useNavigate();
  const paths = usePaths();

  useEffect(() => {
    fetchStoreSalesFx();
  }, []);

  const handleListItemClick = useCallback(
    (item: IArt | ISale) => {
      if (!isSale(item)) handleSell(item);
      navigate(
        isSale(item)
          ? paths.sale({
              ":saleId": item.id,
            })
          : paths.artwork({
              ":artworkId": item.id,
            })
      );
    },
    [handleSell, navigate, paths]
  );

  const renderListItem = useCallback(
    (item: (ISale | IArt) & { isClaimed?: boolean }) => {
      const artworkActionProps = getArtworkActionsProps(item);
      const { artwork } = artworkActionProps;

      return (
        <ArtworkListItem
          onClick={() => handleListItemClick(item)}
          key={artwork.id}
          artwork={artwork}
          sale={isSale(item) ? item : undefined}
          variant="admin-items"
        >
          <ArtworkActions
            isClaimed={item.isClaimed}
            onCloseMarket={onCloseMarket}
            {...artworkActionProps}
          />
        </ArtworkListItem>
      );
    },
    [handleListItemClick, onCloseMarket]
  );

  return (
    <>
      <PageHead title="Your items" />
      <ArtworksList
        defaultView="list"
        // @ts-ignore
        items={sales}
        pending={isLoading}
        tabs={tabs}
        createButton={
          <ArtworkListCreateButton {...smartButtonProps(paths.createArtwork())}>
            New Item
          </ArtworkListCreateButton>
        }
        renderListItem={renderListItem}
      />
      <InfiniteProgress
        isOpen={progress.isVisible}
        title={progress.title}
        subtitle={progress.subtitle}
      />
      {error && <ErrorModal error={error} onClose={resetError} />}
    </>
  );
};
