import { PublicKey } from "@solana/web3.js";

import { IArtCreator } from "state/artworks";
import { toPubkey } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";
import { Wallet } from "wallet";

import { createPrimaryMetadataCreatorsTransaction } from "./createPrimaryMetadataCreatorsTransaction";

interface CreatePrimaryMetadataCreatorsParams {
  wallet: Wallet;
  creators: IArtCreator[];
  metadata: PublicKey;
}

export const createPrimaryMetadataCreators = async ({
  wallet,
  creators,
  metadata,
}: CreatePrimaryMetadataCreatorsParams): Promise<TransactionsBatch> => {
  const { createPrimaryMetadataCreatorsTx } =
    await createPrimaryMetadataCreatorsTransaction({
      wallet,
      metadata,
      creators: creators.map((creator) => ({
        ...creator,
        address: toPubkey(creator.address),
      })),
    });

  return createPrimaryMetadataCreatorsTx;
};
