import { BaseSdkProps, ETransactionProgress } from "sdk/share";
import { ICandyMachine } from "state/sales";
import { createBuy } from "./actions/createBuy";

export type BuyProps = BaseSdkProps & {
  sale: ICandyMachine;
  group?: string;
};

export const buy = async (params: BuyProps) => {
  const { group, wallet, progressChange, sale } = params;

  progressChange?.(ETransactionProgress.creating);

  const { txs, mint } = await createBuy({
    ...params,
    wallet,
    sale,
    group,
  });

  progressChange?.(ETransactionProgress.sending_to_solana);

  return {
    mint,
  };
};
