import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { FC } from "react";
import { IArt } from "state/artworks";
import { isActiveSale, ISale, isEndedSale, SaleState } from "state/sales";
import { ArtworkDetailsColumn } from "../ArtworkDetailsColumn";
import { SaleAmount } from "../SaleAmount";
import { Status } from "../Status";
import { SupplyDetails } from "./SupplyDetails";

interface Props {
  artwork: IArt;
  sale?: ISale;
}

export const AdminItemsDetails: FC<Props> = ({ sale, artwork }) => {
  const { xlUp } = useCustomBreakpoints();

  const { earnings = 0, state = SaleState.Uninitialized } = sale || {};

  if (!artwork) return null;

  const updatedState =
    (!!sale &&
      (isEndedSale(sale)
        ? SaleState.Ended
        : isActiveSale(sale)
        ? SaleState.Active
        : state)) ||
    state;

  return (
    <>
      {xlUp && (
        <ArtworkDetailsColumn title="Primary Sale">
          <SaleAmount earnings={earnings} />
        </ArtworkDetailsColumn>
      )}

      <ArtworkDetailsColumn title="Minted">
        <SupplyDetails {...artwork.prints} />
      </ArtworkDetailsColumn>

      {xlUp && <Status state={updatedState} />}
    </>
  );
};
