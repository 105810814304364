import {
  ICandyMachine,
  IFixedPrice,
  IInstantSale,
  ISale,
  ITimedAuction,
  SaleState,
  SaleType,
} from "./types";

import { IArt } from "../artworks";
import dayjs from "dayjs";
import { populateArtwork } from "sdk/loadArtworks";
import { excludesFalsy } from "utils/excludeFalsy";

export function isSale(sale: ISale | IArt): sale is ISale {
  return (sale as ISale).artwork !== undefined;
}

export function isFixedPrice(sale: ISale): sale is IFixedPrice {
  return sale.type === SaleType.FixedPrice;
}

export function isInstantSale(sale?: ISale | null): sale is IInstantSale {
  return sale?.type === SaleType.InstantSale;
}

export function isTimedAuction(sale: ISale): sale is ITimedAuction {
  return sale.type === SaleType.TimedAuction;
}

export function isCandyMachine(sale: ISale): sale is ICandyMachine {
  return sale.type === SaleType.CandyMachine;
}

export function isEndedSale(sale: ISale): boolean {
  const endDate = getSaleEndDate(sale);
  const now = dayjs().unix();
  const isEnded = endDate ? endDate < now : false;

  return (
    isEnded ||
    sale.state === SaleState.Broken ||
    sale.state === SaleState.Ended ||
    sale.state === SaleState.Suspended ||
    sale.state === SaleState.SoldOut ||
    false
  );
}

export function isActiveSale(sale: ISale): boolean {
  if (isEndedSale(sale)) return false;

  const startDate = getSaleStartDate(sale);
  const now = dayjs().unix();
  const isStarted = startDate ? startDate <= now : true;

  return (
    isStarted && [SaleState.Active, SaleState.Created].includes(sale.state)
  );
}

export function getSaleEndDate(sale: ISale) {
  return isFixedPrice(sale) || isInstantSale(sale)
    ? sale.endDate
    : isCandyMachine(sale)
    ? sale.stopAtDate
    : undefined;
}

export function getSaleStartDate(sale: ISale) {
  return isFixedPrice(sale)
    ? sale.startDate
    : isCandyMachine(sale)
    ? sale.gate?.presale
      ? undefined
      : sale.liveAtDate
    : undefined;
}

export function getSalePrice(sale: ISale) {
  return isTimedAuction(sale) ? sale.minPrice : sale.price;
}

export const populateSaleArtwork = async (sale: ISale | null) => {
  if (!sale) return null;
  return {
    ...sale,
    artwork: await populateArtwork(sale.artwork),
  } as ISale;
};

export const populateAllSaleArtworks = async (
  pureSales: (ISale | null)[]
): Promise<ISale[]> => {
  const sales = await Promise.all(
    pureSales.map((sale) => populateSaleArtwork(sale))
  );
  return sales
    .filter(excludesFalsy)
    .sort((a, b) => (a.artwork.title > b.artwork.title ? 1 : -1));
};
