import { PublicKey } from "@solana/web3.js";
import { BaseSdkProps, ETransactionProgress } from "sdk/share";
import { sendTransactions, TransactionsBatch } from "sdk/transactions";
import { createPrimaryMetadataCreators } from "./actions/createPrimaryMetadataCreators";
import { createWithdrawTransaction } from "./actions/createWithdrawTransaction";
import { createClaimTransaction } from "./actions/createClaimTransaction";
import { IFixedPrice, isEndedSale } from "state/sales";
import { createCloseMarketTransaction } from "./actions/createCloseMarketTransaction";

export interface WithdrawProps extends BaseSdkProps {
  metadata: PublicKey;
  sale: IFixedPrice;
  market: PublicKey;
  store: PublicKey;
}

export const withdraw = async ({
  connection,
  wallet,
  progressChange,
  metadata,
  store,
  market,
  sale,
}: WithdrawProps): Promise<void> => {
  progressChange?.(ETransactionProgress.creating);

  let closeMarketTx: TransactionsBatch | undefined;

  if (!isEndedSale(sale)) {
    closeMarketTx = createCloseMarketTransaction({
      market,
      wallet,
    });
  }

  let createPrimaryMetadataCreatorsTx: TransactionsBatch | undefined;
  let primaryCreators = sale.primaryCreators;
  const creators = sale.artwork.creators;

  if (!primaryCreators.length) {
    primaryCreators = creators;

    createPrimaryMetadataCreatorsTx = await createPrimaryMetadataCreators({
      wallet,
      creators,
      metadata,
    });
  }

  const marketData = sale.refs;

  const withdrawTxs = await Promise.all(
    primaryCreators.map((creator) =>
      createWithdrawTransaction({
        creator,
        wallet,
        metadata,
        marketData,
        market,
      })
    )
  );

  const claimTx = await createClaimTransaction({
    wallet,
    marketData,
    market,
    metadata,
    store,
  });

  progressChange?.(ETransactionProgress.sending_to_solana);
  await sendTransactions(connection, wallet, [
    closeMarketTx,
    createPrimaryMetadataCreatorsTx,
    claimTx,
    ...withdrawTxs,
  ]);
};
