import "./DateTimePicker.css";

import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  StyleProps,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import {
  Timezone,
  localToSelectedTimezone,
  selectedToLocalTimezone,
} from "./utils";

import { DatePickerCustom } from "./DatePickerCustom";
import { DateTimeCustom } from "./DateTimeCustom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { fontSizes } from "theme/typography";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface Props extends StyleProps {
  date: Date;
  minDate: Date;
  onChange: (date: Date) => void;
  displayTimeZone?: boolean;
}

export const DateTimePicker: React.FC<Props> = ({
  minDate,
  onChange,
  date: _date,
  displayTimeZone,
  ...props
}) => {
  const date = useMemo(
    () => (_date instanceof Date ? _date : new Date()),
    [_date]
  );

  const { mdUp } = useCustomBreakpoints();
  const [timezone, setTimezone] = useState<Timezone>("UTC");

  const handleDateChange = useCallback(
    (_date: Date) => onChange(selectedToLocalTimezone(_date, timezone)),
    [onChange, timezone]
  );

  const internalDate = useMemo(
    () => localToSelectedTimezone(date, timezone),
    [date, timezone]
  );

  return (
    <Flex direction={mdUp ? "row" : "column"} align="stretch" {...props}>
      <Box flexBasis={50} flexGrow={1}>
        <DatePickerCustom
          minDate={minDate}
          date={internalDate}
          dateChange={handleDateChange}
        />
      </Box>

      <HStack
        spacing={4}
        flexBasis={50}
        flexGrow={1}
        ml={mdUp ? 4 : 0}
        mt={mdUp ? 0 : 4}
      >
        <Box flexGrow={1}>
          <DateTimeCustom
            minDate={minDate}
            date={internalDate}
            dateChange={handleDateChange}
          />
        </Box>

        {displayTimeZone && (
          <Box>
            <Menu gutter={8} autoSelect={false}>
              <MenuButton
                as={Button}
                variant="tertiary"
                bgColor="whiteAlpha.50"
                size="lg"
                justifyContent="start"
                textAlign="start"
                width="100%"
                rightIcon={<MdKeyboardArrowDown size={fontSizes["2xl"]} />}
              >
                {timezone}
              </MenuButton>
              <MenuList zIndex={10}>
                <MenuItem onClick={() => setTimezone("UTC")}>UTC</MenuItem>
                <MenuItem onClick={() => setTimezone("PDT")}>PDT</MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}
      </HStack>
    </Flex>
  );
};
