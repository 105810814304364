import { Flex, MenuList } from "@chakra-ui/react";
import { RiDiscordFill, RiTwitterFill } from "react-icons/ri";

import { ResourcesMenuItem } from "./ResourcesMenuItem";
import { SidebarMenuItem } from "components/Sidebar/SidebarMenuItem";
import { fontSizes } from "theme/typography";
import { usePaths } from "routes";

interface Props {
  variant?: "menu" | "sidebar";
}

export const ResourcesList: React.FC<Props> = ({ variant }) => {
  const paths = usePaths();

  if (variant === "sidebar") {
    return (
      <Flex direction="column" p={2}>
        <SidebarMenuItem variant="secondary" as="a" href={paths.artistAlley()}>
          Artist alley
        </SidebarMenuItem>
        <SidebarMenuItem
          variant="secondary"
          as="a"
          href="https://twitter.com/OddKeyNFT"
          target="_blank"
          icon={<RiTwitterFill size={fontSizes["2xl"]} />}
        >
          Twitter
        </SidebarMenuItem>
        <SidebarMenuItem
          variant="secondary"
          as="a"
          href="https://discord.com/invite/oddkeynft"
          target="_blank"
          icon={<RiDiscordFill size={fontSizes["2xl"]} />}
        >
          Discord
        </SidebarMenuItem>
      </Flex>
    );
  }

  return (
    <MenuList pos="relative">
      <ResourcesMenuItem as="a" href={paths.artistAlley()}>
        Artist alley
      </ResourcesMenuItem>
      <ResourcesMenuItem
        as="a"
        href="https://twitter.com/OddKeyNFT"
        target="_blank"
        icon={<RiTwitterFill size={fontSizes["2xl"]} />}
      >
        Twitter
      </ResourcesMenuItem>
      <ResourcesMenuItem
        as="a"
        href="https://discord.com/invite/oddkeynft"
        target="_blank"
        icon={<RiDiscordFill size={fontSizes["2xl"]} />}
      >
        Discord
      </ResourcesMenuItem>
    </MenuList>
  );
};
