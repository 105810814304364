import { getWalletSplBalanceFx } from "state/balance";
import { useStore } from "effector-react";
import { useEffect, useMemo } from "react";
import { attach, createEvent, restore, sample } from "effector";
import { $wallet } from "state/wallet";

export const useSplBalance = (token = "") => {
  const { $balance, $pending, getBalance } = useMemo(() => {
    const getBalanceFx = attach({ effect: getWalletSplBalanceFx });
    const getBalance = createEvent();
    sample({
      clock: [getBalance, $wallet],
      fn: () => ({ token }),
      target: getBalanceFx,
    });

    const $balance = restore(getBalanceFx.doneData, null);
    const $pending = getBalanceFx.pending;
    return { $balance, $pending, getBalance };
  }, [token]);

  useEffect(() => {
    if (!token) return;
    getBalance();
  }, [getBalance, token]);

  const balance = useStore($balance);
  const pending = useStore($pending);

  return { balance, pending };
};
