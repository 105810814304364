import React, { useCallback } from "react";
import { Button, HStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { IArt } from "state/artworks";
import {
  ISale,
  isEndedSale,
  isFixedPrice,
  SaleState,
  UnixTimestamp,
} from "state/sales";

import { usePaths } from "routes";

interface Props {
  artwork: IArt;
  sale?: ISale;
  state: SaleState;
  endDate?: UnixTimestamp;
  isSoldOut?: boolean;
  isClaimed?: boolean;
  onClaim?: (params: { market: string; metadata: string }) => Promise<void>;
  onCloseMarket: (sale: ISale, metadata: string) => Promise<unknown>;
}

export const ArtworkActions: React.FC<Props> = ({
  artwork,
  sale,
  state,
  endDate,
  isSoldOut,
  isClaimed,
  onCloseMarket,
}) => {
  const paths = usePaths();
  const isEnded = sale && isFixedPrice(sale) && isEndedSale(sale);

  const shouldRenderSell = state === SaleState.Uninitialized && !isSoldOut;
  const shouldRenderEndSale =
    !endDate && [SaleState.Active, SaleState.Created].includes(state);
  const shouldRenderWithdraw = isEnded && (!sale.isWithdrawn || !isClaimed);

  const handleEndSale = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      if (!sale?.id) return;

      onCloseMarket?.(sale, artwork.accountAddress);
    },
    [sale, onCloseMarket, artwork.accountAddress]
  );

  return (
    <HStack
      spacing={2}
      className="buttons"
      alignSelf="stretch"
      alignItems="center"
      ml="auto"
    >
      {shouldRenderSell && (
        <Button
          w="125px"
          variant="tertiary"
          as={Link}
          to={`${paths.createSale()}?artworkId=${artwork.id}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Sell
        </Button>
      )}

      {(shouldRenderEndSale || shouldRenderWithdraw) && (
        <Button variant="tertiary" onClick={handleEndSale} w="125px">
          {shouldRenderWithdraw ? "Withdraw" : "End Sale"}
        </Button>
      )}
    </HStack>
  );
};
