import { combine } from "effector";
import { $allSales, isEndedSale, isTimedAuction } from "state/sales";
import { $walletAddress } from "../wallet";

export const $unsettledSales = combine(
  $walletAddress,
  $allSales,
  (walletAddress, allSales) =>
    allSales.filter(
      (sale) =>
        isEndedSale(sale) &&
        !sale.isWithdrawn &&
        !isTimedAuction(sale) &&
        sale.refs.seller === walletAddress
    )
);
