import { Button, Heading, HStack } from "@chakra-ui/react";
import { CopyButton } from "components/buttons/CopyButton";
import { useOpenSolanaExplorer } from "hooks/useOpenSolanaExplorer";
import { useShortAddress } from "hooks/useShortAddress";
import React from "react";

interface CollectionBlock {
  address: string;
}

export const CollectionBlock: React.FC<CollectionBlock> = ({
  address,
}: CollectionBlock) => {
  const shortAddress = useShortAddress(address);
  const openExplorer = useOpenSolanaExplorer();

  return (
    <HStack
      spacing={4}
      p={4}
      pl={6}
      bgColor="whiteAlpha.50"
      borderRadius={8}
      alignItems="center"
      justifyContent="space-between"
    >
      <HStack>
        <Heading variant="h4">{shortAddress}</Heading>
        <CopyButton
          text={address}
          variant="ghost"
          size="sm"
          color="whiteAlpha.500"
        />
      </HStack>

      <Button variant="tertiary" onClick={() => openExplorer(address)}>
        View on Explorer
      </Button>
    </HStack>
  );
};
