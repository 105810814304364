import { Flex, FlexProps } from "@chakra-ui/react";
import React from "react";
import { ImageStepper } from "./ImageStepper";

interface Props extends FlexProps {
  showArrow?: boolean;
  coverImage?: string;
  onNextClick?: () => void;
  onPrevClick?: () => void;
  activeIdx?: number;
}

export const DesktopHeroWrapper: React.FC<Props> = ({
  showArrow = false,
  coverImage,
  children,
  ...props
}) => (
  <Flex
    position="relative"
    direction="column"
    justify="end"
    borderRadius="xl"
    h={528}
    minH={528}
    w="full"
    maxW={1600}
    mx="auto"
    py={16}
    pl={20}
    pr={{ base: "40%", xl: "40%", lg: "20%", md: 24 }}
    bgImage={`url("${coverImage ?? ""}")`}
    backgroundSize="cover"
    backgroundPosition="center center"
    {...props}
  >
    {showArrow && <ImageStepper {...props} />}
    {children}
  </Flex>
);
