import { StoreFormProps } from "components/forms/StoreCreateForm";
import dayjs from "dayjs";
import { useStore, useStoreMap } from "effector-react";
import { useCallback, useMemo } from "react";
import { EUploadProgress } from "sdk/nft/uploadJson2Arweave";
import { $store, submitStoreFx } from "state/store";
import { createProgressTools } from "utils/createProgressTools";

type TState = EUploadProgress | null | "updateSettings";

function getContent(state: TState) {
  switch (state) {
    case EUploadProgress.preparing_assets:
      return {
        title: "Preparing Assets",
        subtitle: "",
      };
    case EUploadProgress.signing_metadata_transaction:
      return {
        title: "Signing Metadata Transaction",
        subtitle: "Approve the transaction from your wallet",
      };
    case EUploadProgress.sending_transaction_to_solana:
      return {
        title: "Sending Transaction to Solana",
        subtitle: "This will take a few seconds",
      };
    case EUploadProgress.uploading_to_arweave:
      return {
        title: "Uploading to Arweave",
        subtitle: "",
      };
    case "updateSettings":
      return {
        title: "Update Store configuration",
      };

    default:
      return {
        title: "",
        subtitle: "",
      };
  }
}

export function useLocalState() {
  const ref = useMemo(() => {
    const { $progressMeta, $progress } = createProgressTools(
      getContent,
      null as TState
    );

    return {
      $progressMeta,
      $progress,
    };
  }, []);

  const form = useStoreMap($store, (store) => {
    const ssd = dayjs.unix(store?.salesStartDate as number);
    return {
      ...store,
      name: store?.name ?? "",
      salesStartDate: ssd.isValid() ? ssd.toDate() : undefined,
    };
  });
  const progressMeta = useStore(ref.$progressMeta);

  const submitForm = useCallback(
    async (data: StoreFormProps) => {
      try {
        await submitStoreFx({
          data,
          updateProgress: (state) => ref.$progress.set(state),
        });
        ref.$progress.set("updateSettings");
        ref.$progress.set(null);
      } catch (err) {
        ref.$progress.set(null);
        throw err;
      }
    },
    [ref.$progress]
  );

  return { form, progressMeta, submitForm };
}
