import { useStore } from "effector-react";

import {
  $notifications,
  addNotification,
  clearNotificationList,
  removeNotification,
} from "state/notifications";

export const useNotifications = () => {
  const notifications = useStore($notifications);

  return {
    addNotification,
    clearNotificationList,
    notifications,
    removeNotification,
  };
};
