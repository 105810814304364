import {
  Box,
  BoxProps,
  Flex,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import React from "react";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface Props extends BoxProps {
  children?: React.ReactNode;
  image: string;
  title: string;
  text: string;
  imagePosition?: "left" | "right";
  withShadow?: boolean;
}

export const CustomizedHero: React.FC<Props> = ({
  children,
  image,
  title,
  text,
  imagePosition = "left",
  withShadow,
  ...props
}: Props) => {
  const { mdUp } = useCustomBreakpoints();

  const getPosition = (side: "left" | "right") => {
    switch (side) {
      case "left":
        if (imagePosition === "left") return 0;
        return withShadow ? "30%" : "50%";
      case "right":
        if (imagePosition === "right") return 0;
        return withShadow ? "30%" : "50%";
    }
  };

  return (
    <HStack
      flexDirection={imagePosition === "left" ? "row" : "row-reverse"}
      spacing={0}
      borderRadius={12}
      overflow={mdUp ? "hidden" : "unset"}
      position="relative"
      bgColor={mdUp ? "gray.900" : "transparent"}
      w="full"
      h={mdUp ? 528 : "100%"}
      {...props}
    >
      {mdUp && (
        <Flex flex={1}>
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={imagePosition === "left" ? getPosition("left") : undefined}
            right={imagePosition === "right" ? getPosition("right") : undefined}
            _before={{
              display: withShadow ? "block" : "none",
              content: `""`,
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              boxShadow: `inset ${
                imagePosition === "left" ? "-" : ""
              }14.5rem 0 11.5rem 0 var(--chakra-colors-gray-900)`,
            }}
          >
            <Image src={image} h="full" w="full" objectFit="cover" />
          </Box>
        </Flex>
      )}

      <Box zIndex={2} position="relative" flex={1}>
        <VStack
          p={mdUp ? 16 : 16}
          px={mdUp ? undefined : 0}
          minH={mdUp ? undefined : "375px"}
          spacing={6}
          alignItems="start"
        >
          <Heading whiteSpace="break-spaces" variant={mdUp ? "h2" : "h3"}>
            {title}
          </Heading>

          <Text
            variant={mdUp ? "body-large" : "subtitle-2"}
            whiteSpace="pre-wrap"
          >
            {text}
          </Text>
          {children && (
            <Stack
              direction={mdUp ? "row" : "column"}
              spacing={mdUp ? 4 : 2}
              mt={8}
              w="full"
              flexWrap="wrap"
            >
              {children}
            </Stack>
          )}
        </VStack>
      </Box>
    </HStack>
  );
};
