import { Controller, useFormContext } from "react-hook-form";
import { FormControl, Input, Text } from "@chakra-ui/react";

import React from "react";
import { VStack } from "@chakra-ui/layout";

interface CollectionAddressCellProps {
  id: string;
  title?: string;
  placeholder?: string;
  isInvalid?: boolean;
}

export const CollectionAddressCell: React.FC<CollectionAddressCellProps> = ({
  id,
  title = "",
  placeholder = "",
  isInvalid = false,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <FormControl id={id} {...props}>
      <VStack alignItems="flex-start" spacing={title ? 2 : 0}>
        <Text fontSize={14} fontWeight={600} color="whiteAlpha.500">
          {title.toUpperCase()}
        </Text>
        <Controller
          name={id}
          rules={{
            required: true,
            minLength: 1,
          }}
          control={control}
          render={({ field: { ref, ...restField } }) => (
            <Input
              p={3}
              w="100%"
              isInvalid={isInvalid}
              ref={ref}
              flexGrow={1}
              placeholder={placeholder}
              {...restField}
            />
          )}
        />
      </VStack>
    </FormControl>
  );
};
