import { Button } from "@chakra-ui/react";
import { AddressButton } from "components/buttons/AddressButton";
import { OperationSuccess } from "components/OperationSuccess";
import { useOpenSolanaExplorer } from "hooks/useOpenSolanaExplorer";
import React from "react";
import { Link } from "react-router-dom";
import { usePaths } from "routes";
import { IArt } from "state/artworks";

interface Props {
  artwork: IArt | null;
}

export const OperationSuccessNft: React.FC<Props> = ({ artwork }) => {
  const openExplorer = useOpenSolanaExplorer();
  const ownerAddress = artwork?.creators[0].address || "";
  const paths = usePaths();

  const args: React.ComponentProps<typeof OperationSuccess> = {
    variant: "nft",
    artwork: artwork ? artwork : undefined,
    emoji: "🎉",
    title: "Your NFT has been minted!",
    subtitle: (
      <>
        Congratulations, your artwork has officially been minted on the Solana
        blockchain with Metaplex using the wallet
        <AddressButton
          address={ownerAddress}
          variant="link"
          fontWeight="bold"
          fontSize="sm"
          size="xs"
          pl="1"
        />
      </>
    ),
    actions: (
      <>
        <Button
          variant="primary"
          flexGrow={1}
          as={Link}
          to={
            artwork
              ? `${paths.createSale()}?artworkId=${artwork?.id}`
              : paths.createSale()
          }
        >
          List for sale
        </Button>
        <Button
          variant="tertiary"
          flexGrow={1}
          onClick={() => openExplorer(ownerAddress)}
        >
          View on Solana
        </Button>
      </>
    ),
  };

  return <OperationSuccess {...args} />;
};
