import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/react";

import { AddressCell } from "../AddressCell";
import { CreateButton } from "components/buttons/CreateButton";
import { FC } from "react";
import { useFieldArray } from "react-hook-form";

interface CreatorsSplitsProps {
  type: "secondarySplits" | "splits";
  title?: string;
  text?: string;
}

export const CreatorsSplits: FC<CreatorsSplitsProps> = ({
  type,
  title = "CREATOR SPLITS",
  text,
}) => {
  const { fields, append, remove } = useFieldArray({
    name: type,
  });
  const totalLength = fields?.length;
  const isSecondary = type === "secondarySplits";

  return (
    <FormControl id={type}>
      <FormLabel>{title?.toUpperCase()}</FormLabel>
      <FormHelperText>{text}</FormHelperText>

      {fields.map((field, index) => (
        <AddressCell
          key={field.id}
          id={index}
          shouldShowButton={totalLength > 1}
          onDelete={remove}
          isSecondary={isSecondary}
        />
      ))}

      <CreateButton
        w={156}
        title={totalLength >= 5 ? "Max 5 creators are allowed" : "create"}
        mt={fields.length === 0 ? 0 : 4}
        isDisabled={totalLength >= 5}
        onClick={() => append({ key: "", value: 0, total: 0, isOwner: false })}
        variant="tertiary"
        fontSize="md"
      >
        Add Creator
      </CreateButton>
    </FormControl>
  );
};
