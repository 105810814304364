export function prepareFiles(
  file: File | string,
  preview?: File | string
): File[] {
  const files: File[] = [];

  if (file instanceof File) {
    files.push(file);
  }

  if (preview instanceof File) {
    files.push(preview);
  }

  return files;
}

export function prepareImageUrl(
  file: File | string,
  preview?: File | string
): string {
  if (preview instanceof File) {
    return URL.createObjectURL(preview);
  } else if (typeof preview === "string") {
    return preview;
  }

  if (file instanceof File) {
    return URL.createObjectURL(file);
  } else {
    return file;
  }
}
