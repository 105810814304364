import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Text,
} from "@chakra-ui/react";

import { SolanaIcon } from "components/Icons";
import { forwardRef } from "react";

export const PriceInput = forwardRef<HTMLInputElement, InputProps>(
  (inputProps, ref) => (
    <InputGroup>
      <Input
        ref={ref}
        min={0}
        pl={14}
        step="any"
        placeholder="Price"
        {...inputProps}
      />
      <InputLeftElement
        pointerEvents="none"
        children={<SolanaIcon h="24px" w="24px" boxSize={9} p={1} />}
      />
      <InputRightElement pointerEvents="none" children={<Text>SOL</Text>} />
    </InputGroup>
  )
);
