import { attach, createEffect, StoreValue } from "effector";
import { endSale } from "sdk/auction-house/endSale";

import { $connection } from "state/connection";
import { $store } from "state/store";
import { $wallet } from "state/wallet";

import { IInstantSale } from "../types";

export interface EndSaleFxParams {
  sale: IInstantSale;
}

export interface EndSaleFxSource {
  connection: StoreValue<typeof $connection>;
  store: StoreValue<typeof $store>;
  wallet: StoreValue<typeof $wallet>;
}

export const endSaleFx = attach({
  effect: createEffect(
    async ({
      connection,
      sale,
      store,
      wallet,
    }: EndSaleFxParams & EndSaleFxSource) => {
      if (!store || !wallet) {
        throw new Error("Empty store or wallet!");
      }

      return await endSale({
        auctionHouseId: store.storeAhId,
        connection,
        sale,
        wallet,
      });
    }
  ),
  source: {
    connection: $connection,
    wallet: $wallet,
    store: $store,
  },
  mapParams(data: EndSaleFxParams, source) {
    return { ...data, ...source };
  },
});
