import { BoxProps, Text } from "@chakra-ui/react";
import { CreateButton } from "components/buttons/CreateButton";
import { HaloedIcon } from "components/HaloedIcon";
import React from "react";
import { useNavigate } from "react-router-dom";
import { usePaths } from "routes";
import { FlowStatus } from "../FlowStatus";

interface Props extends BoxProps {
  storeName: string;
  storeId: string;
  onClose?: () => void;
}

export const StoreCreateCongratulations: React.FC<Props> = ({
  storeName,
  storeId,
  onClose,
  ...props
}) => {
  const navigate = useNavigate();
  const paths = usePaths();

  return (
    <FlowStatus
      statusIcon={<HaloedIcon emoji="🎉" />}
      title="Congratulations"
      subtitle={
        <>
          <Text as="span" variant="body-large">
            {storeName}{" "}
          </Text>
          <Text as="span" variant="body-large" color="whiteAlpha.500">
            Storefront is live! Create a new item and list it for sale.
          </Text>
        </>
      }
      actions={
        <CreateButton
          onClick={() => {
            if (storeId) {
              onClose?.();
              navigate(
                paths.createArtwork({
                  ":storeId": storeId,
                })
              );
            }
          }}
          variant="solid"
          size="lg"
        >
          {" "}
          Create New Item
        </CreateButton>
      }
      {...props}
    />
  );
};
