import { Button, HStack, IconButton, Link } from "@chakra-ui/react";
import React, { ReactNode, useCallback } from "react";
import { RiDiscordFill, RiTwitterFill } from "react-icons/ri";

import { DashboardButton } from "../DashboardButton";
import { Hamburger } from "../Hamburger";
import { NavbarNotifications } from "../NavbarNotifications";
import { NetworkSelect } from "../NetworkSelect";
import { PersonProps } from "components/Person";
import { ProfileButton } from "../ProfileButton";
import { Link as RouterLink } from "react-router-dom";
import { setHowToBuyModalState } from "state/store/howToBuyModal";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { useEvent } from "effector-react";
import { usePaths } from "routes";

export interface NavbarActionsProps {
  user?: (PersonProps & { notifications?: number }) | null;
  cta?: ReactNode;
  onToggleSidebar?: () => void;
}

export const NavbarActions: React.FC<NavbarActionsProps> = ({
  user,
  cta,
  onToggleSidebar,
}) => {
  const { mdUp } = useCustomBreakpoints();
  const setModalState = useEvent(setHowToBuyModalState);
 // const setModalState2 = useEvent(setFAQState);
  const paths = usePaths();

  const handleOpenHowToBuyModal = useCallback(
    () => setModalState({ isOpen: true }),
    [setModalState]
  );

  //const handleOpenFAQModal = useCallback(
  //  () => setModalState2({ isOpen: true }),
  //  [setModalState2]
 // );

  return (
    <HStack>
      {mdUp && (
        <IconButton
          aria-label="Twitter"
          as={Link}
          href="https://twitter.com/OddKeyNFT"
          isExternal
          icon={<RiTwitterFill />}
          fontSize="2xl"
          color="whiteAlpha.700"
          isRound
          variant="ghost"
        />
      )}

      {mdUp && (
        <IconButton
          aria-label="Discord"
          as={Link}
          href="https://discord.gg/mcfarlane"
          isExternal
          icon={<RiDiscordFill />}
          fontSize="2xl"
          color="whiteAlpha.700"
          isRound
          variant="ghost"
        />
      )}

      {mdUp && (
        <Button
          onClick={handleOpenHowToBuyModal}
          variant="ghost"
          color="whiteAlpha.500"
        >
          How to buy
        </Button>
      )}

      {/*todo: add link to artist alley*/}
      {mdUp && (
        <a href="https://magiceden.io/creators/oddkey"><Button
          variant="ghost"
          color="whiteAlpha.500"
        >
          Secondary Market
        </Button></a>
      )}

      {mdUp && (
        <a href="https://www.anybodies.com/c/OddKey">
          <Button
          variant="ghost"
          color="whiteAlpha.500"
        >
          Staking
        </Button></a>
      )}

      {/*mdUp && (
        <Button
         onClick={handleOpenFAQModal}
          variant="ghost"
          color="whiteAlpha.500"
        >
          FAQ
        </Button>
      )*/}

      {mdUp && <NetworkSelect />}
      {!mdUp && user && cta}
      {user && <NavbarNotifications notifications={user?.notifications} />}
      <ProfileButton user={user} />
      {mdUp && user && cta}
      {!mdUp && <Hamburger aria-label="Explore" onClick={onToggleSidebar} />}
    </HStack>
  );
};
