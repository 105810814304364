import { Button } from "@chakra-ui/button";
import { ComponentProps } from "react";
import { IconType } from "react-icons/lib";
import { fontSizes } from "theme/typography";
import { MdAdd } from "react-icons/md";

interface CreateButtonProps extends ComponentProps<typeof Button> {
  currentView?: "grid" | "list";
  LeftIcon?: IconType | null;
  RightIcon?: IconType;
}

export const CreateButton: React.FC<CreateButtonProps> = ({
  LeftIcon = MdAdd,
  RightIcon,
  children,
  ...rest
}) => {
  return (
    <Button
      leftIcon={
        LeftIcon ? LeftIcon && <LeftIcon size={fontSizes["2xl"]} /> : undefined
      }
      rightIcon={RightIcon && <RightIcon size={fontSizes["2xl"]} />}
      variant="primary"
      flexShrink={0}
      {...rest}
    >
      {children}
    </Button>
  );
};
