import { Button, Link } from "@chakra-ui/react";

import { $store } from "state/store";
import { AddressButton } from "components/buttons/AddressButton";
import { IArt } from "state/artworks";
import { OperationSuccess } from "components/OperationSuccess";
import React from "react";
import { useOpenSolanaExplorer } from "hooks/useOpenSolanaExplorer";
// import { usePaths } from "routes";
import { useStore } from "effector-react";

interface Props {
  artwork?: IArt | null;
}

export const PurchaseSuccess: React.FC<Props> = ({ artwork }) => {
  const openExplorer = useOpenSolanaExplorer();
  const { name } = useStore($store) || { name: "Metaplex" };
  const artworkAddress = artwork?.mint || "";
  // const paths = usePaths();
  const onClose = () => window.location.reload();

  const args: React.ComponentProps<typeof OperationSuccess> = {
    variant: "nft",
    artwork,
    emoji: "🎉",
    title: `You purchased ${artwork?.title || ""}`,
    subtitle: (
      <>
        Congratulations, you successfully purchased this item from {name}
        <AddressButton
          address={artworkAddress}
          variant="link"
          fontWeight="bold"
          fontSize="sm"
          size="xs"
          pl="1"
        />
      </>
    ),
    actions: (
      <>
        <Button variant="tertiary" flexGrow={1} onClick={onClose}>
          Back to Sale Page
        </Button>
      </>
    ),
    mx: "auto",
  };

  return <OperationSuccess {...args} />;
};
