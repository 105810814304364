import { Box, Button, Heading, HStack, Flex } from "@chakra-ui/react";
import { CopyButton } from "components/buttons/CopyButton";
import { TitledBlock } from "components/TitledBlock";
import { useOpenSolanaExplorer } from "hooks/useOpenSolanaExplorer";
import { useShortAddress } from "hooks/useShortAddress";
import React from "react";

interface Props {
  address: string;
  percentage: number;
  totalPercentage: number;
}

export const CreatorSplitPreview: React.FC<Props> = ({
  address,
  totalPercentage,
  percentage,
}) => {
  const openExplorer = useOpenSolanaExplorer();
  const shortAddress = useShortAddress(address);

  return (
    <Box layerStyle="base" p={3} pl={6}>
      <Flex alignItems="center" mb={4}>
        <HStack>
          <Heading variant="h4">{shortAddress}</Heading>
          <CopyButton
            text={address}
            variant="ghost"
            size="sm"
            color="whiteAlpha.500"
          />
        </HStack>
        <Button
          ml="auto"
          size="md"
          variant="tertiary"
          onClick={() => openExplorer(address)}
        >
          View on Explorer{" "}
        </Button>
      </Flex>
      <Flex>
        <TitledBlock title="PERCENTAGE">{percentage}%</TitledBlock>
        <TitledBlock
          title="PERCENT OF TOTAL"
          ml="72px"
          pl={6}
          borderLeft="1px solid"
          borderColor="whiteAlpha.100"
        >
          {totalPercentage.toFixed(2)}%
        </TitledBlock>
      </Flex>
    </Box>
  );
};
