import { Button } from "@chakra-ui/react";
import { AddressButton } from "components/buttons/AddressButton";
import { OperationSuccess } from "components/OperationSuccess";
import { useStore } from "effector-react";
import { useOpenSolanaExplorer } from "hooks/useOpenSolanaExplorer";
import { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { usePaths } from "routes";
import { isTimedAuction } from "state/sales";
import { $walletAddress } from "state/wallet";
import { createSaleFactory } from "./CreateSaleView.state";

export const SaleSuccessfullyCreated: FC = () => {
  const { $createdSale, $createSalePending } = createSaleFactory.useModel();
  const wallet = useStore($walletAddress);
  const sale = useStore($createdSale);
  const pending = useStore($createSalePending);
  const openExplorer = useOpenSolanaExplorer();
  const paths = usePaths();

  const displayedTitle = useMemo(() => {
    if (sale && isTimedAuction(sale)) {
      return "Your auction is now live!";
    } else {
      return "Your sale is now live!";
    }
  }, [sale]);

  if (!sale && !pending) {
    return null;
  }

  return (
    <OperationSuccess
      variant="sale"
      sale={sale ? sale : undefined}
      emoji="✨"
      title={displayedTitle}
      subtitle={
        <>
          Your auction has officially started! Listed with wallet
          <AddressButton
            address={sale?.artwork.ownerAddress || wallet || ""}
            variant="link"
            fontWeight="bold"
            fontSize="sm"
            size="xs"
            pl="1"
          />
        </>
      }
      actions={
        <>
          <Button
            variant="primary"
            flexGrow={1}
            as={Link}
            to={paths.sale({ ":saleId": sale?.id || "" })}
            disabled={!sale}
          >
            View Sale
          </Button>
          <Button
            variant="tertiary"
            flexGrow={1}
            onClick={() => openExplorer(sale?.artwork.ownerAddress || wallet)}
          >
            View on Solana
          </Button>
        </>
      }
    />
  );
};
