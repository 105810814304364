import {
  createCreateMasterEditionV3Instruction,
  createCreateMetadataAccountV2Instruction,
  DataV2,
} from "@metaplex-foundation/mpl-token-metadata";
import { Cluster } from "@solana/web3.js";
import { AddressRow } from "components/forms/NftCreate";
import { createPrimaryMetadataCreators } from "sdk/fps/actions/createPrimaryMetadataCreators";
import { BaseSdkProps, toPubkey } from "sdk/share";
import { sendTransactions, TransactionsBatch } from "sdk/transactions";
import { IArtCreator } from "state/artworks";
import { parseBN } from "utils/parseBN";
import { prepareTokenAccountAndMintTxs } from "./actions/prepareTokenAccountAndMintTxs";
import { MetadataJson } from "./types";
import { uploadFiles2Arweave } from "./uploadJson2Arweave";
import { findEditionAddress, findMetadataAddress } from "./utils";

export enum ENftProgress {
  minting,
  preparing_assets,
  signing_metadata_transaction,
  sending_transaction_to_solana,
  waiting_for_initial_confirmation,
  waiting_for_final_confirmation,
  uploading_to_arweave,
  updating_metadata,
  signing_token_transaction,
  loading_account,
}

export interface IMintArweaveParams extends BaseSdkProps<ENftProgress> {
  network: Cluster;
  files: File[];
  metadata: MetadataJson;
  maxSupply: number;
  splits: AddressRow[];
}

export async function mintArweaveNFT({
  connection,
  wallet,
  files,
  metadata,
  maxSupply,
  network,
  progressChange,
  splits,
}: IMintArweaveParams) {
  progressChange?.(ENftProgress.preparing_assets);
  const meta = JSON.stringify(metadata);

  const { mint, tx: mintToTx } = await prepareTokenAccountAndMintTxs(
    connection,
    wallet.publicKey
  );

  const { fileUri } = await uploadFiles2Arweave({
    connection,
    wallet,
    meta,
    files,
    mintKey: mint.publicKey.toString(),
    network,
  });

  const [metadataPDA] = await findMetadataAddress(mint.publicKey);

  const walletAddress = wallet.publicKey.toString();
  const creators = metadata.properties.creators.map((c) => ({
    ...c,
    verified: c.address === walletAddress,
    address: toPubkey(c.address),
  }));
  const data: DataV2 = {
    name: metadata.name,
    symbol: metadata.symbol,
    uri: fileUri,
    sellerFeeBasisPoints: metadata.seller_fee_basis_points,
    creators,
    collection: null,
    uses: null,
  };

  const createMetadataIx = createCreateMetadataAccountV2Instruction(
    {
      metadata: metadataPDA,
      mint: mint.publicKey,
      mintAuthority: wallet.publicKey,
      updateAuthority: wallet.publicKey,
      payer: wallet.publicKey,
    },
    {
      createMetadataAccountArgsV2: { data, isMutable: true },
    }
  );

  const [editionPda] = await findEditionAddress(mint.publicKey);

  const createMasterEditionIx = createCreateMasterEditionV3Instruction(
    {
      edition: editionPda,
      mint: mint.publicKey,
      updateAuthority: wallet.publicKey,
      mintAuthority: wallet.publicKey,
      payer: wallet.publicKey,
      metadata: metadataPDA,
    },
    {
      createMasterEditionArgs: {
        maxSupply: maxSupply || null,
      },
    }
  );

  const creatorsPrimary: IArtCreator[] = splits.map((item) => ({
    address: item.key,
    share: parseBN(item.value),
    verified: Boolean(item.isOwner),
  }));

  const createPrimaryMetadataCreatorsTx = await createPrimaryMetadataCreators({
    wallet,
    metadata: metadataPDA,
    creators: creatorsPrimary,
  });

  progressChange?.(ENftProgress.signing_token_transaction);

  const createMetadataTx = new TransactionsBatch({
    instructions: [createMetadataIx, createMasterEditionIx],
  });

  await sendTransactions(connection, wallet, [
    mintToTx,
    createMetadataTx,
    createPrimaryMetadataCreatorsTx,
  ]);

  return {
    mint: mint.publicKey,
    metadata: metadataPDA,
  };
}
