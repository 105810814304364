import {
  createCreateStoreInstruction,
  CreateStoreInstructionArgs,
} from "@metaplex-foundation/mpl-fixed-price-sale";
import { Wallet } from "wallet";
import { Keypair } from "@solana/web3.js";
import { TransactionsBatch } from "sdk/transactions";

type Props = Partial<CreateStoreInstructionArgs> & {
  wallet: Wallet;
};

export const createStoreTransaction = ({
  name = "",
  description = "",
  wallet,
}: Props) => {
  const store = Keypair.generate();

  const ix = createCreateStoreInstruction(
    {
      store: store.publicKey,
      admin: wallet.publicKey,
    },
    {
      name,
      description,
    }
  );

  const storeTx = new TransactionsBatch({
    instructions: [ix],
    signers: [store],
  });

  return { store: store.publicKey, storeTx };
};
