import { Button, Link } from "@chakra-ui/react";
import { HaloedIcon } from "components/HaloedIcon";
import { PurchaseConfirm } from "components/modals/PurchaseNft/PurchaseConfirm";
import { ModalTemplate } from "components/modals/template";
import { DialogHeader } from "components/PurchaseDialog/DialogHeader";
import { FC, useCallback } from "react";
import { MdCheck, MdOutlineThumbDown } from "react-icons/md";
import { ISale } from "state/sales";
import { FlowStatus } from "../FlowStatus";
// import { smartButtonProps } from "utils/smartButtonProps";
import { useLocalState } from "./EndSale.state";

// import { usePaths } from "routes";

interface Props {
  isOpen: boolean;
  sale?: ISale | null;
  onClose: () => void;
}

export const EndSaleModal: FC<Props> = ({ sale, isOpen, onClose }) => {
  const { handleEndSale, status } = useLocalState(sale);

  const handleClose = useCallback(
    () => status !== "pending" && onClose(),
    [status, onClose]
  );

  // const paths = usePaths();

  if (!sale) {
    return null;
  }

  return (
    <ModalTemplate
      header={<DialogHeader title="End sale" onClose={handleClose} />}
      isOpen={isOpen}
      onClose={handleClose}
      bodyProps={{ p: 0 }}
    >
      {status === "error" && (
        <FlowStatus
          statusIcon={<HaloedIcon icon={MdOutlineThumbDown} />}
          title="Failed to end sale"
        />
      )}

      {status === "done" && (
        <FlowStatus
          statusIcon={<HaloedIcon icon={MdCheck} />}
          title="Your have cancelled the listing"
          subtitle="List for sale again or close modal to view the sale"
        >
          <Button
            variant="primary"
            size="lg"
            // {...smartButtonProps(
            //   paths.artwork({
            //     ":artworkId": sale.artwork.id,
            //   })
            // )}
            as={Link}
            href={`https://opensea.io/assets/solana/${sale.artwork.id}`}
            target="_blank"
          >
            List for sale
          </Button>
        </FlowStatus>
      )}

      {(status === null || status === "pending") && (
        <PurchaseConfirm
          sale={sale}
          pending={status === "pending"}
          primaryActionText="End sale"
          onConfirm={handleEndSale}
          onCancel={handleClose}
        />
      )}
    </ModalTemplate>
  );
};
