import React from "react";
import { Box, StyleProps, Text, TextProps } from "@chakra-ui/react";

interface Props extends StyleProps {
  title: string;
  titleProps?: TextProps;
}

export const ArtworkCardColumn: React.FC<Props> = ({
  title,
  children,
  titleProps,
  ...props
}) => {
  return (
    <Box {...props}>
      <Text variant="small" color="whiteAlpha.500" mb="6px" {...titleProps}>
        {title}
      </Text>
      {children}
    </Box>
  );
};
