import { attach, createEffect, StoreValue } from "effector";
import { withdrawFromTreasury } from "sdk/auction-house/withdrawFromTreasury";

import { $connection } from "state/connection";
import { $store } from "state/store";
import { $wallet } from "state/wallet";

export interface WithdrawFromTreasuryFxSource {
  connection: StoreValue<typeof $connection>;
  store: StoreValue<typeof $store>;
  wallet: StoreValue<typeof $wallet>;
  amount: number;
}

export const withdrawFromTreasuryFx = attach({
  effect: createEffect(
    async ({
      connection,
      store,
      wallet,
      amount,
    }: WithdrawFromTreasuryFxSource) => {
      if (!store || !wallet) {
        throw new Error("Empty store or wallet!");
      }

      return await withdrawFromTreasury({
        auctionHouseId: store.storeAhId,
        connection,
        wallet,
        amount,
      });
    }
  ),
  source: {
    connection: $connection,
    wallet: $wallet,
    store: $store,
  },
  mapParams({ amount = 0 }: { amount: number }, sources) {
    return {
      ...sources,
      amount,
    };
  },
});
