import { useMemo } from "react";
import {
  ICandyMachine,
  IFixedPrice,
  ISale,
  isCandyMachine,
  isFixedPrice,
  UnixTimestamp,
} from "state/sales";

export enum EarlyAccessMintMode {
  BurnEveryTime = 0,
  NeverBurn = 1,
}

interface IEarlyAccessConfig {
  mint: string;
  mode: EarlyAccessMintMode;
  presale?: boolean;
  discountPrice?: number | null;
  publicSaleStart?: UnixTimestamp;
}

export const useEarlyAccessConfig = (
  sale?: ISale | null
): IEarlyAccessConfig | null => {
  return useMemo(() => {
    if (!sale) return null;

    if (isCandyMachine(sale)) return getCandyMachineGate(sale);
    if (isFixedPrice(sale)) return getFixedPriceGate(sale);

    return null;
  }, [sale]);
};

function getCandyMachineGate(sale: ICandyMachine) {
  const { gate } = sale;
  if (!gate?.mint) return null;

  const publicSaleStart = sale.liveAtDate;
  return {
    mode: gate.mode as unknown as EarlyAccessMintMode,
    publicSaleStart,
    presale: gate.presale,
    discountPrice: gate.discountPrice,
    mint: gate.mint,
  };
}

function getFixedPriceGate(sale: IFixedPrice) {
  const gate = sale.gate;
  if (!gate?.collection) return null;

  return {
    mode: gate.expireOnUse
      ? EarlyAccessMintMode.BurnEveryTime
      : EarlyAccessMintMode.NeverBurn,
    publicSaleStart: gate.gatingTime,
    mint: gate.collection,
  };
}
