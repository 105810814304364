import {
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
} from "@solana/spl-token";
import { Connection, ParsedAccountData, PublicKey } from "@solana/web3.js";

export const moveTokenToAssociatedToken = async ({
  connection,
  wallet,
  mint,
  associatedToken,
}: {
  connection: Connection;
  wallet: PublicKey;
  mint: PublicKey;
  associatedToken: PublicKey;
}) => {
  const actualToken = (await connection.getTokenLargestAccounts(mint)).value[0]
    .address;

  const ixs = [];

  if (actualToken.toBase58() !== associatedToken.toBase58()) {
    const accountInfo = await connection.getParsedAccountInfo(associatedToken);
    if (accountInfo.value === null) {
      const ix = createAssociatedTokenAccountInstruction(
        wallet,
        associatedToken,
        wallet,
        mint
      );
      ixs.push(ix);
    }
    if (
      !accountInfo.value ||
      (accountInfo.value &&
        (accountInfo.value.data as ParsedAccountData)?.parsed?.info?.tokenAmount
          ?.uiAmount === 0)
    ) {
      const ix = createTransferInstruction(
        actualToken,
        associatedToken,
        wallet,
        1
      );
      ixs.push(ix);
    }
  }

  return ixs;
};
