import { IDuration, TimeDurationInput } from "components/TimeDurationInput";
import { Box, Divider, Heading, Text, VStack } from "@chakra-ui/react";
import { ITickPrice } from "./TickPriceInput";

import { DateTimePicker } from "components/Datepicker/DateTimePicker";
import { EstimatedDate } from "components/EstimatedDate";
import { FC } from "react";
import { FormField } from "components/FormField";
import { GatedAccess } from "components/GatedAccess";
import { ISaleDetailsForm } from "./SaleDetailsForm";
import { MaximumSupply } from "./MaximumSupply";
import { PriceInput } from "components/PriceInput";
import dayjs from "dayjs";
import { toSeconds } from "components/TimeDurationInput/toSeconds";
import { useFormContext } from "react-hook-form";

export interface ITimedAuctionForm {
  price?: number;
  duration?: IDuration;
  gapTime?: IDuration | null;
  tickSize?: ITickPrice;
}

export const FixedPriceSaleForm: FC<{ maxSupply?: number }> = ({
  maxSupply,
}) => {
  const { watch } = useFormContext<ISaleDetailsForm>();

  const duration = watch("duration");
  const date = watch("date");

  return (
    <VStack spacing={12} align="stretch">
      <FormField
        id="price"
        title={"Starting price"}
        placeholder="Amount"
        options={{ required: true, validate: (price) => price > 0 }}
        type="number"
        customInputFactory={(register) => <PriceInput {...register} />}
      />

      <MaximumSupply
        id="pieces"
        title="pieces in on wallet"
        description="pieces sold to one wallet"
        defaultValue={1}
        max={maxSupply}
      />

      <Divider />

      <Box>
        <Heading>Sale Scheduling</Heading>
        <Text mt={2} mb={6} variant="subtitle" color="whiteAlpha.500">
          Set the starting, ending, and optional gating period for your sale.
        </Text>

        <FormField
          id="date"
          title="Sale Start date"
          description="When would you like your sale to start?"
          controlledInputFactory={({ field }) => (
            <DateTimePicker
              displayTimeZone
              date={field.value as Date}
              minDate={dayjs().toDate()}
              onChange={(date) => {
                field.onChange(date);
                field.onBlur();
              }}
            />
          )}
        />
      </Box>

      <TimeDurationInput
        id="duration"
        title="Sale Duration"
        placeholder="Sale Duration"
      />

      <EstimatedDate
        durationInSeconds={
          (date ? date.getSeconds() / 1000 : 0) + (toSeconds(duration) ?? 0)
        }
        fromDate={date}
      />

      <GatedAccess />
    </VStack>
  );
};
