import { FC } from "react";
import { ModalHeader } from "@chakra-ui/react";

import { ErrorNft } from "components/modals/PurchaseNft/ErrorNft";
import { ModalTemplate } from "components/modals/template";
import { PurchaseConfirm } from "components/modals/PurchaseNft/PurchaseConfirm";
import { SoldOut } from "components/modals/PurchaseNft/SoldOut";
import { ListingProgress } from "components/modals/ListingProgress";

import { StateProps, useLocalState } from "./PurchaseDialog.state";
import { DialogHeader } from "./DialogHeader";

interface Props extends StateProps {
  isOpen: boolean;
  onClose: () => void;
  group?: string;
}

export const PurchaseDialog: FC<Props> = ({
  sale,
  isOpen,
  onClose,
  onPurchaseSuccess,
  onSubmit,
  group,
}) => {
  const { error, state, proceedPurchase, resetState, steps } = useLocalState({
    onSubmit,
    onPurchaseSuccess,
    sale,
    group,
  });

  if (!sale) {
    return null;
  }

  if (state === "queue") {
    return (
      <ModalTemplate
        bodyProps={{ bgColor: "whiteAlpha.50", borderRadius: 12, p: 0 }}
        contentProps={{ bgColor: "gray.800", borderRadius: 12 }}
        header={<ModalHeader fontSize="md">Buying</ModalHeader>}
        isOpen={isOpen}
        onClose={() => {}}
        size="lg"
      >
        <ListingProgress steps={steps} artwork={sale.artwork} bgColor="none" />
      </ModalTemplate>
    );
  }

  return (
    <ModalTemplate
      header={
        <DialogHeader
          title={state === "confirm" ? "Confirm Purchase" : undefined}
          onClose={onClose}
        />
      }
      isOpen={isOpen}
      onClose={() => {
        resetState();
        onClose();
      }}
      bodyProps={{ p: 0 }}
    >
      {state === "confirm" ? (
        <PurchaseConfirm
          sale={sale}
          onConfirm={proceedPurchase}
          onCancel={onClose}
        />
      ) : state === "sold-out" ? (
        <SoldOut />
      ) : state === "error" ? (
        <ErrorNft {...error} />
      ) : null}
    </ModalTemplate>
  );
};
