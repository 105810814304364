import { ListingReceipt } from "@metaplex-foundation/mpl-auction-house/dist/src/generated";
import { Connection } from "@solana/web3.js";
import { loadArtworks } from "sdk/loadArtworks";

export async function loadSalesArtworks(
  connection: Connection,
  sales: [string, ListingReceipt][]
) {
  const salesArtPdas = sales.map(([, { metadata, seller }]) => {
    return [metadata, { owner: seller }] as const;
  });
  return loadArtworks(connection, salesArtPdas);
}
