import { ArtworkCard, ArtworkListItem } from "components/Artwork";

import { ArtworkCardOwnedState } from "components/Artwork/ArtworkCard/states/ArtworkCardOwnedState";
import { ArtworksList } from "components/ArtworksList";
import { Layout } from "components/Layout";
import { MainSidebarContent } from "components/MainSidebar";
import { PageHead } from "components/PageHead";
import { VStack } from "@chakra-ui/layout";
import { useLocalState } from "./ProfileItems.state";
import { useCallback, useEffect } from "react";
import { usePaths } from "routes";
import { useNavigate } from "react-router-dom";
import { useBalance } from "state/react/useBalance";
import { fetchProfileArtworksFx } from "state/artworks";

export const ProfileItems: React.FC = () => {
  const { artworks, pendingArtworks, storeName, tabs, user } = useLocalState();
  const { fetch: fetchBalance } = useBalance();
  useEffect(() => {
    fetchBalance();
  }, [fetchBalance]);

  const navigate = useNavigate();
  const paths = usePaths();

  useEffect(() => {
    fetchProfileArtworksFx();
  }, []);

  const handleCardClick = useCallback(
    (id: string) => {
      navigate(
        paths.artwork({
          ":artworkId": id,
        })
      );
    },
    [navigate, paths]
  );

  return (
    <Layout sidebarContent={<MainSidebarContent user={user} />}>
      <PageHead
        title="Your items"
        subtitle={`Items you have purchased from ${storeName || ""}`}
      />

      <VStack spacing={4} p={0} align="stretch">
        <ArtworksList
          defaultView="list"
          items={artworks}
          noItemsText={{
            title: `You don’t have any items from ${storeName || ""} yet`,
            subtitle: "When you do, you’ll find them here.",
          }}
          pending={pendingArtworks}
          tabs={tabs}
          renderGridItem={(item) => (
            <ArtworkCard
              onClick={() => handleCardClick(item.id)}
              key={item.id}
              artwork={item}
            >
              <ArtworkCardOwnedState
                walletAddress={
                  item.creators.length ? item.creators[0].address : ""
                }
              />
            </ArtworkCard>
          )}
          renderListItem={(item) => (
            <ArtworkListItem
              key={item.id}
              artwork={item}
              onClick={() => handleCardClick(item.id)}
              variant="minted"
            />
          )}
        />
      </VStack>
    </Layout>
  );
};
