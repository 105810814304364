import { NotFoundView, StoreLoading } from "views/guards";
import { toPubkey } from "sdk/share";
import { useCallback, useState } from "react";
import { walletAdapterIdentity, getMerkleProof } from "@metaplex-foundation/js";

import { EndSaleModal } from "components/modals/EndSale";
import { ErrorModal } from "../AdminItems/ErrorModal";
import { IArt } from "state/artworks";
import { InfiniteProgress } from "components/modals/InfiniteProgress";
import { ItemViewContent } from "./ItemViewContent";
import { Layout } from "components/Layout";
import { PurchaseDialog } from "components/PurchaseDialog";
import useLocalStorage from "use-local-storage";
import { PurchaseSuccess } from "./PurchaseSuccess";
import { isSale, loadSaleFx } from "state/sales";
import { useDisclosure } from "@chakra-ui/react";
import { createMx, useLocalState } from "./sales.state";
import { useParams } from "react-router-dom";
import { $connection } from "state/connection";
import { useToast } from "components/modals/Toast";
import { $wallet } from "state/wallet";
import { useStore } from "effector-react";

export const SalesView: React.FC = () => {
  const toast = useToast();
  const {
    isOpen: isPurchaseOpen,
    onClose: closePurchase,
    onOpen: openPurchase,
  } = useDisclosure();
  const {
    isOpen: isEndSaleOpen,
    onClose: closeEndSale,
    onOpen: openEndSale,
  } = useDisclosure();
  const connection = useStore($connection);
  const wallet = useStore($wallet);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [purchaseArtwork, setPurchaseArtwork] = useState<IArt>();
  const [group, setGroup] = useState<string>("Public");
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [isVerified, setIsVerified] = useLocalStorage("isVerified", false);

  const { artworkId, saleId } = useParams();
  const { item, pending, progress, resetProgress, handleEndSale } =
    useLocalState({
      saleId,
      artworkId,
    });

  const _isSale = item && isSale(item);

  const handleVerifyWallet = useCallback(
    async (group: string) => {
      if (!wallet) throw new Error("Wallet not connected");
      setIsVerified(true);
      setGroup(group);
      openPurchase();
    },
    [wallet]
  );

  const handleBuyNft = useCallback(() => {
    setGroup("Public");
    openPurchase();
  }, [openPurchase]);

  const handlePurchaseSuccess = (mint?: IArt | null) => {
    if (mint) {
      setPurchaseArtwork(mint);
    }
    closePurchase();
    setPurchaseSuccess(true);
  };

  return item ? (
    <Layout>
      {!purchaseSuccess && (
        <ItemViewContent
          item={item}
          onVerifyWallet={handleVerifyWallet}
          onBuyNft={handleBuyNft}
          onClaim={handleEndSale}
          onEndSale={openEndSale}
          isVerified={isVerified}
          loadingVerification={loadingVerification}
        />
      )}

      {purchaseSuccess && _isSale && (
        <PurchaseSuccess artwork={purchaseArtwork ?? item.artwork} />
      )}

      {_isSale && (
        <PurchaseDialog
          isOpen={isPurchaseOpen}
          onClose={closePurchase}
          sale={item}
          group={group}
          onPurchaseSuccess={handlePurchaseSuccess}
        />
      )}

      <InfiniteProgress
        isOpen={progress.visible}
        title={progress.title}
        subtitle={progress.subtitle}
        onClose={resetProgress}
      />
      {progress.error && (
        <ErrorModal error={progress.error} onClose={resetProgress} />
      )}

      {_isSale && (
        <EndSaleModal
          isOpen={isEndSaleOpen}
          onClose={closeEndSale}
          sale={item}
        />
      )}
    </Layout>
  ) : pending ? (
    <StoreLoading title="Loading" />
  ) : (
    <NotFoundView />
  );
};
