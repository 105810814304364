import { BOUND_STORE_ID } from "global-const/env";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { setStore } from "state/store";

export const DetectStore: React.FC = ({ children }) => {
  const params = useParams();
  const storeId = BOUND_STORE_ID ?? params.storeId;

  useEffect(() => {
    if (storeId) {
      setStore(storeId);
    }
  }, [storeId]);

  return <>{children}</>;
};
