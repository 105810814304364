import { Avatar } from "@chakra-ui/avatar";
import {
  BoxProps,
  Heading,
  HStack,
  Spacer,
  Text,
  TypographyProps,
} from "@chakra-ui/react";
import { CopyButton } from "components/buttons/CopyButton";
import React from "react";
import { getPersonName } from "./getPersonName";
export interface PersonProps extends BoxProps {
  address?: string;
  name?: string;
  avatarUrl?: string;
  size?: "xs" | "sm" | "md" | "lg";
  variant?: "item-price";
  fontWeight?: TypographyProps["fontWeight"];
  enableCopy?: boolean;
  fullWidth?: boolean;
}

const spacing = {
  xs: 2,
  sm: 3,
  md: 4,
  lg: 8,
  xxl: 8,
};

// TODO: Person vs WalletAddress
export const Person: React.FC<PersonProps> = ({
  address,
  name,
  avatarUrl,
  size = "sm",
  variant,
  fontWeight = "normal",
  enableCopy,
  fullWidth,
  ...props
}: PersonProps) => {
  const isItemPrice = variant === "item-price";
  const personName = getPersonName({ name, address });

  return (
    <HStack spacing={spacing[size]} {...props}>
      <Avatar size={size} src={avatarUrl} />
      {isItemPrice ? (
        <Heading variant="h3">{personName}</Heading>
      ) : (
        <Text fontWeight={fontWeight}>{personName}</Text>
      )}
      {enableCopy && (
        <>
          {fullWidth && <Spacer />}
          <CopyButton text={address || ""} variant="ghost" size="sm" />
        </>
      )}
    </HStack>
  );
};
