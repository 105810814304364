import { AuctionHouseProgram } from "@metaplex-foundation/mpl-auction-house";
import { createCreateAuctionHouseInstruction } from "@metaplex-foundation/mpl-auction-house/dist/src/generated/instructions";
import { Wallet } from "wallet";
import { NATIVE_MINT } from "@solana/spl-token";
import { TransactionsBatch } from "sdk/transactions";

export const createStoreTransaction = async ({
  wallet,
  sellerFeeBasisPoints = 0,
}: {
  wallet: Wallet;
  sellerFeeBasisPoints?: number; // 10000 === 100%
}) => {
  const [auctionHouse, bump] =
    await AuctionHouseProgram.findAuctionHouseAddress(
      wallet.publicKey,
      NATIVE_MINT
    );
  const [auctionHouseFeeAccount, feePayerBump] =
    await AuctionHouseProgram.findAuctionHouseFeeAddress(auctionHouse);
  const [auctionHouseTreasury, treasuryBump] =
    await AuctionHouseProgram.findAuctionHouseTreasuryAddress(auctionHouse);

  const ix = createCreateAuctionHouseInstruction(
    {
      payer: wallet.publicKey,
      authority: wallet.publicKey,
      treasuryMint: NATIVE_MINT,
      feeWithdrawalDestination: wallet.publicKey,
      treasuryWithdrawalDestination: wallet.publicKey,
      treasuryWithdrawalDestinationOwner: wallet.publicKey,
      auctionHouse,
      auctionHouseFeeAccount,
      auctionHouseTreasury,
    },
    {
      bump,
      feePayerBump,
      treasuryBump,
      sellerFeeBasisPoints,
      requiresSignOff: false,
      canChangeSalePrice: false,
    }
  );
  const tx = new TransactionsBatch({ instructions: [ix], signers: [] });
  return { auctionHouse, tx };
};
