import { Connection } from "@solana/web3.js";
import { parseBN } from "utils/parseBN";
import { findMetadataAddress } from "../nft";
import { TokenAccount } from "../share";
import { loadArtworks } from "./loadArtworks";

export const loadArtworksByTokenAccounts = async ({
  connection,
  tokenAccounts,
}: {
  connection: Connection;
  tokenAccounts: TokenAccount[];
}) => {
  const accountsWithAmount = tokenAccounts.filter(
    ({ amount }) => parseBN(amount) === 1
  );

  const PDAs = await Promise.all(
    accountsWithAmount.map(async (token) => {
      const [pda] = await findMetadataAddress(token.mint);
      return [pda, { token }] as const;
    })
  );

  return loadArtworks(connection, PDAs);
};
