import { FC } from "react";
import { Image } from "@chakra-ui/react";

interface Props {
  coverImage?: string;
}

export const MobileCoverImage: FC<Props> = ({ coverImage }) => {
  if (!coverImage) return null;

  return (
    <Image
      src={coverImage}
      w="calc(100% + 48px)"
      maxW="calc(100% + 48px)"
      h={375}
      objectFit="cover"
      ml={-6}
    />
  );
};
