import { createCloseMarketInstruction } from "@metaplex-foundation/mpl-fixed-price-sale";
import { Wallet } from "wallet";
import { PublicKey, SYSVAR_CLOCK_PUBKEY } from "@solana/web3.js";
import { TransactionsBatch } from "sdk/transactions";

export interface CloseMarketProps {
  wallet: Wallet;
  market: PublicKey;
}

export const createCloseMarketTransaction = ({
  market,
  wallet,
}: CloseMarketProps) => {
  const ix = createCloseMarketInstruction({
    market,
    owner: wallet.publicKey,
    clock: SYSVAR_CLOCK_PUBKEY,
  });

  const closeMarketTx = new TransactionsBatch({ instructions: [ix] });

  return closeMarketTx;
};
