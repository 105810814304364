import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import { BaseSdkProps, ETransactionProgress, toPubkey } from "sdk/share";
import { sendTransactions } from "sdk/transactions";
import { createSellTransaction } from "./actions/createSellTransaction";

export type SellProps = BaseSdkProps & {
  auctionHouse: StringPublicKey;
  resourceMint: StringPublicKey;
  price: number;
};

export const initSale = async (params: SellProps) => {
  const { connection, wallet, progressChange } = params;

  progressChange?.(ETransactionProgress.creating);
  const { tradeState, receipt, tx } = await createSellTransaction({
    ...params,
    auctionHouse: toPubkey(params.auctionHouse),
    resourceMint: toPubkey(params.resourceMint),
    seller: wallet.publicKey,
  });

  progressChange?.(ETransactionProgress.sending_to_solana);
  await sendTransactions(connection, wallet, [tx]);

  return { tradeState, receipt };
};
