import { config as coreConfig } from "@metaplex-foundation/mpl-core";
import { SetStoreV2Args } from "@metaplex-foundation/mpl-metaplex";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import {
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  TransactionInstruction,
} from "@solana/web3.js";
import { toPubkey } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";

interface SetStoreV2Params {
  store: PublicKey;
  admin: PublicKey;
  config: PublicKey;
  isPublic: boolean;
  settingsUri: string | null;
}

export const createStoreV2Transaction = ({
  admin,
  store,
  config,
  isPublic,
  settingsUri,
}: SetStoreV2Params) => {
  const data = SetStoreV2Args.serialize({ public: isPublic, settingsUri });

  const ix = new TransactionInstruction({
    keys: [
      {
        pubkey: store,
        isSigner: false,
        isWritable: true,
      },
      {
        pubkey: config,
        isSigner: false,
        isWritable: true,
      },
      {
        pubkey: admin,
        isSigner: true,
        isWritable: false,
      },
      {
        pubkey: admin,
        isSigner: true,
        isWritable: false,
      },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
      {
        pubkey: toPubkey(coreConfig.programs.vault),
        isSigner: false,
        isWritable: false,
      },
      {
        pubkey: toPubkey(coreConfig.programs.metadata),
        isSigner: false,
        isWritable: false,
      },
      {
        pubkey: toPubkey(coreConfig.programs.auction),
        isSigner: false,
        isWritable: false,
      },
      {
        pubkey: SystemProgram.programId,
        isSigner: false,
        isWritable: false,
      },
      {
        pubkey: SYSVAR_RENT_PUBKEY,
        isSigner: false,
        isWritable: false,
      },
    ],
    programId: toPubkey(coreConfig.programs.metaplex),
    data,
  });

  return new TransactionsBatch({ instructions: [ix] });
};
