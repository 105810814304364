import { Connection } from "@solana/web3.js";
import {
  getFilesCost,
  getMetadataCost,
  METADATA_FILE_NAME,
  MetadataJson,
} from "sdk/nft";

export async function calculatePrice(
  uploadFiles: File[],
  metadata: MetadataJson,
  connection: Connection
) {
  try {
    const meta = JSON.stringify(metadata);
    const metadataFile = new File([meta], METADATA_FILE_NAME);
    const files = [...uploadFiles, metadataFile];

    const deferMetadataCost = getFilesCost(files);
    const [{ solana }, additionalSol] = await Promise.all([
      deferMetadataCost,
      getMetadataCost(connection),
    ]);
    const price = solana + additionalSol;
    return {
      price,
    };
  } catch (err) {
    /* nope */
  }
}
