import { AuctionHouseProgram } from "@metaplex-foundation/mpl-auction-house";
import { createDepositInstruction } from "@metaplex-foundation/mpl-auction-house/dist/src/generated";
import { AuctionHouse } from "@metaplex-foundation/mpl-auction-house/dist/src/generated/accounts";
import { Connection, PublicKey, SystemProgram } from "@solana/web3.js";
import { loadAccountAndDeserialize } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";

export interface DepositProps {
  connection: Connection;
  wallet: PublicKey;
  auctionHouse: PublicKey;
  amount: number;
}

export const createDepositTransaction = async ({
  connection,
  wallet,
  auctionHouse,
  amount,
}: DepositProps) => {
  const { treasuryMint, authority, auctionHouseFeeAccount } =
    await loadAccountAndDeserialize(connection, AuctionHouse, auctionHouse);

  const [escrowPaymentAccount, escrowPaymentBump] =
    await AuctionHouseProgram.findEscrowPaymentAccountAddress(
      auctionHouse,
      wallet
    );

  const depositIx = createDepositInstruction(
    {
      wallet,
      paymentAccount: wallet,
      transferAuthority: SystemProgram.programId,
      escrowPaymentAccount,
      treasuryMint,
      authority,
      auctionHouse,
      auctionHouseFeeAccount,
    },
    {
      escrowPaymentBump,
      amount,
    }
  );

  const tx = new TransactionsBatch({
    instructions: [depositIx],
  });

  return { tx };
};
