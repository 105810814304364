import { FC } from "react";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { ISale } from "state/sales";
import { SaleEndedPlate } from "../SaleEndedPlate";
import { BuyButton } from "./BuyButton";
import { EndSaleButton } from "./EndSaleButton";
import { VerifyButton } from "./VerifyButton";
import {
  ActionType,
  useActionButtonsSelector,
} from "./useActionButtonsSelector";
import { WithdrawButton } from "./WithdrawButton";

export interface ItemActionHandlersProps {
  onBuyNft?: () => void;
  onClaim?: (saleId: string, artworkAccountAddress: string) => void;
  onEndSale?: (saleId: string, artworkAccountAddress: string) => void;
  onVerifyWallet?: (group: string) => void;
}

interface Props extends ItemActionHandlersProps {
  sale: ISale;
  hasAllowList: boolean;
  isStarted: boolean;
  group: string;
  isVerified?: boolean;
  loadingVerification?: boolean;
}

export const ItemActionButtons: FC<Props> = ({
  sale,
  hasAllowList,
  onBuyNft,
  onVerifyWallet,
  isVerified,
  loadingVerification,
  isStarted,
  onClaim,
  onEndSale,
  group,
}) => {
  const type = useActionButtonsSelector(sale);
  return (
    <>
      {hasAllowList ? (
        <VerifyButton
          isVerified={isVerified}
          loadingVerification={loadingVerification}
          isStarted={isStarted}
          sale={sale}
          handler={onVerifyWallet}
          group={group}
        />
      ) : (
        <BuyButton
          group={group}
          sale={sale}
          isStarted={isStarted}
          handler={onBuyNft}
        />
      )}
    </>
  );
};
