import {
  CandyMachine,
  CandyMachineProgram,
  CollectionPDA,
  EndSettingType,
} from "@metaplex-foundation/mpl-candy-machine";
import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import { AccountInfo, Cluster, Connection } from "@solana/web3.js";
import dayjs from "dayjs";
import { CM_CUSTOM_RPC } from "global-const/candyMachine";
import { loadArtworkByMint } from "sdk/loadArtworks";
import {
  findAccountsAndDeserialize,
  loadAccountAndDeserialize,
  loadAccountsAndDeserialize,
  toPubkey,
} from "sdk/share";
import { IArt } from "state/artworks/types";
import { ICandyMachine, SaleState, SaleType, UnixTimestamp } from "state/sales";
import { excludesFalsy } from "utils/excludeFalsy";
import { lamportsToSol } from "utils/lamportsToSol";
import { parseBN } from "utils/parseBN";
import { getCollectionPDA } from "./utils";

const getCMConnection = (connection: Connection, network: Cluster) => {
  const customRPC = CM_CUSTOM_RPC[network];
  return customRPC ? new Connection(customRPC) : connection;
};

interface Props {
  connection: Connection;
  authority: StringPublicKey;
  network: Cluster;
  hardcodedList?: StringPublicKey[];
}

export const getCandyMachines = async ({
  connection,
  authority,
  network,
  hardcodedList,
}: Props) => {
  const cmsMap = hardcodedList
    ? await loadAccountsAndDeserialize(
        connection,
        CandyMachine,
        hardcodedList.map((id) => toPubkey(id)),
        CandyMachineProgram.PUBKEY
      )
    : await findAccountsAndDeserialize(
        getCMConnection(connection, network),
        CandyMachineProgram,
        CandyMachine,
        [{ offset: 8, bytes: authority }]
      );

  const PDAsMap = new Map<string, IArt | undefined>();
  await Promise.all(
    Array.from(cmsMap).map(async ([pubkey]) => {
      try {
        const [collectionPDA] = await getCollectionPDA(toPubkey(pubkey));
        // TODO: load all artworks together
        const collection = await loadAccountAndDeserialize(
          connection,
          CollectionPDA,
          collectionPDA
        );
        const artwork = await loadArtworkByMint({
          connection,
          mint: collection.mint,
        });

        PDAsMap.set(pubkey, artwork);
      } catch {
        /* nope */
      }
    })
  );

  const cms = Array.from(cmsMap).map(([pubkey, cm]) => {
    const artwork = PDAsMap.get(pubkey);
    if (!artwork) return null;
    return combineCm(cm, pubkey, artwork);
  });
  return cms.filter(excludesFalsy);
};

const combineCm = (
  cm: CandyMachine,
  pubkey: string,
  artwork: IArt
): ICandyMachine => {
  const { data } = cm;

  // If two cms are using the same ticket, this prevents supply overriding
  const localArtwork = { ...artwork };
  localArtwork.prints = {
    maxSupply: parseBN(cm.data.itemsAvailable),
    supply: parseBN(cm.itemsRedeemed),
  };

  const liveAtDate = parseBN(data.goLiveDate);
  let stopAtDate: UnixTimestamp | undefined;
  let stopAtAmount: number | undefined;
  if (data.endSettings?.endSettingType === EndSettingType.Date) {
    stopAtDate = parseBN(data.endSettings.number);
  } else if (data.endSettings?.endSettingType === EndSettingType.Amount) {
    stopAtAmount = parseBN(data.endSettings.number);
  }
  const itemsAvailable = parseBN(data.itemsAvailable);
  const itemsRedeemed = parseBN(cm.itemsRedeemed);

  const gate = data.whitelistMintSettings
    ? {
        mode: data.whitelistMintSettings.mode,
        mint: data.whitelistMintSettings.mint.toString(),
        presale: data.whitelistMintSettings.presale,
        discountPrice: parseBN(
          data.whitelistMintSettings.discountPrice,
          lamportsToSol
        ),
      }
    : undefined;

  const gatekeeper = data.gatekeeper
    ? {
        ...data.gatekeeper,
        gatekeeperNetwork: data.gatekeeper.gatekeeperNetwork.toString(),
      }
    : undefined;

  const isSoldOut =
    (stopAtAmount && stopAtAmount <= itemsRedeemed) ||
    itemsRedeemed >= itemsAvailable;

  const now = dayjs().unix();
  const isEnded = stopAtDate ? stopAtDate <= now : false;
  const isActive = gate?.presale || (liveAtDate ? liveAtDate <= now : false);

  const state = isEnded
    ? SaleState.Ended
    : isSoldOut
    ? SaleState.SoldOut
    : isActive
    ? SaleState.Active
    : SaleState.Created;

  return {
    type: SaleType.CandyMachine,
    artwork: localArtwork,
    id: pubkey,
    price: parseBN(data.price, lamportsToSol),
    symbol: data.symbol,
    liveAtDate,
    stopAtDate,
    stopAtAmount,
    itemsAvailable,
    itemsRedeemed,
    gate,
    gatekeeper,
    state,
    refs: {
      tokenMint: cm.tokenMint?.toString(),
      seller: cm.wallet.toString(),
      authority: cm.authority.toString(),
      retainAuthority: cm.data.retainAuthority,
    },
  };
};

export const loadCandyMachines = async (params: Props) => {
  try {
    return await getCandyMachines(params);
  } catch (e) {
    return [];
  }
};

export const loadCandyMachine = async ({
  connection,
  cmId,
  accountInfo,
}: {
  connection: Connection;
  cmId: StringPublicKey;
  accountInfo?: AccountInfo<Buffer>;
}) => {
  let cmData: CandyMachine;
  if (accountInfo) {
    cmData = CandyMachine.deserialize(accountInfo.data)[0];
  } else {
    const pubkey = toPubkey(cmId);

    cmData = await loadAccountAndDeserialize(connection, CandyMachine, pubkey);
  }

  const [collectionPDA] = await getCollectionPDA(toPubkey(cmId));
  const collection = await loadAccountAndDeserialize(
    connection,
    CollectionPDA,
    collectionPDA
  );
  const artwork = await loadArtworkByMint({
    connection,
    mint: collection.mint,
  });

  return combineCm(cmData, cmId, artwork);
};
