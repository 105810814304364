import { Connection } from "@solana/web3.js";
import { loadAccountAndDeserialize, toPubkey } from "sdk/share";
import { AuctionHouse } from "@metaplex-foundation/mpl-auction-house/dist/src/generated";
import { StringPublicKey } from "@metaplex-foundation/mpl-core";

export async function checkTreasureBalance(
  connection: Connection,
  auctionHouseId: StringPublicKey,
  includeRent = true
) {
  const auctionHouse = await loadAccountAndDeserialize(
    connection,
    AuctionHouse,
    toPubkey(auctionHouseId)
  );

  const treasureAccount = await connection.getAccountInfo(
    auctionHouse.auctionHouseTreasury
  );

  let rentExempt = 0;

  if (includeRent) {
    rentExempt = await connection.getMinimumBalanceForRentExemption(
      treasureAccount?.data.length || 0
    );
  }

  return (treasureAccount?.lamports ?? 0) - rentExempt;
}
