import { FormField, IFormFieldProps } from "components/FormField";
import { InputWithUnitSelector } from "components/InputWithUnitSelector";
import { FC, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

export const TIME_PERIOD_TYPES = [
  "unlimited",
  "days",
  "hours",
  "minutes",
] as const;
export type PeriodType = typeof TIME_PERIOD_TYPES[number];

export interface IDuration {
  value?: number | null;
  units: PeriodType;
  defaultUnit: PeriodType;
}

export const TimeDurationInput: FC<IFormFieldProps> = ({
  id,
  defaultUnit,
  ...props
}) => {
  const { setValue, register } = useFormContext();
  const [isUnlimited, setUnlimitedState] = useState(false);

  useEffect(() => {
    register(`${id}.units`, { value: defaultUnit });
    if (!defaultUnit || defaultUnit === "unlimited") {
      setUnlimitedState(true);
      setValue(`${id}.value`, null, { shouldValidate: false });
    }
  }, [defaultUnit, id, register, setValue]);

  const handleUnitChange = useCallback(
    (unit: string) => {
      if (unit === "unlimited") {
        setUnlimitedState(true);
        setValue(`${id}.value`, null, { shouldValidate: false });
      } else {
        setUnlimitedState(false);
      }
      return setValue(`${id}.units`, unit, { shouldValidate: true });
    },
    [id, setValue]
  );

  return (
    <FormField
      {...props}
      id={`${id}.value`}
      defaultValue={isUnlimited ? "unlimited" : undefined}
      options={{ ...props.options }}
      type={isUnlimited ? "string" : "number"}
      placeholder={isUnlimited ? "Unlimited" : props.placeholder}
      customInputFactory={(register) => {
        const inputProps = { ...register, isInvalid: false };
        return (
          <InputWithUnitSelector
            {...inputProps}
            isDisabled={isUnlimited}
            onUnitChange={handleUnitChange}
            units={TIME_PERIOD_TYPES}
            defaultUnit={defaultUnit}
          />
        );
      }}
    />
  );
};
