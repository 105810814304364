import { attach, restore } from "effector";
import { useStore } from "effector-react";
import { TabsConfig } from "hooks/useTabs";
import { useCallback, useEffect, useMemo } from "react";

import {
  $pendingAHSales,
  fetchAHSalesFx,
  IInstantSale,
  ISale,
  SaleState,
  isInstantSale,
} from "state/sales";
import { endSaleFx } from "state/sales/AH/endSaleFx";

import { $storeName } from "state/store";
import { $user, $walletAddress } from "state/wallet";

import {
  $progress,
  ActionType,
  MODAL_COPY,
} from "views/AdminItems/AdminItems.state";

export const useLocalState = () => {
  const { fetchSalesFx, $sales } = useMemo(() => {
    const fetchSalesFx = attach({
      effect: fetchAHSalesFx,
      source: { wallet: $walletAddress },
      mapParams: (_, { wallet }) => ({ seller: wallet }),
    });

    const $sales = restore(fetchSalesFx.doneData, []);

    return { fetchSalesFx, $sales };
  }, []);

  const progress = useStore($progress.$node);
  const storeName = useStore($storeName);
  const user = useStore($user);

  const salesPending = useStore($pendingAHSales);

  const onCloseMarket = useCallback(
    async (sale: ISale) => {
      try {
        if (!isInstantSale(sale)) {
          return;
        }

        $progress.set({
          type: ActionType.CloseMarket,
          isVisible: true,
          ...MODAL_COPY[ActionType.CloseMarket],
        });
        return await endSaleFx({ sale });
      } finally {
        await fetchSalesFx({});
        $progress.set({ isVisible: false });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [$progress, endSaleFx, fetchSalesFx]
  );

  const tabs: TabsConfig<IInstantSale> = useMemo(
    () => [
      { id: "all", title: "All" },
      {
        id: "live",
        accessor: ({ state }) => state === SaleState.Active,
        title: "Live",
      },
      {
        id: "ended",
        accessor: ({ state }) => state === SaleState.Ended,
        title: "Ended",
      },
    ],
    []
  );

  useEffect(() => {
    fetchSalesFx({});
  }, [fetchSalesFx]);

  return {
    progress,
    sales: $sales,
    salesPending,
    storeName,
    tabs,
    user,
    onCloseMarket,
  };
};
