import {
  Box,
  Button,
  Flex,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ArtworkListItem } from "components/Artwork";
import { EstimatedDate } from "components/EstimatedDate";
import { PageHead } from "components/PageHead";
import { SolUsdDisplay } from "components/SolUsdDisplay";
import { LabelBlock } from "components/TitledBlock";
import { WalletTransaction } from "components/WalletTransaction";
import { useStore } from "effector-react";
import { FC } from "react";
import { toSeconds } from "components/TimeDurationInput/toSeconds";
import { createSaleFactory } from "../CreateSaleView.state";
import { SaleType } from "../SaleDetailsForm/SaleTypeSelector";
import dayjs from "dayjs";
import { CollectionBlock } from "components/CollectionBlock";
import { LoaderComponent } from "components/modals/InfiniteProgress/LoaderComponent";
import { DateDisplay } from "components/DateDisplay";

export const SaleReview: FC = () => {
  const {
    $activeArtwork,
    $saleDetails,
    reviewSubmit,
    $processInProgress,
    $fee,
  } = createSaleFactory.useModel();
  const artwork = useStore($activeArtwork);
  const details = useStore($saleDetails);
  const inProgress = useStore($processInProgress);
  const fee = useStore($fee);
  const {
    saleType,
    duration,
    price,
    gapTime,
    tickSize,
    date,
    collectionAddress,
    gatingTime,
  } = details || {};

  const gatingTimeInMinutes = () => {
    if (!gatingTime || !gatingTime.value) return 0;
    switch (gatingTime.units) {
      case "minutes":
        return gatingTime.value;
      case "days":
        return gatingTime?.value * 24 * 60;
      case "hours":
        return gatingTime?.value * 60;
      default:
        return 0;
    }
  };

  if (!artwork || !details) return <LoaderComponent darkBg />;

  return (
    <>
      <PageHead
        title="List for Sale"
        subtitle="Preview your sale before completing your listing."
      />
      <VStack spacing={12} align="stretch">
        <LabelBlock title="Item to sell" bright>
          <ArtworkListItem artwork={artwork} variant="select" frozen />
        </LabelBlock>

        <VStack
          spacing={6}
          divider={<StackDivider borderColor="whiteAlpha.100" />}
          align="stretch"
        >
          <LabelBlock
            title={
              saleType === SaleType.FixedPrice ? "NFT Price" : "Starting Price"
            }
          >
            <SolUsdDisplay sol={price} />
          </LabelBlock>

          <Flex flexWrap="wrap">
            <LabelBlock title="Start Date">
              {date && saleType === SaleType.FixedPrice ? (
                <DateDisplay date={date} />
              ) : (
                <Text variant="body-bold">Immediately after listing</Text>
              )}
            </LabelBlock>

            {duration?.value && (
              <Box
                ml={{ base: 0, md: 12 }}
                mt={{ base: 4, md: 0 }}
                flexGrow={1}
              >
                <EstimatedDate
                  durationInSeconds={
                    (date ? date.getSeconds() / 1000 : 0) +
                    (toSeconds(duration) ?? 0)
                  }
                />
              </Box>
            )}
          </Flex>

          {collectionAddress && gatingTime && (
            <VStack alignItems="left" spacing={6}>
              <LabelBlock title="Gating Period">
                {date && (
                  <Text variant="body-bold">
                    {dayjs(date).format("dddd, MMMM D, YYYY h:mm:ss A")} -{" "}
                    {dayjs(
                      new Date(date.getTime() + gatingTimeInMinutes() * 60000)
                    ).format("dddd, MMMM D, YYYY h:mm:ss A")}
                  </Text>
                )}
              </LabelBlock>
              <LabelBlock title="Gating device" mb={4}>
                <CollectionBlock address={collectionAddress} />
              </LabelBlock>
            </VStack>
          )}

          {duration?.value && (
            <EstimatedDate
              durationInSeconds={
                (date ? date.getSeconds() / 1000 : 0) +
                (toSeconds(duration) ?? 0)
              }
            />
          )}

          {gapTime?.value && (
            <LabelBlock title="Gap Time">
              <Text variant="body-bold">
                {gapTime.value} {gapTime.units}
              </Text>
            </LabelBlock>
          )}

          {tickSize?.value && (
            <LabelBlock title="Tick Size">
              {tickSize.units === "percentage" ? (
                <Text variant="body-bold">{tickSize.value}%</Text>
              ) : (
                <SolUsdDisplay sol={tickSize.value} />
              )}
            </LabelBlock>
          )}

          <WalletTransaction sol={fee} title="COST TO LIST">
            <Button
              variant="primary"
              size="lg"
              px="30px"
              isLoading={inProgress}
              onClick={() => reviewSubmit()}
            >
              LIST FOR SALE
            </Button>
          </WalletTransaction>
        </VStack>
      </VStack>
    </>
  );
};
