import { $network, NETWORK_LIST, networkChange } from "state/connection";
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
} from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { MdChevronRight, MdOutlineSettings } from "react-icons/md";
import { useEvent, useStore } from "effector-react";
import { LOCK_MAINNET } from "global-const/env";

import { BiNetworkChart } from "react-icons/bi";
import { Cluster } from "@solana/web3.js";
import { NetworkSelectMenuItem } from "./NetworkSelectMenuItem";
import { fontSizes } from "theme/typography";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

export const NetworkSelect: FC = () => {
  const currentNetwork = useStore($network);
  const { mdUp } = useCustomBreakpoints();
  const setNetwork = useEvent(networkChange);

  const handleSetNetwork = useCallback(
    (network: Cluster) => {
      setNetwork(network);
      // Reload the page, forward user selection to the URL querystring.
      // The app will be re-initialized with the correct network
      // (which will also be saved to local storage for future visits)
      // for all its lifecycle.
      window.location.reload();
    },
    [setNetwork]
  );

  if (LOCK_MAINNET) {
    return null;
  }

  return (
    <Menu
      gutter={8}
      autoSelect={false}
      closeOnSelect={false}
      placement="bottom-end"
    >
      {mdUp ? (
        <MenuButton
          as={IconButton}
          aria-label="Select network"
          icon={<MdOutlineSettings size={fontSizes["2xl"]} />}
          minW="48px"
          variant="ghost"
          isRound
          color="whiteAlpha.500"
        />
      ) : (
        <MenuButton
          as={Button}
          leftIcon={<BiNetworkChart size={fontSizes["2xl"]} />}
          rightIcon={
            <MdChevronRight
              size={fontSizes["2xl"]}
              style={{ transform: "rotate(-90deg)" }}
            />
          }
          iconSpacing={4}
          variant="ghost"
          textTransform="capitalize"
          textAlign="left"
        >
          {currentNetwork}
        </MenuButton>
      )}
      <MenuList px={4}>
        <MenuGroup title="Network">
          {NETWORK_LIST.map((network) => (
            <NetworkSelectMenuItem
              key={network}
              isActive={currentNetwork === network}
              network={network}
              setNetwork={handleSetNetwork}
            />
          ))}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
