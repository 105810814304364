import { Divider, Flex, FlexProps, VStack } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { IProgressStep, ProgressSteps } from "components/ProgressSteps";

import { ArtworkListItemHeader } from "components/Artwork/ArtworkListItem/ArtworkListItemHeader";
import { IArt } from "state/artworks";
import { ModalFootnote } from "components/modals/ModalFootnote";
import { StartingPrice } from "./StartingPrice";

interface Props extends FlexProps {
  artwork: IArt | null;
  steps?: IProgressStep[];
  noteIcon?: ReactNode;
  noteText?: ReactNode;
  showPrice?: boolean;
}

export const ListingProgress: FC<Props> = ({
  artwork,
  noteIcon,
  noteText,
  steps,
  showPrice = false,
  ...props
}) => (
  <Flex
    direction="column"
    flexGrow={1}
    bgColor="gray.700"
    borderRadius="xl"
    p={6}
    pt={4}
    {...props}
  >
    <VStack spacing={4} mb={6} align="stretch">
      <Flex justify="space-between" align="center">
        <ArtworkListItemHeader artwork={artwork} includeDescription />
        {showPrice && <StartingPrice />}
      </Flex>

      <Divider />

      <ProgressSteps steps={steps} />
    </VStack>
    <ModalFootnote icon={noteIcon} text={noteText} />
  </Flex>
);
