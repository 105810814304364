import { ArtworkDetailsColumn } from "../ArtworkDetailsColumn";
import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { IArt } from "state/artworks";
import { Person } from "components/Person";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { SupplyDetails } from "./SupplyDetails";

interface Props {
  artwork?: IArt;
}

export const MintedDetails: FC<Props> = ({ artwork }) => {
  const { xlUp, xxlUp } = useCustomBreakpoints();

  if (!artwork) return null;

  return (
    <>
      {xxlUp && <Box px={6} w="full" />}

      {xlUp && (
        <ArtworkDetailsColumn title="Owner">
          <Person address={artwork.ownerAddress} />
        </ArtworkDetailsColumn>
      )}

      <ArtworkDetailsColumn title="Supply">
        <SupplyDetails {...artwork.prints} />
      </ArtworkDetailsColumn>
    </>
  );
};
