import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import { BaseSdkProps, ETransactionProgress, toPubkey } from "sdk/share";
import { sendTransactions } from "sdk/transactions";
import { createwWithdrawFromTreasuryTransaction } from "./actions/createWithdrawFromTreasuryTransaction";

export interface WithdrawFromTreasuryProps extends BaseSdkProps {
  auctionHouseId: StringPublicKey;
  amount: number;
}

export const withdrawFromTreasury = async (
  params: WithdrawFromTreasuryProps
) => {
  const { auctionHouseId, connection, progressChange, wallet, amount } = params;

  progressChange?.(ETransactionProgress.creating);
  const { tx: withdrawFromTreasuryTx } =
    await createwWithdrawFromTreasuryTransaction({
      auctionHouseId: toPubkey(auctionHouseId),
      connection,
      amount,
    });

  progressChange?.(ETransactionProgress.sending_to_solana);
  await sendTransactions(connection, wallet, [withdrawFromTreasuryTx]);
};
