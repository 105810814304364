import { HAS_BOUND_STORE_ID } from "global-const/env";
import { route, variable } from "./utils";

export const storeId = variable(":storeId", HAS_BOUND_STORE_ID);
export const saleId = variable(":saleId");
export const artworkId = variable(":artworkId");

const storeRoute = route(storeId);
const adminRoute = route(storeRoute, "admin");
const profileRoute = route(storeRoute, "profile");

const GLOBAL_ROUTES = {
  home: route(""),
  createStore: HAS_BOUND_STORE_ID ? route("") : route("store", "create"),
  notFound: route("404"),
  artistAlley: route("artist-alley"),
};

const STORE_ROUTES = {
  store: storeRoute,
  pastSale: route(storeRoute, "past", saleId),
  sale: route(storeRoute, "sale", saleId),
  artwork: route(storeRoute, "artwork", artworkId),
  createSale: route(storeRoute, "sale", "create"),
  createArtwork: route(storeRoute, "artwork", "create"),
};

const ADMIN_ROUTES = {
  admin: adminRoute,
  adminStorefront: route(adminRoute, "storefront"),
  adminArtworks: route(adminRoute, "artworks"),
};

const PROFILE_ROUTES = {
  profile: profileRoute,
  profileSales: route(profileRoute, "sales"),
  profileArtworks: route(profileRoute, "artworks"),
};

export const ROUTES = {
  ...GLOBAL_ROUTES,
  ...STORE_ROUTES,
  ...ADMIN_ROUTES,
  ...PROFILE_ROUTES,
} as const;
