import { SupplyType } from "../components/EditionSelector";

export const getSupplyValue = (
  supply: SupplyType,
  defaultSupply: number
): number => {
  if (supply === SupplyType.Unlimited) {
    return 0;
  }

  return supply === SupplyType.Single ? 1 : defaultSupply;
};
