import { Button, Stack } from "@chakra-ui/react";
import { Hero } from "components/Hero";
import { Layout } from "components/Layout";
import { NavbarCta } from "components/NavbarCta/NavbarCta";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { Link } from "react-router-dom";
import { usePaths } from "routes";
import { useWalletStore } from "state/react/useWalletStore";
import { store as mockStore } from "state/store/store.mock";
import metaplexLogo from "components/modals/HowToBuy/images/metaplex-logo.png";

export const Home: React.FC = () => {
  const { mdUp } = useCustomBreakpoints();
  const { data } = useWalletStore();
  const paths = usePaths();

  return (
    <Layout
      noUnderline
      cta={
        <NavbarCta
          aria-label="Create new store"
          as={Link}
          to={paths.createStore()}
        >
          Create
        </NavbarCta>
      }
    >
      <Hero
        logo={metaplexLogo}
        title="Launch NFTs from your own branded storefront"
        text="Discover why thousands of teams use Metaplex to share NFTs with their
      community."
      >
        <Stack
          direction={mdUp ? "row" : "column"}
          spacing={4}
          w={mdUp ? undefined : "full"}
        >
          {!data && (
            <Button flexGrow={1} as={Link} to={paths.createStore()}>
              Create Store
            </Button>
          )}
          {data && (
            <Button
              flexGrow={1}
              as={Link}
              to={paths.store({
                ":storeId": data.storeId,
              })}
            >
              {`Open your store "${data.name}"`}
            </Button>
          )}
          <Button
            flexGrow={1}
            variant="primary"
            as={Link}
            to={paths.store({
              ":storeId": mockStore.storeId,
            })}
          >
            Go to Test Store
          </Button>
        </Stack>
      </Hero>
    </Layout>
  );
};
