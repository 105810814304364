import { createMx } from "views/ItemView/sales.state";
import allowList from "views/ItemView/json/allowlist";
import { Connection, PublicKey } from "@solana/web3.js";
import { ICandyMachine } from "state/sales";
import { Wallet } from "wallet";
import { walletAdapterIdentity, getMerkleProof } from "@metaplex-foundation/js";

interface BuyProps {
  connection: Connection;
  wallet: Wallet;
  sale: ICandyMachine;
  group?: string;
}
export const createBuy = async ({
  connection,
  wallet,
  sale,
  group,
}: BuyProps) => {
  const mx = createMx(connection);
  mx.use(walletAdapterIdentity(wallet));
  const candyMachineAddress = new PublicKey(sale.id);
  const candyMachine = await mx
    .candyMachines()
    .findByAddress({ address: candyMachineAddress });

  if (group === "OG") {
    const verify = await mx.candyMachines().callGuardRoute({
      candyMachine,
      guard: "allowList",
      group,
      settings: {
        path: "proof",
        merkleProof: getMerkleProof(allowList, wallet.publicKey.toBase58()),
      },
    });
  }

  const mintTx = await mx.candyMachines().mint({
    candyMachine,
    collectionUpdateAuthority: candyMachine.authorityAddress,
    group,
  });

  const { response, nft } = mintTx;

  return { txs: [response], metadataAddress: "", mint: nft.address.toString() };
};
