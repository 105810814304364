import { attach, createEffect, StoreValue } from "effector";
import { loadSales } from "sdk/auction-house";
import { loadCandyMachines } from "sdk/candyMachine";
import { loadMarkets } from "sdk/fps/loadMarkets";
import { getArtworksOwnershipFilterFn } from "state/artworks/utils";
import { $connection, $network } from "state/connection";
import { $store, IStore } from "state/store";
import { $wallet } from "state/wallet";
import { ISale } from "./types";
import { isCandyMachine, populateAllSaleArtworks } from "./utils";

interface Sources {
  connection: StoreValue<typeof $connection>;
  wallet: StoreValue<typeof $wallet>;
  store: StoreValue<typeof $store>;
  network: StoreValue<typeof $network>;
}

const ERROR_PARAMS_UNINITIALIZED = new Error(
  "Store or wallet isn't initialized"
);

export const fetchStoreSalesFx = attach({
  effect: createEffect(
    async ({ connection, store, wallet, network }: Sources) => {
      if (!store) {
        throw ERROR_PARAMS_UNINITIALIZED;
      }

      const sales: ISale[][] = await Promise.all([
        loadMarkets({
          connection,
          store: store.storeFpsId,
          wallet,
          isAdmin: store.owner === wallet?.publicKey.toString(),
        }),
        loadSales({
          connection,
          auctionHouse: store.storeAhId,
        }),
        loadCandyMachines({
          connection,
          authority: store.owner,
          hardcodedList: store.candyMachines,
          network,
        }),
      ]);

      const pureSales = sales
        .flat()
        .filter(getSalesOwnershipFilterFn(store))
        .sort(sortById);

      return populateAllSaleArtworks(pureSales);
    }
  ),
  source: {
    connection: $connection,
    wallet: $wallet,
    store: $store,
    network: $network,
  },
});

const getSalesOwnershipFilterFn = (store: IStore) => {
  const artworksFilterFn = getArtworksOwnershipFilterFn(store);
  return (sale: ISale) =>
    isCandyMachine(sale) || artworksFilterFn(sale.artwork);
};
const sortById = (sale1: ISale, sale2: ISale) => (sale1.id > sale2.id ? 1 : -1);
