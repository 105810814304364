import {
  Box,
  Divider,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from "@chakra-ui/react";
import { Balance } from "components/MainSidebar/Balance";
import { UserInfo } from "components/MainSidebar/UserInfo";
import { PersonProps } from "components/Person";
import { SolUsdDisplay } from "components/SolUsdDisplay";

interface Props {
  user: PersonProps;
  balance?: React.ComponentProps<typeof SolUsdDisplay>;
  walletStoreId?: string | null;
  forceOpen?: boolean;
}

export const UserProfile: React.FC<Props> = ({
  children,
  user,
  balance,
  forceOpen,
}) => {
  return (
    <Popover
      modifiers={[
        {
          name: "preventOverflow",
          options: {
            padding: 32,
          },
        },
      ]}
      isOpen={forceOpen}
    >
      <PopoverTrigger>
        <Box>{children}</Box>
      </PopoverTrigger>
      <PopoverContent borderStyle="none" borderRadius="xl">
        <PopoverBody bgColor="gray.700" borderRadius="xl" p={0}>
          <UserInfo user={user} variant="profile-popover" m={4} mb={0} pb={0} />
          <Divider my={4} />
          <VStack spacing={6} m={4}>
            {balance ? <Balance sol={balance.sol} p={0} /> : null}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
