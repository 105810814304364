import { useMemo, useState } from "react";

import { MobileSidebar } from "./MobileSidebar";
import { Person } from "components/Person";
import { PersonButton } from "components/buttons/PersonButton";
import { UserProfile } from "components/modals/UserProfile";
import { useBalance } from "state/react/useBalance";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { ConnectWalletButton } from "components/ConnectWallet";

export interface ProfileButtonProps {
  user?: React.ComponentProps<typeof Person> | null;
}

export const ProfileButton: React.FC<ProfileButtonProps> = ({ user }) => {
  const refBalance = useBalance();
  const { mdUp } = useCustomBreakpoints();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const balance = useMemo(() => {
    return {
      sol: refBalance.balance?.sol,
    };
  }, [refBalance]);

  const handleToggleSidebar = () => {
    setSidebarOpen((current) => !current);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  if (user && mdUp) {
    return (
      <UserProfile user={user} balance={balance}>
        <PersonButton {...user} aria-label="Go to profile" variant="ghost" />
      </UserProfile>
    );
  }

  // On mobile we don't open profile popover, but rather open sidebar
  if (user && !mdUp) {
    return (
      <>
        <PersonButton
          {...user}
          onClick={handleToggleSidebar}
          aria-label="Go to profile"
          variant="ghost"
        />
        <MobileSidebar isOpen={sidebarOpen} onClose={handleCloseSidebar} />
      </>
    );
  }

  return (
    <ConnectWalletButton style={{ minHeight: 48 }}>
      Connect wallet
    </ConnectWalletButton>
  );
};
