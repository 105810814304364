import { FC, ReactNode } from "react";
import { Center, Divider, Flex } from "@chakra-ui/react";

import { DesktopTabs } from "./DesktopTabs";
import { ITab } from "..";
import { MobileTabs } from "./MobileTabs";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface Props {
  tabs: ITab[];
  currentTabIndex: number;
  hideActionButtons?: boolean;
  createButton?: ReactNode;
  onTabChange: (index: number) => void;
}

export const ArtworksListNavbar: FC<Props> = ({
  tabs,
  currentTabIndex,
  hideActionButtons,
  createButton,
  onTabChange,
}) => {
  const { mdUp } = useCustomBreakpoints();

  return (
    <>
      <Flex
        alignSelf="stretch"
        align="center"
        flexWrap="wrap"
        rowGap={6}
        justifyContent="space-between"
      >
        {mdUp && (
          <DesktopTabs
            tabs={tabs}
            currentTabIndex={currentTabIndex}
            onTabChange={onTabChange}
          />
        )}
        {!mdUp && (
          <MobileTabs
            tabs={tabs}
            currentTabIndex={currentTabIndex}
            onTabChange={onTabChange}
          />
        )}
        <Flex>{!hideActionButtons && createButton}</Flex>
      </Flex>

      {!mdUp && <Center my={3} />}
      {mdUp && <Divider my={2} />}
    </>
  );
};
