import { BaseSdkProps, ETransactionProgress } from "sdk/share";
import { sendTransactions } from "sdk/transactions";
import { createStoreTransaction } from "./actions/createStoreTransaction";

export type InitStoreProps = BaseSdkProps & {
  updateProgress?: (status: ETransactionProgress | null) => void;
};

export const initStore = async ({
  connection,
  wallet,
  progressChange,
}: InitStoreProps) => {
  progressChange?.(ETransactionProgress.creating);
  const { store, storeTx } = createStoreTransaction({
    wallet,
  });

  progressChange?.(ETransactionProgress.sending_to_solana);
  await sendTransactions(connection, wallet, [storeTx]);

  return { storeId: store };
};
