import {
  createWithdrawInstruction,
  findPayoutTicketAddress,
  findPrimaryMetadataCreatorsAddress,
  findTreasuryOwnerAddress,
} from "@metaplex-foundation/mpl-fixed-price-sale";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import { PublicKey, SYSVAR_CLOCK_PUBKEY } from "@solana/web3.js";

import { toPubkey } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";
import { Wallet } from "wallet";
import { IArtCreator } from "state/artworks";

export interface WithdrawProps {
  wallet: Wallet;
  creator: IArtCreator;
  metadata: PublicKey;
  market: PublicKey;
  marketData: {
    sellingResource: string;
    treasuryHolder: string;
    treasuryMint: string;
    vault: string;
  };
}

export const createWithdrawTransaction = async ({
  wallet,
  creator,
  metadata,
  market,
  marketData,
}: WithdrawProps) => {
  const [primaryMetadataCreators] = await findPrimaryMetadataCreatorsAddress(
    metadata
  );

  const [payoutTicket, payoutTicketBump] = await findPayoutTicketAddress(
    market,
    toPubkey(creator.address)
  );

  const { treasuryMint, treasuryHolder, sellingResource } = marketData;
  const [treasuryOwner, treasuryOwnerBump] = await findTreasuryOwnerAddress(
    toPubkey(treasuryMint),
    toPubkey(sellingResource)
  );

  const destination = await getAssociatedTokenAddress(
    toPubkey(treasuryMint),
    toPubkey(creator.address)
  );

  const ix = createWithdrawInstruction(
    {
      market,
      sellingResource: toPubkey(sellingResource),
      metadata,
      treasuryHolder: toPubkey(treasuryHolder),
      treasuryMint: toPubkey(treasuryMint),
      owner: treasuryOwner,
      destination,
      funder: toPubkey(creator.address),
      payer: wallet.publicKey,
      payoutTicket,
      associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      primaryMetadataCreators: [primaryMetadataCreators],
      clock: SYSVAR_CLOCK_PUBKEY,
    },
    {
      treasuryOwnerBump,
      payoutTicketBump,
    }
  );

  const withdrawTx = new TransactionsBatch({ instructions: [ix] });

  return withdrawTx;
};
