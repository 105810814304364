import { attach, combine, createEffect, sample, StoreValue } from "effector";
import { debounce } from "patronum";
import { closeEscrowBalance, loadEscrowBalance } from "sdk/auction-house";
import { $connection } from "state/connection";
import { $store } from "state/store";
import { $wallet } from "state/wallet";

interface Props {
  connection: StoreValue<typeof $connection>;
  wallet: StoreValue<typeof $wallet>;
  store: StoreValue<typeof $store>;
}

export const getEscrowBalanceFx = attach({
  effect: createEffect(async ({ wallet, connection, store }: Props) => {
    if (!wallet || !store?.storeAhId) {
      return null;
    }

    return loadEscrowBalance(connection, store.storeAhId, wallet);
  }),
  source: { wallet: $wallet, connection: $connection, store: $store },
});

export const closeEscrowBalanceFx = attach({
  effect: createEffect(async ({ wallet, connection, store }: Props) => {
    if (!wallet || !store?.storeAhId) {
      return null;
    }

    return closeEscrowBalance(connection, store.storeAhId, wallet);
  }),
  source: { wallet: $wallet, connection: $connection, store: $store },
});

const somethingChagned = debounce({
  source: combine([$connection, $wallet, $store]),
  timeout: 2000,
});

sample({
  clock: somethingChagned,
  target: getEscrowBalanceFx,
});
