import { ISale, getSalePrice, isActiveSale, isCandyMachine } from "state/sales";

import { ArtworkCardColumn } from "../ArtworkCardColumn";
import { Button } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/layout";
import React from "react";
import { SolUsdDisplay } from "components/SolUsdDisplay";
import { TimeEntry } from "components/TimeEntry";
import { artworkCardStateProps } from "./artworkCardStateProps";

interface Props {
  sale: ISale;
  onBuy?: () => void;
}

export const ArtworkCardInstantSaleState: React.FC<Props> = ({
  sale,
  onBuy,
}) => {
  const price = getSalePrice(sale) || 0;
  const liveAtDate = isCandyMachine(sale) && sale.liveAtDate;

  return (
    <Flex {...artworkCardStateProps}>
      <ArtworkCardColumn title="PRICE">
        <SolUsdDisplay sol={price} />
      </ArtworkCardColumn>

      {onBuy && (
        <Button ml="auto" variant="tertiary">
          Buy Now
        </Button>
      )}
    </Flex>
  );
};
