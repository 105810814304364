import { Box, Button, Stack, Text } from "@chakra-ui/react";
import { useStore } from "effector-react";
import React from "react";
import { closeEscrowBalanceFx } from "state/sales";
import { lamportsToSol } from "utils/lamportsToSol";

export const WithdrawEscrow: React.FC<{ balance: number }> = ({ balance }) => {
  const pending = useStore(closeEscrowBalanceFx.pending);

  const handler = () => {
    closeEscrowBalanceFx();
  };

  return (
    <>
      <Box mt="5">
        <Text>
          You have <b>{lamportsToSol(balance).toFixed(2)} SOL</b> locked.
          <br />
          Would you like to withdraw?
        </Text>
      </Box>

      <Stack mt={6}>
        <Button variant="notification" isLoading={pending} onClick={handler}>
          Withdraw
        </Button>
      </Stack>
    </>
  );
};
