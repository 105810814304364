import { Box, FlexProps, Heading, Stack, Text, VStack } from "@chakra-ui/react";

import { ConditionalWrapper } from "components/utility/ConditionalWrapper";
import { DesktopHeroWrapper } from "./DesktopHeroWrapper";
import { HeroLogo } from "./HeroLogo";
import { MobileCoverImage } from "./MobileCoverImage";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface Props extends FlexProps {
  title: string;
  text: string;
  logo?: string;
  coverImage?: string;
}

export const Hero: React.FC<Props> = ({
  title,
  text,
  logo,
  coverImage,
  children,
  ...props
}) => {
  const { mdUp } = useCustomBreakpoints();

  return (
    <ConditionalWrapper
      wrap={mdUp}
      wrapper={(wrapperChildren) => (
        <DesktopHeroWrapper coverImage={coverImage} {...props}>
          {wrapperChildren}
        </DesktopHeroWrapper>
      )}
    >
      <VStack align="start" spacing={mdUp ? 8 : 6} mb={!mdUp ? 6 : undefined}>
        <ConditionalWrapper
          wrap={!mdUp}
          wrapper={(wrapperChildren) => (
            <Box position="relative" pb="64px" minH="128px">
              {wrapperChildren}
            </Box>
          )}
        >
          <>
            {logo && <HeroLogo logo={logo} />}
            {!mdUp && <MobileCoverImage coverImage={coverImage} />}
          </>
        </ConditionalWrapper>

        <VStack align="start">
          <Heading variant={mdUp ? "h2" : "h3"}>{title}</Heading>
          <Text
            variant={mdUp ? "body-large" : "subtitle-2"}
            overflowWrap="anywhere"
          >
            {text}
          </Text>
        </VStack>

        {children && (
          <Stack
            direction={mdUp ? "row" : "column"}
            spacing={{ base: 2, md: 4 }}
            mt={8}
            w="full"
          >
            {children}
          </Stack>
        )}
      </VStack>
    </ConditionalWrapper>
  );
};
