import { createEvent, restore } from "effector";

interface HowToBuyModalState {
  isOpen: boolean;
  storeName?: string;
}

export const setHowToBuyModalState = createEvent<HowToBuyModalState>();
export const $howToBuyModalState = restore(setHowToBuyModalState, {
  isOpen: false,
});
