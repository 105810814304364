import { WhitelistMintMode } from "@metaplex-foundation/mpl-candy-machine";
import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import { IArt, IArtCreator } from "state/artworks";

export enum SaleState {
  Uninitialized = 0,
  Created = 1,
  Suspended = 2,
  Active = 3,
  Ended = 4,
  SoldOut = 5,
  Broken = 6,
}

export enum SaleType {
  FixedPrice = "fixed-price",
  InstantSale = "instant-sale",
  TimedAuction = "timed-auction",
  CandyMachine = "candy-machine",
}

export type UnixTimestamp = number;

export interface ISaleFPGate {
  collection: StringPublicKey;
  expireOnUse: boolean;
  gatingTime?: number; // date
}

export interface ISaleCMGate {
  mode: WhitelistMintMode;
  mint: string;
  presale: boolean;
  discountPrice?: number;
}

export interface ISaleGatekeeper {
  gatekeeperNetwork: StringPublicKey;
  expireOnUse: boolean;
}

export interface ISaleBase {
  type: SaleType;
  id: StringPublicKey;
  artwork: IArt;
  state: SaleState;
  isSecondary?: boolean;
  earnings?: number; // move to FP
  isWithdrawn?: boolean; // move to FP
  endDate?: UnixTimestamp;
  groups?: any[];
}

/**
 * Instant sale via FixedPriceSale
 */
export interface IFixedPrice extends ISaleBase {
  type: SaleType.FixedPrice;
  price: number;
  startDate: UnixTimestamp;
  piecesInOneWallet?: number;
  maxSupply?: number;
  gate?: ISaleFPGate;
  isWithdrawn?: boolean;
  primaryCreators: IArtCreator[];
  refs: {
    sellingResource: StringPublicKey;
    treasuryHolder: StringPublicKey;
    treasuryMint: StringPublicKey;
    vault: StringPublicKey;
    resource: StringPublicKey;
    seller: StringPublicKey;
  };
}

/**
 * Instant sale via AuctionHouse
 */
export interface IInstantSale extends ISaleBase {
  type: SaleType.InstantSale;
  price: number;
  refs: {
    tradeState: StringPublicKey;
    seller: StringPublicKey;
  };
}

/**
 * Auction via AuctionHouse
 * not working yet
 */
export interface ITimedAuction extends ISaleBase {
  type: SaleType.TimedAuction;
  endAuctionAt?: number;
  minPrice: number;
  gap?: number;
  gapTickSize?: number;
  tickSize?: number;
}

/**
 * Instant sale via CandyMachine
 */
export interface ICandyMachine extends ISaleBase {
  type: SaleType.CandyMachine;
  price: number;
  symbol: string;
  itemsRedeemed: number;
  itemsAvailable: number;
  liveAtDate?: UnixTimestamp;
  stopAtDate?: UnixTimestamp;
  stopAtAmount?: number;
  gate?: ISaleCMGate;
  gatekeeper?: ISaleGatekeeper;
  refs: {
    seller: StringPublicKey;
    tokenMint?: StringPublicKey;
    authority: StringPublicKey;
    retainAuthority: boolean;
  };
}

/**
 * Union type for all sales
 */
export type ISale = IFixedPrice | IInstantSale | ITimedAuction | ICandyMachine;
