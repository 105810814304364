import { MainSidebarContent } from "components/MainSidebar";
import { DrawerSidebar } from "components/Sidebar/DrawerSidebar";
import { MobileSidebarHeader } from "components/Sidebar/DrawerSidebar/MobileSidebarHeader";
import { useStore } from "effector-react";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { FC } from "react";
import { $isStoreAdmin } from "state/store";
import { $user } from "state/wallet";

export interface SidebarProps {
  isOpen?: boolean;
  focused?: boolean;
  onClose?: () => void;
}

export const MobileSidebar: FC<SidebarProps> = ({
  isOpen = false,
  onClose,
}) => {
  const { mdUp } = useCustomBreakpoints();
  const isAdmin = useStore($isStoreAdmin);
  const user = useStore($user);

  if (mdUp) return null;

  const handleClose = () => {
    onClose?.();
  };

  return (
    <DrawerSidebar isOpen={isOpen} onClose={onClose}>
      <MobileSidebarHeader />
      <MainSidebarContent
        onClose={isAdmin ? handleClose : undefined}
        user={isAdmin ? undefined : user}
      />
    </DrawerSidebar>
  );
};
