import { MetaDataJsonCategory } from "sdk/nft";

export enum FileType {
  AUDIO,
  VIDEO,
  IMAGE,
  VR,
  HTML,
}

export const FiletypeAcceptMap = {
  [FileType.HTML]: "HTML",
  [FileType.IMAGE]: ".png,.jpg,.svg,.gif",
  [FileType.VIDEO]: ".mp4",
  [FileType.VR]: ".glb",
  [FileType.AUDIO]: ".mp3",
};

export const FiletypeDescMap = {
  [FileType.HTML]: "HTML",
  [FileType.IMAGE]: [
    "We recommend an image of at least 600x600.",
    "JPG, PNG, GIF",
  ],
  [FileType.VIDEO]: "File types supported: mp4. Max size 100MB",
  [FileType.VR]: "File types supported: glb. Max size 100MB",
  [FileType.AUDIO]: "File types supported: mp3. Max size 100MB",
};

export const FiletypeToMetadataCategory: {
  [type in FileType]: MetaDataJsonCategory;
} = {
  [FileType.HTML]: "html",
  [FileType.IMAGE]: "image",
  [FileType.VIDEO]: "video",
  [FileType.VR]: "vr",
  [FileType.AUDIO]: "audio",
};
