import { useMemo, useCallback } from "react";
import { useStore } from "effector-react";

import { createProgressTools } from "utils/createProgressTools";

import { useProgressSteps } from "./useProgressSteps";

interface Props {
  stepsEnum: Record<number | string, number | string>;
  getContent: (state: number | null) => {
    title: string;
    subtitle: string;
  };
}

export function useLocalProgress({ stepsEnum, getContent }: Props) {
  const progress = useMemo(
    () => createProgressTools(getContent, null),
    [getContent]
  );
  const currentStep = useStore(progress.$progress.$node);
  const getStepTitle = useCallback(
    (step: number | null) => getContent(step).title,
    [getContent]
  );
  const steps = useProgressSteps({
    stepsEnum,
    currentStep,
    getStepTitle,
  });

  return { ...progress, steps };
}
