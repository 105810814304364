import { Tab, TabList, Tabs, Text } from "@chakra-ui/react";

import { FC } from "react";
import { ITab } from "components/ArtworksList/ArtworksList";

interface DesktopTabsProps {
  tabs?: ITab[];
  currentTabIndex?: number;
  onTabChange: (index: number) => void;
}

export const DesktopTabs: FC<DesktopTabsProps> = ({
  tabs = [],
  currentTabIndex = 0,
  onTabChange,
}) => (
  <Tabs
    onChange={onTabChange}
    defaultIndex={currentTabIndex === -1 ? undefined : currentTabIndex}
  >
    <TabList>
      {tabs.map((tab) => (
        <Tab key={tab.id}>
          {tab.title}
          {typeof tab.count === "number" && (
            <Text fontWeight={400} ml={2}>
              {tab.count}
            </Text>
          )}
        </Tab>
      ))}
    </TabList>
  </Tabs>
);
