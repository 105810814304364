import React, { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { VStack } from "@chakra-ui/layout";
import { Text, HStack } from "@chakra-ui/react";
import { TimeDurationInput } from "components/TimeDurationInput";
import { CollectionAddressCell } from "components/forms/SaleCreate/CollectionAddressCell";
import { LabelBlock } from "components/TitledBlock";
import { Toggle } from "components/Toggle";

export const GatedAccess: React.FC = () => {
  const { unregister } = useFormContext();
  const [gatingVisibility, setGatingVisibility] = useState(false);

  const handleRemoveGating = useCallback(
    (value: boolean) => {
      if (!value) {
        unregister("collectionAddress");
        unregister("gatingTime");
      }

      setGatingVisibility(value);
    },
    [unregister]
  );

  return (
    <VStack alignItems="left" w="full">
      <HStack alignItems="flex-start" justifyContent="space-between">
        <LabelBlock title="GATED ACCESS" bright>
          <Text variant="subtitle" color="whiteAlpha.500">
            Limit early access to this sale using a collection address
          </Text>
        </LabelBlock>
        <Toggle mb={4} onChange={handleRemoveGating} value={gatingVisibility} />
      </HStack>

      {gatingVisibility && (
        <VStack spacing={6} w="full">
          <CollectionAddressCell
            id="collectionAddress"
            placeholder="Enter collection address"
          />

          <TimeDurationInput
            defaultValue="0"
            id="gatingTime"
            title="Gating duration"
            description="How long do you want to limit access?"
          />
        </VStack>
      )}
    </VStack>
  );
};
