import { Outlet, useLocation } from "react-router-dom";

import { FC } from "react";
import { Layout } from "components/Layout";
import { MainSidebarContent } from "components/MainSidebar";

export const AdminLayout: FC = () => {
  const { pathname } = useLocation();

  const sidebar = <MainSidebarContent />;
  const variant = pathname.includes("storefront") ? "narrow" : "medium";

  return (
    <Layout
      sidebarContent={sidebar}
      variant={variant}
      gutterBottom={pathname.includes("storefront")}
    >
      <Outlet />
    </Layout>
  );
};
