import { RouteObject } from "react-router-dom";
import { List } from "ts-toolbelt";
import { IVariable, IRoute, ISegment, ISchema } from "./types";

export function variable<T extends string>(
  path: T,
  skip?: boolean
): IVariable<T> {
  return {
    path,
    skip,
  };
}

export function createRoute(segments: ISegment[]): IRoute {
  const route: IRoute = { segments };
  return route;
}

export function route<T extends ISegment[]>(...segments: T): IRoute<T>;
export function route<R extends ISegment[], T extends ISegment[]>(
  root: IRoute<R>,
  ...segments: T
): IRoute<List.Concat<R, T>>;
export function route(
  first: IRoute<ISegment[]> | ISegment,
  ...rest: ISegment[]
) {
  const segments = isRoute(first)
    ? [...first.segments, ...rest]
    : [first, ...rest];
  const route = createRoute(segments);
  return route;
}

function isRoute(route: IRoute<ISegment[]> | ISegment): route is IRoute {
  return !!(route as unknown as IRoute).segments;
}

export function toPath(route: IRoute): string {
  const pieces = route.segments
    .map((segment) => {
      if (typeof segment === "string") return segment;
      return (!segment.skip && segment.path) || "";
    })
    .filter((piece) => !!`${piece}`);
  return `/${pieces.join("/")}`;
}

// TODO: strict types for params
export function toBuildFn(
  route: IRoute,
  defaultParams?: Record<string, string | number | undefined>
) {
  return (params?: Record<string, string | number>): string => {
    const allParams = { ...defaultParams, ...params };
    const pieces = route.segments
      .map((segment) => {
        if (typeof segment === "string") {
          return segment;
        }
        if (segment.skip) return "";
        const piece = allParams[segment.path];
        if (piece === undefined) {
          // eslint-disable-next-line no-console
          console.info(`WARNING: missed path param: ${segment.path}`);
          return "";
        }
        return piece;
      })
      .filter((piece) => !!`${piece}`);
    return `/${pieces.join("/")}`;
  };
}

export function buildSchema(schema: ISchema[]): RouteObject[] {
  return schema.map(({ route, path, children, ...props }) => ({
    ...props,
    path: route ? toPath(route) : path,
    children: children && buildSchema(children),
  }));
}
