import { getSalePrice, ISale } from "state/sales";
import { useEarlyAccessConfig } from "./useEarlyAccessConfig";
import { useGating } from "./useGating";

export const useSalePrice = (sale?: ISale | null) => {
  const earlyAccessConfig = useEarlyAccessConfig(sale);
  const gate = useGating(sale);

  if (earlyAccessConfig?.discountPrice && gate.isEligible) {
    return earlyAccessConfig.discountPrice;
  }

  return (sale && getSalePrice(sale)) ?? undefined;
};
