import { attach, createEffect } from "effector";
import { $connection } from "state/connection";
import { $store, IStore } from "state/store";
import { checkTreasureBalance } from "sdk/auction-house/checkTreasureBalance";
import { Connection } from "@solana/web3.js";
import { addNotification } from "state/notifications";
import { withdrawFromTreasuryFx } from "./withdrawFromTreasureFx";
import { lamportsToSol } from "utils/lamportsToSol";

export const checkTreasureBalanceAndNotifyUserFx = attach({
  effect: createEffect(
    async ({
      connection,
      store,
    }: {
      connection: Connection;
      store: IStore | null;
    }) => {
      if (!store) {
        throw new Error("Empty store or wallet!");
      }

      const lamports = await checkTreasureBalance(connection, store.storeAhId);

      if (lamports > 0) {
        addNotification({
          id: `withdraw-fee`,
          title: "You have fee to withdraw",
          content: `You have ${lamportsToSol(
            lamports
          )} SOL from treasure account to be withdrawn`,
          actions: [
            {
              children: "Withdraw",
              onClick: () => {
                withdrawFromTreasuryFx({ amount: lamports });
              },
            },
          ],
        });
      }
    }
  ),
  source: {
    connection: $connection,
    store: $store,
  },
});
