import React, { useCallback, useState } from "react";
import { isMobile } from "react-device-detect";

import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { ButtonProps } from "@solana/wallet-adapter-react-ui/lib/Button";

import { ConnectWalletOptimize } from "./ConnectWalletOptimize";
import { WalletName } from "@solana/wallet-adapter-base";

export const ConnectWalletButton: React.FC<ButtonProps> = ({
  children,
  ...props
}) => {
  const { select } = useWallet();

  const [isOpened, setOpenState] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isPhantomWalletApp = isMobile && window.solana?.isPhantom;

  const handleConnectClick = useCallback(
    (event: React.SyntheticEvent) => {
      if (isPhantomWalletApp) {
        event.preventDefault();
        select("Phantom" as WalletName);
      } else if (isMobile) {
        event.preventDefault();
        setOpenState(true);
      }
    },
    [isPhantomWalletApp, select]
  );

  const handleModalClose = useCallback(() => setOpenState(false), []);

  return (
    <>
      <WalletMultiButton onClick={handleConnectClick} {...props}>
        {children}
      </WalletMultiButton>

      {!isPhantomWalletApp && isMobile && (
        <ConnectWalletOptimize isOpen={isOpened} onClose={handleModalClose} />
      )}
    </>
  );
};
