import { AuctionHouse } from "@metaplex-foundation/mpl-auction-house/dist/src/generated/accounts";
import { createUpdateAuctionHouseInstruction } from "@metaplex-foundation/mpl-auction-house/dist/src/generated/instructions";
import { Connection, PublicKey } from "@solana/web3.js";
import { loadAccountAndDeserialize } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";
import { Wallet } from "wallet";

export interface UpdateProps {
  connection: Connection;
  auctionHouse: PublicKey;
  sellerFeeBasisPoints: number;
  wallet: Wallet;
}

export const createUpdateAuctionHouse = async ({
  connection,
  auctionHouse,
  sellerFeeBasisPoints: newSalerFeeBasisPoints,
  wallet,
}: UpdateProps) => {
  const { treasuryMint, sellerFeeBasisPoints } =
    await loadAccountAndDeserialize(connection, AuctionHouse, auctionHouse);

  if (newSalerFeeBasisPoints === sellerFeeBasisPoints) return undefined;

  const updateIx = createUpdateAuctionHouseInstruction(
    {
      auctionHouse,
      treasuryMint,
      authority: wallet.publicKey,
      payer: wallet.publicKey,
      newAuthority: wallet.publicKey,
      feeWithdrawalDestination: wallet.publicKey,
      treasuryWithdrawalDestination: wallet.publicKey,
      treasuryWithdrawalDestinationOwner: wallet.publicKey,
    },
    {
      sellerFeeBasisPoints: newSalerFeeBasisPoints,
      requiresSignOff: false,
      canChangeSalePrice: false,
    }
  );

  const tx = new TransactionsBatch({
    instructions: [updateIx],
  });

  return tx;
};
