import { FC } from "react";
import { Layout } from "components/Layout";
import { Flex, Text, Button, VStack } from "@chakra-ui/react";

import { Background } from "./components/Background";

export const SIGN_UP_LINK =
  "https://docs.google.com/forms/d/e/1FAIpQLSevY9Kcf1YwEzv6GPUqNcuZjjgExdjQyiRoWBabAjx9LHk2Vg/viewform";

export const ArtistAlley: FC = () => {
  return (
    <Layout variant="full" transparentNavbar noPadding>
      <Flex
        bg="#000000"
        bgGradient="linear(brown.300 0%,
        rgba(230, 92, 55, 0%) 80%,
        rgba(230, 92, 55, 0%) 100%)"
        minHeight="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <VStack
          justifyContent="center"
          alignItems="center"
          spacing={8}
          zIndex="1"
        >
          <VStack paddingInline="30px" textAlign="center">
            <Text fontSize="48px" fontWeight="bold" lineHeight="short">
              Sell your art on OddKey
            </Text>
            <Text
              color="white.700"
              fontStyle="regular"
              fontSize="18px"
              lineHeight="short"
              maxW="675px"
              marginBottom={10}
            >
              Oddkey gives you complete creative and intellectual control of
              your NFT art. Sign up below and we will be in touch.
            </Text>
          </VStack>
          <Button
            colorScheme="#E65C37"
            bg="#E65C37"
            size="lg"
            as="a"
            href={SIGN_UP_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign Up
          </Button>
        </VStack>
        <Background />
      </Flex>
    </Layout>
  );
};
