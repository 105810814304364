import type { SystemStyleObject } from "@chakra-ui/theme-tools";
import { Text } from "./Text";

const baseStyle: SystemStyleObject = {
  ...Text.variants["label-bold"],
  fontWeight: 600,
  color: "white",
  marginEnd: 3,
  mb: 2,
};

export const FormLabel = {
  baseStyle,
};
