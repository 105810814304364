import { IFormData } from "components/forms/NftCreate";
import {
  FileType,
  FiletypeToMetadataCategory,
} from "components/MediaTypeSelector";
import { MetadataJson, MetadataJsonCreator } from "sdk/nft";
import { excludesFalsy } from "utils/excludeFalsy";

export function createMetadata(
  address: string | IFormData["splits"] | null,
  formData: Partial<IFormData>
) {
  let creators: MetadataJsonCreator[] = [];
  if (typeof address === "string") {
    creators = [
      {
        address,
        share: 100,
      },
    ];
  } else if (Array.isArray(address)) {
    creators = address.map(({ key, value }) => ({
      address: key,
      share: value,
    }));
  }

  const previewAsImage = formData.mediaType !== FileType.IMAGE;
  const imageFile = previewAsImage ? formData.preview : formData.file;
  const contentFile = previewAsImage ? formData.file : undefined;

  const image = imageFile instanceof File ? imageFile.name : imageFile;
  const content = contentFile instanceof File ? contentFile.name : contentFile;

  const files = [imageFile, contentFile]
    .map((file) => (file instanceof File ? file : undefined))
    .filter(excludesFalsy)
    .map((file) => ({ uri: file.name, type: file.type }));

  const metadata: MetadataJson = {
    name: formData.title ?? "",
    symbol: formData.symbol ?? "",
    description: formData.desc ?? "",
    seller_fee_basis_points: Number(formData.royalty ?? 0) * 100,
    image: image ?? "",
    animation_url: content,
    attributes: formData.properties?.map(({ key, value }) => ({
      trait_type: key,
      value: value,
    })),
    external_url: "",
    properties: {
      files,
      category:
        FiletypeToMetadataCategory[formData.mediaType ?? FileType.IMAGE],
      creators,
    },
  };
  return metadata;
}
