export const layerStyles = {
  base: {
    bg: "whiteAlpha.50",
    borderRadius: "xl",
    padding: 4,
  },
  active: {
    bg: "whiteAlpha.100",
    outline: "1px solid",
    outlineColor: "white",
  },
  error: {
    bg: "whiteAlpha.50",
    outline: "1px solid",
    outlineColor: "red.900",
  },
};
