import { useStore } from "effector-react";
import { FC } from "react";
import { Navigate } from "react-router-dom";
import { usePaths } from "routes";
import { $isStoreAdmin } from "state/store";

export const AdminStoreGuard: FC = ({ children }) => {
  const isStoreAdmin = useStore($isStoreAdmin);

  const { profile } = usePaths();

  return isStoreAdmin ? <>{children}</> : <Navigate to={profile()} replace />;
};
