import { Button, ButtonProps, HStack, Text } from "@chakra-ui/react";
import {
  ISale,
  SaleState,
  getSaleStartDate,
  isActiveSale,
  isEndedSale,
} from "state/sales";
import { useState } from "react";

import { FC } from "react";
import { TimeEntry } from "components/TimeEntry";
import { useBalance } from "state/react/useBalance";
import { useGating } from "hooks/useGating";

interface Props {
  sale: ISale;
  group: string;
  handler?: () => void;
  isStarted: boolean;
}

export const BuyButton: FC<Props & ButtonProps> = ({
  sale,
  group,
  isStarted,
  handler,
  disabled,
  ...props
}) => {
  const { balance } = useBalance();
  const { isEligible, pending } = useGating(sale);
  const solPrice = 1.52;
  const hasEnoughMoney = Boolean(
    balance && solPrice && balance?.sol >= solPrice
  );

  const isActive = isActiveSale(sale);
  const isEnded = isEndedSale(sale);
  const startAt = getSaleStartDate(sale);
  // 0 "Uninitialized"
  // 1 "Created"
  // 2 "Suspended"
  // 3 "Active"
  // 4 "Ended"
  // 5 "SoldOut"
  // 6 "Broken"
  const isSoldOut =
    // @ts-ignore
    Number(sale?.itemsAvailable) === Number(sale?.itemsRedeemed);
  const [isNotStartedYet, setIsNotStartedYet] = useState(!isActive);
  const forbidden =
    !hasEnoughMoney || !isEligible || isNotStartedYet || isEnded || isSoldOut;

  return (
    <Button
      variant="primary"
      w="full"
      {...props}
      isLoading={pending}
      disabled={!isStarted || pending || forbidden || disabled}
      onClick={handler}
    >
      {isSoldOut ? (
        "Sold out"
      ) : isEnded ? (
        "Sale ended"
      ) : isNotStartedYet ? (
        <HStack as="span">
          <Text>Sale begins in</Text>
          <TimeEntry
            countdown={startAt}
            hideZeroUnits
            noUnitColor
            as="span"
            onComplete={() => setIsNotStartedYet(false)}
          />
        </HStack>
      ) : !hasEnoughMoney ? (
        "You do not have enough SOL"
      ) : !isEligible ? (
        "Your wallet cannot buy this item"
      ) : (
        "Buy NFT"
      )}
    </Button>
  );
};
