import { Stack, Text, StackDivider } from "@chakra-ui/layout";
import { SupplyDetails } from "components/Artwork/shared/ArtworkDetails/SupplyDetails";
import { TitledBlock } from "components/TitledBlock";
import React from "react";
import { IPrintNumbers } from "state/artworks";
import { truncateInMiddle } from "utils/truncateInMiddle";

interface IStatsProps {
  type: string;
  prints?: IPrintNumbers;
  creator: string;
}

export const Stats: React.FC<IStatsProps> = ({
  type,
  creator,
  prints,
}: IStatsProps) => {
  const textWrapper = (text: string) => (
    <Text variant="body" fontSize="sm" fontWeight="bold">
      {text.toUpperCase()}
    </Text>
  );

  return (
    <Stack
      direction="row"
      divider={<StackDivider borderColor="whiteAlpha.100" />}
      spacing={6}
    >
      {creator && (
        <TitledBlock title="creator">
          {textWrapper(truncateInMiddle(creator))}
        </TitledBlock>
      )}

      <TitledBlock title="edition">{textWrapper(type)}</TitledBlock>
    </Stack>
  );
};
