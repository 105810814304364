import React from "react";

import {
  Button,
  Image,
  ModalCloseButton,
  ModalHeader,
  ModalProps,
  Text,
} from "@chakra-ui/react";

import imgPhantomIcon from "components/modals/HowToBuy/images/phantom-icon.png";
import { ModalTemplate } from "components/modals/template";

export const ConnectWalletOptimize: React.FC<Omit<ModalProps, "children">> = ({
  isOpen,
  onClose,
}) => {
  const url = new URL(
    encodeURIComponent(window.location.href),
    "https://phantom.app/ul/browse/"
  ).toString();

  return (
    <ModalTemplate
      bodyProps={{ bgColor: "whiteAlpha.50", borderRadius: 12, p: 6 }}
      contentProps={{ bgColor: "gray.800", borderRadius: 12, mx: 6 }}
      header={
        <ModalHeader alignItems="center" display="flex" fontSize="lg">
          Optimize for Mobile
          <ModalCloseButton />
        </ModalHeader>
      }
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <Text fontSize="sm">
        Buying and selling NFTs is not supported while using a web browser. We
        recomend using Phantom because it integrates seamlessly.
      </Text>

      <Text fontSize="sm" mt={5}>
        Open Store Name in Phantom by clicking the button below.
      </Text>

      <Button as="a" href={url} mt={8}>
        <Image mr={2} src={imgPhantomIcon} w={6} />
        Open with Phantom
      </Button>
    </ModalTemplate>
  );
};
