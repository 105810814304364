import dayjs from "dayjs";
import { useStore } from "effector-react";
import { useMemo } from "react";
import { $profileArtworks, IArt } from "state/artworks";
import { useSplBalance } from "state/react/useSplBalance";
import {
  ICandyMachine,
  IFixedPrice,
  ISale,
  isCandyMachine,
  isFixedPrice,
} from "state/sales";
import { useEarlyAccessConfig } from "./useEarlyAccessConfig";

export const useGating = (sale?: ISale | null) => {
  const profileArtworks = useStore($profileArtworks);

  const gate = useEarlyAccessConfig(sale);
  const shouldGate = shouldCallGatekeeper(sale);

  const token = (sale && isCandyMachine(sale) && gate?.mint) || undefined;
  const { balance: splBalance, pending: pendingSplBalance } =
    useSplBalance(token);

  // XXX: disable pending Artwork due to speed up checks
  // const pending = pendingArtworks || pendingSplBalance;
  const pending = pendingSplBalance;

  const gatingArtwork = useMemo(() => {
    if (!sale || !shouldGate) return;

    const selector = findSelector(sale);
    return selector ? profileArtworks.find(selector) : undefined;
  }, [sale, shouldGate, profileArtworks]);

  const isEligible = useMemo(() => {
    if (!shouldGate) return true;

    const isSplOk = splBalance && splBalance > 0;
    const isArtworkOk = Boolean(gatingArtwork);
    return isSplOk || isArtworkOk;
  }, [shouldGate, gatingArtwork, splBalance]);

  return { pending, isEligible, gatingArtwork, gate };
};

const shouldCallGatekeeper = (sale?: ISale | null): boolean => {
  if (!sale) {
    return false;
  }

  if (isFixedPrice(sale)) {
    return Boolean(sale.gate);
  }

  if (isCandyMachine(sale)) {
    const whitelistMint = sale.gate?.mint;
    const isPresale = sale.gate?.presale;
    const liveDate = sale.liveAtDate;
    const isPublicLive =
      isPresale && (liveDate ? liveDate < dayjs().unix() : false);

    return Boolean(!isPublicLive && !!whitelistMint);
  }

  return false;
};

const findSelector = (sale: ISale): ((art: IArt) => boolean) | null => {
  if (isFixedPrice(sale)) {
    return fixedPriceSaleGatekeeper(sale);
  }
  if (isCandyMachine(sale)) {
    return candyMachinGatekeeper(sale);
  }

  return null;
};

const fixedPriceSaleGatekeeper = (
  sale: IFixedPrice
): ((art: IArt) => boolean) => {
  return ({ collection }: IArt) =>
    collection?.address === sale.gate?.collection &&
    Boolean(collection?.verified);
};

const candyMachinGatekeeper = (
  sale: ICandyMachine
): ((art: IArt) => boolean) => {
  const whitelistMint = sale.gate?.mint;

  return ({ mint }: IArt) => Boolean(!whitelistMint || mint === whitelistMint);
};
