import { Cluster } from "@solana/web3.js";
import { attach, Effect } from "effector";
import { composeKey, readCacheFx, writeCacheFx } from "state/cache";
import { $network } from "state/connection";
import { IStore } from "./types";

const getKey = (storeId?: string, network?: Cluster) =>
  composeKey([network, "store", storeId]);

export const readCachedStoreFx = attach({
  effect: readCacheFx,
  source: { network: $network },
  mapParams: (storeId: string, { network }) => ({
    key: getKey(storeId, network),
  }),
}) as Effect<string, IStore | null>;

export const writeCachedStoreFx = attach({
  effect: writeCacheFx,
  source: { network: $network },
  mapParams: (value: IStore | null, { network }) => ({
    value,
    key: getKey(value?.storeId, network),
  }),
});
