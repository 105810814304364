import {
  findTradeHistoryAddress,
  findVaultOwnerAddress,
} from "@metaplex-foundation/mpl-fixed-price-sale";
import { AccountMeta, Connection, PublicKey } from "@solana/web3.js";
import {
  findEditionAddress,
  findEditionMarkerAddress,
  findMetadataAddress,
} from "sdk/nft";
import { createWrappedAccount, toPubkey } from "sdk/share";
import { IArt } from "state/artworks";
import { IFixedPrice } from "state/sales";
import { solToLamports } from "utils/lamportsToSol";
import { Wallet } from "wallet";
import { createBuyTransaction } from "./createBuyTransaction";

interface BuyProps {
  connection: Connection;
  wallet: Wallet;
  sale: IFixedPrice;
  store: PublicKey;
  gatingArtwork?: IArt;
}

export const createBuy = async ({
  connection,
  wallet,
  sale,
  store,
  gatingArtwork,
}: BuyProps) => {
  const {
    refs: { vault, sellingResource, treasuryHolder },
    artwork: { mint: resourceMint },
  } = sale;

  const [vaultOwner, vaultOwnerBump] = await findVaultOwnerAddress(
    toPubkey(resourceMint),
    store
  );

  const [tradeHistory, tradeHistoryBump] = await findTradeHistoryAddress(
    wallet.publicKey,
    toPubkey(sale.id)
  );

  const mintPubkey = toPubkey(resourceMint);
  const [resourceMintMasterEdition] = await findEditionAddress(mintPubkey);
  const [resourceMintMetadata] = await findMetadataAddress(mintPubkey);
  const [resourceMintEditionMarker] = await findEditionMarkerAddress(
    mintPubkey,
    1
  );

  const {
    tokenAccount: wrappedToken,
    tx: wrappedTx,
    closeTx,
  } = await createWrappedAccount({
    connection,
    owner: wallet.publicKey,
    amount: solToLamports(sale.price),
  });

  const additionalKeys: AccountMeta[] = [];

  if (gatingArtwork) {
    additionalKeys.push(
      {
        pubkey: toPubkey(gatingArtwork.token),
        isSigner: false,
        isWritable: true,
      },
      {
        pubkey: toPubkey(gatingArtwork.mint),
        isSigner: false,
        isWritable: true,
      },
      {
        pubkey: toPubkey(gatingArtwork.accountAddress),
        isSigner: false,
        isWritable: false,
      }
    );
  }

  const { mint, tokenAccount, createBuyTxs } = await createBuyTransaction({
    connection,
    wallet,
    market: toPubkey(sale.id),
    marketTreasuryHolder: toPubkey(treasuryHolder),
    sellingResource: toPubkey(sellingResource),
    vault: toPubkey(vault),
    vaultOwner,
    vaultOwnerBump,
    tradeHistory,
    tradeHistoryBump,
    resourceMintMetadata,
    resourceMintEditionMarker,
    resourceMintMasterEdition,
    userTokenAccount: wrappedToken.publicKey,
    additionalKeys,
  });

  return {
    mint,
    tokenAccount,
    txs: [wrappedTx, ...createBuyTxs, closeTx],
  };
};
