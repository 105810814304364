import React, { ComponentProps } from "react";

import { Divider } from "@chakra-ui/react";
import { TitledBlock } from "components/TitledBlock";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface Props extends ComponentProps<typeof TitledBlock> {
  title: string;
}

export const ArtworkDetailsColumn: React.FC<Props> = ({
  title,
  children,
  ...props
}) => {
  const { xlUp } = useCustomBreakpoints();
  return (
    <>
      {xlUp && <Divider minH="64px" orientation="vertical" />}
      <TitledBlock
        title={title}
        py={xlUp ? 0 : 6}
        px={xlUp ? 6 : 4}
        w="full"
        size="sm"
        {...props}
      >
        {children}
      </TitledBlock>
    </>
  );
};
