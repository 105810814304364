import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import { BaseSdkProps, ETransactionProgress, toPubkey } from "sdk/share";
import { sendTransactions } from "sdk/transactions";
import { IInstantSale } from "state/sales";
import { createBuy } from "./actions/createBuy";

export type BuyProps = BaseSdkProps & {
  auctionHouse: StringPublicKey;
  sale: IInstantSale;
};

export const buy = async (params: BuyProps) => {
  const { connection, wallet, progressChange, auctionHouse, sale } = params;

  progressChange?.(ETransactionProgress.creating);
  const { txs, bidReceipt, purchaseReceipt } = await createBuy({
    ...params,
    buyer: wallet.publicKey,
    storeId: toPubkey(auctionHouse),
    sale,
  });

  progressChange?.(ETransactionProgress.sending_to_solana);
  await sendTransactions(connection, wallet, txs);

  return { bidReceipt, purchaseReceipt, mint: undefined };
};
