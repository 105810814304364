import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { useMemo } from "react";
import { sizeToPixels } from "utils/sizeToPixels";

interface Options {
  gutterBottom?: boolean;
  noPadding?: boolean;
}

export const useLayoutContentPadding = ({
  gutterBottom,
  noPadding,
}: Options) => {
  const { mdUp } = useCustomBreakpoints();

  const calculatedPaddings = useMemo(() => {
    const padding = mdUp ? 48 : 24;
    const paddingX = sizeToPixels(padding);
    const paddingTop = sizeToPixels(mdUp ? 64 + padding : 64);
    const paddingBottom = sizeToPixels(gutterBottom ? "30vh" : padding);

    return noPadding
      ? { px: 0, pt: 0, pb: 0 }
      : { px: paddingX, pt: paddingTop, pb: paddingBottom };
  }, [mdUp, gutterBottom, noPadding]);

  return calculatedPaddings;
};
