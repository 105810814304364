import { attach, createEffect, StoreValue } from "effector";
import { approveNftCreator } from "sdk/nft";
import { $wallet } from "../wallet";
import { $connection } from "../connection";

interface Params {
  address: string;
}

export const sendSignMetadataFx = attach({
  effect: createEffect(
    ({
      connection,
      wallet,
      address,
    }: Params & {
      connection: StoreValue<typeof $connection>;
      wallet: StoreValue<typeof $wallet>;
    }) => {
      if (wallet === null) {
        return;
      }

      return approveNftCreator(connection, wallet, address);
    }
  ),
  source: { wallet: $wallet, connection: $connection },
  mapParams(params: Params, source) {
    return { ...params, ...source };
  },
});
