import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import { BaseSdkProps, ETransactionProgress, toPubkey } from "sdk/share";
import { sendTransactions } from "sdk/transactions";
import { createWithdrawTransaction } from "./actions/createWithdrawTransaction";

export interface WithdrawProps extends BaseSdkProps {
  auctionHouseId: StringPublicKey;
}

export const withdraw = async (params: WithdrawProps) => {
  const { auctionHouseId, connection, progressChange, wallet } = params;

  progressChange?.(ETransactionProgress.creating);
  const { tx: withdrawFromTreasuryTx } = await createWithdrawTransaction({
    auctionHouseId: toPubkey(auctionHouseId),
    connection,
    wallet: toPubkey(wallet.publicKey),
  });

  progressChange?.(ETransactionProgress.sending_to_solana);
  await sendTransactions(connection, wallet, [withdrawFromTreasuryTx]);
};
