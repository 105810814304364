import { IArt } from "state/artworks";
import { ISale } from "state/sales";

export const profileArtworksReduceFn = (
  sales: ISale[],
  storeArtworks: IArt[],
  profileArtworks: IArt[]
): (ISale | (IArt & { isClaimed: boolean }))[] => {
  const reducedArts = storeArtworks.reduce(
    (arts: (IArt & { isClaimed: boolean })[], art) => {
      if (!sales.some(({ artwork }) => art.id === artwork.id))
        arts.push({
          ...art,
          isClaimed: !!profileArtworks.find((a) => a.mint === art.mint),
        });

      return arts;
    },
    []
  );
  return [...sales, ...reducedArts];
};
