import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import {
  FixedPriceSaleProgram,
  Market,
  MarketState,
  SellingResource,
} from "@metaplex-foundation/mpl-fixed-price-sale";
import { AccountLayout, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { AccountInfo, Connection } from "@solana/web3.js";
import base58 from "bs58";
import {
  findAccountsAndDeserialize,
  loadAccountAndDeserialize,
  loadAccountsAndDeserializeAsArray,
  toPubkey,
} from "sdk/share";
import { IFixedPrice, SaleState, SaleType } from "state/sales";
import { excludesFalsy } from "utils/excludeFalsy";
import { lamportsToSol } from "utils/lamportsToSol";
import { parseBN } from "utils/parseBN";
import { Wallet } from "wallet";
import { loadArtworksByTokenAccounts } from "../loadArtworks";
import { isSaleWithdrawn } from "./isSaleWighdrawn";
import {
  loadPrimaryCreators,
  loadPrimaryCreatorsForArtworks,
} from "./loadPrimaryCreators";
import { getSellingResource } from "./loadSellingResource";

const discriminator = base58.encode(
  Uint8Array.from([219, 190, 213, 55, 0, 227, 198, 154])
);

type MarketSale = Omit<IFixedPrice, "artwork" | "primaryCreators">;

const getMarkets = async (
  store: StringPublicKey,
  connection: Connection,
  wallet?: Wallet | null,
  isAdmin?: boolean | null
): Promise<IFixedPrice[]> => {
  const marketsAndKeys = await findAccountsAndDeserialize(
    connection,
    FixedPriceSaleProgram,
    Market,
    [
      { offset: 0, bytes: discriminator },
      { offset: 8, bytes: store },
    ]
  );

  const markets: MarketSale[] = await Promise.all(
    Array.from(marketsAndKeys).map(async ([pubkey, market]) => {
      const sellingResource = await getSellingResource(
        connection,
        market.sellingResource
      );
      return combineMarket(
        market,
        sellingResource,
        pubkey,
        connection,
        wallet,
        isAdmin
      );
    })
  );

  const tokenAccounts = await loadAccountsAndDeserializeAsArray(
    connection,
    AccountLayout,
    markets.map((m) => toPubkey(m.refs.vault)),
    TOKEN_PROGRAM_ID
  );

  const artworks = await loadArtworksByTokenAccounts({
    connection,
    tokenAccounts: tokenAccounts.filter(
      (account) => parseBN(account.amount) !== 0
    ),
  });

  const artToPrimaryCreatorsMap = await loadPrimaryCreatorsForArtworks(
    connection,
    artworks
  );

  const salesWithArtworks = artworks.map((artwork) => {
    if (markets.length < 1) {
      return null;
    }
    const market = markets.find(
      (market) => market.refs.resource === artwork.mint
    );
    if (!market) {
      return null;
    }

    const primaryCreators = artToPrimaryCreatorsMap.get(artwork.id) || [];

    const sale: IFixedPrice = {
      ...market,
      artwork,
      primaryCreators,
    };

    return sale;
  });

  return salesWithArtworks.filter(excludesFalsy);
};

const combineMarket = async (
  market: Market,
  sellingResource: SellingResource,
  pubkey: StringPublicKey,
  connection: Connection,
  wallet?: Wallet | null,
  isAdmin?: boolean | null
): Promise<MarketSale> => {
  const price = parseBN(market.price, lamportsToSol);
  const supply = parseBN(sellingResource.supply);
  const earnings = price * supply;

  const startDate = parseBN(market.startDate);
  const endDate = parseBN(market.endDate);

  const piecesInOneWallet = parseBN(market.piecesInOneWallet);
  const maxSupply = parseBN(sellingResource.maxSupply);

  const STATE_MAP = new Map([
    [MarketState.Uninitialized, SaleState.Uninitialized],
    [MarketState.Created, SaleState.Created],
    [MarketState.Active, SaleState.Active],
    [MarketState.Suspended, SaleState.Suspended],
    [MarketState.Ended, SaleState.Ended],
  ]);
  const state: SaleState =
    STATE_MAP.get(market.state) ?? SaleState.Uninitialized;

  const statusesShouldCheckWithdraw = [
    MarketState.Ended,
    MarketState.Created,
    MarketState.Active,
  ];

  const isWithdrawn =
    statusesShouldCheckWithdraw.includes(market.state) &&
    !!wallet &&
    !!isAdmin &&
    (await isSaleWithdrawn({
      connection,
      marketKey: pubkey.toString(),
      wallet,
    }));

  const gate = market.gatekeeper
    ? {
        collection: market.gatekeeper.collection.toString(),
        expireOnUse: market.gatekeeper.expireOnUse,
        gatingTime: parseBN(market.gatekeeper.gatingTime),
      }
    : undefined;

  return {
    type: SaleType.FixedPrice,
    id: pubkey.toString(),
    state,
    price,
    earnings,
    startDate,
    endDate,
    piecesInOneWallet,
    maxSupply,
    gate,
    isWithdrawn,
    refs: {
      sellingResource: market.sellingResource.toString(),
      treasuryHolder: market.treasuryHolder.toString(),
      treasuryMint: market.treasuryMint.toString(),
      vault: sellingResource.vault.toString(),
      resource: sellingResource.resource.toString(),
      seller: market.owner.toString(),
    },
  };
};

export const loadMarkets = async ({
  store,
  connection,
  wallet,
  isAdmin,
}: {
  store: StringPublicKey;
  connection: Connection;
  wallet?: Wallet | null;
  isAdmin?: boolean | null;
}): Promise<IFixedPrice[]> => {
  try {
    return getMarkets(store, connection, wallet, isAdmin);
  } catch {
    return [];
  }
};

export const loadMarket = async ({
  connection,
  marketId,
  accountInfo,
  wallet,
  isAdmin,
}: {
  connection: Connection;
  marketId: StringPublicKey;
  accountInfo?: AccountInfo<Buffer>;
  wallet?: Wallet | null;
  isAdmin?: boolean | null;
}): Promise<IFixedPrice> => {
  let market: Market;
  if (accountInfo) {
    market = Market.deserialize(accountInfo.data)[0];
  } else {
    const pubkey = toPubkey(marketId);

    market = await loadAccountAndDeserialize(connection, Market, pubkey);
  }

  const sellingResource = await loadAccountAndDeserialize(
    connection,
    SellingResource,
    market.sellingResource
  );

  const token = await loadAccountAndDeserialize(
    connection,
    AccountLayout,
    sellingResource.vault,
    TOKEN_PROGRAM_ID
  );

  const [artwork] = await loadArtworksByTokenAccounts({
    connection,
    tokenAccounts: [{ ...token, address: sellingResource.vault }],
  });

  const primaryCreators = await loadPrimaryCreators(connection, artwork);

  const marketData = await combineMarket(
    market,
    sellingResource,
    marketId,
    connection,
    wallet,
    isAdmin
  );
  return { ...marketData, artwork, primaryCreators };
};
