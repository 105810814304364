import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import { BaseSdkProps, ETransactionProgress, toPubkey } from "sdk/share";
import { sendTransactions } from "sdk/transactions";
import { IArt } from "state/artworks";
import { IFixedPrice } from "state/sales";
import { createBuy } from "./actions/createBuy";

export type initMarketProps = BaseSdkProps & {
  sale: IFixedPrice;
  store: StringPublicKey;
  gatingArtwork?: IArt;
};

export const buy = async ({
  connection,
  wallet,
  sale,
  store,
  gatingArtwork,
  progressChange,
}: initMarketProps) => {
  progressChange?.(ETransactionProgress.creating);
  const { mint, tokenAccount, txs } = await createBuy({
    connection,
    wallet,
    sale,
    store: toPubkey(store),
    gatingArtwork,
  });

  progressChange?.(ETransactionProgress.sending_to_solana);
  await sendTransactions(connection, wallet, txs);

  return {
    mint: mint.publicKey.toString(),
    token: tokenAccount.publicKey.toString(),
  };
};
