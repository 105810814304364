import { CandyMachineProgram } from "@metaplex-foundation/mpl-candy-machine";
import { PublicKey } from "@solana/web3.js";

export const getCandyMachineCreator = async (
  candyMachine: PublicKey
): Promise<[PublicKey, number]> => {
  return await PublicKey.findProgramAddress(
    [Buffer.from("candy_machine"), candyMachine.toBuffer()],
    CandyMachineProgram.PUBKEY
  );
};

export const getCollectionPDA = async (
  candyMachineAddress: PublicKey
): Promise<[PublicKey, number]> => {
  return await PublicKey.findProgramAddress(
    [Buffer.from("collection"), candyMachineAddress.toBuffer()],
    CandyMachineProgram.PUBKEY
  );
};

export interface CollectionData {
  mint: PublicKey;
  candyMachine: PublicKey;
}
