import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

export const splitDuration = (duration: number, unit?: string) => {
  const _duration = dayjs.duration(duration);
  const timeUnits = [
    { value: _duration.years(), unit: "year" },
    { value: _duration.months(), unit: "month" },
    { value: _duration.days(), unit: "day" },
    { value: _duration.hours(), unit: "hour" },
    { value: _duration.minutes(), unit: "minute" },
    { value: _duration.seconds(), unit: "second" },
  ];

  const firstNonZeroIndex = timeUnits.findIndex(({ value }) => !!value);
  const validIndex = firstNonZeroIndex < 4 ? firstNonZeroIndex : 3;

  return unit
    ? timeUnits.filter((timeUnit) => timeUnit.unit === unit)
    : timeUnits.slice(validIndex, validIndex + 3);
};
