import { CreateButton } from "components/buttons/CreateButton";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { FC, ComponentProps } from "react";

type Props = ComponentProps<typeof CreateButton>;

export const ArtworkListCreateButton: FC<Props> = ({ children, ...props }) => {
  const { smDown, mdUp } = useCustomBreakpoints();
  if (mdUp) {
    return <CreateButton {...props}>{children}</CreateButton>;
  } else {
    return (
      <CreateButton w="full" px={5} ml={{ smDown } && "33px"} {...props}>
        {children}
      </CreateButton>
    );
  }
};
