import { Stack, Wrap, WrapItem } from "@chakra-ui/react";

import { IArt } from "state/artworks";
import { PropertyCard } from "components/PropertyCard";
import React from "react";
import { TitledBlock } from "components/TitledBlock";

interface IPropertiesProps {
  artwork?: IArt;
}

export const Properties: React.FC<IPropertiesProps> = ({
  artwork,
}: IPropertiesProps) => {
  if (!artwork?.properties?.length) {
    return null;
  }

  return (
    <Stack spacing={1} w="full">
      <TitledBlock title="Properties" />
      <Wrap>
        {artwork.properties?.map((property) => (
          <WrapItem key={property.title}>
            <PropertyCard title={property.title} description={property.value} />
          </WrapItem>
        ))}
      </Wrap>
    </Stack>
  );
};
