import { useStore } from "effector-react";
import { FC } from "react";
import { $hasConnectedWallet } from "state/wallet";
import { WalletNotConnected } from "./WalletNotConnected";

export const ConnectedWalletGuard: FC = ({ children }) => {
  const hasAccess = useStore($hasConnectedWallet);

  return hasAccess ? <>{children}</> : <WalletNotConnected />;
};
