import { FC } from "react";
import { MenuItem, MenuItemProps } from "@chakra-ui/react";

interface Props extends MenuItemProps {
  href?: string;
  target?: string;
}

export const ResourcesMenuItem: FC<Props> = ({ children, ...props }) => (
  <MenuItem
    alignItems="center"
    justifyContent="space-between"
    sx={{ "&:hover > svg": { opacity: 1 } }}
    {...props}
  >
    {children}
  </MenuItem>
);
