import { VStack } from "@chakra-ui/react";
import { FormField } from "components/FormField";
import { PriceInput } from "components/PriceInput";
import { FC } from "react";

export interface IFixedPriceForm {
  price?: number;
}

export const InstantSaleForm: FC = () => {
  return (
    <VStack spacing={12} align="stretch">
      <FormField
        id="price"
        title="NFT Price"
        placeholder="Amount"
        type="number"
        options={{ required: true }}
        customInputFactory={(register) => <PriceInput {...register} />}
      />
    </VStack>
  );
};
