import { Box, Button, ButtonGroup, ButtonGroupProps } from "@chakra-ui/react";
import { useOpenSolanaExplorer } from "hooks/useOpenSolanaExplorer";
import { FC } from "react";
import { MdArrowUpward } from "react-icons/md";
import { truncateInMiddle } from "utils/truncateInMiddle";
import { CopyButton } from "../CopyButton";

interface AddressButtonProps {
  address: string;
  prefix?: string;
}

export const AddressButton: FC<AddressButtonProps & ButtonGroupProps> = ({
  address,
  prefix,
  variant = "tertiary",
  fontWeight = "normal",
  fontSize,
  size,
  ...props
}) => {
  const openExplorer = useOpenSolanaExplorer();

  return (
    <ButtonGroup variant={variant} isAttached {...props}>
      <Button
        pr={2}
        w="full"
        {...{
          fontSize,
          fontWeight,
          size,
        }}
        onClick={() => openExplorer(address)}
      >
        {prefix && `${prefix} `}
        {truncateInMiddle(address)}
        <Box transform={"rotate(45deg)"} ml={1}>
          <MdArrowUpward />
        </Box>
      </Button>
      <CopyButton text={address} {...{ variant, fontSize, size }} />
    </ButtonGroup>
  );
};
