import React, { useState } from "react";

import { $walletAddress } from "state/wallet";
import { Button } from "@chakra-ui/react";
import { IArt } from "state/artworks";
import { sendSignMetadataFx } from "state/notifications/sendSignMetadataFx";
import { useStore } from "effector-react";

interface Props {
  artwork?: IArt;
}

export const ApproveArtwork: React.FC<Props> = ({ artwork }) => {
  const walletPublickey = useStore($walletAddress);
  const [hideSelf, setHideSelf] = useState(false);

  if (!artwork) {
    return null;
  }

  const { creators, accountAddress } = artwork;

  const needToApprove = creators.some(
    (creator) => creator.address === walletPublickey && !creator.verified
  );

  if (!needToApprove || hideSelf) {
    return null;
  }

  return (
    <Button
      size="lg"
      variant="primary"
      w="full"
      onClick={async () => {
        await sendSignMetadataFx({ address: accountAddress });
        setHideSelf(true);
      }}
    >
      Approve
    </Button>
  );
};
