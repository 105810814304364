import {
  Box,
  Center,
  Heading,
  Stack,
  StackProps,
  VStack,
} from "@chakra-ui/react";
import { ComponentProps, FC, ReactNode, useCallback } from "react";
import {
  ISale,
  getSaleEndDate,
  getSalePrice,
  isTimedAuction,
} from "state/sales";
import { useNavigate, useParams } from "react-router-dom";

import { ArtworkCard } from "components/Artwork";
import { ArtworkCardInstantSaleState } from "components/Artwork/ArtworkCard/states/ArtworkCardInstantSaleState";
import { ArtworkCardLiveAuctionState } from "components/Artwork/ArtworkCard/states/ArtworkCardLiveAuctionState";
import { HaloedIcon } from "components/HaloedIcon";
import { IArt } from "state/artworks";
import { LoaderComponent } from "components/modals/InfiniteProgress/LoaderComponent";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { usePaths } from "routes";

interface Props extends StackProps {
  sale?: ISale;
  artwork?: IArt | null;
  iconComponent?: ComponentProps<typeof HaloedIcon>["icon"];
  emoji?: string;
  title?: string;
  subtitle?: ReactNode;
  variant?: "sale" | "nft";
  actions?: ReactNode;
}

export const OperationSuccess: FC<Props> = ({
  sale,
  artwork,
  iconComponent,
  emoji,
  title,
  subtitle,
  variant = "nft",
  actions,
  ...props
}) => {
  const _artwork = sale?.artwork || artwork;
  const navigate = useNavigate();
  const paths = usePaths();
  const { storeId } = useParams();

  const handleClick = useCallback(() => {
    if (!storeId) return;
    if (variant === "sale" && sale) {
      navigate(
        paths.sale({
          ":saleId": sale?.id || "",
        })
      );
    }
    if (variant === "nft" && artwork) {
      navigate(
        paths.artwork({
          ":artworkId": artwork?.id || "",
        })
      );
    }
  }, [storeId, variant, sale, artwork, navigate, paths]);

  const { mdUp } = useCustomBreakpoints();

  return (
    <Stack
      spacing={mdUp ? 12 : 0}
      direction={mdUp ? ["row"] : ["column"]}
      align="start"
      {...props}
    >
      {_artwork ? (
        <ArtworkCard
          artwork={_artwork}
          minW={mdUp ? "320px" : "full"}
          w="320px"
          onClick={handleClick}
        >
          {variant === "sale"
            ? sale &&
              (isTimedAuction(sale) ? (
                <ArtworkCardLiveAuctionState
                  // @todo - perhaps the lastBid was used incorrectly
                  price={getSalePrice(sale) || 0}
                  ends={getSaleEndDate(sale) || 0}
                />
              ) : (
                <ArtworkCardInstantSaleState sale={sale} />
              ))
            : artwork && null}
        </ArtworkCard>
      ) : (
        <Center minW="320px" h="360px">
          <LoaderComponent darkBg />
        </Center>
      )}
      <VStack
        spacing={8}
        align={mdUp ? "start" : "center"}
        w={mdUp ? "320px" : "full"}
        pt={12}
      >
        <HaloedIcon icon={iconComponent} emoji={emoji} />

        <Heading textAlign={mdUp ? "start" : "center"} variant="h3">
          {title}
        </Heading>

        {subtitle && (
          <Box textAlign={mdUp ? "start" : "center"} color="whiteAlpha.700">
            {subtitle}
          </Box>
        )}

        <Stack direction={mdUp ? ["row"] : ["column"]} spacing={2} w="full">
          {actions}
        </Stack>
      </VStack>
    </Stack>
  );
};
