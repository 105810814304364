import {
  FixedPriceSaleProgram,
  TradeHistory,
} from "@metaplex-foundation/mpl-fixed-price-sale";
import { Wallet } from "wallet";
import { Connection, PublicKey } from "@solana/web3.js";
import base58 from "bs58";
import { findAccountsAndDeserialize } from "sdk/share";

const discriminator = base58.encode(
  Uint8Array.from([190, 117, 218, 114, 66, 112, 56, 41])
);

const getTrades = async (buyer: PublicKey, connection: Connection) => {
  const tradesAndKeys = await findAccountsAndDeserialize(
    connection,
    FixedPriceSaleProgram,
    TradeHistory,
    [
      { offset: 0, bytes: discriminator },
      { offset: 8 + 32, bytes: buyer.toString() },
    ]
  );

  return tradesAndKeys;
};

export const loadTrades = async ({
  wallet,
  connection,
}: {
  wallet: Wallet;
  connection: Connection;
}) => {
  try {
    return getTrades(wallet.publicKey, connection);
  } catch {
    return [];
  }
};
