import { FC, useMemo } from "react";
import { Text, VStack } from "@chakra-ui/react";
import { Timezone, localToSelectedTimezone } from "components/Datepicker/utils";
import dayjs, { Dayjs } from "dayjs";

import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  date: Date | Dayjs;
  timezone?: Timezone;
}

export const DateDisplay: FC<Props> = ({ date, timezone = "UTC" }) => {
  const dateToDisplay = useMemo(
    () => dayjs(localToSelectedTimezone(dayjs(date).toDate(), timezone)),
    [date, timezone]
  );

  return (
    <VStack spacing={1} align="start">
      <Text variant="button">{dateToDisplay.format("dddd, MMMM D YYYY")}</Text>
      <Text>
        {dateToDisplay.isValid() &&
          `${timezone}, ${dateToDisplay.format("h:mm:ss A")}`}
        &nbsp;
      </Text>
    </VStack>
  );
};
