import { useState } from "react";

const data = {
  "4gcfWBpBCeeXRaro1L6RGSWbhSWRedxfFMwaMRVoUzYS": {
    id: "4gcfWBpBCeeXRaro1L6RGSWbhSWRedxfFMwaMRVoUzYS",
    type: "candy-machine",
    symbol: "S21",
    stopAtDate: undefined,
    stopAtAmount: 500,
    state: 1,
    groups: [],
    refs: {
      authority: "FSHP7g2kz3Mhy4oQ3w8JYksPR487hMgkcrjYAdjzwtaE",
      retainAuthority: true,
      seller: "DMdKWm4ybBu4Jbnv4ZrhT3tEnQ5bcdkAcHSFCKmXxzPh",
      tokenMint: undefined,
    },
    price: 2,
    liveAtDate: 1672336800,
    itemsRedeemed: 500,
    itemsAvailable: 500,
    whitelistMintSettings: null,
    whitelistToken: null,
    whitelistPrice: 1,
    gatekeeper: undefined,
    gate: undefined,
    artwork: {
      id: "4gcfWBpBCeeXRaro1L6RGSWbhSWRedxfFMwaMRVoUzYS",
      accountAddress: "BPMcd9LKLHWaHznxfJNUGgsmSZW1kFDNwKu8wZ4NRXX3",
      animationUrl:
        "https://nftstorage.link/ipfs/bafybeibt7qap36vqqukrjjjsg4y5rlcvud3jvxnuexo37p5f6jmkv4wrom",
      assets: [
        {
          type: "image/png",
          uri: "https://nftstorage.link/ipfs/bafybeigoo2gx4wnas6kddgqd2rrnlzuudkfyakkobsly7sya4tyrcodoki",
        },
        {
          type: "video/mp4",
          uri: "https://nftstorage.link/ipfs/bafybeibt7qap36vqqukrjjjsg4y5rlcvud3jvxnuexo37p5f6jmkv4wrom",
        },
      ],
      collection: undefined,
      creators: [
        {
          address: "Dq89Qd6D37GWPQqoqNNwtk1tZuny4DbRfdymHV2W1Y2q",
          share: 15,
          verified: false,
        },
        {
          address: "FhtQ2w9w319puuvxUG7yjyui2YLJGPJbDYRSAF3f57qf",
          share: 85,
          verified: false,
        },
      ],
      description:
        "The sixth NFT collection by Todd McFarlane released on the Oddkey Platform. This collection features an animated version of Spawn #21 with sound. Animated by Zaid Sarraf.",
      format: "video",
      image:
        "https://nftstorage.link/ipfs/bafybeigoo2gx4wnas6kddgqd2rrnlzuudkfyakkobsly7sya4tyrcodoki",
      mint: "4gcfWBpBCeeXRaro1L6RGSWbhSWRedxfFMwaMRVoUzYS",
      ownerAddress: "FSHP7g2kz3Mhy4oQ3w8JYksPR487hMgkcrjYAdjzwtaE",
      primarySaleHappened: false,
      prints: {
        maxSupply: 500,
        supply: 500,
      },
      properties: undefined,
      sellerFeeBasisPoints: 1000,
      title: "Spawn #21",
      token: "C5Ty7tHMWmPjPniR69n4B9Td1Lw4XwhtJRB6RbBhtX9Q",
      tokenAmount: 1,
      type: "Master",
    },
  },
};

export const useMeta = (cmId: string) => {
  const [item, setItem] = useState(data[cmId]);
  return [item, setItem];
};
