import React from "react";
import { Circle, Heading, HStack, StackProps, Text } from "@chakra-ui/react";
import { SolanaIcon } from "components/Icons";
import { truncateDecimals } from "utils/truncateDecimals";
import { useSolToUsd } from "state/react/useSolToUsd";

interface SolUsdDisplayProps extends StackProps {
  sol?: number | null;
  variant?: "base" | "big-price";
  userAvatar?: React.ReactNode;
}

export const SolUsdDisplay: React.FC<SolUsdDisplayProps> = ({
  sol = 0,
  variant = "base",
  userAvatar,
  ...props
}) => {
  const usd = useSolToUsd(sol);
  const isBase = variant === "base";

  return (
    <HStack {...props}>
      {userAvatar ?? (
        <Circle bg="whiteAlpha.50">
          <SolanaIcon boxSize={isBase ? "1.5em" : "2em"} />
        </Circle>
      )}
      {isBase ? (
        <Text variant="button" fontWeight={700}>
          {truncateDecimals(sol, 5)} SOL
        </Text>
      ) : (
        <Heading variant="h3">{truncateDecimals(sol, 5)} SOL </Heading>
      )}
      {usd && (
        <Text
          pb={isBase ? undefined : 1}
          variant={isBase ? "button" : "subtitle"}
          color={isBase ? "whiteAlpha.500" : "white"}
          alignSelf="flex-end"
        >
          ${truncateDecimals(usd, 2)}
        </Text>
      )}
    </HStack>
  );
};
