import { HStack, Text } from "@chakra-ui/layout";

import { truncateDecimals } from "utils/truncateDecimals";

interface Props {
  earnings: number;
}

export const SaleAmount: React.FC<Props> = ({ earnings }) => (
  <HStack spacing={4}>
    <Text variant="subtitle" fontWeight={700} fontSize="md" color="green.500">
      {truncateDecimals(earnings, 5)} SOL
    </Text>
  </HStack>
);
