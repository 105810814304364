import { Button, ButtonProps } from "@chakra-ui/react";

import { ReactElement, useMemo } from "react";
import { StepListItem } from "components/StepListItem";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface MenuItemProps extends ButtonProps {
  icon?: ReactElement;
  variant?: "primary" | "secondary" | "step";
  isActive?: boolean;
  href?: string;
  target?: string;
}

export const SidebarMenuItem: React.FC<MenuItemProps> = ({
  icon,
  variant,
  isActive,
  children,
  ...rest
}) => {
  const { mdUp } = useCustomBreakpoints();

  const textSecondary = variant === "secondary" || variant === "step";

  const itemColor = useMemo(() => {
    if (isActive || !textSecondary) {
      return "white";
    } else if (textSecondary) {
      return "whiteAlpha.500";
    } else {
      return "whiteAlpha.50";
    }
  }, [textSecondary, isActive]);

  return variant === "step" ? (
    <StepListItem active={isActive} icon={icon} spacing={4}>
      {children}
    </StepListItem>
  ) : (
    <Button
      variant="ghost"
      flexShrink={0}
      h="56px"
      isFullWidth
      justifyContent="start"
      leftIcon={icon}
      bgColor={isActive ? "whiteAlpha.50" : "transparent"}
      color={itemColor}
      iconSpacing={mdUp ? undefined : 4}
      _hover={{
        color: "white",
        bgColor: isActive ? "whiteAlpha.100" : "transparent",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};
