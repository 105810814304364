import { sample } from "effector";
import { withdrawFromTreasuryFx } from "state/sales/AH/withdrawFromTreasureFx";
import { removeNotification } from "state/notifications";
import { sendSignMetadataFx } from "state/notifications/sendSignMetadataFx";
import { closeMarketAndWithdrawFx } from "views/AdminItems/effects/closeMarketAndWithdrawFx";

sample({
  clock: closeMarketAndWithdrawFx.done,
  fn: ({ params }) => `withdraw-${params.market}`,
  target: removeNotification,
});

sample({
  clock: withdrawFromTreasuryFx.done,
  fn: () => `withdraw-fee`,
  target: removeNotification,
});

sample({
  clock: sendSignMetadataFx.done,
  fn: ({ params }) => `approve-${params.address}`,
  target: removeNotification,
});
