import {
  Box,
  CloseButton,
  FormControl,
  HStack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { MdHelpOutline } from "react-icons/md";

import { fontSizes } from "theme/typography";

import { calcAllErrors, setErrors } from "components/forms/NftCreate/utils";
import { IFormData } from "components/forms/NftCreate";

import { useUpdateTotal } from "./hooks/useUpdateTotal";
import { calcProportionsSum } from "./utils";

import { PercentageAddressCell } from "./components/PercentageAddressCell";
import { WalletAddressCell } from "./components/WalletAddressCell";

interface AddressCellProps {
  id: number;
  shouldShowButton?: boolean;
  onDelete?: (id: number) => void;
  isSecondary?: boolean;
}

export const AddressCell: React.FC<AddressCellProps> = ({
  id,
  onDelete,
  isSecondary = false,
  shouldShowButton,
}) => {
  const { getValues, setError, clearErrors, setValue } =
    useFormContext<IFormData>();
  const { splits, secondarySplits, royalty }: IFormData = getValues();
  const baseId = `${isSecondary ? "secondarySplits" : "splits"}.${id}`;
  const addressWidth = isSecondary
    ? "calc(50% - 56px)"
    : shouldShowButton
    ? "calc(70% - 56px)"
    : "70%";

  const control = isSecondary ? secondarySplits : splits;
  const proportionSum = calcProportionsSum(control, id);
  const walletTitle = !id ? "wallet address" : `address ${id + 1}`;
  const maxValueForTotal = (100 - proportionSum) * Number(royalty) * 0.01;

  useUpdateTotal({
    onUpdate: setValue,
    secondarySplits,
    royalty,
    id,
  });

  const handleIconClick = useCallback(
    () => onDelete && onDelete(id),
    [onDelete, id]
  );

  const errors = calcAllErrors(control, id) || {};

  useEffect(() => {
    setErrors(errors);
    if (errors.errorMessage) {
      setError(
        isSecondary ? "secondarySplits" : "splits",
        {
          type: "custom",
          message: errors.errorMessage,
        },
        {
          shouldFocus: true,
        }
      );
    } else {
      clearErrors(isSecondary ? "secondarySplits" : "splits");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.errorMessage, control]);

  return (
    <FormControl marginTop={6}>
      <HStack alignItems="flex-end">
        <Box minW={addressWidth}>
          <WalletAddressCell
            id={`${baseId}.key`}
            title={walletTitle}
            isOwner={control[id]?.isOwner}
            isDisabled={control[id]?.isOwner}
            placeholder="Enter wallet address"
          />
        </Box>
        <PercentageAddressCell
          id={`${baseId}.value`}
          title="percentage"
          max={100 - proportionSum}
          min={0}
          isInvalid={errors.isSumError}
        />

        {isSecondary && (
          <PercentageAddressCell
            id={`${baseId}.total`}
            title="% of total"
            max={maxValueForTotal}
            min={0}
          />
        )}

        {shouldShowButton &&
          (id === control.length - 1 ? (
            <CloseButton size="lg" variant="forms" onClick={handleIconClick} />
          ) : (
            <Box
              width="56px"
              height="56px"
              aria-label="help"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Tooltip
                borderRadius={16}
                px={4}
                py={3}
                color="white"
                bgColor="black"
                hasArrow
                label="Creator Wallet ID is required by default"
                shouldWrapChildren
                placement="top"
              >
                <MdHelpOutline size={fontSizes["xl"]} />
              </Tooltip>
            </Box>
          ))}
      </HStack>
      <Text color="red.900" mt={2} fontSize={14}>
        {errors.errorMessage}
      </Text>
    </FormControl>
  );
};
