import { SIGN_UP_LINK } from "views/ArtistAlley";
import cover_1 from "../images/hero_back_1.png";
import cover_2 from "../images/hero_back_2.jpg";
import cover_3 from "../images/hero_back_3.jpg";
import banner_1 from "../images/banner_1.png";
import banner_2 from "../images/banner_2.png";
import cover_4 from "../images/hero_back_4.jpg"

export const heroContent = [
  {
    title: "Welcome to Oddkey",
    text: "OddKey is Todd McFarlane's community on Solana where he and notable artists host LIVE drawings in Discord, releases animated art, exclusive original digital collectables, and much more! The opportunities for artists, fans, and collectors are endless here at OddKey!",
    image: cover_1
  },
  {
    title: "Spawn #1 comes to DRiP!",
    text: "Be sure to subscribe to Todd McFarlane on DRiP to receive bi-weekly Spawn Cover releases starting with Spawn #1 and continuing!",
    image: cover_4,
    buttonText: "DRiP",
    buttonAction: () => {
      window.open("https://drip.haus/toddmcfarlane", "_blank");
    },
  },
  {
    title: "CHECK OUT OUR COLLECTIONS",
    text: "OddKey's first release was in November of 2019 and since then, Todd's released multiple animated covers, original art sketches, collaborations with other artists, and many other unqiue digital collectibles!",
    image: cover_2,
    buttonText: "SHOP NOW",
    buttonAction: () => {
      window.open("https://magiceden.io/creators/oddkey", "_blank");
    },
  },
  {
    title: "ODDKEY DIGITAL COMMUNITY",
    text: "The OddKey Discord hosts weekly events that features live drawings/QnAs from all types of artists. Todd also hosts live portfolio reviews to provide artists great insight! Attendance to our events earns you white-list to mint our monthly POAPs which feature unreleased Spawn covers never to be minted again!",
    image: banner_2,
    buttonText: "JOIN",
    buttonAction: () => {
      window.open("https://discord.gg/mcfarlane", "_blank");
    },
  },
  {
    title: "ODDKEY COMMUNITY TOKENS",
    text: "Stake your OddKey collectibles or participate in Discord events to earn OddKey Community Tokens (OCT) This REWARDS currency can only be used for our exclusive rewards such as 'Todd's Originals', physical signed memorabilia, exclusive 1/1 NFTs from featured artists, and other cool opportunities!",
    image: banner_1,
    buttonText: "EARN OCT",
    buttonAction: () => {
      window.open("https://www.anybodies.com/c/OddKey", "_blank");
    },
  },
];
