import { Avatar, Divider, StackProps, VStack, Badge } from "@chakra-ui/react";
import { SolUsdDisplay } from "components/SolUsdDisplay";
import { TitledBlock } from "components/TitledBlock";
import { useStore } from "effector-react";
import { FC } from "react";
import { useBalance } from "state/react/useBalance";
import { ISale } from "state/sales";
import { $hasConnectedWallet } from "state/wallet";
import { useSalePrice } from "hooks/useSalePrice";
import { useSalePriceTitles } from "./useSalePriceTitles";

interface BidsAndBalanceProps extends StackProps {
  title: string;
  price: number;
  allowList: any;
  sale: ISale;
}

export const PriceAndBalance: FC<BidsAndBalanceProps> = ({
  title,
  price,
  allowList,
  sale,
  ...props //
}) => {
  const hasUser = useStore($hasConnectedWallet);
  const { balance } = useBalance();

  return (
    <VStack spacing={4} w="full" divider={<Divider />} {...props}>
      <TitledBlock title={title} size="sm" w="full">
        <SolUsdDisplay variant="big-price" sol={price} />
      </TitledBlock>
    </VStack>
  );
};
