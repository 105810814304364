import { calculate } from "@metaplex/arweave-cost";
import { Cluster } from "@solana/web3.js";
import axios from "axios";
import FormData from "form-data";

export const ARWEAVE_UPLOAD_ENDPOINT =
  "https://us-central1-metaplex-studios.cloudfunctions.net/uploadFile";

export interface ArweaveUploadResult {
  error?: string;
  messages?: {
    filename: string;
    status: "success" | "fail";
    transactionId?: string;
    error?: string;
  }[];
}

interface UploadProps {
  files: File[];
  mintKey: string;
  txId: string;
  network: Cluster;
}

export async function upload({ files, mintKey, txId, network }: UploadProps) {
  const fileEntries = Array.from(files.entries());
  const tags = fileEntries.reduce(
    (acc: Record<string, { name: string; value: string }[]>, [fileName]) => {
      acc[fileName] = [{ name: "mint", value: mintKey }];
      return acc;
    },
    {}
  );

  const body = new FormData();

  body.append("tags", JSON.stringify(tags));
  body.append("transaction", txId);
  body.append("env", network);
  fileEntries.map(([, file]) => {
    body.append("file[]", file);
  });

  const request = axios.post<ArweaveUploadResult>(
    ARWEAVE_UPLOAD_ENDPOINT,
    body
  );

  try {
    const response = await request;
    return response.data;
  } catch (e) {
    throw "Failed to upload file";
  }
}

export function getFilePath(
  filename: string,
  arweaveResult: ArweaveUploadResult
) {
  const dataFile = arweaveResult.messages?.find((m) => m.filename === filename);

  if (!dataFile?.transactionId) {
    throw new Error("Failed to upload files");
  }

  return `https://arweave.net/${dataFile.transactionId}`;
}

export async function getFilesCost(files: File[]) {
  const sizes = files.map((file) => file.size);
  const cost = await calculate(sizes);
  return cost;
}
