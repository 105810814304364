import { Badge, Flex, Stack, HStack, Text } from "@chakra-ui/react";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { useStore } from "effector-react";
import { useSalePrice } from "hooks/useSalePrice";
import { FC } from "react";
import { ISale } from "state/sales";
import { $hasConnectedWallet } from "state/wallet";
import { bigNumToSol } from "utils/bigNumberToNumber";
import { TitledBlock } from "components/TitledBlock";
import dayjs from "dayjs";
import {
  ItemActionButtons,
  ItemActionHandlersProps,
} from "./ItemActionButtons";
import { PriceAndBalance } from "./PriceAndBalance";
import { ConnectWalletButton } from "components/ConnectWallet";
import { BuyOnMEButton } from "./ItemActionButtons/BuyOnMEButton";
import { TimeEntry } from "components/TimeEntry";

interface ItemPriceProps extends ItemActionHandlersProps {
  sale: ISale;
  isVerified?: boolean;
  loadingVerification?: boolean;
}

const getTitle = (label: string) => {
  if (label === "Public") return "Public Phase";
  if (label === "OG") return "Allowlist Phase";
  return `${label} Phase`;
};

const PurchaseDisplay = ({
  hasUser,
  isVerified,
  loadingVerification,
  sale,
  handlers,
  group,
}) => {
  const { label, guards } = group;
  const price = bigNumToSol(guards?.solPayment?.lamports);
  const allowList = guards?.allowList;
  const hasAllowList = !!allowList;
  const title = getTitle(label);
  const startDate = guards?.startDate?.date?.toString(10);
  const endDate = guards?.endDate?.date?.toString(10);
  const hasNotStarted = dayjs().isBefore(dayjs(startDate * 1000));
  const timeEntry = hasNotStarted
    ? { countdown: startDate, title: "Sale Starts" }
    : { countdown: endDate, title: "Sale Ends" };

  return (
    <Stack alignSelf="stretch">
      <Flex
        direction="column"
        align="start"
        borderRadius={6}
        bgColor="gray.700"
        padding={6}
      >
        <PriceAndBalance
          title={title}
          price={price}
          allowList={allowList}
          sale={sale}
          mb={6}
          w="full"
        />

        <div style={{ marginBottom: 24 }}>
          <HStack spacing={12}>
            <TitledBlock title={timeEntry.title} size={"sm"}>
              <TimeEntry countdown={timeEntry.countdown} />
            </TitledBlock>
          </HStack>
        </div>

        {hasUser ? (
          <ItemActionButtons
            hasAllowList={hasAllowList}
            group={label}
            sale={sale}
            isVerified={isVerified}
            loadingVerification={loadingVerification}
            isStarted={!hasNotStarted}
            {...handlers}
          />
        ) : (
          <>
            <ConnectWalletButton style={{ minHeight: 48, width: "100%" }}>
              Connect wallet
            </ConnectWalletButton>
          </>
        )}
      </Flex>
    </Stack>
  );
};

export const ItemPrice: FC<ItemPriceProps> = ({
  sale,
  isVerified,
  loadingVerification,
  ...handlers
}) => {
  const hasUser = useStore($hasConnectedWallet);
  const price = useSalePrice(sale);
  const { groups, ...item } = sale;

  if (price === null) {
    return null;
  }

  return (
    <>
      {groups ? (
        (groups ?? []).map((group) => (
          <PurchaseDisplay
            isVerified={isVerified}
            loadingVerification={loadingVerification}
            hasUser={hasUser}
            sale={item}
            handlers={handlers}
            group={group}
            key={group.label}
          />
        ))
      ) : (
        <Stack alignSelf="stretch">
          <Flex
            direction="column"
            align="start"
            borderRadius={6}
            bgColor="gray.700"
            padding={6}
          >
            <BuyOnMEButton />
          </Flex>
        </Stack>
      )}
    </>
  );
};
