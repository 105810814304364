import { Box, Flex, HStack, Link } from "@chakra-ui/react";

import { $user } from "state/wallet";
import { ArtworkListCreateButton } from "components/ArtworksList";
import { IArt } from "state/artworks";
import { Person } from "components/Person";
import React from "react";
import { Text } from "@chakra-ui/layout";
import { TitledBlock } from "components/TitledBlock";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
// import { smartButtonProps } from "utils/smartButtonProps";
// import { usePaths } from "routes";
import { useStore } from "effector-react";

interface ListForSaleProps {
  artwork?: IArt;
}

export const ListForSale: React.FC<ListForSaleProps> = ({ artwork }) => {
  // const paths = usePaths();
  const user = useStore($user);
  const isOwner = artwork?.ownerAddress === user?.address;
  const { mdUp } = useCustomBreakpoints();
  if (!artwork || !isOwner) {
    return null;
  }

  return (
    <Flex
      flexDirection={mdUp ? "row" : "column"}
      bgColor="gray.700"
      p={6}
      borderRadius={12}
      width="100%"
      justifyContent="space-between"
      alignItems="space-between"
    >
      <Box alignItems="left" mr={mdUp ? "auto" : 0} mb={mdUp ? 0 : 4} w="full">
        <TitledBlock title="Owner" titleProps={{ marginBottom: 0 }} />
        <HStack>
          <Person size="sm" address={artwork.ownerAddress} />
          <Box bgColor="whiteAlpha.50" py={2} px={4} borderRadius={36}>
            <Text color="whiteAlpha.700" variant="body-bold" fontSize="sm">
              You
            </Text>
          </Box>
        </HStack>
      </Box>

      <ArtworkListCreateButton
        size="lg"
        LeftIcon={null}
        // {...smartButtonProps(`${paths.createSale()}?artworkId=${artwork.id}`)}
        as={Link}
        href={`https://opensea.io/assets/solana/${artwork.id}`}
        target="_blank"
      >
        List for sale
      </ArtworkListCreateButton>
    </Flex>
  );
};
