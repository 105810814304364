import React, { FC, useCallback } from "react";
import { Flex, ModalHeader } from "@chakra-ui/react";
import { ListingProgress } from "components/modals/ListingProgress";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { fontSizes } from "theme/typography";
import { ModalTemplate } from "components/modals/template";
import { createSaleFactory, getContent } from "./CreateSaleView.state";
import { useProgressSteps } from "hooks/useProgressSteps";
import { ETransactionProgress } from "sdk/share";
import { IArt } from "state/artworks";
import { useStore } from "effector-react";

export const CreateSaleProgress: FC<{ art: IArt | null }> = ({ art }) => {
  const { $currentStep, $progressMeta } = createSaleFactory.useModel();
  const getStepTitle = useCallback(
    (step: number | null) => getContent(step).title,
    []
  );
  const currentStep = useStore($currentStep);
  const progressMeta = useStore($progressMeta);

  const steps = useProgressSteps({
    stepsEnum: ETransactionProgress,
    currentStep,
    getStepTitle,
  });

  return (
    <ModalTemplate
      isOpen={progressMeta.isOpen}
      onClose={() => {}}
      header={<ModalHeader>Creating sale</ModalHeader>}
      isCentered={true}
      size="lg"
      contentProps={{ borderRadius: "xl", bgColor: "gray.800" }}
      bodyProps={{ p: 0 }}
    >
      <Flex direction="column">
        <ListingProgress
          steps={steps}
          artwork={art}
          noteIcon={<MdOutlineAccountBalanceWallet size={fontSizes["2xl"]} />}
        />
      </Flex>
    </ModalTemplate>
  );
};
