import React from "react";
import { Icon, StyleProps } from "@chakra-ui/react";

export const UnlimitedEditionIcon: React.FC<StyleProps> = (props) => {
  return (
    <Icon viewBox="0 0 20 10" fill="none" {...props}>
      <path
        d="M5.002 0c.66 0 1.26.096 1.801.29.545.192 1.033.439 1.463.739.43.295.807.597 1.132.908.12.118.226.225.315.322.094.096.189.2.283.313a5.4 5.4 0 0 1 .268-.313c.094-.102.199-.21.314-.322.498-.477 1.117-.919 1.856-1.326C13.18.204 14.031 0 14.99 0c.923 0 1.762.228 2.517.683A5.1 5.1 0 0 1 19.324 2.5c.45.756.676 1.589.676 2.5 0 .691-.131 1.34-.393 1.945-.257.6-.616 1.131-1.078 1.592a5.093 5.093 0 0 1-1.596 1.077A4.898 4.898 0 0 1 14.99 10a5.41 5.41 0 0 1-1.785-.281 6.487 6.487 0 0 1-1.455-.708 9.433 9.433 0 0 1-1.133-.884c-.141-.134-.26-.25-.354-.346a8.438 8.438 0 0 1-.267-.321 6.442 6.442 0 0 1-.299.33l-.314.337c-.315.29-.687.579-1.117.868a6.69 6.69 0 0 1-1.463.716c-.54.193-1.14.289-1.801.289a4.821 4.821 0 0 1-2.517-.675A5.141 5.141 0 0 1 .676 7.516 4.817 4.817 0 0 1 0 5c0-.686.128-1.329.385-1.93A5.032 5.032 0 0 1 3.06.394 4.848 4.848 0 0 1 5.002 0Zm-2.06 5c0 .375.091.715.275 1.02.188.301.437.542.747.724.314.177.66.266 1.038.266.45 0 .865-.097 1.243-.29.382-.193.75-.45 1.1-.771A5.55 5.55 0 0 0 8.165 5a7.031 7.031 0 0 0-.402-.466 7.76 7.76 0 0 0-.416-.45 4.578 4.578 0 0 0-1.07-.772 2.65 2.65 0 0 0-1.274-.322 1.99 1.99 0 0 0-1.038.282c-.31.182-.559.426-.747.731-.184.3-.276.633-.276.997Zm14.093 0c0-.364-.092-.697-.275-.997a2.016 2.016 0 0 0-.732-.731 1.963 1.963 0 0 0-1.038-.282c-.304 0-.598.051-.88.153-.284.097-.549.23-.795.402a5.134 5.134 0 0 0-.66.539c-.142.139-.294.302-.457.49-.162.188-.283.33-.362.426.1.15.215.303.346.458.131.15.289.314.472.49.33.311.687.566 1.07.764.388.199.81.298 1.266.298.383 0 .729-.089 1.038-.266.31-.182.553-.423.732-.723.183-.306.275-.646.275-1.021Z"
        fill="#fff"
        fillOpacity={0.53}
      />
    </Icon>
  );
};

export const LimitedEditionIcon: React.FC<StyleProps> = (props) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <rect
        x={6}
        width={14}
        height={14}
        rx={2}
        fill="#fff"
        fillOpacity={0.53}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.8 6H2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1.8H6.8a3 3 0 0 1-3-3V6Z"
        fill="#fff"
        fillOpacity={0.53}
      />
    </Icon>
  );
};

export const OneEditionIcon: React.FC<StyleProps> = (props) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <rect width={20} height={20} rx={2} fill="#fff" fillOpacity={0.53} />
    </Icon>
  );
};
