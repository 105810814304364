import React from "react";

import { ModalTemplate } from "components/modals/template";
import { TransactionError } from "components/modals/TransactionError";

interface Props {
  error: string;
  onClose: () => void;
}

export const ErrorModal: React.FC<Props> = ({ error, onClose }) => {
  return (
    <ModalTemplate isOpen={true} onClose={onClose} bodyProps={{ p: 0 }}>
      <TransactionError text={error} />
    </ModalTemplate>
  );
};
