import { BoxProps, Divider, Flex, Spacer, VStack } from "@chakra-ui/react";
import {
  MdOutlineAccountBalanceWallet,
  MdOutlineStorefront,
} from "react-icons/md";
import { Person, PersonProps } from "components/Person";

import { Balance } from "./Balance";
import { NetworkSelect } from "components/Navbar/NetworkSelect";
import { SidebarMenuItem } from "components/Sidebar/SidebarMenuItem";
import { fontSizes } from "theme/typography";
import { smartButtonProps } from "utils/smartButtonProps";
import { useBalance } from "state/react/useBalance";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { usePaths } from "routes";
import { useLocation } from "react-router-dom";
import { LOCK_MAINNET } from "global-const/env";

export type TMenuTypes =
  | "resources"
  | "storefront"
  | "activity"
  | "items"
  | "sales"
  | "listing";
interface Props extends BoxProps {
  user?: PersonProps | null;
  onClose?: () => void;
}

export const MainSidebarContent: React.FC<Props> = ({
  user,
  onClose,
  ...boxProps
}) => {
  const { mdUp } = useCustomBreakpoints();
  const { balance } = useBalance();
  const paths = usePaths();
  const { pathname } = useLocation();
  const isArtworks = pathname.includes("artworks");
  const isSales = pathname.includes("sales");
  const isStorefront = pathname.includes("storefront");

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        h="100%"
        p={mdUp ? undefined : 6}
        {...boxProps}
      >
        <VStack
          spacing={0}
          onClick={onClose}
          divider={mdUp ? undefined : <Divider />}
          w="100%"
        >
          <SidebarMenuItem
            variant="secondary"
            isActive={isArtworks}
            icon={<MdOutlineAccountBalanceWallet size={fontSizes["2xl"]} />}
            {...smartButtonProps(paths.adminArtworks())}
          >
            Items
          </SidebarMenuItem>

          {user && (
            <SidebarMenuItem
              variant="secondary"
              isActive={isSales}
              icon={<MdOutlineAccountBalanceWallet size={fontSizes["2xl"]} />}
              {...smartButtonProps(paths.profileSales())}
            >
              Sales
            </SidebarMenuItem>
          )}

          {!user && (
            <SidebarMenuItem
              variant="secondary"
              isActive={isStorefront}
              icon={<MdOutlineStorefront size={fontSizes["2xl"]} />}
              {...smartButtonProps(paths.adminStorefront())}
            >
              Your Store
            </SidebarMenuItem>
          )}
        </VStack>

        {!mdUp && <Divider />}
        <Spacer />

        {!mdUp && (
          <>
            <Person
              address={user?.address}
              ml={3}
              mr={2}
              fontWeight="bold"
              enableCopy
              fullWidth
            />
            {!LOCK_MAINNET && <Divider my={3} />}
            <NetworkSelect />
            <Divider mt={3} mb={4} />
          </>
        )}

        {balance && <Balance sol={balance.sol} />}
      </Flex>
    </>
  );
};
