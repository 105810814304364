import { attach, createEffect, StoreValue } from "effector";
import { withdraw } from "sdk/auction-house/withdraw";

import { $connection } from "state/connection";
import { $store } from "state/store";
import { $wallet } from "state/wallet";

export interface WithdrawFxSource {
  connection: StoreValue<typeof $connection>;
  store: StoreValue<typeof $store>;
  wallet: StoreValue<typeof $wallet>;
}

export const withdrawFx = attach({
  effect: createEffect(
    async ({ connection, store, wallet }: WithdrawFxSource) => {
      if (!store || !wallet) {
        throw new Error("Empty store or wallet!");
      }

      return await withdraw({
        auctionHouseId: store.storeAhId,
        connection,
        wallet,
      });
    }
  ),
  source: {
    connection: $connection,
    wallet: $wallet,
    store: $store,
  },
  mapParams(data: never, source) {
    return source;
  },
});
