import { ChakraProvider } from "@chakra-ui/react";
import { WalletProvider } from "wallet";
import { theme } from "theme";
import { AppRoutes } from "routes";
import { ErrorBoundary } from "react-error-boundary";
import { useToast } from "components/modals/Toast";
import debug from "debug";
import { InitNotifications } from "components/Notifications/InitNotifications";
import "state/notifications/index";

const LOGErr = debug("error:App");

export const App = () => {
  const toast = useToast();

  const handleError = (error: Error) => {
    toast({ duration: 9000, title: error.name, text: error.message });
  };

  try {
    return (
      <ChakraProvider theme={theme}>
        <WalletProvider autoConnect>
          <ErrorBoundary onError={handleError} fallback={<div>Oh no</div>}>
            <InitNotifications />
            <AppRoutes />
          </ErrorBoundary>
        </WalletProvider>
      </ChakraProvider>
    );
  } catch (error) {
    LOGErr.log(error);
    return <>error</>;
  }
};
