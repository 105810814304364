import { ButtonProps } from "@chakra-ui/react";
import { createEvent, createStore } from "effector";
import React from "react";
import { v4 } from "uuid";

export interface INotification {
  id: string;
  actions?: ButtonProps[];
  content?: React.ReactNode;
  rawContent?: React.ReactNode;
  title: React.ReactNode;
  // Potential fields
  // timeout: number -  if need a temporary notice
  // type: INFO | SUCCESS | etc - Separation by semantic
}

// Event
export const addNotification = createEvent<
  { id?: string } & Omit<INotification, "id">
>();

export const addMultipleNotifications = createEvent<INotification[]>();
export const clearNotificationList = createEvent();
export const removeNotification = createEvent<string>();

// Stores
export const $notifications = createStore<INotification[]>([])
  .on(addNotification, (list, notification) => {
    const id = notification.id || v4();

    return list.find((item) => item.id === id)
      ? list
      : [...list, { ...notification, id }];
  })
  .on(addMultipleNotifications, (list, newNotifications) => [
    ...list,
    ...newNotifications,
  ])
  .on(removeNotification, (list, notificationId: string) =>
    list.filter(({ id }) => id !== notificationId)
  )
  .reset(clearNotificationList);
