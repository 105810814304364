import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import { attach, createEffect, restore, StoreValue } from "effector";
import { loadSales } from "sdk/auction-house";
import { $connection } from "state/connection";
import { $store } from "state/store";

interface Params {
  seller?: StringPublicKey | null;
}

export const fetchAHSalesFx = attach({
  effect: createEffect(
    async ({
      connection,
      store,
      seller,
    }: {
      connection: StoreValue<typeof $connection>;
      store: StoreValue<typeof $store>;
    } & Params) => {
      if (!store?.storeAhId) {
        throw new Error("AuctionHouse isn't initialized");
      }

      return await loadSales({
        connection,
        auctionHouse: store.storeAhId,
        seller,
      });
    }
  ),
  source: {
    connection: $connection,
    store: $store,
  },
  mapParams(data: Params, source) {
    return { ...data, ...source };
  },
});

export const $ahSales = restore(fetchAHSalesFx.doneData, []);

export const $pendingAHSales = fetchAHSalesFx.pending;
