import { FlowStatus } from "components/modals/FlowStatus";
import { MdErrorOutline } from "react-icons/md";
import { colors } from "theme/colors";
import { fontSizes } from "theme/typography";

interface ListingSuccessProps {
  title?: string;
  text?: string;
}

export const TransactionError: React.FC<ListingSuccessProps> = ({
  title = "Transaction Error",
  text = "There was an issue processing your transaction",
}) => (
  <FlowStatus
    statusIcon={
      <MdErrorOutline size={fontSizes["8xl"]} color={colors.pink?.["500"]} />
    }
    title={title}
    subtitle={text}
  />
);
