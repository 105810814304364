import { Connection, PublicKey } from "@solana/web3.js";
import { AddressRow, IFormData } from "components/forms/NftCreate";
import { useToast } from "components/modals/Toast";
import { attach, createEffect } from "effector";
import { useStore } from "effector-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { loadArtworkByMint } from "sdk/loadArtworks";
import { ENftProgress, MetadataJson } from "sdk/nft";
import { IArt, loadStoreArtworksFx, mintArweaveFx } from "state/artworks";
import { $connection } from "state/connection";
import { $user, $walletAddress } from "state/wallet";
import { waitForResponse } from "utils/waitForResponse";
import { Price } from "views/ItemView/types";
import { prepareFiles, prepareImageUrl } from "./prepareFilesAndImages";
import { calculatePrice } from "./utils/calculatePrice";
import { createMetadata } from "./utils/createMetadata";

export function getContent(state: ENftProgress | null) {
  switch (state) {
    case ENftProgress.minting:
      return {
        title: "Minting",
        subtitle: "Starting Mint Process",
      };
    case ENftProgress.preparing_assets:
      return {
        title: "Preparing Assets",
        subtitle: "",
      };
    case ENftProgress.signing_metadata_transaction:
      return {
        title: "Signing Metadata Transaction",
        subtitle: "Approve the transaction from your wallet",
      };
    case ENftProgress.sending_transaction_to_solana:
      return {
        title: "Sending Transaction to Solana",
        subtitle: "This will take a few seconds",
      };
    case ENftProgress.waiting_for_initial_confirmation:
      return {
        title: "Waiting for Initial Confirmation",
        subtitle: "",
      };
    case ENftProgress.waiting_for_final_confirmation:
      return {
        title: "Waiting for Final Confirmation",
        subtitle: "",
      };
    case ENftProgress.uploading_to_arweave:
      return {
        title: "Uploading to Arweave",
        subtitle: "",
      };
    case ENftProgress.updating_metadata:
      return {
        title: "Updating Metadata",
        subtitle: "",
      };
    case ENftProgress.signing_token_transaction:
      return {
        title: "Signing Token Transaction",
        subtitle: "Approve the final transaction from your wallet",
      };
    case ENftProgress.loading_account:
      return {
        title: "Item is being loaded",
        subtitle: "This will take a few seconds",
      };
    default:
      return {
        title: "",
        subtitle: "",
      };
  }
}

const calculatePriceFx = attach({
  effect: createEffect(
    ({
      files,
      metadata,
      connection,
    }: {
      files: File[];
      metadata: MetadataJson;
      connection: Connection;
    }) => calculatePrice(files, metadata, connection)
  ),
  source: {
    connection: $connection,
  },
  mapParams: (
    { files, metadata }: { files: File[]; metadata: MetadataJson },
    { connection }
  ) => ({ files, metadata, connection }),
});

async function mint({
  file,
  preview,
  metadata,
  maxSupply,
  updateProgress,
  splits,
}: {
  file: File | string | null;
  preview?: File | string;
  metadata: MetadataJson | null;
  maxSupply: number;
  updateProgress: (state: ENftProgress | null) => void;
  splits: AddressRow[];
}) {
  if (!file) {
    throw new Error("Invalid file");
  }
  if (!metadata) {
    throw new Error("Invalid metadata");
  }

  const response = await mintArweaveFx({
    files: prepareFiles(file, preview),
    metadata,
    maxSupply,
    updateProgress,
    splits,
  });
  await loadStoreArtworksFx();
  updateProgress(null);
  return response;
}

export function useLocalState() {
  const user = useStore($user);
  const connection = useStore($connection);
  const address = useStore($walletAddress);
  const toast = useToast();
  const [previewForm, setPreviewForm] = useState<IFormData | null>(null);
  const [price, setPrice] = useState<Price | null>(null);
  const [pageState, setPageState] = useState<
    "form" | "preview" | "minting" | "loading-minted" | "success"
  >("form");
  const [step, setStep] = useState<ENftProgress | null>(null);
  const ref = useRef<IFormData | null>(null);
  const [successArtwork, setSuccessArtwork] = useState<IArt | null>(null);
  const [artworkError, setArtworkError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchPrice() {
      if (previewForm) {
        const calculatedPrice = await calculatePriceFx({
          files: prepareFiles(previewForm.file, previewForm.preview),
          metadata: createMetadata(
            previewForm.secondarySplits.length > 0
              ? previewForm.secondarySplits
              : address,
            previewForm
          ),
        });

        setPrice({
          sol: calculatedPrice?.price || 0,
        });
      }
    }

    fetchPrice();
  }, [address, previewForm]);

  const onMint = useCallback(async () => {
    if (!user) {
      toast({
        title: "Transaction Failed",
        text: "Your transaction failed because your wallet is not connected.",
        duration: 9000,
      });
      return;
    }

    if (ref.current === null || address === null) {
      return;
    }

    const data: IFormData = ref.current;
    setPageState("minting");

    let res: { mint: PublicKey; metadata: PublicKey } | undefined;

    try {
      res = await mint({
        file: data.file,
        preview: data?.preview,
        metadata: createMetadata(
          data?.secondarySplits.length > 0 ? data?.secondarySplits : address,
          data
        ),
        updateProgress: (data) => {
          setStep(data);
        },
        maxSupply: data.supply,
        splits: data?.splits,
      });
    } catch (e) {
      setArtworkError(typeof e === "string" ? e : "Transaction failed");
    }

    if (!res?.mint) {
      return;
    }

    const artworkMint = res.mint;

    setStep(ENftProgress.loading_account);

    const mintedArtwork = await waitForResponse(
      async () =>
        await loadArtworkByMint({
          mint: artworkMint,
          connection,
        })
    );

    if (!mintedArtwork) {
      return;
    }

    setPageState("success");
    // TODO temp solution to load image faster
    setSuccessArtwork({
      ...mintedArtwork,
      image: prepareImageUrl(data.file, data.preview),
    });

    if (mintedArtwork) {
      // TODO: append minterArtwork to $storeArtworks & cache
    }
  }, [address, connection, toast, user]);

  const onFormSubmit = useCallback((formData: IFormData) => {
    setPreviewForm({
      ...formData,
      properties: formData.properties.filter(
        (property) => property.key !== "" && property.value !== ""
      ),
    });
    setPageState("preview");

    ref.current = formData;

    return true;
  }, []);

  return {
    artworkError,
    setArtworkError,
    successArtwork,
    form: ref.current,
    step,
    onMint,
    onFormSubmit,
    previewForm,
    price,
    pageState,
    setPageState,
  };
}
