import { useEvent } from "effector-react";
import React, { useEffect } from "react";
import { unsetStore } from "state/store";

export const DeactivateStore: React.FC = ({ children }) => {
  const trigger = useEvent(unsetStore);

  useEffect(() => {
    trigger();
  }, [trigger]);

  return <>{children}</>;
};
