import {
  CloseButton,
  FormControl,
  HStack,
  Input,
  VStack,
} from "@chakra-ui/react";
import { CreateButton } from "components/buttons/CreateButton";
import { IFormFieldRegister } from "components/FormField";
import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

interface Props {
  register: IFormFieldRegister;
  button: string;
}

export const ListInput: FC<Props> = ({
  register: { name, placeholder },
  button,
}) => {
  const { register } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
  });

  return (
    <VStack alignItems="start">
      {fields.map((field, index) => {
        return (
          <FormControl key={field.id}>
            <HStack alignItems="flex-end">
              <Input
                {...register(`${name}.${index}`)}
                placeholder={placeholder}
              />
              <CloseButton
                size="lg"
                variant="forms"
                onClick={() => remove(index)}
              />
            </HStack>
          </FormControl>
        );
      })}

      <CreateButton
        title="create"
        onClick={() => append("")}
        variant="tertiary"
        fontSize="md"
      >
        {button}
      </CreateButton>
    </VStack>
  );
};
