import { BottomNav } from "components/BottonNav";
import { Layout } from "components/Layout";
import { useStore } from "effector-react";
import React, { FC, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FailModal } from "./FailModal";
import { SaleDetailsForm } from "./SaleDetailsForm";
import { SaleReview } from "./SaleReview";
import { createSaleFactory, Step } from "./CreateSaleView.state";
import { SaleSuccessfullyCreated } from "./SaleSuccessfullyCreated";
import { $profileArtworks } from "state/artworks";
import { CreateSaleProgress } from "./CreateSaleProgress";

export const CreateSaleView: FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const artworkId = query.get("artworkId");

  const model = artworkId
    ? createSaleFactory.createModel({
        mint: artworkId,
      })
    : createSaleFactory.createModel();

  return (
    <createSaleFactory.Provider value={model}>
      <ViewContent />
    </createSaleFactory.Provider>
  );
};

export const ViewContent: FC = () => {
  const navigate = useNavigate();
  const artworks = useStore($profileArtworks);

  const {
    $step,
    forwardPressed,
    backPressed,
    $forwardDisabledReason,
    $isReturnBack,
    fetchSelectedArtwork,
    artworkSelect,
    $activeArtwork,
    $activeArtworkMint,
  } = createSaleFactory.useModel();

  const artworkMint = useStore($activeArtworkMint);

  useEffect(() => {
    const selectedArtwork = artworks.find(
      (artwork) => artwork.mint === artworkMint
    );
    selectedArtwork ? artworkSelect(selectedArtwork) : fetchSelectedArtwork();
  }, [artworkMint, artworkSelect, artworks, fetchSelectedArtwork]);

  const step = useStore($step);
  const forwardDisabledReason = useStore($forwardDisabledReason);
  const isReturn = useStore($isReturnBack);
  const artwork = useStore($activeArtwork);

  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <Layout variant="narrow" focused>
      {step !== Step.RESULT && (
        <>
          {step === Step.FILL_FORM && <SaleDetailsForm />}
          {step === Step.CONFIRM && <SaleReview />}
          <BottomNav
            onForward={step === Step.CONFIRM ? undefined : forwardPressed}
            onBack={isReturn ? goBack : backPressed}
            forwardDisabled={!!forwardDisabledReason || !artwork}
            forwardDisabledTooltip={forwardDisabledReason || undefined}
          />
          <CreateSaleProgress art={artwork} />
          <FailModal />
        </>
      )}
      {step === Step.RESULT && <SaleSuccessfullyCreated />}
    </Layout>
  );
};
