/* eslint-disable @typescript-eslint/no-non-null-assertion, no-console */
import { Button, VStack } from "@chakra-ui/react";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { Layout } from "components/Layout";
import dayjs from "dayjs";
import { useStore } from "effector-react";
import { FC, useEffect } from "react";
import { buy } from "sdk/fps/buy";
import { initMarket, initMarketProps } from "sdk/fps/initMarket";
import { initStore } from "sdk/fps/initStore";
import { loadMarket, loadMarkets } from "sdk/fps/loadMarkets";
import { loadStore, loadStores } from "sdk/fps/loadStore";
import { loadTrades } from "sdk/fps/loadTrades";
import {
  $pendingProfileArtworks,
  $profileArtworks,
  fetchProfileArtworksFx,
} from "state/artworks";
import { $connection } from "state/connection";
import { $wallet } from "state/wallet";

export const TestPage: FC = () => {
  const connection = useStore($connection);
  const wallet = useStore($wallet)!;

  const arts = useStore($profileArtworks);
  const pending = useStore($pendingProfileArtworks);
  console.log(arts, pending);

  useEffect(() => {
    fetchProfileArtworksFx();
  }, []);

  const cw = { connection, wallet };
  const co = { connection, owner: wallet.publicKey };
  const store = new PublicKey("7zAnT9j4WDA3TvYQkwu5R6gsxAQSw9tbRqoJPvi4JhMU");
  const market: initMarketProps = {
    ...cw,
    store,
    resourceMint: new PublicKey("4bSf3X66V9GYzfaZnjxZKjVbYHcG7bqNpsLfNzYyYqks"),
    resourceToken: new PublicKey(
      "H3SCShtFFAvNfH1u4gQsCLN7fDZcq5Uopv4Xiccaw5DS"
    ),
    maxSupply: 10000,
    marketSettings: {
      name: "",
      description: "",
      mutable: false,
      price: 0.2 * LAMPORTS_PER_SOL,
      piecesInOneWallet: 1,
      startDate: dayjs().unix() + 90,
      endDate: null,
      gatingCollection: "",
      gatingTime: 0,
      expireOnUse: false,
    },
  };
  const lm = {
    connection,
    store: store.toString(),
    wallet,
  };

  return (
    <Layout variant="narrow">
      <VStack spacing={4}>
        <Button onClick={() => log(initStore({ ...cw }))}>Create store</Button>

        <Button onClick={() => log(loadStores(co))}>Load stores</Button>
        <Button
          onClick={() =>
            log(
              loadStore({
                connection,
                storeId: "8TnzZGAJP3DxMj1onqMMGAfJyAaeKzt6iMnH7qyzqXyL",
              })
            )
          }
        >
          Load store
        </Button>

        <Button onClick={() => log(initMarket(market))}>create market</Button>
        <Button onClick={() => log(loadMarkets(lm))}>load markets</Button>

        <Button
          onClick={async () =>
            log(
              buy({
                ...cw,
                store: store.toString(),
                sale: await loadMarket({
                  connection,
                  marketId: "8KMvxMe1ELeTgwCVX4HJ23qw29XXMfrBQ2wZjbgqBAov",
                  wallet,
                }),
              })
            )
          }
        >
          buy
        </Button>
        <Button onClick={() => log(loadTrades(cw))}>trade</Button>
      </VStack>
    </Layout>
  );
};

async function log(a: Promise<unknown>) {
  const res = await a;
  console.log("##", res);
}
