import { WithdrawEscrow } from "components/Notifications/WithdrawEscrow";
import { sample } from "effector";
import { addNotification, removeNotification } from "state/notifications";
import { closeEscrowBalanceFx, getEscrowBalanceFx } from "state/sales";
import { solToLamports } from "utils/lamportsToSol";

sample({
  clock: getEscrowBalanceFx.doneData,
  source: [],
  filter: (_, balance) => filterBalance(balance),
  fn: (_, balance) => ({
    id: "closeEscrow",
    title: "You have funds to claim",
    rawContent: <WithdrawEscrow balance={balance || 0} />,
  }),

  target: addNotification,
});

sample({
  clock: getEscrowBalanceFx.doneData,
  source: [],
  filter: (_, balance) => !filterBalance(balance),
  fn: () => "closeEscrow",
  target: removeNotification,
});

sample({
  clock: closeEscrowBalanceFx.doneData,
  source: [],
  fn: () => "closeEscrow",
  target: removeNotification,
});

const filterBalance = (balance: number | null) => {
  return !!balance && balance >= solToLamports(0.01);
};
