import {
  loadStoreByAddressFx,
  setShowStoreCongratulations,
  submitStoreFx,
} from "state/store";
import { useMemo } from "react";
import { useEvent, useStore } from "effector-react";
import { createEffect } from "effector";

import { EUploadProgress } from "sdk/nft/uploadJson2Arweave";
import { IEntry } from "state/utils";
import { StoreFormProps } from "components/forms/StoreCreateForm";
import { useWalletStore } from "state/react/useWalletStore";
import { waitForResponse } from "utils/waitForResponse";
import { useLocalProgress } from "hooks/useLocalProgress";

export interface IOptions {
  onSuccessStoreCreate(storeId: string): void;
  onStoreCreationError(storeId: string): void;
}

function getContent(state: EUploadProgress | null) {
  switch (state) {
    case EUploadProgress.preparing_assets:
      return {
        title: "Preparing Assets",
        subtitle: "",
      };
    case EUploadProgress.signing_metadata_transaction:
      return {
        title: "Signing Metadata Transaction",
        subtitle: "Approve the transaction from your wallet",
      };
    case EUploadProgress.sending_transaction_to_solana:
      return {
        title: "Sending Transaction to Solana",
        subtitle: "This will take a few seconds",
      };
    case EUploadProgress.uploading_to_arweave:
      return {
        title: "Uploading to Arweave",
        subtitle: "",
      };

    default:
      return {
        title: "",
        subtitle: "",
      };
  }
}

type ILocalStateOptions = IOptions & {
  $progress: IEntry<EUploadProgress | null>;
};

function createLocalState({
  onSuccessStoreCreate,
  onStoreCreationError,
  $progress,
}: ILocalStateOptions) {
  const submitFx = createEffect(async (data: StoreFormProps) => {
    try {
      const { storeId } = await submitStoreFx({
        data,
        updateProgress: (state) => $progress.set(state),
      });
      if (!storeId) {
        onStoreCreationError("Failed to create store");
      }

      await waitForResponse(async () => {
        await loadStoreByAddressFx(storeId);
      });

      setShowStoreCongratulations(true);
      onSuccessStoreCreate(storeId.toString());
    } catch (e) {
      onStoreCreationError("Failed to create store.");
    }
  });

  return { submitFx };
}

export const useLocalState = (opts: IOptions) => {
  const { $progress, $progressMeta, steps } = useLocalProgress({
    getContent,
    stepsEnum: EUploadProgress,
  });
  const state = useMemo(
    () => createLocalState({ ...opts, $progress }),
    [opts, $progress]
  );
  const progressMeta = useStore($progressMeta);
  const onSubmit = useEvent(state.submitFx);
  const { data, fail } = useWalletStore();

  return {
    store: data,
    hasStore: data ? true : fail ? false : null,
    onSubmit,
    progressMeta,
    steps,
  };
};
