import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const usePagination = <T>(
  items: T[] = [],
  tabIndex: number,
  initialItemsPerPage = 12
) => {
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);
  const [page, setPage] = useState({ [tabIndex]: 1 });
  const [searchParams, setSearchParams] = useSearchParams();
  const pageInfo = searchParams.get("page");
  const tab = searchParams.get("tab");

  if (!Object.keys(page).includes(tabIndex.toString())) {
    setPage({ [tabIndex]: 1 });
  }

  const handleSetPage = useCallback(
    (selectedPage?: number) => {
      if (selectedPage !== undefined) {
        setPage({ [tabIndex]: selectedPage });
        setSearchParams({
          page: selectedPage.toString(),
          ...(tab ? { tab } : {}),
        });
      }
    },
    [setSearchParams, tab, tabIndex]
  );

  useEffect(() => {
    if (pageInfo) handleSetPage(parseInt(pageInfo));
  }, [handleSetPage, pageInfo]);

  const pageItems = useMemo(
    () =>
      items.slice(
        (page[tabIndex] - 1) * itemsPerPage,
        page[tabIndex] * itemsPerPage
      ),
    [items, itemsPerPage, page, tabIndex]
  );

  return {
    items: pageItems,
    page: page[tabIndex],
    setPage: handleSetPage,
    itemsPerPage,
    setItemsPerPage,
  };
};
