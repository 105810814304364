import { ArtworkDetailsColumn } from "../ArtworkDetailsColumn";
import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { SolUsdDisplay } from "components/SolUsdDisplay";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { IFixedPrice } from "state/sales";
import { SupplyDetails } from "./SupplyDetails";

interface Props {
  sale?: IFixedPrice;
}

export const InstantBuyDetails: FC<Props> = ({ sale }) => {
  const { xlUp, xxlUp } = useCustomBreakpoints();

  if (!sale) return null;

  const { artwork } = sale;
  const sol = sale.price;

  return (
    <>
      {xxlUp && <Box px={6} w="full" />}

      <ArtworkDetailsColumn title="Price">
        <SolUsdDisplay sol={sol || 0} />
      </ArtworkDetailsColumn>

      {xlUp && (
        <ArtworkDetailsColumn title="Supply">
          <SupplyDetails {...artwork.prints} />
        </ArtworkDetailsColumn>
      )}
    </>
  );
};
