import { useStoreMap } from "effector-react";
import { $network } from "state/connection";

export const useOpenSolanaExplorer = () =>
  useStoreMap($network, (network) => (address?: string | null) => {
    if (!address) return;
    window.open(
      `https://explorer.solana.com/address/${address}?cluster=${network}`,
      "_blank"
    );
  });
