import { ModalCloseButton, ModalHeader } from "@chakra-ui/react";
import { ModalTemplate } from "components/modals/template";
import { TransactionError } from "components/modals/TransactionError";
import { useStore } from "effector-react";
import { FC } from "react";
import { createSaleFactory } from "./CreateSaleView.state";

export const FailModal: FC = () => {
  const { $processFail, processFailReset } = createSaleFactory.useModel();
  const isOpen = useStore($processFail);

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={() => {
        processFailReset();
      }}
      isCentered={true}
      size="lg"
      bodyProps={{ p: 0 }}
      header={
        <ModalHeader display="flex" p={6}>
          <ModalCloseButton top={3} onClick={() => processFailReset()} />
        </ModalHeader>
      }
    >
      <TransactionError />
    </ModalTemplate>
  );
};
