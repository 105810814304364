import {
  Box,
  Button,
  Divider,
  Heading,
  StyleProps,
  VStack,
} from "@chakra-ui/react";
import { Flex } from "@chakra-ui/layout";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { FileType, FiletypeDescMap } from "components/MediaTypeSelector";
import { FileUpload } from "components/FileUpload";
import { FormField } from "components/FormField";
import { PageHead } from "components/PageHead";
import { ToggleSelect } from "components/ToggleSelect";

import { User } from "state/wallet";

import { SupplyTypeInput } from "./components/SupplyTypeInput";
import { CreatorsSplits } from "./components/CreatorsSplits";
import { NftProperties } from "./components/NftProperties";

import { getDefaultValues } from "./utils/getDefaultValues";

import { IFormData } from "./interface";
import { OPTIONS } from "./data";
import { useIsValid } from "./hooks/useIsValid";
import { FormFieldWithLength } from "components/FormFieldWithLength";
import { Accordion } from "components/Accordion";

export interface NftCreationFormProps extends StyleProps {
  cancel: () => void;
  formValues?: Partial<IFormData> | null;
  user: User;

  onSubmit?(form: IFormData): void;
}

export const NftCreationForm: FC<NftCreationFormProps> = ({
  onSubmit,
  cancel,
  formValues,
  user,
  ...props
}) => {
  const methods = useForm<IFormData>({
    mode: "onChange",
    defaultValues: formValues
      ? {
          ...formValues,
          properties: formValues?.properties ?? [{ key: "", value: "" }],
        }
      : getDefaultValues(user),
  });
  const { handleSubmit, watch } = methods;

  const isValid = useIsValid({ methods });
  const metadataCategory = watch("mediaType");
  watch();

  useEffect(() => {
    formValues && methods.trigger();
  }, [formValues, methods]);

  useEffect(() => {
    methods.resetField("file");
  }, [metadataCategory, methods]);

  return (
    <Box {...props}>
      <PageHead title="Create new item" />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => onSubmit?.(data))} noValidate>
          <VStack spacing={12} align="stretch">
            <FormField
              id="mediaType"
              title="MEDIA TYPE"
              options={{ required: true }}
              description="What type of NFT do you want to create?"
              controlledInputFactory={({ field }) => (
                <ToggleSelect<FileType>
                  options={OPTIONS}
                  onSelect={field.onChange}
                  selectedOptionId={field.value as FileType}
                />
              )}
            />

            <FormField
              id="file"
              title="UPLOAD FILE"
              description={FiletypeDescMap[metadataCategory]}
              placeholder="e. g. “Awesome logo collectible”"
              options={{ required: true }}
              controlledInputFactory={({ field, fieldState }) => (
                <FileUpload
                  value={field.value as File | string}
                  onBlur={field.onBlur}
                  isInvalid={fieldState.invalid}
                  onFileChange={field.onChange}
                  type={metadataCategory}
                  variant={
                    metadataCategory === FileType.IMAGE ? "preview" : "base"
                  }
                />
              )}
            />

            {metadataCategory !== FileType.IMAGE && (
              <FormField
                id="preview"
                title="PREVIEW IMAGE"
                description="Multimedia assets require a preview image (JPG, PNG) for the card view display."
                placeholder="e. g. “Awesome logo collectible”"
                options={{ required: true }}
                controlledInputFactory={({ field, fieldState }) => (
                  <FileUpload
                    value={field.value as File | string}
                    onBlur={field.onBlur}
                    isInvalid={fieldState.invalid}
                    onFileChange={field.onChange}
                    type={FileType.IMAGE}
                    variant="logo-preview"
                  />
                )}
              />
            )}

            <Divider />
            <Heading>Item details</Heading>

            <FormFieldWithLength
              id="title"
              title="name"
              placeholder="e. g. “Awesome logo collectible”"
              options={{
                required: "Title field is required",
                minLength: 1,
              }}
              maxLength={32}
            />

            <FormFieldWithLength
              id="desc"
              title="Description"
              placeholder="e. g. “Exploration of the metaverse”"
              maxLength={240}
              h={32}
              textarea
            />

            <SupplyTypeInput />

            <Divider />
            <Heading>Primary sale profit distribution</Heading>

            <CreatorsSplits
              type="splits"
              text="Enter wallet addresses and percentages that primary sales will be distributed to"
            />

            <Divider />
            <Heading>Secondary sales royalties distribution</Heading>

            <FormField
              type="number"
              id="royalty"
              title="% of resale royalty"
              description="The percentage of all future sales creators will receive from secondary sales."
              options={{ required: true }}
            />

            <CreatorsSplits
              type="secondarySplits"
              text="The percentage of profits automatically deposited into each recipient’s wallet."
            />

            <Divider />

            <Accordion title="Advanced Settings" allowToggle>
              <NftProperties />

              <FormField
                id="symbol"
                title="symbol"
                placeholder="e.g. AXR"
                options={{ minLength: 1 }}
              />
            </Accordion>

            <Divider mt={10} mb={8} />
            <Flex>
              <Button variant="tertiary" px={12} onClick={cancel}>
                Back
              </Button>
              <Button
                px={7}
                type="submit"
                variant="primary"
                ml="auto"
                disabled={!isValid}
              >
                Continue
              </Button>
            </Flex>
          </VStack>
        </form>
      </FormProvider>
    </Box>
  );
};
