import { AnyPublicKey } from "@metaplex-foundation/mpl-core";
import { toSeconds } from "components/TimeDurationInput/toSeconds";
import dayjs from "dayjs";
import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  guard,
  restore,
  sample,
} from "effector";
import { modelFactory } from "effector-factorio";
import { TabsConfig } from "hooks/useTabs";
import { useMemo } from "react";
import { MarketSettings } from "sdk/fps/actions/createMarketTransaction";
import { ETransactionProgress } from "sdk/share";
import {
  $pendingArtworks,
  $storeArtworks,
  ArtType,
  IArt,
  loadStoreArtworksFx,
} from "state/artworks";
import { loadArtworkByMintFx } from "state/artworks/loadArtworkByMintFx";
import { createSaleFx, loadSaleFx } from "state/sales";
import { createProgressTools } from "utils/createProgressTools";
import { solToLamports } from "utils/lamportsToSol";
import { ISaleDetailsForm } from "./SaleDetailsForm";
import { SaleType } from "./SaleDetailsForm/SaleTypeSelector";

export enum Step {
  SELECT_ART = 0,
  FILL_FORM = 1,
  CONFIRM = 2,
  RESULT = 3,
}

type ModelDefaults = Partial<{
  step?: Step;
  activeArtwork?: IArt;
  mint?: AnyPublicKey | null;
}>;

export function getContent(state: ETransactionProgress | null) {
  switch (state) {
    case ETransactionProgress.creating:
      return {
        title: "Preparing Sale data",
        subtitle: "",
      };
    case ETransactionProgress.sending_to_solana:
      return {
        title: "Sending Transaction to Solana",
        subtitle: "This will take a few seconds",
      };
    case ETransactionProgress.waiting_for_confirmation:
      return {
        title: "Waiting for confirmation",
        subtitle: "",
      };
    case ETransactionProgress.loading_account:
      return {
        title: "Item is being loaded",
        subtitle: "This will take a few seconds",
      };

    default:
      return {
        title: "",
        subtitle: "",
      };
  }
}

export const createSaleFactory = modelFactory((defaults?: ModelDefaults) => {
  const nextStep = createEvent<1 | -1>();
  const forwardPressed = nextStep.prepend(() => 1);
  const backPressed = nextStep.prepend(() => -1);

  const $step = createStore<Step>(defaults?.step ?? Step.FILL_FORM);
  $step.on(nextStep, (step, increment) => step + increment);

  const fetchArtworksList = createEvent();
  forward({ from: fetchArtworksList, to: loadStoreArtworksFx });

  const $activeArtworkMint = createStore(defaults?.mint ?? null);

  const loadSelectedArtworkFx = attach({
    effect: loadArtworkByMintFx,
    source: {
      mint: $activeArtworkMint,
    },
    mapParams: (_, { mint }) => {
      if (!mint) {
        throw new Error("get artwork: missed mint");
      }
      return { mint };
    },
  });

  const $fee = createStore(0.001);

  const fetchSelectedArtwork = createEvent();
  forward({
    from: fetchSelectedArtwork,
    to: loadSelectedArtworkFx,
  });

  const artworkSelect = createEvent<IArt | null>();
  const $activeArtwork = restore(
    artworkSelect,
    defaults?.activeArtwork ?? null
  );

  forward({
    from: loadSelectedArtworkFx.doneData,
    to: $activeArtwork,
  });

  const setIsFormValid = createEvent<boolean>();
  const $isFormValid = restore(setIsFormValid, false);
  const formSubmitFx = createEffect();
  const formSubmit = createEvent<ISaleDetailsForm>();
  const $saleDetails = restore(formSubmit, null);

  const {
    $progress: { $node: $currentStep, set: updateProgress },
    $progressMeta,
  } = createProgressTools(getContent, null);
  guard({
    clock: forwardPressed,
    filter: $step.map((step) => step === Step.CONFIRM),
    target: formSubmitFx,
  });

  const reviewSubmit = createEvent();

  const processFx = attach({
    effect: createSaleFx,
    source: {
      artwork: $activeArtwork,
      details: $saleDetails,
    },
    mapParams: (_, { artwork, details }) => {
      const settings = prepareSettings(details);

      if (!artwork || !settings) {
        throw new Error("create sale: missed artwork or settings");
      }

      return {
        artwork,
        settings,
        updateProgress,
      };
    },
  });
  sample({
    clock: processFx.finally,
    fn: () => ETransactionProgress.loading_account,
    target: updateProgress,
  });

  const $processInProgress = processFx.pending;
  const setProcessFail = createEvent<boolean>();
  const processFailReset = setProcessFail.prepend(() => false);
  const $processFail = restore(setProcessFail, false);

  forward({ from: reviewSubmit, to: processFx });

  const loadFx = attach({ effect: loadSaleFx });

  sample({
    clock: processFx.doneData,
    fn: (res) => {
      if ("receipt" in res) {
        return { saleId: res.receipt.toString() };
      }

      if ("market" in res) {
        return { saleId: res.market };
      }

      throw new Error("Not implemented yet");
    },
    target: loadFx,
  });
  forward({ from: loadFx.doneData, to: forwardPressed });
  $progressMeta.reset(loadFx.finally);

  sample({
    clock: processFx.failData,
    fn: () => true,
    target: setProcessFail,
  });

  const $createdSale = restore(loadFx.doneData, null);
  const $createSalePending = loadFx.pending;

  const $isReturnBack = $step.map((step) => step === Step.FILL_FORM);
  const $forwardDisabledReason = combine(
    $step,
    $activeArtwork,
    $isFormValid,
    (step, artwork, isFormValid) => {
      if (step === Step.FILL_FORM && !isFormValid) {
        return "Please fill sale details";
      }
      return null;
    }
  );

  const tabs: TabsConfig<IArt> = useMemo(
    () => [
      { id: "all", title: "All" },
      {
        id: "masters",
        accessor: ({ type }) => type === ArtType.Master,
        title: "Masters",
      },
      {
        id: "editions",
        accessor: ({ type }) => type === ArtType.Print,
        title: "Editions",
      },
    ],
    []
  );

  return {
    $step,
    forwardPressed,
    backPressed,
    $forwardDisabledReason,
    $isReturnBack,

    fetchSelectedArtwork,

    fetchArtworksList,
    $artworks: $storeArtworks,
    $isArtworksPending: $pendingArtworks,
    tabs,
    $activeArtwork,
    artworkSelect,
    $activeArtworkMint,

    $fee,

    setIsFormValid,
    formSubmitFx,
    formSubmit,
    $saleDetails,

    reviewSubmit,
    $processInProgress,
    $processFail,
    processFailReset,

    $createdSale,
    $createSalePending,

    $currentStep,
    $progressMeta,
  };
});

function prepareSettings(
  data: ISaleDetailsForm | null
): (MarketSettings & { type: SaleType }) | null {
  if (!data || !data.price) {
    return null;
  }

  const gatingTime = () => {
    if (data.gatingTime?.value === 0) return 0;
    if (data.gatingTime?.value)
      return startDate + (toSeconds(data.gatingTime) ?? 0);
    return null;
  };

  const DELAY_IN_SECONDS = 30;
  const now = Date.now();
  const startDate =
    !data.date || data.date.getTime() < now
      ? dayjs().unix() + DELAY_IN_SECONDS
      : data.date.getTime() / 1000 + DELAY_IN_SECONDS;

  return {
    type: data.saleType,
    name: "",
    description: "",
    mutable: false,
    price: solToLamports(data.price),
    piecesInOneWallet: typeof data.pieces === "string" ? null : data.pieces,
    startDate,
    endDate: data.duration?.value
      ? startDate + (toSeconds(data.duration) ?? 0)
      : null,
    gatingCollection: data.collectionAddress ?? "",
    gatingTime: gatingTime(),
    expireOnUse: data.expireOnUse ?? false,
  };
}
