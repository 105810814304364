import { Grid } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { ToggleButton } from "./ToggleButton";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface Option<T> {
  id: T;
  title: string;
  Icon: IconType;
  disabled?: boolean;
}

export type ToggleSelectOptions<T> = Option<T>[];

interface Props<T> {
  options: ToggleSelectOptions<T>;
  selectedOptionId?: T;
  onSelect: (id: T) => void;
}

export const ToggleSelect = <T,>({
  options,
  selectedOptionId,
  onSelect,
}: Props<T>) => {
  const { mdUp } = useCustomBreakpoints();

  return (
    <Grid
      templateColumns={mdUp ? `repeat(${options.length}, 1fr)}` : "1fr 1fr"}
      gap={4}
    >
      {options.map((option) => (
        <ToggleButton
          disabled={option.disabled}
          key={String(option.id)}
          id={option.id}
          title={option.title}
          Icon={option.Icon}
          onSelect={onSelect}
          selected={option.id === selectedOptionId}
          w="full"
          cursor="pointer"
        />
      ))}
    </Grid>
  );
};
