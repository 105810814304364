import React from "react";
import { IArt } from "state/artworks";
import ReactAudioPlayer from "react-audio-player";
import { Box, Image } from "@chakra-ui/react";

interface ArtAudioProps {
  artwork: IArt;
}

export const ArtAudio: React.FC<ArtAudioProps> = ({ artwork }) => {
  return (
    <>
      <Box position="absolute" bottom={2} left={4} right={4}>
        <ReactAudioPlayer
          style={{
            width: "100%",
          }}
          src={artwork.animationUrl}
          controls
        />
      </Box>
      <Image
        id="artImage"
        h="full"
        w="full"
        objectFit="cover"
        src={artwork.image}
      />
    </>
  );
};
