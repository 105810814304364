export const Button = {
  baseStyle: {
    _focus: {
      boxShadow: "none",
    },
  },
  variants: {
    primary: {
      bg: "#F83939",
      color: "black",
      _hover: {
        bg: "#F83939",
        _disabled: {
          bg: "gray.700",
        },
      },
      _focus: {
        bg: "#F83939",
      },
      _disabled: {
        opacity: 1,
        boxShadow: "0px 0px 24px rgba(26, 26, 26, 0.12)",
        color: "whiteAlpha.500",
        bg: "gray.800",
      },
      _active: { bg: "whiteAlpha.900" },
    },
    secondary: {
      bg: "white",
      color: "black",
      _hover: {
        bg: "gray.500",
        color: "white",
      },
      _disabled: {
        bg: "gray.800",
        color: "whiteAlpha.500",
      },
      _active: { bg: "white" },
    },
    tertiary: {
      bg: "whiteAlpha.100",
      color: "white",
      _hover: {
        bg: "whiteAlpha.50",
      },
      _focus: {
        bg: "whiteAlpha.300",
      },
      _active: { bg: "whiteAlpha.200" },
    },
    ghost: {
      color: "white",
      _hover: {
        bg: "whiteAlpha.100",
      },
      _focus: {
        bg: "whiteAlpha.200",
      },
      _active: {
        bg: "whiteAlpha.200",
      },
    },
    notification: {
      bg: "whiteAlpha.100",
      color: "white",
      _hover: {
        bg: "whiteAlpha.200",
      },
      _focus: {
        bg: "whiteAlpha.200",
      },
      _active: {
        bg: "whiteAlpha.200",
      },
    },
    solid: {
      bg: "white",
      color: "gray.900",
      _hover: {
        bg: "whiteAlpha.900",
      },
      _focus: {
        bg: "whiteAlpha.700",
      },
    },
    link: {
      color: "white",
      _hover: {
        color: "whiteAlpha.700",
        textDecoration: "none",
      },
      _focus: {
        color: "whiteAlpha.500",
        textDecoration: "none",
      },
    },
  },
  sizes: {
    // do we need xl?
    xl: {
      borderRadius: "8px",
      minW: 12,
      fontSize: "md",
      px: 6,
      py: 5,
    },
    lg: {
      h: 14,
      borderRadius: "lg",
      fontSize: "md",
      lineHeight: "base",
      fontWeight: "bold",
      px: 4,
    },
    md: {
      h: 12,
      minW: 12,
      borderRadius: "lg",
      fontSize: "md",
      lineHeight: "base",
      fontWeight: "bold",
      px: 4,
    },
    sm: {
      h: 10,
      minW: 10,
      borderRadius: "lg",
      fontSize: "md",
      lineHeight: "base",
      fontWeight: "bold",
      px: 4,
    },
  },
};
