import React, { useCallback, useState } from "react";

import { CreateStoreProgress } from "components/modals/CreateStoreProgress";
import { Layout } from "components/Layout";
import { Link } from "@chakra-ui/react";
import { StoreCreateForm } from "components/forms/StoreCreateForm";
import { StorefrontExists } from "components/modals/StorefrontExists";
import { useLocalState } from "./StoreCreate.state";
import { useNavigate } from "react-router-dom";
import { usePaths } from "routes";

export const StoreCreate: React.FC = () => {
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();
  const paths = usePaths();

  const onSuccessStoreCreate = useCallback(
    (storeId: string) => {
      navigate(paths.store({ ":storeId": storeId }));
    },
    [navigate, paths]
  );

  const onStoreCreationError = useCallback((errorMessage: string) => {
    setError(errorMessage);
  }, []);

  const onClose = useCallback(() => {
    navigate(paths.home());
  }, [navigate, paths]);

  const { onSubmit, store, hasStore, progressMeta, steps } = useLocalState({
    onSuccessStoreCreate,
    onStoreCreationError,
  });

  const handleCloseError = useCallback(() => {
    setError("");
    progressMeta.isOpen = false;
  }, [progressMeta]);

  return (
    <Layout variant="narrow" gutterBottom>
      <StoreCreateForm
        onSubmit={onSubmit}
        title="Create your storefront"
        actionName="CREATE"
        actionButtonName="Create Storefront"
        disabled={hasStore !== false || progressMeta.isOpen} // we allow work with form only we can't load store config for wallet
      >
        <>
          No technical setup or coding required. Takes less than 5 minutes.
          <Link href="#"> Learn more</Link>
        </>
      </StoreCreateForm>
      {store && (
        <StorefrontExists
          isOpen
          storeName={store.name}
          logoUri={store.logoImg}
          storeId={store.storeId}
          onClose={onClose}
        />
      )}

      <CreateStoreProgress
        isOpen={progressMeta.isOpen}
        onClose={handleCloseError}
        steps={steps}
        error={error}
      />
    </Layout>
  );
};
