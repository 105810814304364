import { Link } from "react-router-dom";
import type { ButtonProps } from "@chakra-ui/button";

export function smartButtonProps(action?: string | (() => void)) {
  const onClick = typeof action === "string" ? undefined : action;
  const asLink = typeof action === "string" ? Link : undefined;
  const to = typeof action === "string" ? action : undefined;

  const props: Pick<ButtonProps, "onClick" | "as"> & { to?: string } = {
    onClick,
    to,
    as: asLink,
  };
  return props;
}
