import { clusterApiUrl, Connection } from "@solana/web3.js";
import { combine } from "effector";
import { $network } from "./network";

export const DEFAULT_TIMEOUT = 180_000; // 3 minutes

export const $connection = combine($network, (network) => {
  const endpoint =
    network === "mainnet-beta"
      ? "https://mainnet.helius-rpc.com/?api-key=2430c5ec-78ab-411d-8c70-dadf86064475"
      : network === "devnet"
      ? "https://liquid.devnet.rpcpool.com/5ebea512d12be102f53d319dafc8/"
      : clusterApiUrl(network);
  return new Connection(endpoint, {
    confirmTransactionInitialTimeout: DEFAULT_TIMEOUT,
  });
});
