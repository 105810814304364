import React from "react";
import { artworkCardStateProps } from "./artworkCardStateProps";
import { Flex } from "@chakra-ui/layout";
import { ArtworkCardColumn } from "../ArtworkCardColumn";
import { SolUsdDisplay } from "components/SolUsdDisplay";
import { TimeEntry } from "components/TimeEntry";

interface Props {
  price: number;
  ends: number;
}

export const ArtworkCardLiveAuctionState: React.FC<Props> = ({
  price,
  ends,
}) => {
  return (
    <Flex {...artworkCardStateProps}>
      <ArtworkCardColumn title="CURRENT BID">
        <SolUsdDisplay sol={price} />
      </ArtworkCardColumn>

      <ArtworkCardColumn
        title="LIVE"
        ml="auto"
        titleProps={{ color: "green.500", textAlign: "end" }}
      >
        <TimeEntry countdown={ends} />
      </ArtworkCardColumn>
    </Flex>
  );
};
