import { combine, createEvent, sample } from "effector";
import { interval } from "patronum";
import { createCachedStore } from "state/cache";
import { $hasStore, $store } from "state/store";
import { $wallet } from "state/wallet";
import { upgradeListFn } from "utils/upgradeListFn";
import { readCachedSalesFx, writeCachedSalesFx } from "./cached";
import { fetchStoreSalesFx } from "./fetchStoreSalesFx";
import { loadSaleFx } from "./loadSaleFx";
import { isTimedAuction } from "./utils";

export const startStoreFetch = createEvent();
export const stopStoreFetch = createEvent();

export const { tick } = interval({
  timeout: 15_000,
  start: startStoreFetch,
  stop: stopStoreFetch,
});

const cachedSales = createCachedStore({
  defaultValue: [],
  checkEmpty: (v) => !!v.length,
  fetchFx: fetchStoreSalesFx,
  readCacheFx: readCachedSalesFx,
  writeCacheFx: writeCachedSalesFx,
});

export const { $store: $allSales, $pending: $pendingSales } = cachedSales;

// update all sales on tick
sample({ clock: tick, target: fetchStoreSalesFx });

// after load one sale - add it to list (and cache)
sample({
  clock: loadSaleFx.doneData,
  source: $allSales,
  fn: (sales, sale) => {
    return upgradeListFn(sales, sale);
  },
  target: cachedSales.upgrade,
});

// load sale after change $store
sample({
  clock: $store,
  filter: $hasStore,
  target: cachedSales.init,
});
export const $adminSales = combine($allSales, $wallet, (allSales, wallet) =>
  allSales.filter(
    (sale) =>
      !sale.isSecondary ||
      (!isTimedAuction(sale) &&
        sale.refs.seller === wallet?.publicKey.toString())
  )
);
