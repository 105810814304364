import { Button, ButtonProps } from "@chakra-ui/react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface Props {}

export const BuyOnMEButton: FC<Props & ButtonProps> = ({
  disabled,
  ...props
}) => {
  const navigate = useNavigate();
  const onClick = () => {
    window.open("https://magiceden.io/creators/oddkey", "_blank");
  };
  return (
    <Button variant="solid" w="full" {...props} onClick={onClick}>
      Buy on Magic Eden
    </Button>
  );
};
