import { Button, Flex, FlexProps, ModalHeader } from "@chakra-ui/react";
import { IProgressStep, ProgressSteps } from "components/ProgressSteps";

import { FC } from "react";
import { ModalTemplate } from "components/modals/template";
import { TransactionError } from "../TransactionError";

interface CreateStoreProgressProps extends FlexProps {
  isOpen: boolean;
  steps: IProgressStep[];
  error?: string;
  onClose: () => void;
}

export const CreateStoreProgress: FC<CreateStoreProgressProps> = ({
  isOpen,
  steps,
  error,
  onClose,
  ...props
}) => (
  <ModalTemplate
    isOpen={isOpen}
    onClose={onClose}
    header={<ModalHeader>Creating store</ModalHeader>}
    contentProps={{ borderRadius: "xl", bgColor: "gray.800" }}
    bodyProps={{ p: 6, borderRadius: "xl", bgColor: "gray.700" }}
  >
    {error ? (
      <>
        <TransactionError text={error} />
        <Button
          variant="primary"
          size="lg"
          width="50%"
          alignSelf="center"
          marginBottom="3%"
          onClick={onClose}
        >
          Dismiss
        </Button>
      </>
    ) : (
      <Flex direction="column" {...props}>
        <ProgressSteps steps={steps} />
      </Flex>
    )}
  </ModalTemplate>
);
