import { attach, createEffect, StoreValue } from "effector";
import { loadArtworksByOwner, populateArtwork } from "sdk/loadArtworks";
import { $connection } from "state/connection";
import { $store } from "state/store";
import { getArtworksOwnershipFilterFn } from "./utils";

interface Source {
  connection: StoreValue<typeof $connection>;
  store: StoreValue<typeof $store>;
}

export const loadStoreArtworksFx = attach({
  effect: createEffect(async ({ connection, store }: Source) => {
    if (!store) {
      return []; // TODO: throw error
    }

    const ownedArts = await loadArtworksByOwner({
      connection,
      owner: store.owner,
    });

    const pureArts = ownedArts.filter(getArtworksOwnershipFilterFn(store));
    return Promise.all(pureArts.map((art) => populateArtwork(art)));
  }),
  source: {
    connection: $connection,
    store: $store,
  },
});
