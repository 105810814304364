import {
  createSavePrimaryMetadataCreatorsInstruction,
  Creator,
  findPrimaryMetadataCreatorsAddress,
} from "@metaplex-foundation/mpl-fixed-price-sale";
import { Wallet } from "wallet";
import { PublicKey } from "@solana/web3.js";
import { TransactionsBatch } from "sdk/transactions";

export interface CreatePrimaryMetadataCreatorsProps {
  wallet: Wallet;
  metadata: PublicKey;
  creators: Creator[];
}

export const createPrimaryMetadataCreatorsTransaction = async ({
  wallet,
  metadata,
  creators,
}: CreatePrimaryMetadataCreatorsProps) => {
  const [primaryMetadataCreators, primaryMetadataCreatorsBump] =
    await findPrimaryMetadataCreatorsAddress(metadata);

  const ix = createSavePrimaryMetadataCreatorsInstruction(
    {
      admin: wallet.publicKey,
      metadata,
      primaryMetadataCreators,
    },
    {
      primaryMetadataCreatorsBump,
      creators,
    }
  );

  const createPrimaryMetadataCreatorsTx = new TransactionsBatch({
    instructions: [ix],
  });

  return { createPrimaryMetadataCreatorsTx, primaryMetadataCreators };
};
