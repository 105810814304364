import { attach, restore } from "effector";
import { loadStoreByAddressFx } from "state/store";
import { useStore } from "effector-react";
import { useEffect, useMemo } from "react";
import { useWalletStoreId } from "./useWalletStoreId";

export function useWalletStore() {
  const { loadStoreFx, $store, $pending } = useMemo(() => {
    const loadStoreFx = attach({ effect: loadStoreByAddressFx });
    const $store = restore(loadStoreFx.doneData, null);
    const $pending = loadStoreFx.pending;
    return { loadStoreFx, $store, $pending };
  }, []);

  const [walletStoreId, error] = useWalletStoreId();
  const store = useStore($store);
  const pending = useStore($pending);

  useEffect(() => {
    if (store || pending || !walletStoreId) {
      return;
    }
    loadStoreFx(walletStoreId);
  }, [walletStoreId, error, pending, store, loadStoreFx]);

  return { data: store, fail: !store && !pending };
}
