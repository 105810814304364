import { Avatar, Heading, HStack, StackProps, VStack } from "@chakra-ui/react";
import { CopyButton } from "components/buttons/CopyButton";
import { PersonProps } from "components/Person";
import { getPersonName } from "components/Person/getPersonName";

interface Props extends StackProps {
  user: PersonProps;
  variant?: "sidebar" | "profile-popover";
}

export const UserInfo: React.FC<Props> = ({
  user: { address, avatarUrl, name },
  variant = "sidebar",
  ...props
}) => {
  return (
    <VStack px={6} py={2} spacing={4} {...props}>
      <Avatar size={variant === "sidebar" ? "2xl" : "md"} src={avatarUrl} />
      <HStack>
        <Heading variant={variant === "sidebar" ? "h4" : "h5"}>
          {getPersonName({ name, address })}
        </Heading>
        <CopyButton text={address || ""} variant="ghost" size="sm" />
      </HStack>
    </VStack>
  );
};
