import { Box, Flex, HStack, Image } from "@chakra-ui/react";

import { FC } from "react";
import Img1 from "../images/1.jpg";
import Img2 from "../images/2.jpg";
import Img3 from "../images/3.jpg";

export const Background: FC = () => {
  return (
    <Flex
      position="absolute"
      transform="translateY(-50%)"
      top="50%"
      maxW="100vw"
      overflow="hidden"
      justify="center"
      userSelect="none"
    >
      <HStack
        overflow="hidden"
        w="max-content"
        flexShrink={0}
        spacing={4}
        justify="center"
      >
        <Box width="full">
          <Image
            objectFit="cover"
            src={Img1}
            h="calc(95vh - 100px)"
            w="full"
            borderRadius="12px"
            filter="brightness(30%)"
          />
        </Box>

        <Box width="full">
          <Image
            objectFit="cover"
            src={Img2}
            h="calc(95vh - 100px)"
            w="full"
            borderRadius="12px"
            filter="brightness(30%)"
          />
        </Box>

        <Box width="full">
          <Image
            objectFit="cover"
            src={Img3}
            h="calc(95vh - 100px)"
            w="full"
            borderRadius="12px"
            filter="brightness(30%)"
          />
        </Box>
      </HStack>
    </Flex>
  );
};
