import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { DateDisplay } from "components/DateDisplay";
import { LabelBlock } from "components/TitledBlock";
import dayjs, { Dayjs } from "dayjs";
import { FC, useMemo, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { fontSizes } from "theme/typography";
import { getEndDate } from "./getEndDate";

type Timezone = "UTC" | "PDT";

interface Props {
  durationInSeconds?: number;
  fromDate?: Date | Dayjs | null;
}

export const EstimatedDate: FC<Props> = ({
  durationInSeconds = 0,
  fromDate,
}) => {
  const [timezone, setTimezone] = useState<Timezone>("UTC");

  const date = useMemo(() => fromDate || dayjs(), [fromDate]);

  const endDate = getEndDate(date, durationInSeconds);

  return (
    <LabelBlock title="Estimated end date">
      <Flex justify="space-between">
        <DateDisplay date={endDate} timezone={timezone} />
        <Menu gutter={8} autoSelect={false}>
          <MenuButton
            as={Button}
            variant="tertiary"
            rightIcon={<MdKeyboardArrowDown size={fontSizes["2xl"]} />}
          >
            {timezone}
          </MenuButton>
          <MenuList zIndex="popover">
            <MenuItem onClick={() => setTimezone("UTC")}>UTC</MenuItem>
            <MenuItem onClick={() => setTimezone("PDT")}>PDT</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </LabelBlock>
  );
};
