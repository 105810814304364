import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { VStack } from "@chakra-ui/layout";

import { IInstantSale } from "state/sales";
import { usePaths } from "routes";

import { ArtworkListItem } from "components/Artwork";
import { ArtworksList } from "components/ArtworksList";
import { ArtworkActions } from "components/Artwork/ArtworkListItem/ArtworkActions";
import { InfiniteProgress } from "components/modals/InfiniteProgress";
import { Layout } from "components/Layout";
import { MainSidebarContent } from "components/MainSidebar";
import { PageHead } from "components/PageHead";

import getArtworkActionsProps from "components/Artwork/ArtworkListItem/utils/getArtworkActionsProps";

import { useLocalState } from "./ProfileSales.state";

export const ProfileSales: React.FC = () => {
  const {
    progress,
    sales,
    salesPending,
    storeName,
    tabs,
    user,
    onCloseMarket,
  } = useLocalState();

  const navigate = useNavigate();
  const paths = usePaths();

  const renderListItem = useCallback(
    (item: IInstantSale) => {
      const artworkActionProps = getArtworkActionsProps(item);

      return (
        <ArtworkListItem
          key={item.id}
          artwork={item.artwork}
          onClick={() => navigate(paths.sale({ ":saleId": item.id }))}
          sale={item}
          variant="admin-items"
        >
          <ArtworkActions
            onCloseMarket={onCloseMarket}
            {...artworkActionProps}
          />
        </ArtworkListItem>
      );
    },
    [onCloseMarket, navigate, paths]
  );

  return (
    <Layout sidebarContent={<MainSidebarContent user={user} />}>
      <PageHead
        title="Sales"
        subtitle={`Items you previously purchased from ${
          storeName || ""
        }, and have listed for resale`}
      />

      <VStack spacing={4} p={0} align="stretch">
        <ArtworksList
          items={sales}
          tabs={tabs}
          pending={salesPending}
          noItemsText={{
            title: `You don’t have any items from ${storeName || ""} yet`,
            subtitle: "When you do, you’ll find them here.",
          }}
          defaultView="list"
          renderListItem={renderListItem}
        />
      </VStack>

      <InfiniteProgress
        isOpen={progress.isVisible}
        title={progress.title}
        subtitle={progress.subtitle}
      />
    </Layout>
  );
};
