import { VStack } from "@chakra-ui/react";
import { ArtworkListItem } from "components/Artwork";
import { PageHead } from "components/PageHead";
import { LabelBlock } from "components/TitledBlock";
import { useStore } from "effector-react";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ArtType } from "state/artworks";
import { createSaleFactory } from "../CreateSaleView.state";
import { InstantSaleForm, IFixedPriceForm } from "./InstantSaleForm";
import { SaleType, SaleTypeSelector } from "./SaleTypeSelector";
import { ITimedAuctionForm, FixedPriceSaleForm } from "./FixedPriceSaleForm";
import { IDuration } from "components/TimeDurationInput";
import { LoaderComponent } from "components/modals/InfiniteProgress/LoaderComponent";

export type ISaleDetailsForm = {
  saleType: SaleType;
  pieces: number | string;
  date?: Date | null;
  collectionAddress?: string;
  gatingTime?: IDuration | null;
  expireOnUse?: boolean;
} & (IFixedPriceForm & ITimedAuctionForm);

export const SaleDetailsForm: FC = () => {
  const { $activeArtwork, setIsFormValid, formSubmitFx, formSubmit } =
    createSaleFactory.useModel();

  const artwork = useStore($activeArtwork);

  const shouldHideSaleSelector =
    artwork?.type === ArtType.Print ||
    artwork?.prints?.maxSupply === 0 ||
    artwork?.prints?.maxSupply === 1;

  const methods = useForm<ISaleDetailsForm>({
    mode: "onChange",
    defaultValues: {
      saleType: shouldHideSaleSelector
        ? SaleType.InstantSale
        : SaleType.FixedPrice,
      pieces: 1,
      date: null,
    },
  });

  useEffect(() => {
    if (shouldHideSaleSelector) {
      methods.setValue("saleType", SaleType.InstantSale);
    }
  }, [methods, shouldHideSaleSelector]);

  const saleType = methods.watch("saleType");

  useEffect(() => {
    setIsFormValid(methods.formState.isValid);
  }, [setIsFormValid, methods.formState.isValid]);

  useEffect(() => {
    const handler = methods.handleSubmit(formSubmit);
    formSubmitFx.use(() => handler());
    // return () => {
    //   formSubmitFx.use(() => {});
    // };
  }, [formSubmit, formSubmitFx, methods, methods.handleSubmit]);

  if (!artwork) return <LoaderComponent darkBg />;
  const currentSupply = artwork.prints?.supply || artwork.prints?.edition || 0;
  const maxSupply =
    artwork.prints?.maxSupply && artwork.prints?.maxSupply - currentSupply;

  return (
    <>
      <PageHead title="Configure your sales settings" />
      <VStack spacing={12} align="stretch">
        <LabelBlock title="Item to sell" bright>
          <ArtworkListItem artwork={artwork} variant="select" frozen />
        </LabelBlock>
        <FormProvider {...methods}>
          {!shouldHideSaleSelector && <SaleTypeSelector />}
          {saleType === SaleType.InstantSale && <InstantSaleForm />}
          {saleType === SaleType.FixedPrice && (
            <FixedPriceSaleForm maxSupply={maxSupply} />
          )}
        </FormProvider>
      </VStack>
    </>
  );
};
