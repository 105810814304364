import { attach, createEffect, StoreValue } from "effector";
import { loadSale } from "sdk/loadSale";
import { $connection } from "state/connection";
import { waitForResponse } from "utils/waitForResponse";

import { $wallet } from "../wallet";
import { populateSaleArtwork } from "./utils";

interface Params {
  saleId: string;
}

export const loadSaleFx = attach({
  effect: createEffect(
    async (
      params: Params & {
        connection: StoreValue<typeof $connection>;
        wallet: StoreValue<typeof $wallet>;
      }
    ) => {
      const sale = await waitForResponse(async () => await loadSale(params));
      return populateSaleArtwork(sale);
    }
  ),
  source: {
    connection: $connection,
    wallet: $wallet,
  },
  mapParams(data: Params, source) {
    return { ...data, ...source };
  },
});
