import { Box, BoxProps, HStack, Text } from "@chakra-ui/react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { FC, useCallback, useMemo } from "react";

import { UnixTimestamp } from "state/sales";
import dayjs from "dayjs";
import { splitDuration } from "utils/splitDuration";

export interface TimeEntryProps {
  countdown?: UnixTimestamp | dayjs.Dayjs;
  duration?: number;
  unit?: "year" | "month" | "day" | "hour" | "minute" | "second";
  hideZeroUnits?: boolean;
  noUnitColor?: boolean;
  variant?: "default" | "bold";
  onComplete?: () => void;
}

export const TimeEntry: FC<TimeEntryProps & BoxProps> = ({
  countdown,
  duration = 0,
  unit,
  hideZeroUnits,
  noUnitColor,
  variant = "default",
  onComplete = () => {},
  ...props
}) => {
  const renderTimeUnits = useCallback(
    (countdown?: CountdownRenderProps) => {
      const timeUnits = splitDuration(countdown?.total || duration, unit);

      return timeUnits.map(({ value, unit }) => {
        return hideZeroUnits && value === 0 ? null : (
          <Text
            key={`${value}-${unit}`}
            variant={variant === "bold" ? "body-bold" : "body"}
          >
            {value}

            <Box as="span" color={noUnitColor ? undefined : "whiteAlpha.500"}>
              {unit.charAt(0)}
            </Box>
          </Text>
        );
      });
    },
    [duration, hideZeroUnits, unit, noUnitColor, variant]
  );

  const dateInSeconds = useMemo(
    () =>
      dayjs.isDayjs(countdown)
        ? countdown.toDate()
        : countdown
        ? countdown * 1000
        : undefined,
    [countdown]
  );

  return (
    <HStack {...props}>
      {countdown ? (
        <Countdown
          date={dateInSeconds}
          renderer={renderTimeUnits}
          onComplete={onComplete}
        />
      ) : (
        renderTimeUnits()
      )}
    </HStack>
  );
};
