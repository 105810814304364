import {
  AuctionHouse,
  createWithdrawFromTreasuryInstruction,
} from "@metaplex-foundation/mpl-auction-house/dist/src/generated";
import { Connection, PublicKey } from "@solana/web3.js";

import { loadAccountAndDeserialize } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";

export interface CreateWithdrawFromTreasuryTransactionProps {
  amount?: number;
  auctionHouseId: PublicKey;
  connection: Connection;
}

export const createwWithdrawFromTreasuryTransaction = async ({
  amount = 0,
  auctionHouseId,
  connection,
}: CreateWithdrawFromTreasuryTransactionProps) => {
  const auctionHouse = await loadAccountAndDeserialize(
    connection,
    AuctionHouse,
    auctionHouseId
  );

  const withdrawIx = createWithdrawFromTreasuryInstruction(
    {
      auctionHouse: auctionHouseId,
      auctionHouseTreasury: auctionHouse.auctionHouseTreasury,
      authority: auctionHouse.authority,
      treasuryMint: auctionHouse.treasuryMint,
      treasuryWithdrawalDestination: auctionHouse.treasuryWithdrawalDestination,
    },
    {
      amount,
    }
  );

  const tx = new TransactionsBatch({
    instructions: [withdrawIx],
  });

  return { tx };
};
