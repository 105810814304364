import { attach, createEffect } from "effector";
import { $allSales, ISale, loadSaleFx } from "state/sales";

interface Params {
  saleId: string;
}

interface Sources {
  sales: ISale[];
}

export const getSaleByIdFx = attach({
  effect: createEffect(async ({ saleId, sales }: Params & Sources) => {
    const sale = sales.find((sale) => sale.id === saleId);
    if (!sale) {
      return loadSaleFx({ saleId });
    }
    return sale || null;
  }),
  source: { sales: $allSales },
  mapParams: (params: Params, source) => ({ ...params, ...source }),
});
