import { IconButton, ModalCloseButton, ModalHeader } from "@chakra-ui/react";

import { FC } from "react";
import { MdArrowBack } from "react-icons/md";
import { fontSizes } from "theme/typography";

interface Props {
  title?: string;
  showClose?: boolean;
  onClose?(): void;
  onBack?(): void;
}

export const DialogHeader: FC<Props> = ({
  title,
  onClose,
  showClose = true,
  onBack,
}) => (
  <ModalHeader
    display="flex"
    justifyContent={!onBack ? "flex-start" : "center"}
  >
    {title}
    {showClose && (
      <ModalCloseButton top={title ? "unset" : 3} onClick={onClose} />
    )}
    {onBack && (
      <IconButton
        aria-label="Back"
        onClick={onBack}
        variant="ghost"
        position="absolute"
        left={4}
        size="sm"
      >
        <MdArrowBack size={fontSizes["2xl"]} />
      </IconButton>
    )}
  </ModalHeader>
);
