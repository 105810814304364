import { IArt } from "state/artworks";
import React from "react";
import { Text } from "@chakra-ui/layout";
import { TitledBlock } from "components/TitledBlock";

interface DescriptionProps {
  artwork?: IArt;
}

export const Description: React.FC<DescriptionProps> = ({ artwork }) => {
  if (!artwork?.description) {
    return null;
  }

  return (
    <TitledBlock title="Description">
      <Text variant="body" align="left" overflowWrap="anywhere">
        {artwork.description}
      </Text>
    </TitledBlock>
  );
};
