import { FC, ReactNode } from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { BoxProps } from "@chakra-ui/layout";

interface Props extends BoxProps {
  title?: string;
  subtitle?: string | ReactNode;
}

export const PageHead: FC<Props> = ({ title, subtitle, ...props }) => (
  <Box mt={6} mb={6} {...props}>
    <Heading variant="h3">{title}</Heading>
    {subtitle && (
      <Text color="whiteAlpha.700" variant="body">
        {subtitle}
      </Text>
    )}
  </Box>
);
