import { attach, combine, createEffect } from "effector";
import { $profileArtworks, $storeArtworks, IArt } from "state/artworks";
import { loadArtworkByMintFx } from "state/artworks";

interface Params {
  artworkId: string;
}

interface Sources {
  artworks: IArt[];
}

export const getArtworkByIdFx = attach({
  effect: createEffect(async ({ artworkId, artworks }: Params & Sources) => {
    const artwork = artworks.find((artwork) => artwork.id === artworkId);
    if (!artwork) {
      return loadArtworkByMintFx({ mint: artworkId });
    }
    return artwork || null;
  }),
  source: {
    artworks: combine($storeArtworks, $profileArtworks, (a1, a2) => [
      ...a1,
      ...a2,
    ]),
  },
  mapParams: (params: Params, source) => ({ ...params, ...source }),
});
