import { createBuyInstruction } from "@metaplex-foundation/mpl-fixed-price-sale";
import { PROGRAM_ID } from "@metaplex-foundation/mpl-token-metadata";
import {
  AccountMeta,
  Connection,
  PublicKey,
  SYSVAR_CLOCK_PUBKEY,
} from "@solana/web3.js";
import { findEditionAddress, findMetadataAddress } from "sdk/nft";
import { mintTokenAccount } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";
import { Wallet } from "wallet";

export interface BuyProps {
  connection: Connection;
  wallet: Wallet;
  userTokenAccount: PublicKey;
  resourceMintMetadata: PublicKey;
  resourceMintEditionMarker: PublicKey;
  resourceMintMasterEdition: PublicKey;
  sellingResource: PublicKey;
  tradeHistory: PublicKey;
  tradeHistoryBump: number;
  market: PublicKey;
  marketTreasuryHolder: PublicKey;
  vaultOwner: PublicKey;
  vault: PublicKey;
  vaultOwnerBump: number;
  additionalKeys: AccountMeta[];
}

export const createBuyTransaction = async ({
  connection,
  wallet,
  userTokenAccount,
  resourceMintMetadata,
  resourceMintEditionMarker,
  resourceMintMasterEdition,
  sellingResource,
  tradeHistory,
  tradeHistoryBump,
  market,
  marketTreasuryHolder,
  vault,
  vaultOwner,
  vaultOwnerBump,
  additionalKeys,
}: BuyProps) => {
  const {
    mint,
    tokenAccount,
    tx: mintTx,
  } = await mintTokenAccount({
    payer: wallet.publicKey,
    connection,
  });

  const [newMintMetadata] = await findMetadataAddress(mint.publicKey);
  const [newMintEdition] = await findEditionAddress(mint.publicKey);

  const ix = createBuyInstruction(
    {
      userWallet: wallet.publicKey,
      userTokenAccount,
      editionMarker: resourceMintEditionMarker,
      masterEdition: resourceMintMasterEdition,
      masterEditionMetadata: resourceMintMetadata,
      vault,
      sellingResource,
      owner: vaultOwner,
      market,
      tradeHistory,
      treasuryHolder: marketTreasuryHolder,
      newMint: mint.publicKey,
      newMetadata: newMintMetadata,
      newEdition: newMintEdition,
      newTokenAccount: tokenAccount.publicKey,
      tokenMetadataProgram: PROGRAM_ID,
      clock: SYSVAR_CLOCK_PUBKEY,
      additionalKeys,
    },
    { tradeHistoryBump, vaultOwnerBump }
  );

  const buyTx = new TransactionsBatch({
    instructions: [ix],
    signers: [],
  });

  return {
    mint,
    tokenAccount,
    createBuyTxs: [mintTx, buyTx],
  };
};
