import { Metadata, PROGRAM_ID } from "@metaplex-foundation/mpl-token-metadata";
import { AccountLayout, getAssociatedTokenAddress } from "@solana/spl-token";
import { Connection, PublicKey } from "@solana/web3.js";
import { findEditionAddress } from "sdk/nft";
import {
  ApplyAccountFn,
  ApplyChunkFn,
  getMultipleAccounts,
  loadAccountsByChunks,
  processAccountInfo,
  TokenAccount,
} from "sdk/share";
import { excludesFalsy } from "utils/excludeFalsy";
import { combineArtwork } from "./combineArtwork";
import {
  AnyEdition,
  AnyEditionDeserializer,
  getEditionProps,
  isEdition,
} from "./loadArtworkEdition";

export type IPdaInput = Readonly<
  [PublicKey, { owner?: PublicKey; token?: TokenAccount }]
>;

interface AllData {
  metadata?: Metadata;
  token?: TokenAccount;
  edition?: AnyEdition;
  master?: AnyEdition;
  owner?: PublicKey;
}

export async function loadArtworks(
  connection: Connection,
  PDAsAndTokens: IPdaInput[]
) {
  const dataMap: WeakMap<PublicKey, AllData> = new WeakMap();
  const pdaMap: WeakMap<PublicKey, PublicKey> = new WeakMap();
  const updateDataMap = (refPda: PublicKey, patch: Partial<AllData>) => {
    const pda = pdaMap.get(refPda);
    const data = pda && dataMap.get(pda);
    if (!data) return;
    dataMap.set(pda, { ...data, ...patch });
  };

  const promises: Promise<void>[] = [];

  const PDAs = PDAsAndTokens.map((entry) => {
    const [pda, { owner, token }] = entry;
    dataMap.set(pda, { owner, token });
    return pda;
  });

  const decodeMetadata = processAccountInfo(Metadata, PROGRAM_ID);
  const findEditionPda = async (pda: PublicKey, mint: PublicKey) => {
    const [editionPda] = await findEditionAddress(mint);
    pdaMap.set(editionPda, pda);
    return editionPda;
  };
  const findTokenPda = async (
    pda: PublicKey,
    mint: PublicKey,
    owner: PublicKey
  ) => {
    const tokenPda = await getAssociatedTokenAddress(mint, owner);
    pdaMap.set(tokenPda, pda);
    return tokenPda;
  };

  const editonFn =
    (masters: Promise<PublicKey>[]): ApplyAccountFn<AnyEdition> =>
    (editionPDA, edition) => {
      const pda = pdaMap.get(editionPDA);
      updateDataMap(editionPDA, { edition });
      if (pda && isEdition(edition)) {
        masters.push(findEditionPda(pda, edition.parent));
      }
    };

  const loadChunkEditions = async (editions: Promise<PublicKey>[]) => {
    const masters: Promise<PublicKey>[] = [];

    const editionPDAs = await Promise.all(editions);
    await loadAccountsByChunks(
      editonFn(masters),
      connection,
      AnyEditionDeserializer,
      editionPDAs
    );

    const masterPDAs = await Promise.all(masters);
    await loadAccountsByChunks(
      (editionPDA, master) => updateDataMap(editionPDA, { master }),
      connection,
      AnyEditionDeserializer,
      masterPDAs
    );
  };

  const loadChunkTokens = async (tokens: Promise<PublicKey>[]) => {
    const tokenPDAs = await Promise.all(tokens);

    await loadAccountsByChunks(
      (tokenPDA, token) =>
        updateDataMap(tokenPDA, { token: { ...token, address: tokenPDA } }),
      connection,
      AccountLayout,
      tokenPDAs
    );
  };

  const metadataChunk: ApplyChunkFn = (infos, addresses) => {
    const editions: Promise<PublicKey>[] = [];
    const tokens: Promise<PublicKey>[] = [];

    infos.forEach((info, index) => {
      const account = decodeMetadata(info, addresses[index]);
      if (!account) return;
      const [pda, metadata] = account;
      const data = dataMap.get(pda);
      if (!data?.token && !data?.owner) return;

      dataMap.set(pda, { ...data, metadata });
      if (data.owner) {
        tokens.push(findTokenPda(pda, metadata.mint, data.owner));
      }
      editions.push(findEditionPda(pda, metadata.mint));
    });

    promises.push(loadChunkEditions(editions));
    promises.push(loadChunkTokens(tokens));
  };

  await getMultipleAccounts(connection, PDAs, metadataChunk);
  await Promise.all(promises);

  return PDAs.map((pda) => combineAllTogether(pda, dataMap.get(pda))).filter(
    excludesFalsy
  );
}

function combineAllTogether(pubkey: PublicKey, data?: AllData) {
  if (!data?.metadata || !data.token) return null;

  const { metadata, token, edition, master } = data;
  const editionProps = getEditionProps(edition, master);

  return combineArtwork({
    editionProps,
    metadata,
    pubkey,
    token,
  });
}
