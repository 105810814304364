import { Button, Divider, Stack } from "@chakra-ui/react";
import { DateTimePicker } from "components/Datepicker/DateTimePicker";
import { FileUpload } from "components/FileUpload";
import { FormField } from "components/FormField";
import { ListInput } from "components/ListInput";
import { PageHead } from "components/PageHead";
import { WalletTransaction } from "components/WalletTransaction";
import dayjs from "dayjs";
import { useStore } from "effector-react";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useBalance } from "state/react/useBalance";
import { $user } from "state/wallet";
import { StoreFormProps } from "./types";
import { getFilesCost } from "./utils";
import { FormFieldWithLength } from "components/FormFieldWithLength";
import { ConnectWalletButton } from "components/ConnectWallet";

interface Props {
  onSubmit: SubmitHandler<StoreFormProps>;
  defaultValues?: StoreFormProps;
  title: string;
  actionName?: string;
  actionButtonName: string;
  disabled?: boolean;
}
const BASE_PRICE = 0.00408552 * 2;

export const StoreCreateForm: React.FC<Props> = ({
  onSubmit,
  title,
  defaultValues,
  actionName,
  actionButtonName,
  children,
  disabled,
}) => {
  const user = useStore($user);
  const methods = useForm<StoreFormProps>({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    const subscription = methods.watch(({ coverImg, logoImg }) => {
      getFilesCost(coverImg as File, logoImg as File).then(setArwPrice);
    });
    return () => subscription.unsubscribe();
  }, [methods]);

  useEffect(() => {
    if (defaultValues) {
      for (const [key, value] of Object.entries(defaultValues)) {
        methods.setValue(key as keyof typeof defaultValues, value as string);
      }
    }
  }, [defaultValues, methods]);

  const { balance } = useBalance();
  const [arwPrice, setArwPrice] = useState(0.0);
  const solPrice = BASE_PRICE + arwPrice;

  const notValidBalance = solPrice > (balance?.sol || 0);
  return (
    <>
      <PageHead title={title} subtitle={children} />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack spacing={8} mt={14}>
            <FormField
              id="name"
              title="Store Name"
              options={{ required: true, disabled }}
            />

            <FormField
              id="logoImg"
              title="Store Logo"
              description="PNG or JPEG. Max 100mb."
              options={{ disabled }}
              controlledInputFactory={({ field, fieldState }) => (
                <FileUpload
                  value={field.value ? (field.value as string) : undefined}
                  isInvalid={fieldState.invalid}
                  onFileChange={field.onChange}
                  disabled={disabled}
                  variant="logo-preview"
                />
              )}
            />

            <FormField
              id="coverImg"
              title="Store Cover Image"
              description={`We recommend an image of at least 900x400.\n You can upload a PNG, JPG, or an animated GIF under 10 MB.`}
              controlledInputFactory={({ field, fieldState }) => (
                <FileUpload
                  value={field.value ? (field.value as string) : undefined}
                  isInvalid={fieldState.invalid}
                  onFileChange={field.onChange}
                  disabled={disabled}
                  variant="preview"
                />
              )}
            />

            <FormFieldWithLength
              id="description"
              title="Store Description"
              description="Tell us a little bit about your store"
              options={{ disabled }}
              maxLength={240}
              h={32}
              textarea
            />

            <FormField
              id="link"
              title="External Link"
              description="Include your own webpage URL and we’ll automatically display it on this items page to provide additional details to your customers."
              options={{ disabled }}
              placeholder="http://yourwebpage.io"
            />

            {/* <FormField
              type="number"
              id="sellerFeeBasisPoints"
              title="Instant sale fee basis points"
              options={{ disabled }}
              placeholder="Enter fee"
            /> */}

            <FormField
              id="candyMachines"
              title="Candy Machines List"
              description={`Storefront will handle these list of Candy Machines`}
              placeholder="Cnady Machine address"
              customInputFactory={(register) => (
                <ListInput register={register} button="Add Candy Machine" />
              )}
            />

            <FormField
              id="whitelistedCollections"
              title="Whitelisted Collections"
              description="Storefront will also display item from these collections"
              placeholder="Collection address"
              customInputFactory={(register) => (
                <ListInput register={register} button="Add Collection" />
              )}
            />

            <FormField
              id="salesStartDate"
              title="Sales Start Date"
              controlledInputFactory={({ field }) => (
                <DateTimePicker
                  displayTimeZone
                  date={field.value as Date}
                  minDate={dayjs("05-01-2022", "MM-DD-YYYY").toDate()}
                  onChange={(date) => {
                    field.onChange(date);
                    field.onBlur();
                  }}
                />
              )}
            />

            <Divider />

            <WalletTransaction
              sol={solPrice}
              title={actionName && `COST TO ${actionName}`}
            >
              {user !== null ? (
                <Button
                  isDisabled={
                    disabled || !methods.formState.isValid || notValidBalance
                  }
                  type="submit"
                  size="lg"
                  variant="primary"
                  w="full"
                >
                  {actionButtonName}
                </Button>
              ) : (
                <ConnectWalletButton
                  style={{ minHeight: 48, height: "100%" }}
                />
              )}
            </WalletTransaction>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};
