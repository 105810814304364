import type { IStore } from "./types";

export const store: IStore = {
  coverImg:
    "https://i.picsum.photos/id/1051/1024/448.jpg?hmac=clDwW7TAa5fmO3psaXhNvYg3_UtCb6Q9f7QgP3X1eRk",
  description: "The amazing world of Street Dreams and one more thing",
  link: "http://example.com/test",
  logoImg: "",
  name: "It's my store",
  owner: "Bq1HroN8htqwPiw2P8pWt3sghBUkmPnQgRGxYiN8ffit",
  storeAhId: "82arFqjdmr921nG7NTV42UsBCeZQBnT8t6bkPTxpxesH",
  storeFpsId: "FUGByth3Cju9qm4LBk7pvjTmPbgAf5p8hvTcZ89zdydE",
  storeId: "2j88NvYENNiaGr4hGCiQXkzvqLB3AUKCtxr8ZEJCVZgp",
  whitelistedCollections: [],
  sellerFeeBasisPoints: 0,
};
