import {
  Box,
  Button,
  ButtonProps,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";

export interface NotificationProps {
  actions?: ButtonProps[];
  content?: React.ReactNode;
  title: React.ReactNode;
  rawContent?: React.ReactNode;
  onNotificationClick?: () => void;
}

export const Notification: React.FC<NotificationProps> = ({
  rawContent,
  actions,
  content,
  title,
  onNotificationClick,
}) => {
  const handleClick = (
    action: ButtonProps,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onNotificationClick && onNotificationClick();
    action.onClick && action.onClick(event);
  };

  return (
    <Box bg="whiteAlpha.50" borderRadius={7} p={4}>
      <Heading color="whiteAlpha.700" size="xs">
        {title}
      </Heading>

      {content && (
        <Text as="div" mt={5} onClick={onNotificationClick}>
          {content}
        </Text>
      )}

      {rawContent || null}

      {actions && (
        <Stack mt={6}>
          {actions.map((action, index) => (
            <Button
              variant="notification"
              {...action}
              onClick={(e) => handleClick(action, e)}
              key={index}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};
