import React from "react";
// import { ApproveNotifications } from "./ApproveNotifications";
// import { AhWithdrawFeeNotifications } from "./AhWithdrawFeeNotifications";
// import { UnsettledAuctionNotifications } from "./UnsettledAuctionNotifications";
import "state/notifications/notifications-actions";

export const InitNotifications = React.memo(() => {
  return (
    <>
      {/* <ApproveNotifications /> */}
      {/* <AhWithdrawFeeNotifications /> */}
      {/* <UnsettledAuctionNotifications /> */}
    </>
  );
});
