import React, { useMemo } from "react";

import { FileType, FiletypeAcceptMap } from "components/MediaTypeSelector";

import { FilePreviewAudio } from "./components/Audio";
import { FilePreviewImage } from "./components/Image";
import { FilePreviewVideo } from "./components/Video";
import { FilePreviewVr } from "./components/Vr";

import { FilePreviewProps } from "./Preview.props";
import { Box, Flex } from "@chakra-ui/react";
import { UploadButton } from "components/UploadButton";

export const FilePreview: React.FC<FilePreviewProps> = (props) => {
  const { isLogo } = props;
  const isImageType = props.type === FileType.IMAGE;

  const Component = useMemo(() => {
    switch (props.type) {
      case FileType.AUDIO:
        return <FilePreviewAudio {...props} />;
      case FileType.VIDEO:
        return <FilePreviewVideo {...props} />;
      case FileType.VR:
        return <FilePreviewVr {...props} />;
      case FileType.IMAGE:
      default:
        return <FilePreviewImage {...props} />;
    }
  }, [props]);

  return (
    <Flex
      align={isLogo ? "center" : "start"}
      borderRadius="2xl"
      overflow={!isLogo && isImageType ? "hidden" : "unset"}
      pos="relative"
      flexDirection={isLogo ? "row" : "column"}
    >
      <Box
        borderRadius="xl"
        overflow="hidden"
        width={isLogo ? "unset" : "full"}
      >
        {Component}
      </Box>
      {(!isImageType || isLogo) && (
        <UploadButton
          accept={FiletypeAcceptMap[props.type]}
          onUpload={props.onFileUpload}
          size="lg"
          m={isLogo ? "0 1.5rem 0" : "1.5rem 0 0"}
          variant="tertiary"
        >
          Replace
        </UploadButton>
      )}
    </Flex>
  );
};
