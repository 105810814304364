import { createEffect } from "effector";
import { getSaleByIdFx } from "./getSaleByIdFx";
import { getArtworkByIdFx } from "./getArtworkByIdFx";

interface Params {
  saleId?: string;
  artworkId?: string;
}

export const getSaleOrArtworkByIdFx = createEffect(
  ({ saleId, artworkId }: Params) => {
    if (saleId) return getSaleByIdFx({ saleId });
    if (artworkId) return getArtworkByIdFx({ artworkId });
    return null;
  }
);
