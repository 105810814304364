import {
  attach,
  createEvent,
  createStore,
  forward,
  StoreValue,
} from "effector";
import { loadArtworksByOwner, populateArtwork } from "sdk/loadArtworks";
import { IArt } from "state/artworks/types";
import { $connection } from "state/connection";
import { $wallet } from "state/wallet";
import { $store } from "../store";
import { getArtworksOwnershipFilterFn } from "./utils";

export const $profileArtworks = createStore<IArt[]>([]);
export const pushToProfileArtworks = createEvent<IArt>();

$profileArtworks.on(pushToProfileArtworks, (profileArtworks, artwork) => [
  ...profileArtworks,
  artwork,
]);

export const fetchProfileArtworksFx = attach({
  effect: async ({
    connection,
    wallet,
    store,
  }: {
    connection: StoreValue<typeof $connection>;
    wallet: StoreValue<typeof $wallet>;
    store: StoreValue<typeof $store>;
  }) => {
    if (!wallet) {
      throw new Error("wallet isn't initialize");
    }

    if (!store) return [];

    const ownedArts = await loadArtworksByOwner({
      connection,
      owner: wallet.publicKey,
    });

    const pureArts = ownedArts.filter(getArtworksOwnershipFilterFn(store));

    return Promise.all(pureArts.map((art) => populateArtwork(art)));
  },
  source: {
    connection: $connection,
    wallet: $wallet,
    store: $store,
  },
});
forward({ from: fetchProfileArtworksFx.doneData, to: $profileArtworks });

export const $pendingProfileArtworks = fetchProfileArtworksFx.pending;
