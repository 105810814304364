import React from "react";

import { FilePreviewProps } from "../Preview.props";

export const FilePreviewVideo: React.FC<FilePreviewProps> = ({ url }) => (
  <video
    autoPlay
    muted
    controls
    src={url}
    style={{ objectFit: "cover" }}
    width="100%"
  />
);
