import { useStore, useStoreMap } from "effector-react";

import {
  $pendingProfileArtworks,
  $profileArtworks,
  IArt,
  ArtType,
} from "state/artworks";
import { $user } from "state/wallet";
import { $storeName, $store } from "state/store";
import { TabsConfig } from "hooks/useTabs";
import { useMemo } from "react";

export const useLocalState = () => {
  const pendingArtworks = useStore($pendingProfileArtworks);

  const storeName = useStore($storeName);
  const storeId = useStoreMap($store, (store) => store?.storeId);

  const tabs: TabsConfig<IArt> = useMemo(
    () => [
      { id: "all", title: "All" },
      {
        id: "masters",
        accessor: ({ type }) => type === ArtType.Master,
        title: "Masters",
      },
      {
        id: "editions",
        accessor: ({ type }) => type === ArtType.Print,
        title: "Editions",
      },
    ],
    []
  );

  const user = useStore($user);

  return {
    artworks: $profileArtworks,
    pendingArtworks,
    storeId,
    storeName,
    tabs,
    user,
  };
};
