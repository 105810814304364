import { Box } from "@chakra-ui/react";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { FC } from "react";

interface Props {
  coverImg?: string;
}

export const BlurredBackgroundImage: FC<Props> = ({ coverImg }) => {
  const { mdUp } = useCustomBreakpoints();

  return (
    <Box
      zIndex={-1}
      position="absolute"
      top={mdUp ? 0 : "96px"}
      left={0}
      right={0}
      height={mdUp ? "704px" : "600px"}
      bgImage={
        coverImg
          ? `linear-gradient(to bottom, rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, 0.2) 66%, rgba(0, 0, 0, 1) 100%), url("${coverImg}")`
          : `linear-gradient(180deg, rgba(230, 92, 55, 0.3) 0%, rgba(230, 92, 55, 0) 100%);`
      }
      bgPos="top"
      bgRepeat="no-repeat"
      bgSize="cover"
      bgAttachment="scroll"
      filter={`blur(${mdUp ? 100 : 30}px) ${mdUp ? "" : "brightness(0.35)"}`}
      willChange="transform"
      style={{ transform: "translateZ(0)" }}
    />
  );
};
