import { StoreValue, attach, createEffect } from "effector";

import { $walletAddress } from "state/wallet";
import { EUploadProgress } from "sdk/nft/uploadJson2Arweave";
import { IStoreConfig } from "./types";
import { StoreFormProps } from "components/forms/StoreCreateForm";
import { initStoreFx } from "./init";
import dayjs from "dayjs";

export function convertStoreFormPropsToIStoreConfig(
  data: StoreFormProps,
  walletAddress: string
) {
  const json: IStoreConfig = {
    name: data.name,
    description: data.description || "",
    link: data.link || "",
    logoImg:
      data.logoImg instanceof File ? data.logoImg.name : data.logoImg || "",
    coverImg:
      data.coverImg instanceof File ? data.coverImg.name : data.coverImg || "",
    owner: walletAddress,
    storeFpsId: data.storeFpsId || "",
    storeAhId: data.storeAhId || "",
    sellerFeeBasisPoints: data.sellerFeeBasisPoints || 0,
    whitelistedCollections: data.whitelistedCollections || [],
    candyMachines: data.candyMachines,
    salesStartDate: data.salesStartDate
      ? dayjs(data.salesStartDate).unix()
      : undefined,
  };
  const files = [data.logoImg, data.coverImg].filter(
    (f) => f instanceof File
  ) as File[];

  return { json, files };
}

export interface ISubmitProps {
  updateProgress?: (status: EUploadProgress | null) => void;
  data: StoreFormProps;
}

export const submitStoreFx = attach({
  effect: createEffect(
    async ({
      data,
      updateProgress,
      walletAddress,
    }: ISubmitProps & {
      walletAddress: StoreValue<typeof $walletAddress>;
    }) => {
      if (!walletAddress) {
        throw new Error(`Wallet wasn't connected`);
      }

      updateProgress?.(EUploadProgress.preparing_assets);

      const { json, files } = convertStoreFormPropsToIStoreConfig(
        { ...data },
        walletAddress
      );

      const result = await initStoreFx({
        json,
        files,
        updateProgress,
        sellerFeeBasisPoints: data.sellerFeeBasisPoints ?? 0,
      });

      return result;
    }
  ),
  source: {
    walletAddress: $walletAddress,
  },
  mapParams({ data, updateProgress }: ISubmitProps, { walletAddress }) {
    return { data, updateProgress, walletAddress };
  },
});
