import { FC } from "react";
import { InfiniteProgress } from "components/modals/InfiniteProgress";
import { StoreCreateForm } from "components/forms/StoreCreateForm";
import { useLocalState } from "./AdminStoreSetting.state";

export const AdminStoreSetting: FC = () => {
  const { form, submitForm, progressMeta } = useLocalState();

  return (
    <StoreCreateForm
      onSubmit={submitForm}
      title="My Storefront"
      actionName="UPDATE"
      actionButtonName="Update Store"
      defaultValues={form ?? undefined}
    >
      <InfiniteProgress
        isOpen={progressMeta.isOpen}
        title={progressMeta.title}
        subtitle={progressMeta.subtitle}
      />
    </StoreCreateForm>
  );
};
