import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  RequiredIndicator,
} from "@chakra-ui/react";
import {
  Controller,
  ControllerProps,
  FieldValues,
  useFormContext,
  UseFormRegister,
} from "react-hook-form";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { PeriodType } from "components/TimeDurationInput";

export type IFormFieldRegister = ReturnType<UseFormRegister<FieldValues>> & {
  placeholder: string;
};

export interface IFormFieldProps {
  id: string;
  variant?: "row" | "column";
  title: string;
  placeholder?: string;
  description?: string | string[] | JSX.Element;
  options?: RegisterOptions;
  type?: string;
  isError?: boolean;
  min?: number;
  max?: number;
  defaultUnit?: PeriodType;
  defaultValue?: string | number;
  customInputFactory?: (register: IFormFieldRegister) => React.ReactChild;
  controlledInputFactory?: ControllerProps["render"];
}

export const FormField: React.FC<IFormFieldProps> = ({
  id,
  variant = "column",
  type = "text",
  defaultValue = "",
  title,
  placeholder = title,
  description,
  options: propOptions,
  customInputFactory,
  controlledInputFactory,
  isError,
  ...props
}) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const isInvalid = !!errors[id] || isError;

  const defaultOptions: RegisterOptions = {
    setValueAs:
      type === "number"
        ? (value: string) => {
            const parsedNumber = parseFloat(value);
            return isNaN(parsedNumber) ? undefined : parsedNumber;
          }
        : undefined,
  };
  const options = { ...defaultOptions, ...propOptions };

  const inputRegister = {
    ...register(id, options),
    placeholder,
    type,
    defaultValue,
    isInvalid,
  };

  const descriptionString = Array.isArray(description)
    ? description.join("\n")
    : description;

  return (
    <FormControl
      id={id}
      isRequired={!!options?.required}
      isInvalid={isInvalid}
      pos="relative"
    >
      <Flex flexDir={variant}>
        <Box flexGrow={1}>
          <FormLabel
            requiredIndicator={
              <RequiredIndicator color="whiteAlpha.500">
                (required)
              </RequiredIndicator>
            }
          >
            {title}
          </FormLabel>

          {descriptionString && descriptionString !== "" && (
            <FormHelperText>{descriptionString}</FormHelperText>
          )}
        </Box>
        {customInputFactory ? (
          customInputFactory(inputRegister)
        ) : controlledInputFactory ? (
          <Controller
            control={control}
            name={id}
            defaultValue={defaultValue}
            rules={options}
            render={controlledInputFactory}
          />
        ) : (
          <Input {...inputRegister} {...props} />
        )}
      </Flex>
      <FormErrorMessage pos="absolute" top="100%" left={0} mx={0}>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
        {errors[id]?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
