import { createEvent, restore, sample } from "effector";
import { useStore } from "effector-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePaths } from "routes";
import { getSaleOrArtworkByIdFx } from "state/item";
import { $allSales, fetchStoreSalesFx, isSale } from "state/sales";
import { closeMarketAndWithdrawFx } from "../AdminItems/effects/closeMarketAndWithdrawFx";

interface ILocalStateProps {
  saleId?: string;
  artworkId?: string;
}

interface Progress {
  visible: boolean;
  title?: string;
  subtitle?: string;
  error?: string;
}

export function useLocalState({ saleId, artworkId }: ILocalStateProps) {
  const { $item, $pending, loadItem } = useMemo(() => {
    const loadItem = createEvent<ILocalStateProps>();
    const $usedIds = restore(loadItem, {});
    const $item = restore(getSaleOrArtworkByIdFx.doneData, null);
    sample({
      clock: $allSales,
      source: { sale: $item, ids: $usedIds },
      filter: ({ ids }) => !!ids.saleId,
      fn: ({ sale, ids }, sales) => {
        const item = sales.find(({ id }) => id === ids.saleId);
        return item || sale || null;
      },
      target: $item,
    });
    const $pending = getSaleOrArtworkByIdFx.pending;
    sample({
      clock: loadItem,
      target: getSaleOrArtworkByIdFx,
    });
    return { $item, $pending, loadItem };
  }, []);

  useEffect(() => {
    loadItem({ saleId, artworkId });
  }, [saleId, artworkId, loadItem]);

  const item = useStore($item);
  const pending = useStore($pending);

  const [progress, setProgress] = useState<Progress>({ visible: false });
  const navigate = useNavigate();
  const paths = usePaths();

  const handleError = (error: unknown) => {
    const message =
      (error instanceof Error && error.message) || "Unknown error occurred.";
    setProgress({ visible: false, error: message });
  };

  const handleEndSale = useCallback(
    async (market?: string, metadata?: string) => {
      try {
        setProgress({
          visible: true,
          title: "Withdraw is in progress",
          subtitle:
            "After you approve the transaction with your wallet, SOL will be transferred to wallets and NFT will be added to your wallet.",
        });
        market &&
          metadata &&
          (await closeMarketAndWithdrawFx({ market, metadata }));
        setProgress({
          visible: false,
        });
        if (item && isSale(item) && item.artwork.id) {
          navigate(
            paths.artwork({
              ":artworkId": item.artwork.id,
            })
          );
        }
      } catch (e) {
        handleError(e);
      } finally {
        await fetchStoreSalesFx();
      }
    },
    [navigate, paths, item]
  );

  const resetProgress = () => setProgress({ visible: false });

  return {
    item,
    pending,
    handleEndSale,
    progress,
    resetProgress,
  };
}
