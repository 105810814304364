import { StackProps, Text, VStack } from "@chakra-ui/react";

import { IconType } from "react-icons";
import { fontSizes } from "theme/typography";
import { colors } from "theme/colors";

interface Props<T> extends Omit<StackProps, "onSelect" | "id"> {
  id: T;
  Icon: IconType;
  title?: string;
  selected?: boolean;
  onSelect?: (id: T) => void;
  disabled?: boolean;
}

export const ToggleButton = <T,>({
  id,
  Icon,
  title,
  selected,
  onSelect,
  children,
  disabled = false,
  ...props
}: Props<T>) => {
  const handleClick = () => {
    onSelect && onSelect(id);
  };

  return (
    <VStack
      pointerEvents={disabled ? "none" : undefined}
      onClick={handleClick}
      h="133px"
      minW="133px"
      borderRadius="12px"
      border="1px solid"
      borderColor={
        selected ? "white" : disabled ? "whiteAlpha.100" : "whiteAlpha.500"
      }
      bgColor={selected ? "whiteAlpha.100" : undefined}
      direction="column"
      align="center"
      justify="center"
      cursor={disabled ? "default" : "pointer"}
      {...props}
    >
      <Icon
        fill={disabled ? colors.whiteAlpha[500] : "white"}
        stroke={disabled ? colors.whiteAlpha[500] : "white"}
        size={fontSizes["2xl"]}
      />
      <Text color={disabled ? "whiteAlpha.500" : "whiteAlpha.700"}>
        {title || children}
      </Text>
    </VStack>
  );
};
