import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { BottomPanel, BottomPanelProps } from "./BottomPanel";

export const BottomNav: FC<BottomPanelProps> = ({ ...props }) => {
  return (
    <Box w="full">
      <Box h="80px" />
      <BottomPanel {...props} />
    </Box>
  );
};
