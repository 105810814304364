import { Button, ButtonProps } from "@chakra-ui/react";
import { FC } from "react";
import { ISale } from "state/sales";
import { useBalance } from "state/react/useBalance";
import { useWallet } from "@solana/wallet-adapter-react";
import allowlist from "views/ItemView/json/allowlist";

interface Props {
  sale: ISale;
  handler?: (group: string) => void;
  group: string;
  isVerified?: boolean;
  isStarted?: boolean;
  loadingVerification?: boolean;
}

export const VerifyButton: FC<Props & ButtonProps> = ({
  sale,
  handler,
  disabled,
  group,
  loadingVerification,
  isVerified,
  isStarted,
  ...props
}) => {
  const { publicKey } = useWallet();
  const { balance } = useBalance();
  const solPrice = 1.02;
  const hasEnoughMoney = Boolean(
    balance && solPrice && balance?.sol >= solPrice
  );
  const onAllowlist = allowlist.includes(publicKey?.toBase58() || "");
  const forbidden = !hasEnoughMoney || !isStarted || !onAllowlist;

  return (
    <Button
      variant="primary"
      w="full"
      isLoading={loadingVerification}
      disabled={forbidden}
      {...props}
      onClick={() => handler(group)}
    >
      {!onAllowlist
        ? "Not on Allowlist"
        : !hasEnoughMoney
        ? "You do not have enough SOL"
        : "Buy NFT"}
    </Button>
  );
};
