import { useStore } from "effector-react";
import { BOUND_STORE_ID } from "global-const/env";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { $storeId } from "state/store";
import { ROUTES } from "./routes";
import { toBuildFn } from "./utils";

export const usePaths = () => {
  const { storeId } = useParams();
  const stateStoreId = useStore($storeId);

  return useMemo(
    () =>
      Object.keys(ROUTES).reduce((acc, key) => {
        const route = ROUTES[key as keyof typeof ROUTES];
        const getPath = toBuildFn(route, {
          ":storeId": storeId || BOUND_STORE_ID || stateStoreId,
        });
        return { ...acc, [key]: getPath };
      }, {} as Record<keyof typeof ROUTES, ReturnType<typeof toBuildFn>>),
    [stateStoreId, storeId]
  );
};
