import { attach, createEffect } from "effector";
import { $allSales, isFixedPrice } from "state/sales";
import { withdraw } from "sdk/fps/withdraw";
import { PublicKey } from "@solana/web3.js";
import { $connection } from "state/connection";
import { $wallet } from "state/wallet";
import { $store } from "state/store";

import { StoreValue } from "effector";

export interface ISource {
  connection: StoreValue<typeof $connection>;
  wallet: StoreValue<typeof $wallet>;
  markets: StoreValue<typeof $allSales>;
  store: StoreValue<typeof $store>;
}

export interface IParams {
  metadata: string;
  market: string;
}

export const closeMarketAndWithdrawFx = attach({
  effect: createEffect(
    async ({
      metadata,
      market,
      markets,
      store,
      wallet,
      connection,
    }: ISource & IParams) => {
      if (!wallet || !store?.storeId) {
        return;
      }

      const sale = markets.find(({ id }) => id === market);
      if (!sale || !isFixedPrice(sale)) return;

      return await withdraw({
        connection,
        wallet,
        store: new PublicKey(store.storeFpsId),
        sale,
        market: new PublicKey(market),
        metadata: new PublicKey(metadata),
      });
    }
  ),
  source: {
    connection: $connection,
    wallet: $wallet,
    markets: $allSales,
    store: $store,
  },
  mapParams: ({ metadata, market }: IParams, sources) => ({
    ...sources,
    metadata,
    market,
  }),
});
