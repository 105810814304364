import { ISale, isCandyMachine, isInstantSale } from "state/sales";
import { useCallback, useState } from "react";

import { endSaleFx } from "state/sales/AH/endSaleFx";
import { closeMarketAndWithdrawFx } from "views/AdminItems/effects/closeMarketAndWithdrawFx";

export const useLocalState = (sale?: ISale | null) => {
  const [status, setStatus] = useState<"error" | "pending" | "done" | null>(
    null
  );

  const handleEndSale = useCallback(async () => {
    if (!sale || isCandyMachine(sale)) {
      return;
    }
    setStatus("pending");

    try {
      isInstantSale(sale)
        ? await endSaleFx({ sale })
        : await closeMarketAndWithdrawFx({
            market: sale.id,
            metadata: sale.artwork.accountAddress,
          });
      setStatus("done");
    } catch {
      setStatus("error");
    }
  }, [sale]);

  return { status, handleEndSale };
};
