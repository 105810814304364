import { Box, Button, Flex, Spacer, Tooltip } from "@chakra-ui/react";
import { LayoutContentVariant, layoutVariantToWidth } from "components/Layout";
import { useLayoutContentPadding } from "components/Layout/useLayoutContentPadding";
import { ConditionalWrapper } from "components/utility/ConditionalWrapper";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { FC } from "react";
import { smartButtonProps } from "utils/smartButtonProps";

export interface BottomPanelProps {
  variant?: LayoutContentVariant;
  backDisabled?: boolean;
  forwardDisabled?: boolean;
  forwardDisabledTooltip?: string;
  onBack?: () => void | string;
  onForward?: () => void | string;
}

export const BottomPanel: FC<BottomPanelProps> = ({
  variant = "narrow",
  backDisabled,
  forwardDisabled,
  forwardDisabledTooltip,
  onBack,
  onForward,
}) => {
  const { mdUp } = useCustomBreakpoints();
  const maxWidth = layoutVariantToWidth[variant];

  const { px } = useLayoutContentPadding({});

  return (
    <Box
      w="full"
      backgroundColor="gray.900"
      borderTop="1px solid"
      borderTopColor="whiteAlpha.100"
      bottom={0}
      right={0}
      left="auto"
      position="fixed"
      zIndex="sticky"
    >
      <Flex maxW={mdUp ? maxWidth : "full"} mx="auto" py={4} px={px}>
        {onBack && (
          <Button
            variant="tertiary"
            disabled={backDisabled}
            {...smartButtonProps(onBack)}
          >
            Back
          </Button>
        )}
        <Spacer />
        {onForward && (
          <ConditionalWrapper
            wrap={forwardDisabled && !!forwardDisabledTooltip}
            wrapper={(wrapperChild) => (
              <Tooltip
                label={forwardDisabledTooltip}
                aria-label={forwardDisabledTooltip}
                shouldWrapChildren
                placement="top"
              >
                {wrapperChild}
              </Tooltip>
            )}
          >
            <Button
              variant="primary"
              disabled={forwardDisabled}
              {...smartButtonProps(onForward)}
            >
              Continue
            </Button>
          </ConditionalWrapper>
        )}
      </Flex>
    </Box>
  );
};
