import { combine, forward } from "effector";
import { IArt } from "state/artworks/types";
import { createCachedStore } from "state/cache";
import { $connection } from "state/connection";
import { $store } from "state/store";
import { readCachedSalesFx, writeCachedSalesFx } from "./cached";
import { loadStoreArtworksFx } from "./loadStoreArtworksFx";

const cachedArtworks = createCachedStore<IArt[]>({
  defaultValue: [],
  checkEmpty: (v) => !!v.length,
  readCacheFx: readCachedSalesFx,
  writeCacheFx: writeCachedSalesFx,
  fetchFx: loadStoreArtworksFx,
});

export const $storeArtworks = cachedArtworks.$store;
export const $pendingArtworks = cachedArtworks.$pending;

// TODO: check when it needed and drop it
forward({
  from: combine($connection, $store, (connection, store) => ({
    connection,
    store,
  })),
  to: loadStoreArtworksFx,
});
