import { $connection, $network } from "../connection";
import { ENftProgress, mintArweaveNFT } from "sdk/nft/mintArveaweNFT";
import { StoreValue, attach, createEffect } from "effector";

import { $wallet } from "../wallet";
import { AddressRow } from "components/forms/NftCreate";
import { MetadataJson } from "sdk/nft";

export interface IMintArweaveParams {
  files: File[];
  metadata: MetadataJson;
  maxSupply: number;
  updateProgress?: (status: ENftProgress | null) => void;
  splits: AddressRow[];
}

export interface IMintArweaveSource {
  connection: StoreValue<typeof $connection>;
  wallet: StoreValue<typeof $wallet>;
  network: StoreValue<typeof $network>;
}

export type IMintArweaveParamsWithSource = IMintArweaveParams &
  IMintArweaveSource;

export const mintArweaveFx = attach({
  effect: createEffect(
    ({
      files,
      metadata,
      maxSupply,
      updateProgress,
      connection,
      network,
      wallet,
      splits,
    }: IMintArweaveParamsWithSource) => {
      if (!wallet) {
        throw new Error("Wallet not connected");
      }

      return mintArweaveNFT({
        connection,
        wallet,
        files,
        metadata,
        maxSupply,
        network,
        progressChange: updateProgress,
        splits,
      });
    }
  ),
  source: {
    connection: $connection,
    network: $network,
    wallet: $wallet,
  },
  mapParams: (params: IMintArweaveParams, source: IMintArweaveSource) => {
    return { ...source, ...params };
  },
});
