import {
  $allSales,
  $pendingSales,
  ISale,
  fetchStoreSalesFx,
  isEndedSale,
} from "state/sales";
import { $isStoreAdmin, $store } from "state/store";
import { useEffect, useMemo } from "react";

import { $user } from "state/wallet";
import { TabsConfig } from "hooks/useTabs";
import { useStore } from "effector-react";
export type ViewMode = "list" | "grid";

const $sales = $allSales.map((sales) =>
  sales.filter((sale) => !sale.isSecondary)
);

export function useLocalState() {
  useEffect(() => {
    fetchStoreSalesFx();
  }, []);

  const pendingSales = useStore($pendingSales);
  const form = useStore($store);
  const storeId = form?.storeId;
  const isSeller = useStore($isStoreAdmin);

  const user = useStore($user);

  const tabs: TabsConfig<ISale> = useMemo(
    () => [
      {
        id: "active",
        accessor: (item) => !isEndedSale(item),
        title: "Live Sales",
      },
      // {
      //   id: "secondary",
      //   accessor: ({ isSecondary, ...item }) =>
      //     !isEndedSale(item) && !!isSecondary,
      //   title: "Resale",
      // },
      // { id: "all", title: "All Sales" },
      // { id: "participated", title: "Participated" },
      {
        id: "ended",
        accessor: (item) => isEndedSale(item),
        title: "Ended",
      },
    ],
    []
  );

  return {
    form,
    isSeller,
    pendingSales,
    sales: $sales,
    storeId,
    tabs,
    user,
  };
}
