import { Button, Divider, Stack, VStack } from "@chakra-ui/react";

import { FC } from "react";
import { ISale } from "state/sales";
import { PurchaseConfirmHead } from "./PurchaseConfirmHead";
import { SolUsdDisplay } from "components/SolUsdDisplay";
import { TitledBlock } from "components/TitledBlock";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { useSalePrice } from "hooks/useSalePrice";

interface Props {
  sale?: ISale;
  pending?: boolean;
  primaryActionText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const PurchaseConfirm: FC<Props> = ({
  sale,
  pending,
  primaryActionText,
  onConfirm,
  onCancel,
}) => {
  const { mdUp } = useCustomBreakpoints();
  const price = useSalePrice(sale);

  if (!sale) {
    return null;
  }

  const {
    image,
    title,
    creators: [artist],
  } = sale.artwork;

  return (
    <VStack
      bgColor="whiteAlpha.50"
      borderRadius="lg"
      borderColor="white"
      alignItems="stretch"
      p={6}
      spacing={6}
    >
      <PurchaseConfirmHead
        image={image}
        title={title}
        address={artist.address}
      />
      <Divider />
      <Stack
        direction={mdUp ? "row" : "column-reverse"}
        justify="flex-end"
        alignItems="stretch"
        spacing={3}
      >
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="primary" isLoading={pending}>
          {primaryActionText || "Confirm"}
        </Button>
      </Stack>
    </VStack>
  );
};
