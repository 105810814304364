import { useState } from "react";
import { useStore } from "effector-react";

import { ISale, isCandyMachine, purchaseCmFx } from "state/sales";
import { useGating } from "hooks/useGating";

import { IErrorNft } from "components/modals/PurchaseNft/ErrorNft";
import { pushToProfileArtworks, IArt } from "state/artworks";
import { loadArtworkByMint } from "sdk/loadArtworks";
import { $connection } from "state/connection";
import { waitForResponse } from "utils/waitForResponse";
import { ETransactionProgress } from "sdk/share";
import { useLocalProgress } from "hooks/useLocalProgress";
import { createEffect, createEvent, forward, sample } from "effector";
import useLocalStorage from "use-local-storage";

export interface StateProps {
  sale: ISale;
  onPurchaseSuccess: (mint?: IArt | null) => void;
  onSubmit?: (price: number, endDate: Date) => void;
  group?: string;
}
type State = "confirm" | "queue" | "success" | "sold-out" | "error";

export function getContent(state: ETransactionProgress | null) {
  switch (state) {
    case ETransactionProgress.creating:
      return {
        title: "Preparing data",
        subtitle: "",
      };
    case ETransactionProgress.sending_to_solana:
      return {
        title: "Sending Transaction to Solana",
        subtitle: "This will take a few seconds",
      };
    case ETransactionProgress.waiting_for_confirmation:
      return {
        title: "Waiting for confirmation",
        subtitle: "",
      };
    case ETransactionProgress.loading_account:
      return {
        title: "Item is being loaded",
        subtitle: "This will take a few seconds",
      };

    default:
      return {
        title: "",
        subtitle: "",
      };
  }
}

export const useLocalState = ({
  sale,
  onPurchaseSuccess,
  group,
}: StateProps) => {
  const connection = useStore($connection);
  const [state, setState] = useState<State>("confirm");
  const [error, setError] = useState<IErrorNft>({});
  const [mintedWL, setMintedWL] = useLocalStorage("mintedWL", false);

  const { $progress, steps } = useLocalProgress({
    getContent,
    stepsEnum: ETransactionProgress,
  });

  const resetState = () => {
    setState("confirm");
    setError({});
  };

  const handleError = (title: string, subtitle: string) => {
    setError({
      title: title,
      subtitle: subtitle,
    });
  };

  const purchaseFx = createEffect(async () => {
    setState("queue");
    $progress.set(ETransactionProgress.creating);

    let response;
    if (isCandyMachine(sale)) {
      if (group === "OG") {
        response = await purchaseCmFx({ sale, group });
        setMintedWL(true);
      } else if (group === "Public") {
        response = await purchaseCmFx({ sale, group });
      } else {
        setState("error");
        handleError("An error has occurred", "Try minting again");
        return;
      }
    } else {
      setState("error");
      handleError("Something went wrong", "not implemented");
      return;
    }
    $progress.set(ETransactionProgress.sending_to_solana);

    return response.mint;
  });
  sample({
    clock: purchaseFx.finally,
    fn: () => ETransactionProgress.loading_account,
    target: $progress.set,
  });

  const proceedPurchase = createEvent();
  forward({ from: proceedPurchase, to: purchaseFx });

  const handleErrorFx = createEffect((error: Error) => {
    setState("error");
    handleError(
      "Something went wrong",
      error instanceof Error
        ? error.message
        : "Some error occurs. Check your balance and try again."
    );
  });
  sample({
    clock: purchaseFx.failData,
    target: handleErrorFx,
  });

  const loadArtFx = createEffect(async (mint?: string) => {
    if (!mint) return;

    const art: IArt | null = await waitForResponse(
      async () => await loadArtworkByMint({ connection, mint })
    );

    if (!art) return onPurchaseSuccess();

    pushToProfileArtworks(art);
    onPurchaseSuccess(art);
  });
  sample({
    clock: purchaseFx.doneData,
    target: loadArtFx,
  });

  return {
    error,
    state,
    proceedPurchase,
    resetState,
    steps,
  };
};
