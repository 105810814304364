import { useEffect, useRef } from "react";

const isWindow = (element: Element | Window): element is Window => {
  return "window" in globalThis && element === window;
};

interface GetTriggerOptions {
  target?: Element | Window | null;
  threshold: number;
}

const getTrigger = (options: GetTriggerOptions) => {
  const { target, threshold } = options;

  let current = 0;

  if (target) {
    current = isWindow(target) ? target.pageYOffset : target.scrollTop;
  }

  return current > threshold;
};

const defaultTarget = typeof window !== "undefined" ? window : null;

interface UseScrollTriggerOptions {
  threshold?: number;
  onTrigger?: (value: boolean) => void;
}

export const useScrollTrigger = (
  target: Window | Element | null = defaultTarget,
  options: UseScrollTriggerOptions = {}
) => {
  const { threshold = 24, onTrigger = () => {} } = options;
  const previousValueRef = useRef(!getTrigger({ target, threshold }));

  useEffect(() => {
    const handleScroll = () => {
      const currentTriggerValue = getTrigger({ target, threshold });

      if (currentTriggerValue !== previousValueRef.current) {
        onTrigger(currentTriggerValue);
      }

      previousValueRef.current = currentTriggerValue;
    };

    handleScroll(); // Re-evaluate trigger when dependencies change

    target?.addEventListener("scroll", handleScroll);
    return () => {
      target?.removeEventListener("scroll", handleScroll);
    };
  }, [target, onTrigger, threshold]);
};
