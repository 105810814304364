import { Stack } from "@chakra-ui/layout";
import { ArtMedia } from "components/ArtMedia";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { isSale } from "state/sales";
import {
  DescriptionColumn,
  IDescriptionColumnProps,
} from "./DescriptionColumn";

export const ItemViewContent: React.FC<IDescriptionColumnProps> = ({
  item,
  isVerified,
  loadingVerification,
  ...handlers
}) => {
  const { mdUp, lgUp, xxlUp } = useCustomBreakpoints();
  const getImageWidth = xxlUp ? "50%" : mdUp ? "60%" : "100%";

  const artwork = isSale(item) ? item.artwork : item;

  return (
    <Stack
      direction={mdUp ? "row" : "column"}
      spacing={lgUp ? 20 : 10}
      width="100%"
    >
      <Stack
        width={getImageWidth}
        align={mdUp ? "unset" : "center"}
        flexGrow={mdUp ? "unset" : 1}
      >
        <ArtMedia
          maxW="100vw"
          w={mdUp ? "unset" : "4xl"}
          position={"sticky"}
          top={"112px"}
          artwork={artwork}
        />
      </Stack>

      <Stack flex={1}>
        <DescriptionColumn
          item={item}
          isVerified={isVerified}
          loadingVerification={loadingVerification}
          {...handlers}
        />
      </Stack>
    </Stack>
  );
};
