import { bignum, COption } from "@metaplex-foundation/beet";
import { AnyPublicKey } from "@metaplex-foundation/mpl-core";
import { BaseSdkProps, ETransactionProgress, toPubkey } from "sdk/share";
import { sendTransactions } from "sdk/transactions";
import { createMarket } from "./actions/createMarket";
import { MarketSettings } from "./actions/createMarketTransaction";

export type initMarketProps = BaseSdkProps & {
  store: AnyPublicKey;
  resourceMint: AnyPublicKey;
  resourceToken: AnyPublicKey;
  marketSettings: MarketSettings;
  maxSupply: COption<bignum>;
};

export const initMarket = async (params: initMarketProps) => {
  const { connection, wallet, progressChange } = params;

  progressChange?.(ETransactionProgress.creating);
  const { store, resourceMint, resourceToken } = params;
  const { market, txs } = await createMarket({
    ...params,
    store: toPubkey(store),
    resourceMint: toPubkey(resourceMint),
    resourceToken: toPubkey(resourceToken),
  });

  progressChange?.(ETransactionProgress.sending_to_solana);
  await sendTransactions(connection, wallet, txs);

  return { market: market.publicKey.toString() };
};
