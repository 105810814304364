import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  NumberInput,
  NumberInputField,
  RequiredIndicator,
} from "@chakra-ui/react";
import {
  Controller,
  ControllerProps,
  FieldValues,
  UseFormRegister,
  useFormContext,
} from "react-hook-form";
import { MAXIMUM_SUPPLY_DEFAULT, SupplyType, SupplyTypesMap } from "./data";
import React, { useEffect, useState } from "react";

import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface FormFieldProps {
  id: string;
  title: string;
  placeholder?: string;
  description?: string | string[];
  options?: RegisterOptions;
  defaultValue?: string | number;
  min?: number;
  max?: number;
  defaultActiveType?: SupplyType;
  customInputFactory?: (
    register: ReturnType<UseFormRegister<FieldValues>> & { placeholder: string }
  ) => React.ReactChild;
  controlledInputFactory?: ControllerProps["render"];
}

export const MaximumSupply: React.FC<FormFieldProps> = ({
  id,
  defaultValue = MAXIMUM_SUPPLY_DEFAULT,
  min = 1,
  max,
  title,
  placeholder = MAXIMUM_SUPPLY_DEFAULT,
  description,
  options,
  defaultActiveType = SupplyType.LIMITED,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const [activeSupplyType, setActiveSupplyOption] = useState(defaultActiveType);
  const { mdUp } = useCustomBreakpoints();

  const isInvalid = !!errors[id];

  const descriptionString = Array.isArray(description)
    ? description.join("\n")
    : description;

  useEffect(() => {
    if (activeSupplyType === SupplyType.UNLIMITED) {
      setValue(id, "");
      if (id === "supply") setValue("supplyType", "unlimited");
    }
    if (activeSupplyType === SupplyType.LIMITED) {
      setValue(id, defaultValue);
      if (id === "supply") setValue("supplyType", "limited");
    }
  }, [activeSupplyType, defaultValue, id, setValue]);

  return (
    <FormControl
      id={id}
      isRequired={options?.required !== null}
      isInvalid={isInvalid}
    >
      <Flex justifyContent="space-between">
        <Box>
          <FormLabel
            requiredIndicator={
              <RequiredIndicator>(required)</RequiredIndicator>
            }
            color="white"
          >
            {title}
          </FormLabel>

          {descriptionString && (
            <FormHelperText>{descriptionString}</FormHelperText>
          )}
        </Box>
        <Flex
          direction={mdUp ? "row" : "column"}
          p="1"
          marginBottom="4"
          borderRadius="xl"
          bg="gray.800"
        >
          {SupplyTypesMap.map(({ value, label }) => (
            <Button
              disabled={!!max}
              h={8}
              size="sm"
              borderRadius="xl"
              key={value}
              onClick={() => setActiveSupplyOption(value)}
              variant={value === activeSupplyType ? "solid" : "ghost"}
              marginRight="5px"
              _last={{ marginRight: 0 }}
            >
              {label}
            </Button>
          ))}
        </Flex>
      </Flex>
      <Controller
        control={control}
        name={id}
        defaultValue={defaultValue}
        rules={options}
        render={({ field: { ref, onChange, ...restField } }) => (
          <NumberInput
            {...restField}
            min={min}
            max={max}
            keepWithinRange
            onChange={(value) => {
              onChange(value !== "" ? Number.parseFloat(value) : 0);
            }}
            isInvalid={isInvalid}
          >
            <NumberInputField
              disabled={activeSupplyType === SupplyType.UNLIMITED}
              ref={ref}
              name={restField.name}
              placeholder={
                activeSupplyType === SupplyType.UNLIMITED
                  ? "Unlimited"
                  : placeholder
              }
            />
          </NumberInput>
        )}
      />
      {/* eslint-disable @typescript-eslint/no-unsafe-member-access */}
      <FormErrorMessage>{errors[id]?.message}</FormErrorMessage>
    </FormControl>
  );
};
