import { PeriodType, IDuration } from "./TimeDurationInput";

const TIME_PERIODS: Record<PeriodType, number | null> = {
  days: 60 * 60 * 24,
  hours: 60 * 60,
  minutes: 60,
  unlimited: null,
};

export function toSeconds(duration?: IDuration): number | null {
  const periodTime = TIME_PERIODS[duration?.units || "days"];
  return periodTime ? (duration?.value || 0) * periodTime : null;
}
