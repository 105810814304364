import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import { BaseSdkProps, ETransactionProgress, toPubkey } from "sdk/share";
import { sendTransactions } from "sdk/transactions";
import { IInstantSale } from "state/sales";
import { createCancelTransaction } from "./actions/createCancelTransaction";

export interface EndSaleProps extends BaseSdkProps {
  auctionHouseId: StringPublicKey;
  sale: IInstantSale;
}

export const endSale = async (params: EndSaleProps) => {
  const { auctionHouseId, connection, progressChange, wallet } = params;

  progressChange?.(ETransactionProgress.creating);
  const { tx } = await createCancelTransaction({
    ...params,
    auctionHouseId: toPubkey(auctionHouseId),
    connection,
    wallet: wallet.publicKey,
  });

  progressChange?.(ETransactionProgress.sending_to_solana);
  await sendTransactions(connection, wallet, [tx]);
};
