import { ArtworkDetailsColumn } from "../ArtworkDetailsColumn";
import { FC } from "react";
import { getSalePrice, ISale } from "state/sales";
import { Person } from "components/Person";
import { SolUsdDisplay } from "components/SolUsdDisplay";
import { getOwnerAddress } from "./getOwnerAddress";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { SupplyDetails } from "./SupplyDetails";

interface Props {
  sale?: ISale;
}

export const EndedAuctionDetails: FC<Props> = ({ sale }) => {
  const { xlUp, xxlUp } = useCustomBreakpoints();

  if (!sale) return null;

  return (
    <>
      {xxlUp && (
        <ArtworkDetailsColumn title="Owned by">
          <Person address={getOwnerAddress(sale)} />
        </ArtworkDetailsColumn>
      )}

      {xlUp && (
        <ArtworkDetailsColumn title="Last sold">
          <SolUsdDisplay sol={getSalePrice(sale) || 0} />
        </ArtworkDetailsColumn>
      )}

      <ArtworkDetailsColumn title="Supply">
        <SupplyDetails {...sale.artwork.prints} />
      </ArtworkDetailsColumn>
    </>
  );
};
