import { AuctionHouseProgram } from "@metaplex-foundation/mpl-auction-house";
import { AuctionHouse } from "@metaplex-foundation/mpl-auction-house/dist/src/generated/accounts";
import { AnyPublicKey, StringPublicKey } from "@metaplex-foundation/mpl-core";
import { Connection } from "@solana/web3.js";
import base58 from "bs58";
import {
  findAccountsAndDeserialize,
  loadAccountAndDeserialize,
  toPubkey,
} from "sdk/share";

const discriminator = base58.encode(
  Uint8Array.from([40, 108, 215, 107, 213, 85, 245, 48])
);

export const getStores = async (
  owner: AnyPublicKey,
  connection: Connection
) => {
  const storesAndKeys = await findAccountsAndDeserialize(
    connection,
    AuctionHouseProgram,
    AuctionHouse,
    [
      { offset: 0, bytes: discriminator },
      { offset: 8 + 32 * 6, bytes: owner.toString() },
    ]
  );

  return Array.from(storesAndKeys).map(([pubkey, store]) =>
    combineStore(store, pubkey)
  );
};

const combineStore = (data: AuctionHouse, pubkey: AnyPublicKey) => ({
  ...data,
  storeId: pubkey.toString(),
  auctionHouseFeeAccount: data.auctionHouseFeeAccount.toString(),
  auctionHouseTreasury: data.auctionHouseTreasury.toString(),
  treasuryWithdrawalDestination: data.treasuryWithdrawalDestination.toString(),
  feeWithdrawalDestination: data.feeWithdrawalDestination.toString(),
  treasuryMint: data.treasuryMint.toString(),
  authority: data.authority.toString(),
  creator: data.creator.toString(),
});

export const loadStores = async ({
  owner,
  connection,
}: {
  owner: AnyPublicKey;
  connection: Connection;
}) => {
  try {
    return await getStores(owner, connection);
  } catch {
    return null;
  }
};

export const loadStore = async ({
  storeId,
  connection,
}: {
  storeId: StringPublicKey;
  connection: Connection;
}) => {
  try {
    const pubkey = toPubkey(storeId);

    const storeData = await loadAccountAndDeserialize(
      connection,
      AuctionHouse,
      pubkey
    );

    return combineStore(storeData, pubkey);
  } catch {
    return null;
  }
};
