import { FC } from "react";
import { MobileSidebarHeader } from "components/Sidebar/DrawerSidebar/MobileSidebarHeader";
import { ResourcesList } from "components/Navbar/ResourcesList";

export const ResourcesSidebarContent: FC = () => (
  <>
    <MobileSidebarHeader />
    <ResourcesList variant="sidebar" />
  </>
);
