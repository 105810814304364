// @ts-nocheck
import { createMintToInstruction } from "@solana/spl-token";
import { Connection, PublicKey } from "@solana/web3.js";
import { TransactionsBatch } from "sdk/transactions";
import { createMintAccount } from "./createMintAccount";
import { createTokenAccount } from "./createTokenAccount";

export const mintTokenAccount = async ({
  payer,
  connection,
}: {
  payer: PublicKey;
  connection: Connection;
}) => {
  const { mint, tx: mintTx } = await createMintAccount({
    payer,
    connection,
  });

  const { tokenAccount, tx: tokenTx } = await createTokenAccount({
    payer,
    mint: mint.publicKey,
    connection,
  });

  const createMintIx = createMintToInstruction(
    mint.publicKey,
    tokenAccount.publicKey,
    payer,
    1
  );

  const tx = new TransactionsBatch({
    transactions: [...mintTx.toTransactions(), ...tokenTx.toTransactions()],
    signers: [...mintTx.signers, ...tokenTx.signers],
    instructions: [createMintIx],
  });

  return { mint, tokenAccount, tx };
};
