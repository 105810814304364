import React from "react";
import { IArt } from "state/artworks";

interface ArtVrProps {
  artwork: IArt;
}

export const ArtVr: React.FC<ArtVrProps> = ({ artwork }) => {
  return (
    <model-viewer
      poster={artwork.image}
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100px",
        minWidth: "100px",
      }}
      src={artwork.animationUrl}
      alt="VR element"
      camera-controls
    />
  );
};
