import { Box, BoxProps, Text } from "@chakra-ui/react";

import { $store } from "state/store";
import { Avatar } from "@chakra-ui/avatar";
import { Flex } from "@chakra-ui/layout";
import { IArt } from "state/artworks";
import { Image } from "@chakra-ui/image";
import React from "react";
import { SupplyDetails } from "../shared/ArtworkDetails/SupplyDetails";
import { WalletAddress } from "components/WalletAddress";
import { useStore } from "effector-react";

interface Props extends BoxProps {
  artwork: IArt;
  resaleWalletAddress?: string;
}

export const ArtworkCard: React.FC<Props> = ({
  artwork,
  resaleWalletAddress,
  children,
  ...boxProps
}) => {
  const store = useStore($store);
  const title = artwork.title;
  const imageSrc = artwork.image;

  return (
    <Box
      layerStyle="base"
      p={0}
      overflow="hidden"
      w="full"
      maxW="full"
      {...boxProps}
      role="group"
      transition="transform 100ms ease-in-out"
      _hover={{
        transform: "translateY(-2px)",
      }}
      cursor="pointer"
    >
      <Box layerStyle="base" p={0}>
        <Box
          borderRadius="xl"
          transition="background 100ms ease-in-out"
          _groupHover={{
            bg: "rgba(255, 255, 255, 0.02)",
          }}
        >
          <Image
            pos="relative"
            _groupHover={{
              zIndex: -1,
              filter: "none",
            }}
            src={imageSrc}
            objectFit="cover"
            w="full"
            h="360px"
            filter="drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.2))"
            borderRadius="xl"
          />
        </Box>

        <Box p={4}>
          <Flex alignItems="center">
            <Avatar src={store?.logoImg} size="2xs" borderRadius="2px" />
            <Text variant="body" ml={2} mr="auto" maxW="250px" isTruncated>
              {store?.name}
            </Text>

            <SupplyDetails
              {...artwork.prints}
              color="whiteAlpha.700"
              variant="verbal"
            />
          </Flex>

          <Text
            mt={4}
            fontSize="2xl"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {title}
          </Text>

          {resaleWalletAddress && (
            <Box mt={4}>
              <Text variant="small" color="whiteAlpha.500">
                RESALE BY
              </Text>
              <WalletAddress address={resaleWalletAddress} />
            </Box>
          )}
        </Box>
      </Box>
      {children}
    </Box>
  );
};
