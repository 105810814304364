import React, { useCallback } from "react";

import DatePicker from "react-datepicker";
import { DatePickerProps } from "./DatePickerProps";
import { Input } from "@chakra-ui/react";

export const DateTimeCustom: React.FC<DatePickerProps> = ({
  date,
  dateChange,
}) => {
  const onDateChange = useCallback(
    (newDate: Date) => {
      const _date = new Date(date);
      const hours = newDate.getHours();
      const minutes = newDate.getMinutes();
      dateChange(new Date(_date.setHours(hours, minutes, 0)));
    },
    [date, dateChange]
  );

  return (
    <DatePicker
      selected={date}
      onChange={onDateChange}
      showTimeSelect
      showTimeSelectOnly
      dateFormat="h:mm aa"
      customInput={
        <Input
          w="full"
          fontSize="md"
          h="56px"
          size="lg"
          minW="142px"
          bg="whiteAlpha.50"
          justifyContent="flex-start"
        />
      }
    />
  );
};
