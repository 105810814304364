import { SellingResource } from "@metaplex-foundation/mpl-fixed-price-sale";
import { Connection, PublicKey } from "@solana/web3.js";
import { loadAccountAndDeserialize } from "sdk/share";

export const getSellingResource = async (
  connection: Connection,
  pubkey: PublicKey
) => {
  const data = await loadAccountAndDeserialize(
    connection,
    SellingResource,
    pubkey
  );
  return data;
};
