import { AuctionHouseProgram } from "@metaplex-foundation/mpl-auction-house";
import {
  createBuyInstruction,
  createPrintBidReceiptInstruction,
} from "@metaplex-foundation/mpl-auction-house/dist/src/generated";
import { AuctionHouse } from "@metaplex-foundation/mpl-auction-house/dist/src/generated/accounts";
import {
  Connection,
  PublicKey,
  SYSVAR_INSTRUCTIONS_PUBKEY,
} from "@solana/web3.js";
import { toPubkey } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";
import { IInstantSale } from "state/sales";
import { excludesFalsy } from "utils/excludeFalsy";
import { solToLamports } from "utils/lamportsToSol";
import { topupFeeAccount } from "./topupFeeAccount";

export interface BuyProps {
  connection: Connection;
  auctionHouse: AuctionHouse;
  auctionHouseKey: PublicKey;
  buyer: PublicKey;
  sale: IInstantSale;
  tokenSize?: number;
}

export const createBuyTransaction = async ({
  connection,
  auctionHouse,
  auctionHouseKey,
  buyer,
  sale,
  tokenSize = 1,
}: BuyProps) => {
  const { treasuryMint, authority, auctionHouseFeeAccount } = auctionHouse;
  const tokenAccount = toPubkey(sale.artwork.token);
  const mint = toPubkey(sale.artwork.mint);
  const metadata = toPubkey(sale.artwork.accountAddress);
  const price = solToLamports(sale.price);

  const topupFeeIx = buyer.equals(authority)
    ? await topupFeeAccount({
        connection,
        payer: buyer,
        auctionHouseFeeAccount,
      })
    : undefined;

  const [escrowPaymentAccount, escrowPaymentBump] =
    await AuctionHouseProgram.findEscrowPaymentAccountAddress(
      auctionHouseKey,
      buyer
    );

  const [buyerTradeState, tradeStateBump] =
    await AuctionHouseProgram.findTradeStateAddress(
      buyer,
      auctionHouseKey,
      tokenAccount,
      treasuryMint,
      mint,
      price,
      tokenSize
    );

  const buyIx = createBuyInstruction(
    {
      wallet: buyer,
      paymentAccount: buyer,
      transferAuthority: buyer,
      treasuryMint,
      tokenAccount,
      metadata,
      escrowPaymentAccount,
      authority,
      auctionHouse: auctionHouseKey,
      auctionHouseFeeAccount,
      buyerTradeState,
    },
    {
      tradeStateBump,
      escrowPaymentBump,
      buyerPrice: price,
      tokenSize,
    }
  );

  const [receipt, receiptBump] =
    await AuctionHouseProgram.findBidReceiptAddress(buyerTradeState);

  const receiptIx = createPrintBidReceiptInstruction(
    {
      receipt,
      bookkeeper: buyer,
      instruction: SYSVAR_INSTRUCTIONS_PUBKEY,
    },
    { receiptBump }
  );

  const tx = new TransactionsBatch({
    instructions: [topupFeeIx, buyIx, receiptIx].filter(excludesFalsy),
  });

  return { tradeState: buyerTradeState, receipt, tx };
};
