import { Avatar, IconButton } from "@chakra-ui/react";
import { ComponentProps, forwardRef } from "react";

import type { PersonProps } from "components/Person";

export type PersonButtonProps = PersonProps & ComponentProps<typeof IconButton>;

export const PersonButton = forwardRef<HTMLButtonElement, PersonButtonProps>(
  ({ avatarUrl, ...props }, ref) => (
    <IconButton
      ref={ref}
      icon={<Avatar size="sm" src={avatarUrl} />}
      variant="ghost"
      minW="48px"
      isRound
      {...props}
    />
  )
);
