import { ColorHues } from "@chakra-ui/react";

export const colors: Record<string, ColorHues> = {
  green: {
    50: "#E5FFF8",
    100: "#BDFFEE",
    200: "#85FFDF",
    300: "#3DFFCD",
    400: "#1FFFC5",
    500: "#00FFBD",
    600: "#00E0A6",
    700: "#00C290",
    800: "#00A379",
    900: "#008562",
  },
  purple: {
    50: "#EFEAFB",
    100: "#C3AEEE",
    200: "#A080E5",
    300: "#7544D9",
    400: "#622BD3",
    500: "#5626BA",
    600: "#4A21A1",
    700: "#3F1C87",
    800: "#33166E",
    900: "#271154",
  },
  pink: {
    50: "#FCF2FD",
    100: "#FAE5FC",
    200: "#F0B2F7",
    300: "#E372F1",
    400: "#DD56EE",
    500: "#D83AEB",
    600: "#D21EE8",
    700: "#BE15D2",
    800: "#A513B7",
    900: "#8C109B",
  },
  gray: {
    50: "#CECECE",
    100: "#A7A7A7",
    200: "#8B8B8B",
    300: "#686868",
    400: "#585858",
    500: "#494949",
    600: "#3A3A3A",
    700: "#2A2A2A",
    800: "#1E1E1E",
    900: "#121212",
  },
  whiteAlpha: {
    50: "rgba(255, 255, 255, 0.05)",
    100: "rgba(255, 255, 255, 0.1)",
    200: "rgba(255, 255, 255, 0.2)",
    300: "rgba(255, 255, 255, 0.3)",
    400: "rgba(255, 255, 255, 0.4)",
    500: "rgba(255, 255, 255, 0.5)",
    600: "rgba(255, 255, 255, 0.6)",
    700: "rgba(255, 255, 255, 0.7)",
    800: "rgba(255, 255, 255, 0.8)",
    900: "rgba(255, 255, 255, 0.9)",
  },
  blackAlpha: {
    50: "rgba(0, 0, 0, 0.05)",
    100: "rgba(0, 0, 0, 0.1)",
    200: "rgba(0, 0, 0, 0.2)",
    300: "rgba(0, 0, 0, 0.3)",
    400: "rgba(0, 0, 0, 0.4)",
    500: "rgba(0, 0, 0, 0.5)",
    600: "rgba(0, 0, 0, 0.6)",
    700: "rgba(0, 0, 0, 0.7)",
    800: "rgba(0, 0, 0, 0.8)",
    900: "rgba(0, 0, 0, 0.9)",
  },
  brown: {
    50: "rgba(230, 92, 55, 0.05)",
    100: "rgba(230, 92, 55, 0.1)",
    200: "rgba(230, 92, 55, 0.2)",
    300: "rgba(230, 92, 55, 0.3)",
    400: "rgba(230, 92, 55, 0.4)",
    500: "rgba(230, 92, 55, 0.5)",
    600: "rgba(230, 92, 55, 0.6)",
    700: "rgba(230, 92, 55, 0.7)",
    800: "rgba(230, 92, 55, 0.8)",
    900: "rgba(230, 92, 55, 0.9)",
  },
  red: {
    50: "rgba(223, 23, 83, 0.05)",
    100: "rgba(223, 23, 83, 0.1)",
    200: "rgba(223, 23, 83, 0.2)",
    300: "rgba(223, 23, 83, 0.3)",
    400: "rgba(223, 23, 83, 0.4)",
    500: "rgba(223, 23, 83, 0.5)",
    600: "rgba(223, 23, 83, 0.6)",
    700: "rgba(223, 23, 83, 0.7)",
    800: "rgba(223, 23, 83, 0.8)",
    900: "#FF0000",
  },
};
