import { config } from "@metaplex-foundation/mpl-core";
import { SystemProgram, TransactionInstruction } from "@solana/web3.js";
import { toPubkey } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";
import { solToLamports } from "utils/lamportsToSol";
import { Wallet } from "wallet";
import { getFilesCost } from "../arweave";
import { getFileHash } from "./getFileHash";

export const ARWEAVE_WALLET = toPubkey(
  "6FKvsq4ydWFci6nGq9ckbjYMtnmaqAoatz5c9XWjiDuS"
);

export interface IPayForFilesParams {
  wallet: Wallet;
  files: File[];
}

export async function payForFiles({ files, wallet }: IPayForFilesParams) {
  const fileHashes = await Promise.all(files.map((file) => getFileHash(file)));
  const { solana } = await getFilesCost(files);
  const lamports = solToLamports(solana);

  const ix = SystemProgram.transfer({
    fromPubkey: wallet.publicKey,
    toPubkey: ARWEAVE_WALLET,
    lamports,
  });

  const ixs = fileHashes.map(
    (data) =>
      new TransactionInstruction({
        keys: [],
        programId: toPubkey(config.programs.memo),
        data,
      })
  );

  const tx = new TransactionsBatch({ instructions: [ix, ...ixs] });

  return tx;
}
