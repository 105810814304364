import React, { useCallback, useEffect, useRef } from "react";
import { MdAdd } from "react-icons/md";

import { Box, BoxProps, Flex } from "@chakra-ui/react";

import { DragAndDrop } from "components/DragAndDrop";
import { FileInput } from "components/FileInput";
import { FileType, FiletypeAcceptMap } from "components/MediaTypeSelector";
import { TitledBlock } from "components/TitledBlock";
import { UploadButton } from "components/UploadButton";

import { FilePreview } from "./Preview";

import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { useFileReader } from "hooks/useFileReader";

import { fontSizes } from "theme/typography";

export interface FileUploadProps extends BoxProps {
  type?: FileType;
  onFileChange: (data: string | File | null) => void;
  onBlur?: () => void;
  isInvalid?: boolean;
  value?: File | string;
  disabled?: boolean;
  variant?: "base" | "preview" | "logo-preview";
}

export const FILE_UPLOAD_CONTENT_HEIGHT = 160;

export const FileUpload: React.FC<FileUploadProps> = ({
  disabled,
  isInvalid,
  onFileChange,
  type = FileType.IMAGE,
  value,
  variant = "preview",
}) => {
  const { mdUp } = useCustomBreakpoints();
  const [contentUrl, setContentUrl, read] = useFileReader(value);
  const $fileRef = useRef<HTMLInputElement>(null);

  const isLogo = variant === "logo-preview";

  const handleFileUpload = useCallback(
    (data: File | FileList | null) => {
      const file = data instanceof FileList ? data[0] : data;

      onFileChange(file);

      if (file !== null) {
        read(file);
      } else {
        setContentUrl(null);
      }
    },
    [onFileChange, read, setContentUrl]
  );

  const handleLinkSubmit = useCallback(() => {
    const value = $fileRef.current?.value;

    if (value) {
      onFileChange(value);
      setContentUrl(value);
    }
  }, [onFileChange, setContentUrl]);

  useEffect(() => {
    if (!value) {
      setContentUrl(null);
    }
  }, [setContentUrl, value]);

  return (
    <Box
      bg="whiteAlpha.50"
      borderColor="white"
      borderRadius="2xl"
      layerStyle={isInvalid ? "error" : undefined}
      p={mdUp ? 8 : 4}
      pos="relative"
    >
      {contentUrl ? (
        <FilePreview
          isLogo={isLogo}
          onFileUpload={handleFileUpload}
          type={type}
          url={contentUrl}
        />
      ) : (
        <DragAndDrop onFileUpload={handleFileUpload}>
          <Flex
            align="center"
            borderRadius="2xl"
            justifyContent="center"
            layerStyle="base"
            maxH={isLogo ? FILE_UPLOAD_CONTENT_HEIGHT : undefined}
            minH={FILE_UPLOAD_CONTENT_HEIGHT}
            w={isLogo ? FILE_UPLOAD_CONTENT_HEIGHT : "full"}
          >
            <UploadButton
              accept={FiletypeAcceptMap[type]}
              disabled={disabled}
              leftIcon={<MdAdd size={fontSizes["2xl"]} />}
              onUpload={handleFileUpload}
              size="lg"
              variant="ghost"
            >
              Add File
            </UploadButton>
          </Flex>
        </DragAndDrop>
      )}

      <TitledBlock
        fontWeight={600}
        mt={6}
        title="Or use absolute URL to content"
      >
        <FileInput
          action={handleLinkSubmit}
          actionText="Submit"
          ref={$fileRef}
          w="full"
        />
      </TitledBlock>
    </Box>
  );
};
