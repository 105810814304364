import { Box, BoxProps, VStack } from "@chakra-ui/react";

import { AddressButton } from "components/buttons/AddressButton";
import { EarningsRow } from "./EarningsRow";
import { IArtCreator } from "state/artworks";
import React from "react";
import { TitledBlock } from "components/TitledBlock";

export interface EarningsListProps extends BoxProps {
  data: Array<IArtCreator>;
  title?: string;
}

export const EarningsList: React.FC<EarningsListProps> = ({
  data = [],
  title = "Royalties split between",
  ...props
}) => (
  <Box {...props}>
    <TitledBlock title={title} />

    <VStack spacing={0}>
      {data.map(({ address, share }, index) => (
        <EarningsRow key={`${address}-${index}`} value={share}>
          <AddressButton address={address} variant="link" />
        </EarningsRow>
      ))}
    </VStack>
  </Box>
);
