import { ComponentProps, FC } from "react";

import { CreateButton } from "components/buttons/CreateButton";
import { IconButton } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { MdAdd } from "react-icons/md";
import { fontSizes } from "theme/typography";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface Props extends ComponentProps<typeof IconButton> {
  LeftIcon?: IconType;
}

export const NavbarCta: FC<Props> = ({
  LeftIcon = MdAdd,
  children,
  ...props
}) => {
  const { mdUp } = useCustomBreakpoints();

  return mdUp ? (
    <CreateButton variant="solid" {...props}>
      {children}
    </CreateButton>
  ) : (
    <IconButton
      variant="ghost"
      isRound
      icon={<LeftIcon size={fontSizes["2xl"]} />}
      color="whiteAlpha.500"
      {...props}
    />
  );
};
