import { CircularProgress, Icon, Tag, TagLabel } from "@chakra-ui/react";
import { MdOutlineLock, MdOutlineLockOpen } from "react-icons/md";

import { FC } from "react";

interface SaleLockChipProps {
  locked?: boolean;
  pending?: boolean;
}

export const SaleLockChip: FC<SaleLockChipProps> = ({
  locked,
  pending = true,
}) => {
  return (
    <Tag variant="solid" bgColor="whiteAlpha.100" px={2} py={1}>
      {pending ? (
        <>
          <CircularProgress
            isIndeterminate
            color="green.500"
            size="16px"
            trackColor="none"
          />
          <TagLabel ml={2}>Status pending</TagLabel>
        </>
      ) : (
        <>
          <Icon
            as={locked ? MdOutlineLock : MdOutlineLockOpen}
            color="green.500"
          />
          <TagLabel ml={2}>{locked ? "Locked" : "Unlocked"}</TagLabel>
        </>
      )}
    </Tag>
  );
};
