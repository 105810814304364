import { attach, createEffect, StoreValue } from "effector";
import { $connection } from "../connection";
import { AnyPublicKey } from "@metaplex-foundation/mpl-core";
import { loadArtworkByMint } from "sdk/loadArtworks";

interface Params {
  mint: AnyPublicKey;
}

export const loadArtworkByMintFx = attach({
  effect: createEffect(
    ({
      connection,
      ...params
    }: Params & {
      connection: StoreValue<typeof $connection>;
    }) => {
      return loadArtworkByMint({
        connection,
        ...params,
      });
    }
  ),
  source: {
    connection: $connection,
  },
  mapParams(data: Params, source) {
    return { ...data, ...source };
  },
});
