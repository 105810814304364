import React, { useEffect, useState } from "react";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { useWindowSize } from "utils/resize";
import { Box, Flex, FlexProps } from "@chakra-ui/react";
import { IArt } from "state/artworks";
import {
  isAudioFormat,
  isImageFormat,
  isVideoFormat,
  isVrFormat,
} from "state/artworks/utils";
import { ArtImage } from "components/ArtImage";
import { ArtVideo } from "components/ArtVideo";
import { ArtAudio } from "components/ArtAudio";
import { ArtVr } from "../ArtVr";

interface ArtMediaProps extends FlexProps {
  artwork?: IArt | null;
}

export const ArtMedia: React.FC<ArtMediaProps> = ({ artwork, ...rest }) => {
  const { mdUp } = useCustomBreakpoints();
  const [imageHeight, setImageHeight] = useState("");
  const [, screenHeight] = useWindowSize();

  const paddingsAndHeaderHeight = 160;
  const maxMonitorHeight = 1500;
  const defaultHeightForUltraHeightMonitors = 1000;

  useEffect(() => {
    const height =
      screenHeight > maxMonitorHeight
        ? defaultHeightForUltraHeightMonitors
        : screenHeight - paddingsAndHeaderHeight;

    setImageHeight(`${height}px`);
  }, [screenHeight]);

  if (!artwork) return null;

  return (
    <Flex justify="center" cursor="pointer" {...rest}>
      <Box
        role="group"
        position="relative"
        width={mdUp ? "90%" : "100vw"}
        overflow="hidden"
        borderRadius={mdUp ? 8 : 0}
        h={imageHeight}
      >
        {isImageFormat(artwork) && <ArtImage artwork={artwork} />}
        {isVideoFormat(artwork) && <ArtVideo artwork={artwork} />}
        {isAudioFormat(artwork) && <ArtAudio artwork={artwork} />}
        {isVrFormat(artwork) && <ArtVr artwork={artwork} />}
      </Box>
    </Flex>
  );
};
