import { AuctionHouseProgram } from "@metaplex-foundation/mpl-auction-house";
import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import { BaseSdkProps, ETransactionProgress, toPubkey } from "sdk/share";
import { sendTransactions } from "sdk/transactions";
import { lamportsToSol } from "utils/lamportsToSol";
import { createDepositTransaction } from "./actions/createDepositTransaction";

export type DepositProps = BaseSdkProps & {
  auctionHouse: StringPublicKey;
  amount: number;
};

export const initDeposit = async (params: DepositProps) => {
  const { connection, wallet, progressChange } = params;

  progressChange?.(ETransactionProgress.creating);
  const { tx } = await createDepositTransaction({
    ...params,
    wallet: wallet.publicKey,
    auctionHouse: toPubkey(params.auctionHouse),
  });

  progressChange?.(ETransactionProgress.sending_to_solana);
  await sendTransactions(connection, wallet, [tx]);
};

export const loadDeposit = async ({
  connection,
  auctionHouse,
  wallet,
}: Omit<DepositProps, "amount">) => {
  const [escrowPaymentAccount] =
    await AuctionHouseProgram.findEscrowPaymentAccountAddress(
      toPubkey(auctionHouse),
      wallet.publicKey
    );
  const value = await connection.getBalance(escrowPaymentAccount);
  return lamportsToSol(value);
};
