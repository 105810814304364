import { attach, createEffect, StoreValue } from "effector";
import { buy } from "sdk/candyMachine/buy";
import { $connection } from "state/connection";
import { $store } from "state/store";
import { $wallet } from "state/wallet";
import { ICandyMachine } from "./types";

interface IBuyProps {
  sale: ICandyMachine;
  group: string;
}

export const purchaseCmFx = attach({
  effect: createEffect(
    async ({
      sale,
      wallet,
      connection,
      store,
      group,
    }: IBuyProps & {
      connection: StoreValue<typeof $connection>;
      wallet: StoreValue<typeof $wallet>;
      store: StoreValue<typeof $store>;
    }) => {
      if (!store) {
        throw new Error("Empty store");
      }

      if (!wallet) {
        throw new Error("Empty wallet");
      }

      return await buy({
        connection,
        wallet,
        sale,
        group,
      });
    }
  ),
  source: {
    wallet: $wallet,
    connection: $connection,
    store: $store,
  },
  mapParams: (params: IBuyProps, sources) => ({ ...params, ...sources }),
});
