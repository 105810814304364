/* eslint-disable @typescript-eslint/no-non-null-assertion, no-console */
import { Button, VStack } from "@chakra-ui/react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { Layout } from "components/Layout";
import { useStore } from "effector-react";
import { FC, useEffect } from "react";
import {
  buy,
  initDeposit,
  initSale,
  initStore,
  loadBids,
  loadDeposit,
  loadSale,
  loadSales,
  loadStore,
  loadStores,
} from "sdk/auction-house";
import {
  $pendingProfileArtworks,
  $profileArtworks,
  fetchProfileArtworksFx,
} from "state/artworks";
import { $connection } from "state/connection";
import { $wallet } from "state/wallet";

export const Test2Page: FC = () => {
  const connection = useStore($connection);
  const wallet = useStore($wallet)!;

  const arts = useStore($profileArtworks);
  const pending = useStore($pendingProfileArtworks);
  console.log(arts, pending);

  useEffect(() => {
    fetchProfileArtworksFx();
  }, []);

  const cw = { connection, wallet };
  const co = { connection, owner: wallet.publicKey };
  const store = "28dbMPrsnkpa5dvyG9X4J9u241s4pZUfGbmy29SxE1Kr";
  const market = {
    ...cw,
    auctionHouse: store,
    resourceMint: "64jDhv99npss2hn2TC4H2VFrzgthEuNxn6owX2f83HGC",
    price: 0.1 * LAMPORTS_PER_SOL,
    isStoreAdmin: true,
  };
  const lm = { ...cw, auctionHouse: store };

  return (
    <Layout variant="narrow">
      <VStack spacing={4}>
        <Button onClick={() => log(initStore(cw))}>Create store</Button>

        <Button onClick={() => log(loadStores(co))}>Load stores</Button>
        <Button
          onClick={() =>
            log(
              loadStore({
                connection,
                storeId: store.toString(),
              })
            )
          }
        >
          Load store
        </Button>

        <Button onClick={() => log(initSale(market))}>create sale</Button>
        <Button onClick={() => log(loadSales(lm))}>load sales</Button>
        <Button onClick={() => log(loadBids(lm))}>load bids</Button>

        <Button
          onClick={() =>
            log(initDeposit({ ...lm, amount: 0.1 * LAMPORTS_PER_SOL }))
          }
        >
          deposit
        </Button>
        <Button onClick={() => log(loadDeposit(lm))}>check deposit</Button>

        <Button
          onClick={async () =>
            log(
              buy({
                ...cw,
                auctionHouse: store,
                sale: await loadSale({
                  connection,
                  saleId: "g2ToyqUeB1L8Q6V8dVGGzMXsyBjp1xuvMEgJpVz3BmN",
                }),
              })
            )
          }
        >
          buy
        </Button>
      </VStack>
    </Layout>
  );
};

async function log(a: Promise<unknown>) {
  const res = await a;
  console.log("##", res);
}
