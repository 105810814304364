import type {
  PartsStyleFunction,
  SystemStyleObject,
} from "@chakra-ui/theme-tools";

import { modalAnatomy as parts } from "@chakra-ui/anatomy";

const baseStyleCloseButton: SystemStyleObject = {
  position: "absolute",
  top: 2,
  insetEnd: 3,
  _focus: {
    boxShadow: "none",
  },
};

const baseStyle: PartsStyleFunction<typeof parts> = () => ({
  closeButton: baseStyleCloseButton,
});

export const Modal = {
  baseStyle,
};
