import { Box, Center, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";

import { ArtworksListNavbar } from "./ArtworksListNavbar";
import { LoaderComponent } from "components/modals/InfiniteProgress/LoaderComponent";
import { Pagination } from "components/Pagination";
import { VStack } from "@chakra-ui/layout";
import { usePagination } from "hooks/usePagination";
import { useTemplateColumns } from "./useTemplateColumns";
import { startStoreFetch, stopStoreFetch } from "state/sales";
import { Store } from "effector";
import { TabsConfig, useTabs } from "hooks/useTabs";

export interface ITab {
  id: string;
  title: string;
  count?: number;
}

interface Props<T> {
  items: Store<T[]>;
  tabs: TabsConfig<T>;
  createButton?: ReactNode;
  hideActionButtons?: boolean;
  defaultView?: "grid" | "list";
  pending?: boolean;
  noItemsText?: {
    title: string;
    subtitle: string;
  };
  renderGridItem?: (item: T) => ReactNode;
  renderListItem?: (item: T) => ReactNode;
}

export const ArtworksList = <T,>({
  items: itemsProp,
  tabs: tabsConfig,
  createButton,
  hideActionButtons,
  defaultView = "grid",
  pending = false,
  noItemsText,
  renderGridItem,
  renderListItem,
}: Props<T>) => {
  const [isLoading, setIsLoading] = useState(true);

  const { currentTabIndex, items, onTabChange, tabs } = useTabs<T>(
    itemsProp,
    tabsConfig
  );

  const {
    items: currentPageItems,
    page,
    setPage,
    itemsPerPage,
  } = usePagination(items, currentTabIndex);

  const templateColumns = useTemplateColumns();

  useEffect(() => {
    if (!pending) setIsLoading(false);
  }, [pending]);

  useEffect(() => {
    startStoreFetch();
    return () => stopStoreFetch();
  }, []);

  const isGrid = defaultView === "grid";

  return (
    <Flex direction="column" align="flex-start" h="100%">
      {isLoading ? (
        <Center width="full">
          <LoaderComponent title="loading items" darkBg />
        </Center>
      ) : (
        <>
          <ArtworksListNavbar
            tabs={tabs}
            currentTabIndex={currentTabIndex}
            hideActionButtons={hideActionButtons}
            createButton={createButton}
            onTabChange={onTabChange}
          />

          {items.length === 0 && (
            <Box pt={5}>
              <Heading variant="h3" color="whiteAlpha.700" maxW="700px">
                {noItemsText?.title}
              </Heading>
              <Text variant="body" color="whiteAlpha.700" mt={4}>
                {noItemsText?.subtitle}
              </Text>
            </Box>
          )}

          {isGrid
            ? renderGridItem && (
                <Grid templateColumns={templateColumns} width="100%" gap={8}>
                  {currentPageItems.map((item) => renderGridItem(item))}
                </Grid>
              )
            : renderListItem && (
                <VStack w="full" spacing={4} overflowY="scroll">
                  {currentPageItems.map((item) => renderListItem(item))}
                </VStack>
              )}
          {items?.length > currentPageItems?.length && (
            <Pagination
              currentPage={page}
              itemsPerPage={itemsPerPage}
              total={items.length}
              onPageChange={setPage}
              mt={6}
              w="full"
            />
          )}
        </>
      )}
    </Flex>
  );
};
