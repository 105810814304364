import React, { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { VStack } from "@chakra-ui/react";

import { FormField } from "components/FormField";
import { IFormData } from "components/forms/NftCreate/interface";
import {
  EditionSelector,
  SupplyType,
} from "components/EditionSelector/EditionSelector";
import { getSupplyType } from "utils/getSupplyType";
import { getSupplyValue } from "utils/getSupplyValue";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

const DEFAULT_SUPPLY = 1000;

export const SupplyTypeInput: React.FC = () => {
  const { watch, setValue } = useFormContext<IFormData>();
  const { mdUp } = useCustomBreakpoints();

  const supply = watch("supply");

  const supplyType = useMemo(() => getSupplyType(supply), [supply]);
  const isUnlimited = supplyType === SupplyType.Unlimited;

  const onSupplyTypeChange = useCallback(
    (type: SupplyType) => {
      setValue("supply", getSupplyValue(type, DEFAULT_SUPPLY));
    },
    [setValue]
  );

  return (
    <VStack spacing={mdUp ? 4 : 10}>
      <FormField
        id="supplyType"
        title="SUPPLY"
        description={[
          "Do you want your NFT to be be a single one of a kind or",
          "allow a set quantity of identical NFTs to be minted?",
        ]}
        variant={mdUp ? "row" : "column"}
        controlledInputFactory={() => (
          <EditionSelector
            value={supplyType}
            onChange={onSupplyTypeChange}
            w={mdUp ? "260px" : "100%"}
          />
        )}
      />

      {!isUnlimited && (
        <FormField
          id="supply"
          title="NUMBER OF EDITIONS"
          type="number"
          description="Set the number of copes allowed to be minted in addition to your Original."
          options={{
            min: 1,
          }}
          defaultValue={DEFAULT_SUPPLY}
          placeholder="1"
        />
      )}
    </VStack>
  );
};
