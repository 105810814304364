import { useStore } from "effector-react";
import { FC } from "react";
import { Navigate } from "react-router-dom";
import { usePaths } from "routes";
import { $isStoreAdmin } from "state/store";

export const ProfileStoreGuard: FC = ({ children }) => {
  const isStoreAdmin = useStore($isStoreAdmin);
  const { admin } = usePaths();

  return !isStoreAdmin ? <>{children}</> : <Navigate to={admin()} replace />;
};
