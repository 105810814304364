import React from "react";
import { Center, Flex, MenuItem, MenuItemProps, Text } from "@chakra-ui/react";

interface Props extends MenuItemProps {
  title: string;
  subtitle: string;
  isActive: boolean;
}

export const EditionSelectorItem: React.FC<Props> = ({
  icon,
  title,
  subtitle,
  isActive,
  ...props
}) => {
  return (
    <MenuItem
      px={1}
      py={2}
      borderRadius="lg"
      _focus={{
        bg: undefined,
      }}
      bg={isActive ? "whiteAlpha.100" : undefined}
      _hover={{
        bg: "whiteAlpha.100",
      }}
      icon={
        <Center h={8} w={8}>
          {icon}
        </Center>
      }
      iconSpacing={2}
      {...props}
    >
      <Flex flexDir="column">
        <Text variant="subtitle-bold" color="white">
          {title}
        </Text>
        <Text
          variant="subtitle"
          fontWeight="normal"
          color="whiteAlpha.500"
          mt="6px"
        >
          {subtitle}
        </Text>
      </Flex>
    </MenuItem>
  );
};
