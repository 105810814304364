import { HStack, Heading, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { AddressButton } from "components/buttons/AddressButton";
import { SquarePicture } from "components/SquarePicture";

interface Props {
  image: string;
  title?: string;
  address?: string;
}

export const PurchaseConfirmHead: FC<Props> = ({
  image,
  title,
  address,
}: Props) => {
  const { mdUp } = useCustomBreakpoints();

  return (
    <HStack alignItems="start" spacing={6}>
      <SquarePicture size={187} imageSrc={image} />
      <VStack alignItems="start" spacing={4}>
        <Heading variant={mdUp ? "h3" : "h4"}>{title}</Heading>
        {address && (
          <AddressButton address={address} variant="link" size="sm" />
        )}
      </VStack>
    </HStack>
  );
};
