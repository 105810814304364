import { Connection, PublicKey, SystemProgram } from "@solana/web3.js";
import { loadAccount } from "sdk/share";

interface Props {
  connection: Connection;
  payer: PublicKey;
  auctionHouseFeeAccount: PublicKey;
  amount?: number;
}

export const topupFeeAccount = async ({
  connection,
  payer,
  auctionHouseFeeAccount,
  amount = 0,
}: Props) => {
  if (!amount) {
    amount = await connection.getMinimumBalanceForRentExemption(1); // size of empy account
  }

  const account = await loadAccount(connection, auctionHouseFeeAccount, true);

  const actualFeeAccountAmount = account?.lamports ?? 0;

  if (actualFeeAccountAmount < amount) {
    const topupFeeIx = SystemProgram.transfer({
      fromPubkey: payer,
      toPubkey: auctionHouseFeeAccount,
      lamports: amount,
    });

    return topupFeeIx;
  }
  return undefined;
};
