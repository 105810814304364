import React from "react";
import { useFullScreenHandle } from "react-full-screen";

import { Box, Button, CloseButton, Flex, Image } from "@chakra-ui/react";

import { Lightbox } from "components/Lightbox";
import { FiletypeAcceptMap } from "components/MediaTypeSelector";
import { UploadButton } from "components/UploadButton";

import { FilePreviewProps } from "../Preview.props";
import { FILE_UPLOAD_CONTENT_HEIGHT } from "components/FileUpload/FileUpload";

export const FilePreviewImage: React.FC<FilePreviewProps> = ({
  isLogo,
  onFileUpload,
  type,
  url,
}) => {
  const handleFullscreen = useFullScreenHandle();

  return (
    <>
      {!isLogo && (
        <>
          <Flex
            align="center"
            direction="column"
            h="full"
            justifyContent="center"
            pos="absolute"
            visibility="hidden"
            w="full"
            _groupHover={{ visibility: "visible" }}
          >
            <UploadButton
              accept={FiletypeAcceptMap[type]}
              onUpload={onFileUpload}
              size="lg"
              variant="tertiary"
            >
              Replace file
            </UploadButton>

            <Button onClick={handleFullscreen.enter} mt={2} variant="solid">
              Preview
            </Button>
          </Flex>

          <Lightbox artwork={url} handle={handleFullscreen} />
        </>
      )}

      <Box pos="relative">
        <Image
          maxH={isLogo ? FILE_UPLOAD_CONTENT_HEIGHT : undefined}
          objectFit="cover"
          src={url}
          w={isLogo ? FILE_UPLOAD_CONTENT_HEIGHT : "full"}
        />
        <CloseButton
          pos="absolute"
          top={2}
          right={2}
          onClick={() => onFileUpload(null)}
        />
      </Box>
    </>
  );
};
