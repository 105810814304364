import { $connection, $network } from "state/connection";
import { StoreValue, attach, createEffect } from "effector";

import { $wallet } from "state/wallet";
import { EUploadProgress } from "sdk/nft/uploadJson2Arweave";
import { IStoreConfig } from "./types";
import { initStore } from "sdk/metaplex";

export interface IUploadJSON2ArweaveParams {
  json: IStoreConfig;
  files: File[];
  updateProgress?: (status: EUploadProgress | null) => void;
  sellerFeeBasisPoints: number;
}

export interface IMintArweaveSource {
  connection: StoreValue<typeof $connection>;
  network: StoreValue<typeof $network>;
  wallet: StoreValue<typeof $wallet>;
}

export type IUploadJSON2ArweaveParamsWithSource = IUploadJSON2ArweaveParams &
  IMintArweaveSource;

export const initStoreFx = attach({
  effect: createEffect(
    async ({
      json,
      files,
      updateProgress,
      connection,
      wallet,
      network,
      sellerFeeBasisPoints,
    }: IUploadJSON2ArweaveParamsWithSource) => {
      if (!wallet) {
        throw new Error("Wallet wasn't connected");
      }

      const store = await initStore({
        sellerFeeBasisPoints,
        connection,
        wallet,
        network,
        json,
        files,
        updateProgress,
      });
      return store;
    }
  ),
  source: {
    connection: $connection,
    network: $network,
    wallet: $wallet,
  },
  mapParams: (params: IUploadJSON2ArweaveParams, sources) => ({
    ...sources,
    ...params,
  }),
});
