import { Flex } from "@chakra-ui/layout";
import { WalletAddress } from "components/WalletAddress";
import React from "react";
import { ArtworkCardColumn } from "../ArtworkCardColumn";
import { artworkCardStateProps } from "./artworkCardStateProps";

interface Props {
  walletAddress: string;
}

export const ArtworkCardOwnedState: React.FC<Props> = ({ walletAddress }) => {
  return (
    <Flex {...artworkCardStateProps}>
      <ArtworkCardColumn title="OWNED BY">
        <WalletAddress address={walletAddress} enableCopying />
      </ArtworkCardColumn>
    </Flex>
  );
};
