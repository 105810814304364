import { combine } from "effector";
import { useStore } from "effector-react";
import { useCallback, useMemo, useState } from "react";
import {
  $pendingArtworks,
  $storeArtworks,
  IArt,
  $profileArtworks,
  loadStoreArtworksFx,
} from "state/artworks";
import {
  $adminSales,
  $pendingSales,
  fetchStoreSalesFx,
  isActiveSale,
  ISale,
  isEndedSale,
  isInstantSale,
  isSale,
} from "state/sales";
import { $store } from "state/store";
import { createEntry } from "state/utils";
import { endSaleFx } from "state/sales/AH/endSaleFx";
import { TabsConfig } from "hooks/useTabs";
import { closeMarketAndWithdrawFx } from "./effects/closeMarketAndWithdrawFx";
import { profileArtworksReduceFn } from "./profileArtworksReduceFn";

export type ViewMode = "list" | "grid";

export enum ActionType {
  CloseMarket,
  Withdraw,
  Claim,
}

export const $progress = createEntry<{
  type?: ActionType;
  isVisible: boolean;
  title?: string;
  subtitle?: string;
  claimedImg?: string;
}>({
  isVisible: false,
});

export function useLocalState() {
  const isArtworksLoading = useStore($pendingArtworks);
  const isSalesLoading = useStore($pendingSales);
  const store = useStore($store);
  const progress = useStore($progress.$node);
  const [selectedArtwork, setSelectedArtwork] = useState<IArt | null>(null);
  const [error, setError] = useState<string>();

  const isLoading = isArtworksLoading || isSalesLoading;

  const hideModal = useCallback(() => {
    setSelectedArtwork(null);
  }, [setSelectedArtwork]);

  const handleSell = useCallback(
    (art: IArt) => {
      setSelectedArtwork(art);
    },
    [setSelectedArtwork]
  );

  const handleError = (error: unknown) => {
    const message =
      (error instanceof Error && error.message) || "Unknown error occurred.";
    setError(message);
  };
  const resetError = () => setError(undefined);

  const onEndSale = useCallback(
    async (sale: ISale, metadata: string) => {
      try {
        $progress.set({
          type: ActionType.CloseMarket,
          isVisible: true,
          ...MODAL_COPY[ActionType.CloseMarket],
        });

        return await (isInstantSale(sale)
          ? endSaleFx({ sale })
          : closeMarketAndWithdrawFx({ market: sale.id, metadata }));
      } catch (e) {
        handleError(e);
        $progress.set({ isVisible: false });
      } finally {
        await fetchStoreSalesFx();
        await loadStoreArtworksFx();
        $progress.set({ isVisible: false });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [$progress, fetchStoreSalesFx]
  );

  const tabs: TabsConfig<IArt | ISale> = useMemo(
    () => [
      { id: "all", title: "All" },
      {
        id: "live",
        accessor: (item) => isSale(item) && isActiveSale(item),
        title: "Live",
      },
      {
        id: "ended",
        accessor: (item) => isSale(item) && isEndedSale(item),
        title: "Ended",
      },
    ],
    []
  );

  const $allItems = combine(
    $adminSales,
    $storeArtworks,
    $profileArtworks,
    profileArtworksReduceFn
  );

  return {
    sales: $allItems,
    onCloseMarket: onEndSale,
    hideModal,
    handleSell,
    selectedArtwork,
    setSelectedArtwork,
    form: store,
    isLoading,
    progress,
    error,
    resetError,
    tabs,
  };
}

export const MODAL_COPY: Record<
  ActionType,
  { title: string; subtitle: string }
> = {
  [ActionType.CloseMarket]: {
    title: "Remove token from the sale",
    subtitle:
      "After you approve the transaction with your wallet, the sale will be stopped.",
  },
  [ActionType.Withdraw]: {
    title: "Withdraw is in progress",
    subtitle:
      "After you approve the transaction with your wallet, SOL will be transferred to wallets.",
  },
  [ActionType.Claim]: {
    title: "Claiming token to wallet",
    subtitle:
      "After you approve the transaction with your wallet, NFT will be added to your wallet.",
  },
};
