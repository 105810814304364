import {
  Box,
  Center,
  Flex,
  HStack,
  Heading,
  Image,
  Stack,
  StyleProps,
  Text,
  VStack,
} from "@chakra-ui/react";

import { $store } from "state/store";
import { $user } from "state/wallet";
import { Circle } from "@chakra-ui/layout";
import { FiRepeat } from "react-icons/fi";
import React from "react";
import imgPhantom from "./images/phantom.png";
import imgPhantomIcon from "./images/phantom-icon.png";
import imgPhantomWallet from "./images/phantom-wallet.png";
import oddKeyLogo from "views/StorePage/images/odd_key_logo.png";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { useStore } from "effector-react";
import { ConnectWalletButton } from "components/ConnectWallet";

interface Props extends StyleProps {
  variant?: "page" | "modal";
}

export const HowToBuy: React.FC<Props> = ({ variant = "modal", ...props }) => {
  const { mdUp, lgUp } = useCustomBreakpoints();
  const user = useStore($user);
  const store = useStore($store);

  const isModal = variant === "modal";
  const containerPadding = mdUp ? 12 : 6;

  return (
    <Center
      layerStyle={isModal ? "base" : undefined}
      p={isModal ? containerPadding : 0}
      {...props}
    >
      <Stack
        direction={lgUp ? "row" : "column"}
        spacing={isModal ? 12 : 8}
        alignItems="flex-start"
      >
        <VStack
          spacing={6}
          maxW="352px"
          flexBasis="352px"
          layerStyle={isModal ? undefined : "base"}
        >
          <Center h="240px" layerStyle="base" bgColor="whiteAlpha.100">
            <Image src={imgPhantom} />
          </Center>
          <VStack spacing={2} alignSelf="start" alignItems="start">
            <Heading variant="h5">Create a SOL wallet.</Heading>
            <Text variant="body-short" color="whiteAlpha.700">
              SOL is the cryptocurrency we use for purchases on OddKey. To keep
              your SOL safe, you’ll need a crypto wallet—we recommend using one
              called <a href="https://phantom.app/">Phantom</a>. Just head to Phantom’s site (<a href="https://phantom.app/">https://phantom.app/</a>), install the Chrome
              extension, and create an account.
            </Text>
          </VStack>
        </VStack>

        <VStack
          spacing={6}
          maxW="352px"
          flexBasis="352px"
          layerStyle={isModal ? undefined : "base"}
        >
          <Center h="240px" layerStyle="base" bgColor="whiteAlpha.100">
            <Image src={imgPhantomWallet} />
          </Center>
          <VStack spacing={2} alignSelf="start" alignItems="start">
            <Heading variant="h5">Add funds to your wallet.</Heading>
            <Text variant="body-short" color="whiteAlpha.700">
              To fund your wallet, you’ll need to purchase SOL tokens. Open your
              wallet, tap “Deposit SOL”, and copy your wallet address. You can
              send the SOL from your exchange to your wallet address.
            </Text>
          </VStack>
        </VStack>

        <Flex flexDir="column" layerStyle={isModal ? undefined : "base"}>
          <VStack spacing={6} maxW="352px" flexBasis="352px">
            <Center
              h="240px"
              layerStyle="base"
              bgColor="whiteAlpha.100"
              w="full"
            >
              <HStack spacing={6}>
                <Circle bgColor="whiteAlpha.50" size="84px">
                  <Image src={imgPhantomIcon} w="50px" h="50px" />
                </Circle>
                <Box color="whiteAlpha.500">
                  <FiRepeat />
                </Box>
                <Circle bgColor="whiteAlpha.50" size="84px">
                  <Image src={store?.logoImg || oddKeyLogo} w="50px" h="50px" />
                </Circle>
              </HStack>
            </Center>
            <VStack spacing={2} alignSelf="start" alignItems="start">
              <Heading variant="h5">
                Connect your wallet to purchase a drop.
              </Heading>
              <Text variant="body-short" color="whiteAlpha.700">
              To connect your wallet, tap “Connect Wallet” here on the site. 
              Select the Phantom option, and your wallet will connect. 
              After that, you can start purchasing NFTs. 
              If you need additional assistance, staff can help you in the official <a href="https://discord.gg/mcfarlane">Discord</a>
              </Text>
            </VStack>
          </VStack>

          {user === null && (
            <Box
              alignSelf={lgUp ? "flex-end" : "flex-start"}
              mt={mdUp ? 7 : 12}
            >
              <ConnectWalletButton />
            </Box>
          )}
        </Flex>
      </Stack>
    </Center>
  );
};
