import { AuctionHouseProgram } from "@metaplex-foundation/mpl-auction-house";
import { AuctionHouse } from "@metaplex-foundation/mpl-auction-house/dist/src/generated/accounts";
import {
  createPrintListingReceiptInstruction,
  createSellInstruction,
} from "@metaplex-foundation/mpl-auction-house/dist/src/generated/instructions";
import {
  Connection,
  PublicKey,
  SYSVAR_INSTRUCTIONS_PUBKEY,
} from "@solana/web3.js";
import { findMetadataAddress } from "sdk/nft";
import { loadAccountAndDeserialize } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";
import { excludesFalsy } from "utils/excludeFalsy";
import { moveTokenToAssociatedToken } from "./moveTokenToAssociatedToken";
import { topupFeeAccount } from "./topupFeeAccount";

export interface SellProps {
  connection: Connection;
  seller: PublicKey;
  auctionHouse: PublicKey;
  resourceMint: PublicKey;
  price: number;
  size?: number;
}

export const createSellTransaction = async ({
  connection,
  seller: wallet,
  auctionHouse,
  resourceMint,
  price,
  size = 1,
}: SellProps) => {
  const { treasuryMint, authority, auctionHouseFeeAccount } =
    await loadAccountAndDeserialize(connection, AuctionHouse, auctionHouse);

  const topupFeeIx = wallet.equals(authority)
    ? await topupFeeAccount({
        connection,
        payer: wallet,
        auctionHouseFeeAccount,
      })
    : undefined;

  const [resourceMetadata] = await findMetadataAddress(resourceMint);
  const [resourceToken] =
    await AuctionHouseProgram.findAssociatedTokenAccountAddress(
      resourceMint,
      wallet
    );

  const associatedIxs = await moveTokenToAssociatedToken({
    connection,
    wallet,
    mint: resourceMint,
    associatedToken: resourceToken,
  });

  const [programAsSigner, programAsSignerBump] =
    await AuctionHouseProgram.findAuctionHouseProgramAsSignerAddress();

  const [tradeState, tradeStateBump] =
    await AuctionHouseProgram.findTradeStateAddress(
      wallet,
      auctionHouse,
      resourceToken,
      treasuryMint,
      resourceMint,
      price,
      size
    );
  const [freeTradeState, freeTradeStateBump] =
    await AuctionHouseProgram.findTradeStateAddress(
      wallet,
      auctionHouse,
      resourceToken,
      treasuryMint,
      resourceMint,
      0,
      size
    );

  const sellIx = createSellInstruction(
    {
      wallet,
      auctionHouse,
      tokenAccount: resourceToken,
      metadata: resourceMetadata,
      authority,
      auctionHouseFeeAccount,
      sellerTradeState: tradeState,
      freeSellerTradeState: freeTradeState,
      programAsSigner,
    },
    {
      tradeStateBump,
      freeTradeStateBump,
      programAsSignerBump,
      buyerPrice: price,
      tokenSize: size,
    }
  );

  const [receipt, receiptBump] =
    await AuctionHouseProgram.findListingReceiptAddress(tradeState);

  const receiptIx = createPrintListingReceiptInstruction(
    {
      receipt,
      bookkeeper: wallet,
      instruction: SYSVAR_INSTRUCTIONS_PUBKEY,
    },
    { receiptBump }
  );

  const tx = new TransactionsBatch({
    instructions: [...associatedIxs, topupFeeIx, sellIx, receiptIx].filter(
      excludesFalsy
    ),
  });

  return { tradeState, freeTradeState, receipt, tx };
};
