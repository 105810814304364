import { Flex, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";

import { ISale } from "state/sales";
import { SaleLockChip } from "./SaleLockChip";
import { TimeEntry } from "components/TimeEntry";
import { TitledBlock } from "components/TitledBlock";
import { WalletAddress } from "components/WalletAddress";
import dayjs from "dayjs";
import { fetchProfileArtworksFx } from "state/artworks";
import { useGating } from "hooks/useGating";
import { useTick } from "hooks/useTick";

interface IEarlyAccessProps {
  sale: ISale;
}

export const EarlyAccess: React.FC<IEarlyAccessProps> = ({
  sale,
}: IEarlyAccessProps) => {
  const { isEligible, pending, gate } = useGating(sale);

  useTick(gate ? 1 : undefined);

  // TODO: load art & name of gate.mint

  useEffect(() => {
    fetchProfileArtworksFx();
  }, []);

  if (!gate) return null;

  const isPublic = gate.publicSaleStart
    ? gate.publicSaleStart <= dayjs().unix()
    : false;
  if (isPublic) return null;

  return (
    <VStack
      alignItems="left"
      maxW="100%"
      p={6}
      border="1px solid"
      borderColor={isEligible ? "green.500" : "whiteAlpha.500"}
      borderRadius={12}
      spacing={8}
    >
      <VStack alignItems="left" spacing={4}>
        <HStack spacing={4}>
          <Heading variant="h5">Whitelist sale</Heading>
          <SaleLockChip locked={!isEligible} pending={pending} />
        </HStack>
        <Text color="whiteAlpha.600">
          Access to the whitelist sale is limited to buyers with an item from
          the required collection in their wallet.
        </Text>
      </VStack>
      <Flex>
        <TitledBlock title="Ends in" w="full">
          <TimeEntry
            countdown={gate.publicSaleStart}
            hideZeroUnits
            noUnitColor
            variant="bold"
          />
        </TitledBlock>
        <TitledBlock title="Gated by" w="full">
          <WalletAddress address={gate.mint} noAvatar enableCopying />
        </TitledBlock>
      </Flex>
    </VStack>
  );
};
