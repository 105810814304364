import dayjs from "dayjs";
import { useEffect, useState } from "react";

export const useTick = (seconds?: number) => {
  const [time, setTime] = useState(dayjs().unix());

  useEffect(() => {
    if (seconds === undefined) return;
    const interval = setInterval(() => setTime(dayjs().unix()), seconds * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  return time;
};
