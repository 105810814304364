import { MenuItem } from "@chakra-ui/react";

interface Props<T> {
  onSelect?: (unit: T) => void;
  children?: T;
}

export const UnitItem = <T,>({ onSelect, children }: Props<T>) => {
  const handleSelect = () => {
    onSelect && children && onSelect(children);
  };

  return (
    <MenuItem onClick={handleSelect} textTransform="capitalize">
      {children}
    </MenuItem>
  );
};
