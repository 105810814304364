import { Flex, FlexProps, HStack, Spacer } from "@chakra-ui/react";
import React, { useState } from "react";

import { $storeId } from "state/store";
import { Image } from "@chakra-ui/image";
import { Link } from "react-router-dom";
import oddKeyLogo from "views/StorePage/images/odd_key_logo.png";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { usePaths } from "routes";
import { useScrollTrigger } from "../Layout/useScrollTrigger";
import { useStore } from "effector-react";

interface Props {
  transparent?: boolean;
  props?: FlexProps;
  children: React.ReactNode;
  noUnderline?: boolean;
}

export const Navbar = React.memo<Props>(
  ({ transparent, children, props, noUnderline }) => {
    const { mdUp } = useCustomBreakpoints();
    const paths = usePaths();
    const storeId = useStore($storeId);
    const [isIntersecting, setIsIntersecting] = useState(false);

    useScrollTrigger(window, { onTrigger: setIsIntersecting });

    return (
      <Flex
        w="full"
        as="header"
        pl={mdUp ? 8 : 6}
        pr={mdUp ? 10 : 6}
        height={16}
        bgColor={transparent && !isIntersecting ? "transparent" : "gray.900"}
        transition="background-color .3s ease"
        top={0}
        right={0}
        left="auto"
        width="full"
        pos="fixed"
        zIndex="sticky"
        borderBottom={
          noUnderline
            ? "unset"
            : "1px solid var(--chakra-colors-whiteAlpha-100)"
        }
        {...props}
      >
        <HStack as={Link} to={storeId ? paths.store() : paths.home()}>
          <Image src={oddKeyLogo} h={12} />
        </HStack>
        <Spacer />
        {children}
      </Flex>
    );
  }
);
