import {
  Center,
  Flex,
  FlexProps,
  Heading,
  Text,
  CenterProps,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";

import { ModalFootnote } from "../ModalFootnote";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

export interface FlowStatusProps extends FlexProps {
  statusIcon?: ReactNode;
  title?: string;
  subtitle?: string | ReactNode;
  actions?: ReactNode;
  noteIcon?: ReactNode;
  noteText?: ReactNode;
  centerProps?: CenterProps;
}

export const FlowStatus: FC<FlowStatusProps> = ({
  statusIcon,
  title,
  subtitle,
  actions,
  noteIcon,
  noteText,
  centerProps,
  children,
  ...props
}) => {
  const { mdUp } = useCustomBreakpoints();

  return (
    <Flex direction="column" flexGrow={1} {...props}>
      <Center
        w="full"
        minH="250px"
        flexDirection="column"
        flexGrow={1}
        px={mdUp ? 16 : 0}
        my={mdUp ? 14 : 6}
        {...centerProps}
      >
        {statusIcon}
        <Heading variant="h4" mt={9} mb={2} textAlign="center">
          {title}
        </Heading>

        {typeof subtitle === "string" ? (
          <Text
            overflowWrap="break-word"
            wordBreak="break-word"
            align="center"
            color="whiteAlpha.700"
            fontSize="lg"
          >
            {subtitle}
          </Text>
        ) : (
          subtitle
        )}

        <Flex mt={12}>{actions}</Flex>
        {children}
      </Center>
      <ModalFootnote icon={noteIcon} text={noteText} />
    </Flex>
  );
};
