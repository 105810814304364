import { Flex, FlexProps, Text, TextProps } from "@chakra-ui/react";

import { ReactNode } from "react";

export interface ITitledBlockProps extends FlexProps {
  title: string;
  subtitle?: string | string[];
  content?: ReactNode;
  size?: "xs" | "sm" | "md" | "lg";
  titleProps?: TextProps;
  subtitleProps?: TextProps;
  bright?: boolean;
}

const spacing = {
  xs: 1,
  sm: 2,
  md: 4,
  lg: 6,
};

export const TitledBlock: React.FC<ITitledBlockProps> = ({
  title,
  subtitle,
  content,
  children,
  size = "md",
  titleProps,
  subtitleProps,
  bright = false,
  ...props
}) => {
  const isXs = size === "xs";

  const subtitleString = Array.isArray(subtitle)
    ? subtitle.join("\n")
    : subtitle;

  return (
    <Flex align="stretch" direction="column" {...props}>
      <Text
        variant={isXs ? "small" : "label"}
        mb={subtitleString ? 1 : spacing[size]}
        color={bright ? "white" : undefined}
        {...titleProps}
      >
        {title}
      </Text>
      {subtitleString && (
        <Text
          variant="subtitle"
          color="whiteAlpha.500"
          whiteSpace="pre-wrap"
          mb={spacing[size]}
          {...subtitleProps}
        >
          {subtitleString}
        </Text>
      )}
      {content ? content : children}
    </Flex>
  );
};
