import { ITab } from "components/ArtworksList";
import { combine, Store } from "effector";
import { useStore } from "effector-react";
import { useCallback, useEffect, useMemo } from "react";

import { createEntry } from "state/utils";
import { useSearchParams } from "react-router-dom";

export type TabsConfig<T> = Array<{
  accessor?: (item: T) => boolean;
  id: string;
  defaultSelected?: boolean;
  title: string;
}>;

export const useTabs = <T>($store: Store<T[]>, config: TabsConfig<T> = []) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabTitle = searchParams.get("tab");

  const { $map, $tab, $tabs } = useMemo(() => {
    const { id = "default" } =
      config.find(({ defaultSelected }) => !!defaultSelected) ||
      config[0] ||
      {};

    const $tab = createEntry(id);

    const $map = $store.map((list) => {
      const mapping: Record<string, T[]> = {};

      config.forEach(({ id }) => {
        mapping[id] = [];
      });

      list.forEach((item) => {
        config.forEach(({ accessor, id }) => {
          if (!accessor || (accessor && accessor(item))) {
            mapping[id].push(item);
          }
        });
      });

      return mapping;
    });

    const $tabs = $map.map((mapping) =>
      config.map(
        ({ id, title }): ITab => ({
          id,
          title,
          count: mapping[id].length,
        })
      )
    );

    return { $map, $tab, $tabs };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const $filter = combine($map, $tab.$node, (mapping, tab) => mapping[tab]);

  const currentTab = useStore($tab.$node);

  const onTabChange = useCallback(
    (index: number): void => {
      const tabId = config[index].id;
      setSearchParams({ tab: tabId });
      $tab.set(config[index].id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [$tab.set]
  );

  useEffect(() => {
    if (tabTitle) onTabChange(config.findIndex((tab) => tab.id === tabTitle));
  }, [onTabChange, tabTitle, config]);

  const currentTabIndex = config.findIndex(({ id }) => id === currentTab);

  return {
    currentTab,
    currentTabIndex: currentTabIndex >= 0 ? currentTabIndex : 0,
    items: useStore($filter) || [],
    onTabChange,
    tabs: useStore($tabs),
  };
};
