import { AuctionHouseProgram } from "@metaplex-foundation/mpl-auction-house";
import {
  AuctionHouse,
  createCancelInstruction,
  createCancelListingReceiptInstruction,
} from "@metaplex-foundation/mpl-auction-house/dist/src/generated";
import {
  Connection,
  PublicKey,
  SYSVAR_INSTRUCTIONS_PUBKEY,
} from "@solana/web3.js";
import { loadAccountAndDeserialize, toPubkey } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";
import { IInstantSale } from "state/sales";
import { solToLamports } from "utils/lamportsToSol";

export interface CreateCancelTransactionProps {
  auctionHouseId: PublicKey;
  connection: Connection;
  sale: IInstantSale;
  tokenSize?: number;
  wallet: PublicKey;
}

export const createCancelTransaction = async ({
  auctionHouseId,
  connection,
  sale,
  tokenSize = 1,
  wallet,
}: CreateCancelTransactionProps) => {
  const price = solToLamports(sale.price);
  const tokenAccount = toPubkey(sale.artwork.token);
  const tokenMint = toPubkey(sale.artwork.mint);

  const auctionHouse = await loadAccountAndDeserialize(
    connection,
    AuctionHouse,
    auctionHouseId
  );

  const [tradeState] = await AuctionHouseProgram.findTradeStateAddress(
    toPubkey(sale.refs.seller),
    auctionHouseId,
    tokenAccount,
    auctionHouse.treasuryMint,
    tokenMint,
    price,
    tokenSize
  );

  const cancelIx = createCancelInstruction(
    {
      auctionHouse: auctionHouseId,
      auctionHouseFeeAccount: auctionHouse.auctionHouseFeeAccount,
      authority: auctionHouse.authority,
      tokenAccount: toPubkey(sale.artwork.token),
      tokenMint: toPubkey(sale.artwork.mint),
      tradeState,
      wallet,
    },
    {
      buyerPrice: price,
      tokenSize,
    }
  );

  const cancelListingReceiptIx = createCancelListingReceiptInstruction({
    receipt: toPubkey(sale.id),
    instruction: SYSVAR_INSTRUCTIONS_PUBKEY,
  });

  const tx = new TransactionsBatch({
    instructions: [cancelIx, cancelListingReceiptIx],
  });

  return { tradeState, tx };
};
