// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ArtworkCard, ArtworkListItem } from "components/Artwork";
import { ArtworkListCreateButton, ArtworksList } from "components/ArtworksList";
import { Button, Flex } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import {
  isCandyMachine,
  isFixedPrice,
  isInstantSale,
  startStoreFetch,
  stopStoreFetch,
} from "state/sales";

import { AddressButton } from "components/buttons/AddressButton";
import { ArtworkCardInstantSaleState } from "components/Artwork/ArtworkCard/states/ArtworkCardInstantSaleState";
import { BlurredBackgroundImage } from "./BlurredBackgroundImage";
import { Carousel } from "components/Carousel";
import { CongratulationsModal } from "./CongratulationsModal";
import { CustomizedHero } from "components/Hero/Сustomized";
import { ExternalSiteButton } from "components/buttons/ExternalSiteButton";
import { Layout } from "components/Layout";
import { MobileBackgroundImage } from "./MobileBackgroundImage";
import { WalletNotConnectedPageContent } from "./WalletNotConnectedPageContent";
import { heroContent } from "./constants/heroContent";
import { smartButtonProps } from "utils/smartButtonProps";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { useLocalState } from "./StorePage.state";
import { useNavigate } from "react-router-dom";
import { usePaths } from "routes";
import { useStoreMap } from "effector-react";
import { createStore } from "effector";
import dayjs from "dayjs";

export const StorePage: FC = () => {
  const navigate = useNavigate();
  const paths = usePaths();
  const { mdUp } = useCustomBreakpoints();

  const { form, sales, pendingSales, tabs, isSeller } = useLocalState();
  const salesLength = useStoreMap(sales, (sales) => sales.length);

  const [loading, setLoading] = useState(true);

  const [bgIndex, setBgIndex] = useState(0);
  const storeUrl = paths.createArtwork();

  const handleCardClick = useCallback((id: string, status: string) => {
    if (id === "ApmWwFN8e734pysjJD1hWEbQmR1mNDYjAxLLRPsuHfkS") {
      window.location.href = `https://monolith.oddkey.com/`;
      return;
    }else if (id === "5xEG43Q4ZWwdjzgNg7yXoBnN5dbRW2uNGg656X178U6D") {
      window.location.href = `https://spawnmobile.oddkey.com/`;
      return;
    }else if (id === "BUi963DpGNmeLbrzNk6wpLEbuWgjm8VzyEDfVSnStRT") {
      window.location.href = `https://to3.oddkey.com/`;
      return;
    }else if (id === "7dJmeJGqee15cNroYHpbAZ3daBMqjyAzJBpx6iAQVJ5q") {
      window.location.href = `https://spawn150.oddkey.com/`;
      return;
    }
    navigate(`/${status}/${id}`);
  }, []);

  useEffect(() => {
    if (!pendingSales) setLoading(false);
  }, [pendingSales]);

  useEffect(() => {
    startStoreFetch();
    return () => stopStoreFetch();
  }, []);

  const onEditStoreClick = () => {
    navigate(paths.adminStorefront());
  };

  const $newSales = createStore([
    {
      id: "7dJmeJGqee15cNroYHpbAZ3daBMqjyAzJBpx6iAQVJ5q",
      tab: "sale",
      artwork: {
        title: "Spawn #150",
        image:
          "https://shdw-drive.genesysgo.net/Hr6yhqdhJu2TNyx3X6N59CzUdWQDKX29janX7GJHr6kA/collection_still.png",
        prints: { edition: 500, maxSupply: 500, supply: 500 },
      },
      startDate: dayjs().subtract(1, "day").unix(),
      price: 1.5,
      liveAtDate: dayjs(new Date(Date.UTC(2024, 9, 6, 15))).unix(),
      type: "candy-machine",
      endDate: dayjs(new Date(Date.UTC(2024, 9, 13, 15))).unix(),
      stopAtDate: dayjs(new Date(Date.UTC(2024, 9, 13, 15))).unix(),
    },
    {
      id: "BUi963DpGNmeLbrzNk6wpLEbuWgjm8VzyEDfVSnStRT",
      tab: "sale",
      artwork: {
        title: "Todd Originals 3",
        image:
          "https://shdw-drive.genesysgo.net/G5cnbdcubDSwwKiw71rbEU9an6EBaPA4Kxn8SoHyLC6v/collection.gif",
        prints: { edition: 300, maxSupply: 300, supply: 300 },
      },
      startDate: dayjs().subtract(1, "day").unix(),
      price: 1.5,
      liveAtDate: dayjs(new Date(Date.UTC(2024, 3, 4, 17))).unix(),
      type: "candy-machine",
      endDate: dayjs(new Date(Date.UTC(2024, 2, 12, 17))).unix(),
      stopAtDate: dayjs(new Date(Date.UTC(2024, 2, 12, 17))).unix(),
    },
    {
      id: "5xEG43Q4ZWwdjzgNg7yXoBnN5dbRW2uNGg656X178U6D",
      tab: "sale",
      artwork: {
        title: "Spawnmobile Puzzle",
        image:
          "https://shdw-drive.genesysgo.net/8cxYToZSUu9EVK5mXdX2Cw2ZMyPaxLMyBaJMfmFubAU4/collection.png",
        prints: { edition: null, maxSupply: undefined, supply: 500 },
      },
      startDate: dayjs().subtract(1, "day").unix(),
      price: 1.5,
      liveAtDate: dayjs(new Date(Date.UTC(2024, 2, 1, 17))).unix(),
      type: "candy-machine",
      endDate: dayjs(new Date(Date.UTC(2024, 2, 12, 17))).unix(),
      stopAtDate: dayjs(new Date(Date.UTC(2024, 2, 12, 17))).unix(),
    },
    {
      id: "ApmWwFN8e734pysjJD1hWEbQmR1mNDYjAxLLRPsuHfkS",
      tab: "sale",
      artwork: {
        title: "Monolith - Spears x McFarlane",
        image:
          "https://nftstorage.link/ipfs/bafybeihcgrs25tfsrxn37mu223kg2nivqu6gh5wxtrg3h6kizv3k4vdpom",
        prints: { edition: 500, maxSupply: 500, supply: 500 },
      },
      startDate: dayjs().subtract(1, "day").unix(),
      price: 1.5,
      liveAtDate: dayjs(new Date(Date.UTC(2023, 12, 5, 17))).unix(),
      type: "candy-machine",
      endDate: dayjs(new Date(Date.UTC(2023, 12, 12, 17))).unix(),
      stopAtDate: dayjs(new Date(Date.UTC(2023, 12, 12, 17))).unix(),
    },
    {
      id: "CvPWdwRwmH11d1HFCAdScvZoosmffqvnvaeozAy9mXky",
      tab: "sale",
      artwork: {
        title: "Todd's Originals 2",
        image:
          "https://nftstorage.link/ipfs/bafybeibk363bragibgtcnqdvorleligu5i4cvq6crednoinm7geqrcuwh4",
        prints: { edition: 240, maxSupply: 240, supply: 240 },
      },
      startDate: dayjs().subtract(1, "day").unix(),
      price: 2,
      liveAtDate: dayjs(new Date(Date.UTC(2023, 9, 4, 17))).unix(),
      type: "candy-machine",
      endDate: dayjs(new Date(Date.UTC(2023, 9, 9, 17))).unix(),
      stopAtDate: dayjs(new Date(Date.UTC(2023, 9, 9, 17))).unix(),
    },
    {
      id: "4gcfWBpBCeeXRaro1L6RGSWbhSWRedxfFMwaMRVoUzYS",
      tab: "past",
      artwork: {
        title: "Spawn #21",
        image:
          "https://nftstorage.link/ipfs/bafybeigoo2gx4wnas6kddgqd2rrnlzuudkfyakkobsly7sya4tyrcodoki",
        prints: { edition: 500, maxSupply: 500, supply: 500 },
      },
      startDate: dayjs().subtract(1, "day").unix(),
      price: 2,
      liveAtDate: dayjs(new Date(2023, 6, 14, 18)).unix(),
      type: "candy-machine",
      endDate: dayjs(new Date(2023, 6, 14, 18)).unix(),
      stopAtDate: dayjs(new Date(2023, 6, 14, 18)).unix(),
    },
    {
      id: "8ujXbxf3XxumCorJ5PJJFQN8NrBYAcfQzASNjnXHjDRN",
      tab: "past",
      artwork: {
        title: "Todd's Originals 1",
        image:
          "https://nftstorage.link/ipfs/bafybeicn5mh775ydhi23ga5y5ovj3gm3dcttqspybr6ifdnuvekvsfxd6e",
        prints: { edition: 100, maxSupply: 100, supply: 100 },
      },
      startDate: dayjs().subtract(1, "day").unix(),
      price: 1.5,
      liveAtDate: dayjs(new Date(2023, 4, 13, 18)).unix(),
      type: "candy-machine",
      endDate: dayjs(new Date(2023, 4, 13, 18)).unix(),
      stopAtDate: dayjs(new Date(2023, 4, 13, 18)).unix(),
    },
    {
      id: "A3T2MZ42pKk7ACzHPeRutaqKGRtJFehJgfroiEjJc7aM",
      tab: "past",
      artwork: {
        title: "Gunslinger 1",
        image:
          "https://nftstorage.link/ipfs/bafybeifslywrdiaqpuht6pz34codkeu2oyg2pfialxjwkfllbpor4cxoxy",
        prints: { edition: 666, maxSupply: 666, supply: 666 },
      },
      startDate: dayjs().subtract(1, "day").unix(),
      price: 1.5,
      liveAtDate: dayjs(new Date(2023, 2, 7, 18)).unix(),
      type: "candy-machine",
      endDate: dayjs(new Date(2023, 2, 30, 18)).unix(),
      stopAtDate: dayjs(new Date(2023, 2, 30, 18)).unix(),
    },
    {
      id: "EMdGH5Ahm1KASNCqGcG1Qc9hSCga6bqUj2Fze2ruyZhV",
      tab: "past",
      artwork: {
        title: "Todd McFarlane x Christmas",
        image:
          "https://bafybeifxjvjq6egb5clakdcukxfv5g4k4brc6sb26n2puf2vdj23ybe3sa.ipfs.nftstorage.link/",
        prints: { edition: 365, maxSupply: 365, supply: 365 },
      },
      startDate: dayjs().subtract(1, "day").unix(),
      price: 1.5,
      liveAtDate: dayjs(new Date(2022, 12, 29, 18)).unix(),
      type: "candy-machine",
      endDate: dayjs(new Date(2022, 12, 30, 18)).unix(),
      stopAtDate: dayjs(new Date(2022, 12, 30, 18)).unix(),
    },
    {
      id: "6iPr93izLyGpxfDfErqnmZcAvcjKWnAMCzfgUjoKPWTP",
      tab: "past",
      artwork: {
        title: "J. Scott Campbell Spawn Cover Drop 1",
        image:
          "https://nftstorage.link/ipfs/bafybeidjzzegxsx7xqxek26wqtf2li47oeviwvv4ia5fzrpg6qzf5vd4ke",
        prints: { edition: 666, maxSupply: 666, supply: 666 },
      },
      startDate: dayjs().subtract(1, "day").unix(),
      price: 1.5,
      liveAtDate: dayjs(new Date(2022, 10, 14, 18)).unix(),
      type: "candy-machine",
      endDate: dayjs(new Date(2022, 10, 15, 18)).unix(),
      stopAtDate: dayjs(new Date(2022, 10, 15, 18)).unix(),
    },
    {
      id: "5hXKkJy5eG1BkwfKn915Vc5m15tvGSHpXteLJCMshChn",
      tab: "past",
      artwork: {
        title: "Neon Future x Heavy Metal",
        image:
          "https://arweave.net/MwzbfwxfCUxsK3m_yaESJdFwaoezELrb08et53R-TVc",
        prints: { edition: 777, maxSupply: 777, supply: 777 },
      },
      startDate: dayjs().subtract(1, "day").unix(),
      price: 1,
      liveAtDate: dayjs(new Date(2022, 7, 29, 18)).unix(),
      type: "candy-machine",
      endDate: dayjs(new Date(2022, 7, 30, 18)).unix(),
      stopAtDate: dayjs(new Date(2022, 7, 30, 18)).unix(),
    },
    {
      id: "B76zNLbbcuXRQCJx3bh9zBgL2EPR3MAn82u7k92cei6p",
      tab: "past",
      artwork: {
        title: "Spawn #301 Record Breaker Cover",
        image:
          "https://arweave.net/SwdOuKf5hzq6COUB7cZFnPAYBS8stc-4e7-2Lh70NF0?ext=png",
        prints: { edition: 500, maxSupply: 500, supply: 500 },
      },
      startDate: dayjs().subtract(1, "day").unix(),
      price: 2,
      liveAtDate: dayjs(new Date(2022, 7, 29, 18)).unix(),
      type: "candy-machine",
      endDate: dayjs(new Date(2022, 7, 30, 18)).unix(),
      stopAtDate: dayjs(new Date(2022, 7, 30, 18)).unix(),
    },
    {
      id: "G6ifCoT3PZWT156oBtjmupGNT8Ss4LcAET9ybVW88UYx",
      tab: "past",
      artwork: {
        title: "Spawn #301 Tribute Cover",
        image:
          "https://arweave.net/7r3w0DY1IZlCYq5Wp9y0jwgVFtWyk-tJoWH61Bgm5Pk",
        prints: { edition: 1000, maxSupply: 1000, supply: 1000 },
      },
      startDate: dayjs().subtract(1, "day").unix(),
      price: 1,
      liveAtDate: dayjs(new Date(2022, 7, 29, 18)).unix(),
      type: "candy-machine",
      endDate: dayjs(new Date(2022, 7, 30, 18)).unix(),
      stopAtDate: dayjs(new Date(2022, 7, 30, 18)).unix(),
    },
  ]);
  const newSalesLength = useStoreMap(
    $newSales,
    ($newSales) => $newSales.length
  );

  return (
    <Layout transparentNavbar={mdUp} noUnderline>
      <CongratulationsModal />

      <BlurredBackgroundImage coverImg={heroContent[bgIndex].image} />
      <MobileBackgroundImage coverImg={heroContent[0].image} />

      {mdUp ? (
        <Carousel
          mb={2}
          slides={heroContent.map((item) => (
            <CustomizedHero
              key={item.title}
              imagePosition="right"
              withShadow
              image={item.image}
              title={item.title}
              text={item.text}
            >
              {item.buttonText && (
                <Button onClick={item.buttonAction} variant="primary">
                  {item.buttonText}
                </Button>
              )}
            </CustomizedHero>
          ))}
          onSlideChange={setBgIndex}
        />
      ) : (
        <CustomizedHero
          mb={4}
          image={heroContent[0].image || ""}
          title={heroContent[0].title}
          text={heroContent[0].text}
        >
          {isSeller && <Button onClick={onEditStoreClick}>Edit Store</Button>}
          <Flex>
            <AddressButton
              address={form?.owner || ""}
              prefix="Address"
              variant="tertiary"
              w={mdUp ? undefined : "full"}
            />
            <ExternalSiteButton url={form?.link} variant="tertiary" />
          </Flex>
        </CustomizedHero>
      )}

      {!salesLength && <WalletNotConnectedPageContent />}

      {!!newSalesLength && (
        <ArtworksList
          tabs={tabs}
          items={$newSales}
          renderGridItem={(item) => (
            <ArtworkCard
              key={item.id}
              artwork={item.artwork}
              onClick={() => handleCardClick(item.id, item.tab)}
            />
          )}
          renderListItem={(item) => (
            <ArtworkListItem
              key={item.artwork.id}
              artwork={item.artwork}
              variant="instant-buy"
            />
          )}
          hideActionButtons
          pending={loading}
          createButton={
            <ArtworkListCreateButton {...smartButtonProps(storeUrl)}>
              New Item
            </ArtworkListCreateButton>
          }
        />
      )}
    </Layout>
  );
};
