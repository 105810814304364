import { Store, StoreConfig } from "@metaplex-foundation/mpl-metaplex";
import { Cluster, Connection } from "@solana/web3.js";
import { createStoreTransaction as createAhStore } from "sdk/auction-house/actions/createStoreTransaction";
import { createUpdateAuctionHouse } from "sdk/auction-house/actions/createUpdateStoreTransaction";
import { createStoreTransaction as createFpStore } from "sdk/fps/actions/createStoreTransaction";
import {
  EUploadProgress,
  uploadFiles2Arweave,
} from "sdk/nft/uploadJson2Arweave";
import { checkAccount, toPubkey } from "sdk/share";
import { IStoreConfig } from "state/store";
import { Wallet } from "wallet";
import { sendTransactions } from "../transactions";
import { createStoreV2Transaction } from "./actions/createStoreV2Transaction";

export interface InitStoreProps {
  json: IStoreConfig;
  files: File[];
  updateProgress?: (status: EUploadProgress | null) => void;
  connection: Connection;
  wallet: Wallet;
  network: Cluster;
  sellerFeeBasisPoints: number;
}

export const initStore = async ({
  json,
  files,
  connection,
  wallet,
  updateProgress,
  network,
  sellerFeeBasisPoints,
}: InitStoreProps) => {
  const { auctionHouse: storeAhId, tx: createAhStoreTx } = json.storeAhId
    ? { auctionHouse: json.storeAhId, tx: undefined }
    : await createAhStore({
        wallet,
        sellerFeeBasisPoints,
      });

  const { store: storeFpsId, storeTx: createFpStoreTx } = json.storeFpsId
    ? { store: json.storeFpsId, storeTx: undefined }
    : createFpStore({
        wallet,
      });

  const existsAH = await checkAccount(connection, toPubkey(storeAhId));
  const existsFP = await checkAccount(connection, toPubkey(storeFpsId));

  const jsonWithStores: IStoreConfig = {
    ...json,
    storeAhId: storeAhId.toString(),
    storeFpsId: storeFpsId.toString(),
  };
  const { fileUri: settingsUri } = await uploadFiles2Arweave({
    connection,
    wallet,
    files,
    meta: JSON.stringify(jsonWithStores),
    network,
    mintKey: wallet.publicKey.toString(),
    progressChange: updateProgress,
  });

  const store = await Store.getPDA(wallet.publicKey);
  const config = await StoreConfig.getPDA(store);

  const storeTx = createStoreV2Transaction({
    admin: wallet.publicKey,
    store,
    config,
    isPublic: true,
    settingsUri,
  });

  const updateAHTx = createAhStoreTx
    ? undefined
    : await createUpdateAuctionHouse({
        connection,
        wallet,
        sellerFeeBasisPoints,
        auctionHouse: toPubkey(storeAhId),
      });

  await sendTransactions(connection, wallet, [
    storeTx,
    updateAHTx,
    !existsFP ? createFpStoreTx : undefined,
    !existsAH ? createAhStoreTx : undefined,
  ]);

  return { storeId: store.toString() };
};
