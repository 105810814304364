import { createEvent, sample } from "effector";
import { createCachedStore } from "state/cache";
import { readCachedStoreFx, writeCachedStoreFx } from "./cached";
import { loadStoreByAddressFx } from "./loadStoreByAddressFx";
import { IStore } from "./types";

const cachedStore = createCachedStore<IStore | null, string>({
  defaultValue: null,
  fetchFx: loadStoreByAddressFx,
  readCacheFx: readCachedStoreFx,
  writeCacheFx: writeCachedStoreFx,
});

export const { $store, $pending: $pendingStore } = cachedStore;

export const setStore = createEvent<string>();
export const unsetStore = createEvent();

sample({
  clock: setStore,
  target: cachedStore.init,
});
$store.on(unsetStore, () => null);

export const $storeOwner = $store.map((store) => store?.owner);
export const $storeId = $store.map((store) => store?.storeId);
export const $storeName = $store.map((store) => store?.name);
export const $hasStore = $store.map((store) => !!store);
