import { ComponentStyleConfig } from "@chakra-ui/react";

export const CloseButton: ComponentStyleConfig = {
  variants: {
    forms: {
      bg: "gray.800",
      boxShadow: " 0px 0px 24px rgba(26, 26, 26, 0.12)",
      color: "whiteAlpha.700",
      _hover: {
        bg: "gray.700",
      },
      _focus: {
        boxShadow: "none",
      },
    },
  },
  sizes: {
    lg: {
      h: 14,
      w: 14,
    },
  },
};
