import { FC } from "react";
import { ITitledBlockProps, TitledBlock } from "./TitledBlock";

// TODO: turn into TitleBlock variant
export const LabelBlock: FC<ITitledBlockProps> = ({ children, ...props }) => {
  return (
    <TitledBlock size="sm" titleProps={{ fontWeight: 600 }} {...props}>
      {children}
    </TitledBlock>
  );
};
