import {
  MdAudiotrack,
  MdOutlineImage,
  MdOutlinePlayCircle,
  MdOutlineViewInAr,
} from "react-icons/md";
import { FileType } from "components/MediaTypeSelector";
import { ToggleSelectOptions } from "components/ToggleSelect";

export const OPTIONS: ToggleSelectOptions<FileType> = [
  { id: FileType.IMAGE, title: "Image", Icon: MdOutlineImage },
  {
    id: FileType.VIDEO,
    title: "Video",
    Icon: MdOutlinePlayCircle,
  },
  { id: FileType.AUDIO, title: "Audio", Icon: MdAudiotrack },
  { id: FileType.VR, title: "3D", Icon: MdOutlineViewInAr },
];
