import { TitledBlock } from "components/TitledBlock";
import React from "react";

interface IPropertyCardProps {
  title: string;
  description?: string;
}

export const PropertyCard: React.FC<IPropertyCardProps> = ({
  title,
  description,
}: IPropertyCardProps) => {
  return (
    <TitledBlock
      size="xs"
      padding={4}
      border={"1px solid"}
      borderRadius={12}
      borderColor={"gray.600"}
      title={title}
    >
      {description}
    </TitledBlock>
  );
};
