import { useStore } from "effector-react";
import { useMemo } from "react";
import {
  ISale,
  isCandyMachine,
  isEndedSale,
  isInstantSale,
  isTimedAuction,
} from "state/sales";
import { $storeOwner } from "state/store";
import { $walletAddress } from "state/wallet";

export enum ActionType {
  BuyNft,
  WithdrawFunds,
  ClaimNFT,
  EndSale,
  SaleEndedPlate,
  VerifyAllowlist,
}

export const useActionButtonsSelector = (sale: ISale) => {
  const userAddress = useStore($walletAddress);
  const storeOwner = useStore($storeOwner);

  return useMemo(() => {
    if (isCandyMachine(sale)) {
      return ActionType.BuyNft;
    }

    const isEnded = isEndedSale(sale);
    const isStoreOwner = userAddress === storeOwner;
    const isSeller = isTimedAuction(sale)
      ? isStoreOwner
      : userAddress === sale.refs.seller;

    if (!isEnded && isSeller) {
      return ActionType.EndSale;
    }

    if ((isEnded && !isSeller) || (isEnded && isInstantSale(sale))) {
      return ActionType.SaleEndedPlate;
    }

    if (isEnded && isSeller && !sale.isWithdrawn) {
      return ActionType.WithdrawFunds;
    }

    if (isEnded && isSeller && sale.isWithdrawn) {
      return ActionType.ClaimNFT;
    }

    return ActionType.BuyNft;
  }, [sale, userAddress, storeOwner]);
};
