import { Badge } from "./Badge";
import { Button } from "./Button";
import { CloseButton } from "./CloseButton";
import { Divider } from "./Divider";
import { Drawer } from "./Drawer";
import { Form } from "./Form";
import { FormError } from "./FormError";
import { FormLabel } from "./FormLabel";
import { Heading } from "./Heading";
import { Input } from "./Input";
import { Link } from "./Link";
import { Menu } from "./Menu";
import { Modal } from "./Modal";
import { Popover } from "./Popover";
import { Tabs } from "./Tabs";
import { Tag } from "./Tag";
import { Text } from "./Text";
import { Textarea } from "./Textarea";

export const components = {
  Badge,
  Button,
  CloseButton,
  Divider,
  Drawer,
  Form,
  FormError,
  FormLabel,
  Heading,
  Input,
  NumberInput: Input,
  Link,
  Menu,
  Modal,
  Popover,
  Tabs,
  Tag,
  Text,
  Textarea,
};
