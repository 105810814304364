import { useMeta } from "jsons/data-loader";
import { Connection, PublicKey } from "@solana/web3.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Metaplex, toCandyMachineData } from "@metaplex-foundation/js";
import { useStore } from "effector-react";
import { $connection } from "state/connection";

interface ILocalStateProps {
  saleId?: string;
  artworkId?: string;
}

interface Progress {
  visible: boolean;
  title?: string;
  subtitle?: string;
  error?: string;
}

export const createMx = (connection: Connection) => {
  const metaplex = new Metaplex(connection);
  return metaplex;
};

const fetchCm = async (connection: Connection, cmId: string) => {
  const mx = createMx(connection);
  const address = new PublicKey(cmId);
  const cm = await mx.candyMachines().findByAddress({ address });

  return cm;
};

export function useLocalState({ saleId, artworkId }: ILocalStateProps) {
  const connection = useStore($connection);
  const [pending, setPending] = useState(false);
  const [item, setItem] = useMeta(saleId);
  const [candyMachine, setCandyMachine] = useState<any>();

  const [progress, setProgress] = useState<Progress>({ visible: false });
  const navigate = useNavigate();

  useEffect(() => {
    if (item) {
      fetchCm(connection, saleId).then((cm) => {
        setCandyMachine(cm);
        setItem((i) => ({
          ...i,
          artwork: {
            ...i.artwork,
            prints: {
              maxSupply: cm.itemsAvailable.toString(10),
              supply: cm.itemsRemaining.toString(10),
            },
          },
          itemsAvailable: cm.itemsAvailable.toString(10),
          itemsRedeemed: cm.itemsMinted.toString(10),
          groups: cm.candyGuard.groups,
        }));
      });
    }
  }, [saleId]);

  const handleError = (error: unknown) => {
    const message =
      (error instanceof Error && error.message) || "Unknown error occurred.";
    setProgress({ visible: false, error: message });
  };

  const handleEndSale = useCallback(
    async (market?: string, metadata?: string) => {},
    []
  );

  const resetProgress = () => setProgress({ visible: false });

  return {
    item,
    pending,
    setPending,
    handleEndSale,
    progress,
    resetProgress,
    candyMachine,
  };
}
