import { Box, Divider, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { SaleState } from "state/sales";
import { StatusTheme } from "./data";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface Props {
  state: SaleState;
}

export const Status: React.FC<Props> = ({ state }) => {
  const { xlUp } = useCustomBreakpoints();
  return (
    <>
      {xlUp && <Divider minH="64px" orientation="vertical" />}

      <HStack spacing={4} px={xlUp ? 4 : 0} w="full">
        <Box
          borderRadius={24}
          backgroundColor={StatusTheme.backgroundColor[state]}
          py={2}
          px={4}
          height="100%"
          margin="auto 0"
        >
          <Text
            fontWeight="bold"
            fontSize="sm"
            lineHeight={4}
            textTransform="uppercase"
            color={StatusTheme.color[state]}
          >
            {StatusTheme.text[state]}
          </Text>
        </Box>
      </HStack>
    </>
  );
};
