import { calculate } from "@metaplex/arweave-cost";

export const getFilesCost = async (
  coverImg?: File,
  logoImg?: File
): Promise<number> => {
  const files: number[] = [];
  if (coverImg && coverImg.size) files.push(coverImg.size);
  if (logoImg && logoImg.size) files.push(logoImg.size);
  if (files && files.length > 0) return (await calculate(files)).solana;

  return 0;
};
