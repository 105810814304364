import {
  $howToBuyModalState,
  setHowToBuyModalState,
} from "state/store/howToBuyModal";
import { ModalCloseButton, ModalHeader } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useEvent, useStore } from "effector-react";

import { HowToBuy } from "components/modals/HowToBuy";
import { ModalTemplate } from "components/modals/template";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

export const HowToBuyModal: React.FC = () => {
  const { lgUp } = useCustomBreakpoints();
  const setModalState = useEvent(setHowToBuyModalState);
  const { isOpen, storeName } = useStore($howToBuyModalState);

  const handleClose = useCallback(
    () => setModalState({ isOpen: false }),
    [setModalState]
  );

  const header = (
    <ModalHeader pos="relative">
      {storeName ? `Buying NFTs on ${storeName}` : "How to buy"}
      <ModalCloseButton top="50%" transform="translateY(-50%)" />
    </ModalHeader>
  );

  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={handleClose}
      header={header}
      isCentered={lgUp ? true : false}
      bodyProps={{ p: 0 }}
      contentProps={{ maxW: "1248px" }}
    >
      <HowToBuy />
    </ModalTemplate>
  );
};
