import React from "react";

import { Box, Heading, VStack } from "@chakra-ui/react";
import { AddressButton } from "components/buttons/AddressButton";
import { EarlyAccess } from "components/EarlyAccess";
import { Earnings } from "components/Earnings";

import { IArt } from "state/artworks";
import { ISale, isSale } from "state/sales";

import { ApproveArtwork } from "./Approve";
import { Description } from "./Description";
import { ItemPrice } from "./ItemPrice";
import { ItemActionHandlersProps } from "./ItemPrice/ItemActionButtons";
import { ListForSale } from "./ListForSale";
import { Properties } from "./Properties";
import { Stats } from "./Stats";

export interface IDescriptionColumnProps extends ItemActionHandlersProps {
  item: ISale | IArt;
  isVerified?: boolean;
  loadingVerification?: boolean;
}

export const DescriptionColumn: React.FC<IDescriptionColumnProps> = ({
  item,
  isVerified,
  loadingVerification,
  ...handlers
}) => {
  const _isSale = isSale(item);
  const artwork = _isSale ? item.artwork : item;

  return (
    <VStack align="flex-start" maxW={432} pb={12} spacing={12}>
      {_isSale && <EarlyAccess sale={item} />}

      <Box>
        <Heading mb={5} variant="h2">
          {artwork.title}
        </Heading>

        <AddressButton address={artwork.id} variant="link" />
      </Box>

      <Stats
        creator={artwork.creators[0].address}
        prints={artwork.prints}
        type={artwork.type}
      />

      {!_isSale && <ListForSale artwork={artwork} />}
      {_isSale && (
        <ItemPrice
          sale={item}
          isVerified={isVerified}
          loadingVerification={loadingVerification}
          {...handlers}
        />
      )}

      {artwork && (artwork.description || artwork.properties) && (
        <Box>
          <Heading variant="h4">Details</Heading>

          <VStack mt={6} spacing={12}>
            <Description artwork={artwork} />
            <Properties artwork={artwork} />
          </VStack>
        </Box>
      )}

      <Earnings artwork={artwork} />

      {!_isSale && <ApproveArtwork artwork={artwork} />}
    </VStack>
  );
};
