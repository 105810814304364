import { AuctionHouseProgram } from "@metaplex-foundation/mpl-auction-house";
import { ListingReceipt } from "@metaplex-foundation/mpl-auction-house/dist/src/generated/accounts";
import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { AccountInfo, Connection } from "@solana/web3.js";
import base58 from "bs58";
import { loadArtworkByMint } from "sdk/loadArtworks";
import {
  findAccountsAndDeserialize,
  loadAccountAndDeserialize,
  toPubkey,
} from "sdk/share";
import { IArt } from "state/artworks";
import { IInstantSale, SaleState, SaleType } from "state/sales";
import { excludesFalsy } from "utils/excludeFalsy";
import { lamportsToSol } from "utils/lamportsToSol";
import { parseBN } from "utils/parseBN";
import { loadSalesArtworks } from "./loadSalesArtworks";

const discriminator = base58.encode(
  Uint8Array.from([240, 71, 225, 94, 200, 75, 84, 231])
);

export const getSales = async ({
  connection,
  auctionHouse,
  seller,
}: {
  connection: Connection;
  auctionHouse: StringPublicKey;
  seller?: StringPublicKey | null;
}) => {
  const salesMap = await findAccountsAndDeserialize(
    connection,
    AuctionHouseProgram,
    ListingReceipt,
    [
      { offset: 0, bytes: discriminator },
      { offset: 8 + 32 * 2, bytes: auctionHouse },
      ...(seller ? [{ offset: 8 + 32 * 3, bytes: seller }] : []),
    ]
  );

  const artworks = await loadSalesArtworks(connection, Array.from(salesMap));

  const sales = Array.from(salesMap).map(([pubkey, sale]) => {
    const metadataPDA = sale.metadata.toString();
    const artwork = artworks.find((art) => art.accountAddress === metadataPDA);
    if (!artwork) return null;
    return combineSale(sale, pubkey, artwork);
  });

  return sales
    .filter((sale) => sale?.state !== SaleState.Broken)
    .filter(excludesFalsy);
};

const combineSale = (
  data: ListingReceipt,
  pubkey: string,
  artwork: IArt
): IInstantSale => {
  const seller = data.seller.toString();
  const tradeState = data.tradeState.toString();

  const isPurchased = !!data.purchaseReceipt;
  const isCanceled = !isPurchased && !!data.canceledAt;
  const isBroken = !isPurchased && artwork.ownerAddress !== seller;
  const isEmptyToken = artwork.tokenAmount === 0;
  const state = isPurchased
    ? SaleState.Ended
    : isBroken || isEmptyToken || isCanceled
    ? SaleState.Broken
    : SaleState.Active;

  return {
    type: SaleType.InstantSale,
    artwork,
    id: pubkey,
    price: parseBN(data.price, lamportsToSol),
    state,
    isSecondary: true,
    refs: {
      tradeState,
      seller,
    },
    ...(data.canceledAt && { endDate: parseBN(data.canceledAt) }),
  };
};

export const loadSales = async ({
  connection,
  auctionHouse,
  seller,
}: {
  connection: Connection;
  auctionHouse: StringPublicKey;
  seller?: StringPublicKey | null;
}) => {
  return await getSales({ connection, auctionHouse, seller });
};

export const loadSale = async ({
  connection,
  saleId,
  accountInfo,
}: {
  connection: Connection;
  saleId: StringPublicKey;
  accountInfo?: AccountInfo<Buffer>;
}) => {
  let saleData: ListingReceipt;
  if (accountInfo) {
    saleData = ListingReceipt.deserialize(accountInfo.data)[0];
  } else {
    const pubkey = toPubkey(saleId);

    saleData = await loadAccountAndDeserialize(
      connection,
      ListingReceipt,
      pubkey
    );
  }

  const metadata = await loadAccountAndDeserialize(
    connection,
    Metadata,
    saleData.metadata
  );
  const { mint } = metadata;
  const artwork = await loadArtworkByMint({ connection, mint, metadata });

  return combineSale(saleData, saleId, artwork);
};
