import { useStore } from "effector-react";
import { UseFormReturn } from "react-hook-form";

import { IFormData } from "../interface";
import { $errorsStore, calcAllErrors } from "../utils";

interface UseIsValidParams {
  methods: UseFormReturn<IFormData>;
}

export const useIsValid = ({ methods }: UseIsValidParams) => {
  const errorsData = useStore($errorsStore);

  const { formState } = methods;

  const primary = methods.getValues("splits");
  const secondary = methods.getValues("secondarySplits");
  const primaryErrors = calcAllErrors(primary);
  const secondaryErrors = calcAllErrors(secondary);

  const hasErrors =
    primaryErrors?.isAnyErrors ||
    secondaryErrors?.isAnyErrors ||
    errorsData?.isAnyErrors ||
    Object.values(formState.errors).length;

  return !hasErrors;
};
