import { BaseSdkProps, ETransactionProgress } from "sdk/share";
import { sendTransactions } from "sdk/transactions";
import { createStoreTransaction } from "./actions/createStoreTransaction";

export const initStore = async ({
  connection,
  wallet,
  progressChange,
}: BaseSdkProps) => {
  progressChange?.(ETransactionProgress.creating);
  const { auctionHouse, tx } = await createStoreTransaction({
    wallet,
  });

  progressChange?.(ETransactionProgress.sending_to_solana);
  await sendTransactions(connection, wallet, [tx]);

  return { auctionHouse: auctionHouse.toString() };
};
