import { AnyPublicKey } from "@metaplex-foundation/mpl-core";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { AccountLayout, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Connection } from "@solana/web3.js";
import { findMetadataAddress } from "../nft";
import { loadAccountAndDeserialize, toPubkey } from "../share";
import { combineArtwork } from "./combineArtwork";
import { loadArtworkEdition } from "./loadArtworkEdition";
import { populateArtwork } from "./populateArtwork";

export const loadArtworkByMint = async ({
  connection,
  mint,
  metadata: data,
}: {
  connection: Connection;
  mint: AnyPublicKey;
  metadata?: Metadata;
}) => {
  const [pubkey] = await findMetadataAddress(toPubkey(mint));

  const metadata =
    data ?? (await loadAccountAndDeserialize(connection, Metadata, pubkey));
  const token = await loadTokenAccountByMint(connection, mint);
  const editionProps = await loadArtworkEdition(connection, metadata.mint);

  const art = combineArtwork({
    editionProps,
    metadata,
    pubkey,
    token,
  });

  return populateArtwork(art);
};

export const loadTokenAccountByMint = async (
  connection: Connection,
  mint: AnyPublicKey
) => {
  const { address: tokenAddress } = (
    await connection.getTokenLargestAccounts(toPubkey(mint))
  ).value[0];

  const token = await loadAccountAndDeserialize(
    connection,
    AccountLayout,
    tokenAddress,
    TOKEN_PROGRAM_ID
  );
  return { ...token, address: tokenAddress };
};
