import React from "react";
import { FilePreviewProps } from "components/FileUpload/Preview/Preview.props";

export const FilePreviewVr: React.FC<FilePreviewProps> = ({ url }) => (
  <model-viewer
    style={{
      width: "100%",
      height: "100%",
      minHeight: "500px",
      minWidth: "100px",
    }}
    src={url}
    alt="VR element"
    camera-controls
  />
);
