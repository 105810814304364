import { useStore } from "effector-react";
import { FC } from "react";
import { $hasStore, $pendingStore } from "state/store";
import { NotFoundView } from "./NotFoundView";
import { StoreLoading } from "./StoreLoading/AppLoading";

export const StoreExistsGuard: FC = ({ children }) => {
  const hasStore = useStore($hasStore);
  const pendingStore = useStore($pendingStore);

  return pendingStore ? (
    <StoreLoading />
  ) : hasStore ? (
    <>{children}</>
  ) : (
    <NotFoundView />
  );
};
