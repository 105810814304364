import { createCloseAccountInstruction, NATIVE_MINT } from "@solana/spl-token";
import { Connection, PublicKey } from "@solana/web3.js";
import { TransactionsBatch } from "sdk/transactions";
import { createTokenAccount } from "./createTokenAccount";

export const createWrappedAccount = async ({
  connection,
  owner,
  amount = 0,
}: {
  connection: Connection;
  owner: PublicKey;
  amount?: number;
}) => {
  const { tx, tokenAccount } = await createTokenAccount({
    connection,
    payer: owner,
    mint: NATIVE_MINT,
    amount,
  });

  const closeTokenIx = createCloseAccountInstruction(
    tokenAccount.publicKey,
    owner,
    owner
  );

  const closeTx = new TransactionsBatch({ instructions: [closeTokenIx] });

  return { tokenAccount, tx, closeTx };
};
