import { Cluster } from "@solana/web3.js";
import { getStorage } from "utils/storage";
import { createEffect, createEvent, restore, sample } from "effector";
import { LOCK_MAINNET } from "global-const/env";

export const NETWORK_KEY = "networkkey";
export const NETWORK_LIST: Cluster[] = ["devnet", "testnet", "mainnet-beta"];

export function getSavedNetwork(
  defaultValue?: Cluster,
  storage = getStorage()
): Cluster {
  if (LOCK_MAINNET) {
    return "mainnet-beta";
  }
  const data = storage?.getItem(NETWORK_KEY);
  if (data && NETWORK_LIST.includes(data as Cluster)) {
    return data as Cluster;
  }
  return (
    defaultValue ??
    (process.env.NODE_ENV === "production" ? "mainnet-beta" : "devnet")
  );
}

export const networkChange = createEvent<Cluster>();
export const $network = restore(networkChange, getSavedNetwork());

export const setNetworkToStorageFx = createEffect(
  ({
    storage = getStorage(),
    network,
  }: {
    storage?: Storage | null;
    network: Cluster;
  }) => {
    storage?.setItem(NETWORK_KEY, network);
  }
);
sample({
  clock: $network,
  fn: (network) => ({ network }),
  target: setNetworkToStorageFx,
});
