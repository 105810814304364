import { StringPublicKey } from "@metaplex-foundation/mpl-core";
import { Cluster } from "@solana/web3.js";
import { BaseSdkProps } from "sdk/share";
import { sendTransactions } from "sdk/transactions";
import { payForFiles } from "./actions/payForFiles";
import { getFilePath, upload } from "./arweave";

export const METADATA_FILE_NAME = "metadata.json";

export enum EUploadProgress {
  preparing_assets,
  signing_metadata_transaction,
  sending_transaction_to_solana,
  uploading_to_arweave,
}

interface IUploadFile2ArweaveParams extends BaseSdkProps<EUploadProgress> {
  network: Cluster;
  meta: string;
  files: File[];
  mintKey: StringPublicKey;
}

export const uploadFiles2Arweave = async ({
  connection,
  wallet,
  meta,
  files: uploadFiles,
  mintKey,
  network,
  progressChange,
}: IUploadFile2ArweaveParams) => {
  progressChange?.(EUploadProgress.preparing_assets);

  const metadataFile = new File([meta], METADATA_FILE_NAME);
  const files = [...uploadFiles, metadataFile];

  const payForFilesTx = await payForFiles({
    wallet,
    files,
  });

  progressChange?.(EUploadProgress.signing_metadata_transaction);
  const [txId] = await sendTransactions(connection, wallet, [payForFilesTx]);

  progressChange?.(EUploadProgress.uploading_to_arweave);
  const arweaveResult = await upload({
    files,
    mintKey,
    txId,
    network,
  });
  const fileUri = getFilePath(METADATA_FILE_NAME, arweaveResult);

  return {
    fileUri,
    arweaveResult,
  };
};
