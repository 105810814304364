import { $user } from "state/wallet";
import { FC, useCallback, useState } from "react";
import { Layout } from "components/Layout";
import { NftCreationForm } from "components/forms/NftCreate";
import { OperationSuccessNft } from "./OperationSuccessNft";
import { PreviewNft } from "components/PreviewNft";
import { ProgressModal } from "./ProgressModal";
import { useLocalState } from "./NftCreationView.state";
import { useNavigate } from "react-router-dom";
import { useStore } from "effector-react";

export const NftCreationView: FC = () => {
  const {
    pageState,
    setPageState,
    previewForm,
    price,
    onMint,
    onFormSubmit,
    step,
    form,
    successArtwork,
    artworkError,
    setArtworkError,
  } = useLocalState();
  const user = useStore($user);
  const navigate = useNavigate();
  const [isModalOpen, setModalVisibility] = useState(false);

  const successOrLoadingMinted =
    pageState === "success" || pageState === "loading-minted";

  const resetError = useCallback(() => {
    setArtworkError(null);
    setModalVisibility(false);
    setPageState("preview");
  }, [setArtworkError, setModalVisibility, setPageState]);

  return (
    <Layout variant="narrow" focused>
      {pageState === "form" && user?.address && (
        <NftCreationForm
          cancel={() => navigate(-1)}
          formValues={form}
          user={user}
          onSubmit={onFormSubmit}
        />
      )}

      {(pageState === "preview" || pageState === "minting") &&
        previewForm !== null && (
          <PreviewNft
            isLoading={pageState === "minting" && !artworkError}
            preview={previewForm}
            price={price}
            onBack={() => setPageState("form")}
            onMint={onMint}
          />
        )}

      {form !== null && !isModalOpen && (
        <ProgressModal
          cancelHandling={resetError}
          error={artworkError}
          formData={form}
          step={step}
          isOpen={pageState === "minting"}
        />
      )}

      {successOrLoadingMinted && successArtwork && (
        <OperationSuccessNft artwork={successArtwork} />
      )}
    </Layout>
  );
};
