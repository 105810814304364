import { User } from "state/wallet";
import { FileType } from "components/MediaTypeSelector";

import { IFormData } from "../interface";

export const getDefaultValues = (user: User): Partial<IFormData> => ({
  splits: [{ key: user?.address ?? "", value: 100, isOwner: true }],
  secondarySplits: [
    { key: user?.address ?? "", value: 100, isOwner: true, total: 0 },
  ],
  properties: [{ key: "", value: "" }],
  royalty: "0",
  mediaType: FileType.IMAGE,
});
