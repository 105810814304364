import { HStack, Image, Text } from "@chakra-ui/react";
import { Lightbox } from "components/Lightbox";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { FC } from "react";
import { useFullScreenHandle } from "react-full-screen";
import { CgArrowsExpandRight } from "react-icons/cg";
import { IArt } from "state/artworks";

interface IArtImageProps {
  artwork: IArt;
}

export const ArtImage: FC<IArtImageProps> = ({ artwork }: IArtImageProps) => {
  const { mdUp } = useCustomBreakpoints();
  const handleFullscreen = useFullScreenHandle();

  const handleOpen = () => {
    handleFullscreen.enter();
  };

  const handleClose = () => {
    handleFullscreen.exit();
  };

  return (
    <>
      <Image
        id="artImage"
        h="full"
        w="full"
        objectFit="cover"
        src={artwork.image}
        onClick={handleOpen}
      />
      <HStack
        display={mdUp ? "flex" : "none"}
        bgColor="blackAlpha.500"
        p={2}
        borderRadius="full"
        onClick={handleOpen}
        position="absolute"
        alignItems="center"
        right={4}
        bottom={4}
        visibility="hidden"
        opacity={0.75}
        _groupHover={{ visibility: "visible" }}
        _hover={{ opacity: 1 }}
      >
        <CgArrowsExpandRight size="20" />
        <Text fontWeight="bold">Expand</Text>
      </HStack>
      <Lightbox
        artwork={artwork.image}
        handle={handleFullscreen}
        onClose={handleClose}
      />
    </>
  );
};
