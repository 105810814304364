import { Circle, HStack, Image } from "@chakra-ui/react";
import { FC } from "react";
import { FullScreen, FullScreenHandle } from "react-full-screen";
import { CgCompressRight } from "react-icons/cg";

interface LightboxProps {
  artwork: string;
  handle: FullScreenHandle;
  onClose?: () => void;
}

export const Lightbox: FC<LightboxProps> = ({ artwork, handle, onClose }) => {
  return (
    <FullScreen handle={handle}>
      {handle.active && (
        <HStack onClick={onClose} backgroundColor="gray.800" h="full">
          <Image
            onClick={(e) => e.stopPropagation()}
            margin="auto"
            maxW="100vw"
            maxH="100vh"
            objectFit="contain"
            src={artwork}
          />
          <Circle
            bgColor="whiteAlpha.100"
            p={2}
            onClick={onClose}
            position="absolute"
            alignItems="center"
            right={4}
            top={4}
            cursor="pointer"
            opacity={0.75}
            _hover={{ opacity: 1 }}
          >
            <CgCompressRight size="20" />
          </Circle>
        </HStack>
      )}
    </FullScreen>
  );
};
