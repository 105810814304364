import { createSignMetadataInstruction } from "@metaplex-foundation/mpl-token-metadata";
import { Connection } from "@solana/web3.js";
import { Wallet } from "wallet";
import { toPubkey } from "../share";
import { sendTransactions, TransactionsBatch } from "../transactions";

export async function approveNftCreator(
  connection: Connection,
  creatorWallet: Wallet,
  address: string
) {
  const ix = createSignMetadataInstruction({
    metadata: toPubkey(address),
    creator: creatorWallet.publicKey,
  });

  const tx = new TransactionsBatch({ instructions: [ix] });

  await sendTransactions(connection, creatorWallet, [tx]);
}
