import { bignum } from "@metaplex-foundation/beet/dist/types/src/types";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import BN from "bn.js";

export function bigNumberToNumber(n: bignum): number {
  return new BN(n).toNumber();
}

export function bigNumToSol(n: bignum): number {
  return new BN(n).toNumber() / LAMPORTS_PER_SOL;
}
