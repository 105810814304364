import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";

import { MdKeyboardArrowDown } from "react-icons/md";
import { UnitItem } from "./UnitItem";
import { fontSizes } from "theme/typography";
import { ChangeEvent, forwardRef, useState } from "react";
import { PeriodType } from "../TimeDurationInput";

interface Props extends Omit<InputProps, "onChange"> {
  units?: readonly string[];
  defaultUnit?: PeriodType;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onUnitChange?: (unit: string) => void;
  translateUnit?: (unit: string) => string;
}

export const InputWithUnitSelector = forwardRef<HTMLInputElement, Props>(
  (
    {
      units = [],
      defaultUnit,
      onChange,
      onUnitChange,
      translateUnit,
      ...inputProps
    },
    ref
  ) => {
    const [currentUnit, setCurrentUnit] = useState(defaultUnit || units[0]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
    };

    const handleUnitChange = (unit: string) => {
      setCurrentUnit(unit);
      onUnitChange && onUnitChange(unit);
    };

    return (
      <InputGroup>
        <Input ref={ref} onChange={handleChange} {...inputProps} />
        <InputRightElement width="unset" right={1}>
          <Menu gutter={8} autoSelect={false}>
            <MenuButton
              as={Button}
              variant="ghost"
              rightIcon={<MdKeyboardArrowDown size={fontSizes["2xl"]} />}
              textTransform="capitalize"
            >
              {translateUnit ? translateUnit(currentUnit) : currentUnit}
            </MenuButton>
            <MenuList>
              {units?.map((unit) => (
                <UnitItem
                  key={String(unit)}
                  onSelect={() => handleUnitChange(unit)}
                >
                  {translateUnit ? translateUnit(unit) : unit}
                </UnitItem>
              ))}
            </MenuList>
          </Menu>
        </InputRightElement>
      </InputGroup>
    );
  }
);
