import { config, StringPublicKey } from "@metaplex-foundation/mpl-core";
import { FixedPriceSaleProgram } from "@metaplex-foundation/mpl-fixed-price-sale";
import { Connection } from "@solana/web3.js";
import { Wallet } from "wallet";
import { loadSale as loadAhSale } from "./auction-house";
import { loadCandyMachine as loadCmSale } from "./candyMachine/loadCandyMachines";
import { loadMarket as loadFpSale } from "./fps/loadMarkets";
import { loadAccount, toPubkey } from "./share";

const AuctionHousePubkey = toPubkey(config.programs.auctionHouse);
const CandyMachinePubkey = toPubkey(config.programs.candyMachine);

export const loadSale = async ({
  connection,
  saleId,
  wallet,
}: {
  saleId: StringPublicKey;
  connection: Connection;
  wallet?: Wallet | null;
}) => {
  const pubkey = toPubkey(saleId);
  const accountInfo = await loadAccount(connection, pubkey);

  if (accountInfo.owner.equals(FixedPriceSaleProgram.PUBKEY)) {
    return loadFpSale({ connection, marketId: saleId, accountInfo, wallet });
  } else if (accountInfo.owner.equals(AuctionHousePubkey)) {
    return loadAhSale({ connection, saleId, accountInfo });
  } else if (accountInfo.owner.equals(CandyMachinePubkey)) {
    return loadCmSale({ connection, cmId: saleId, accountInfo });
  }
  throw new Error("Can't load sale");
};
