import { Store } from "@metaplex-foundation/mpl-metaplex";
import { createEffect, StoreValue } from "effector";
import { loadStore } from "sdk/loadStore";
import { $connection } from "state/connection";
import { $wallet } from "state/wallet";

export const checkWalletStoreOriginFx = createEffect(
  async ({
    connection,
    wallet,
  }: {
    connection: StoreValue<typeof $connection>;
    wallet: StoreValue<typeof $wallet>;
  }) => {
    if (!wallet) return null;
    const storeAddress = await Store.getPDA(wallet.publicKey);
    const store = await loadStore({ connection, storeAddress });
    return (store?.owner && store.storeId) || null;
  }
);
