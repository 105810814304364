export function upgradeListFn<T extends { id: string }>(
  items: T[],
  item: T | null
) {
  if (!item) {
    return items;
  }

  const index = items.findIndex(({ id }) => id === item.id);
  const draft = [...items];
  if (index < 0) {
    draft.push(item);
  } else {
    draft[index] = item;
  }
  return draft;
}
