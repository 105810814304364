import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { LayoutContent, LayoutContentVariant } from ".";

import { $user } from "state/wallet";
import { Box, Flex, FlexProps } from "@chakra-ui/react";
import { HowToBuyModal } from "views/StorePage/HowToBuyModal";
import { Navbar } from "components/Navbar";
import { NavbarActions } from "components/Navbar/NavbarActions";
import { Sidebar } from "components/Sidebar";
import { useStore } from "effector-react";

export interface LayoutProps {
  variant?: LayoutContentVariant;
  focused?: boolean;
  gutterBottom?: boolean;
  sidebarContent?: ReactNode;
  transparentNavbar?: boolean;
  cta?: ReactNode;
  noPadding?: boolean;
  children: ReactNode;
  noUnderline?: boolean;
  bodyStyles?: FlexProps;
}

export const Layout = React.memo<LayoutProps>(
  ({
    variant,
    focused,
    gutterBottom,
    children,
    sidebarContent,
    transparentNavbar,
    cta,
    noPadding,
    bodyStyles,
    noUnderline,
  }) => {
    const user = useStore($user);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const handleToggleSidebar = useCallback(
      () => setSidebarOpen((currentState) => !currentState),
      [setSidebarOpen]
    );

    const handleCloseSidebar = useCallback(
      () => setSidebarOpen(false),
      [setSidebarOpen]
    );

    const navbarActions = useMemo(() => {
      return (
        <NavbarActions
          user={user}
          cta={cta}
          onToggleSidebar={handleToggleSidebar}
        />
      );
    }, [user, cta, handleToggleSidebar]);

    return (
      <Box>
        <Navbar transparent={transparentNavbar} noUnderline={noUnderline}>
          {navbarActions}
        </Navbar>
        <Flex>
          <Sidebar
            isOpen={sidebarOpen}
            onClose={handleCloseSidebar}
            focused={focused}
          >
            {sidebarContent}
          </Sidebar>

          <LayoutContent
            variant={variant}
            gutterBottom={gutterBottom}
            noPadding={noPadding}
            props={bodyStyles}
          >
            {children}
          </LayoutContent>

          <HowToBuyModal />
        </Flex>
      </Box>
    );
  }
);
