import { attach, createEffect, StoreValue } from "effector";
import { loadStore } from "sdk/loadStore";
import { $connection } from "state/connection";

export const loadStoreByAddressFx = attach({
  effect: createEffect(
    ({
      connection,
      storeAddress,
    }: {
      storeAddress: string;
      connection: StoreValue<typeof $connection>;
    }) => loadStore({ connection, storeAddress })
  ),
  source: {
    connection: $connection,
  },
  mapParams(storeAddress: string, { connection }) {
    return { storeAddress, connection };
  },
});
