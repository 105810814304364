import { SaleState } from "state/sales";

interface StatusThemeType {
  [key: string]: {
    [key: number]: string;
  };
}

export const StatusTheme: StatusThemeType = {
  backgroundColor: {
    [SaleState.Uninitialized]: "whiteAlpha.100",
    [SaleState.Created]: "whiteAlpha.100",
    [SaleState.Active]: "rgba(0, 255, 189, 0.15)",
    [SaleState.Ended]: "whiteAlpha.100",
    [SaleState.SoldOut]: "whiteAlpha.100",
  },
  color: {
    [SaleState.Uninitialized]: "white",
    [SaleState.Created]: "white",
    [SaleState.Active]: "green.500",
    [SaleState.Ended]: "whiteAlpha.700",
    [SaleState.SoldOut]: "whiteAlpha.700",
  },
  text: {
    [SaleState.Uninitialized]: "New",
    [SaleState.Created]: "Not Live",
    [SaleState.Active]: "Live",
    [SaleState.Ended]: "Ended",
    [SaleState.SoldOut]: "Sold Out",
  },
};
