import { FormField } from "components/FormField";
import { ToggleSelect, ToggleSelectOptions } from "components/ToggleSelect";
import { FC } from "react";
import { MdOutlineLocalOffer } from "react-icons/md";

export const enum SaleType {
  FixedPrice = "fixed-price",
  InstantSale = "instant-sale",
  TimedAuction = "timed-auction",
}

export const saleTypeOptions: ToggleSelectOptions<SaleType> = [
  {
    id: SaleType.FixedPrice,
    title: "Fixed price",
    Icon: MdOutlineLocalOffer,
  },
  {
    id: SaleType.InstantSale,
    title: "Instant sale",
    Icon: MdOutlineLocalOffer,
  },
];

export const SaleTypeSelector: FC = () => {
  return (
    <FormField
      id="saleType"
      title="Select sales type"
      options={{ value: "fixed-price" }}
      controlledInputFactory={({ field }) => (
        <ToggleSelect<SaleType>
          options={saleTypeOptions}
          onSelect={field.onChange}
          selectedOptionId={field.value as SaleType}
        />
      )}
    />
  );
};
