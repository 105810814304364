import { AnyPublicKey } from "@metaplex-foundation/mpl-core";
import { Store, StoreConfig } from "@metaplex-foundation/mpl-metaplex";
import { Connection } from "@solana/web3.js";
import { IStore, IStoreConfig } from "state/store";
import { excludesFalsy } from "utils/excludeFalsy";
import { loadExtraContent } from "./nft";
import { loadAccountAndDeserialize, toPubkey } from "sdk/share";
import { AuctionHouse } from "@metaplex-foundation/mpl-auction-house/dist/src/generated/accounts";

export const loadStore = async ({
  connection,
  storeAddress,
}: {
  connection: Connection;
  storeAddress: AnyPublicKey;
}): Promise<IStore | null> => {
  const storeAccount = await Store.load(connection, storeAddress);
  if (!storeAccount.data) {
    return null;
  }

  try {
    const configId = await StoreConfig.getPDA(storeAddress);
    const configAccount = await StoreConfig.load(connection, configId);
    const {
      data: { settingsUri },
    } = configAccount;

    if (!settingsUri) {
      return null;
    }

    const config = await loadExtraContent<IStoreConfig>(settingsUri);

    if (!config.storeAhId || !config.storeFpsId) {
      return null;
    }

    const ahConfig = await loadAccountAndDeserialize(
      connection,
      AuctionHouse,
      toPubkey(config.storeAhId)
    );

    return {
      ...config,
      storeId: storeAccount.pubkey.toString(),
      whitelistedCollections:
        config.whitelistedCollections?.filter(excludesFalsy) ?? [],
      candyMachines: config.candyMachines?.filter(excludesFalsy) ?? [],
      sellerFeeBasisPoints: ahConfig.sellerFeeBasisPoints ?? 0,
    };
  } catch {
    return null;
  }
};
