import React from "react";
import { Box, HStack, StackProps, Text } from "@chakra-ui/react";

export interface EarningsRowProps extends StackProps {
  value: number;
}

export const EarningsRow: React.FC<EarningsRowProps> = ({
  children,
  value,
  ...props
}) => {
  return (
    <HStack
      borderBottom="1px solid"
      borderBottomColor="whiteAlpha.100"
      h={14}
      justifyContent="space-between"
      w="full"
      {...props}
    >
      <Box fontSize="sm" textTransform="uppercase">
        {children}
      </Box>

      <Text color="whiteAlpha.700" fontSize="sm">
        {value}%
      </Text>
    </HStack>
  );
};
