const convertTimezone = (date: Date, timezone: string) => {
  return new Date(date.toLocaleString("en-US", { timeZone: timezone }));
};

const getTimezoneOffset = (timezone: string) => {
  const localDate = new Date();

  const convertedDate = convertTimezone(localDate, timezone);
  const offset = +convertedDate - +localDate;

  return offset;
};

const timezoneOffset = {
  PDT: getTimezoneOffset("Canada/Yukon"),
  UTC: getTimezoneOffset("UTC"),
};

export type Timezone = "PDT" | "UTC";

export const localToSelectedTimezone = (date: Date, timezone: Timezone) => {
  return new Date(date.getTime() + timezoneOffset[timezone]);
};

export const selectedToLocalTimezone = (date: Date, timezone: Timezone) => {
  return new Date(date.getTime() - timezoneOffset[timezone]);
};
