import { ArtworkDetailsColumn } from "../ArtworkDetailsColumn";
import { FC } from "react";
import { getSaleEndDate, getSalePrice, ISale } from "state/sales";
import { SolUsdDisplay } from "components/SolUsdDisplay";
import { TimeEntry } from "components/TimeEntry";
import dayjs from "dayjs";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { SupplyDetails } from "./SupplyDetails";

interface Props {
  sale?: ISale;
}

export const LiveAuctionDetails: FC<Props> = ({ sale }) => {
  const { xlUp, xxlUp } = useCustomBreakpoints();

  if (!sale) return null;

  return (
    <>
      <ArtworkDetailsColumn title="Live" titleProps={{ color: "green.500" }}>
        <TimeEntry
          duration={dayjs.unix(getSaleEndDate(sale) || 0).diff(dayjs())}
        />
      </ArtworkDetailsColumn>

      {xxlUp && (
        <ArtworkDetailsColumn title="Current bid">
          <SolUsdDisplay sol={getSalePrice(sale) || 0} />
        </ArtworkDetailsColumn>
      )}

      {xlUp && (
        <ArtworkDetailsColumn title="Supply">
          <SupplyDetails {...sale.artwork.prints} />
        </ArtworkDetailsColumn>
      )}
    </>
  );
};
