import { VStack } from "@chakra-ui/layout";
import { Button, Center, Heading } from "@chakra-ui/react";
import { Layout } from "components/Layout";
import { useStore } from "effector-react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { usePaths } from "routes";
import { $hasStore } from "state/store";

interface Props {
  href: string;
  linkTitle: string;
}

export const NotFountContent: FC<Props> = ({ linkTitle, href, children }) => (
  <Center height="full">
    <VStack spacing={4}>
      <Heading variant="h1">{children || "Page was not found"}</Heading>
      <Button flexGrow={1} size="lg" as={Link} to={href}>
        {linkTitle}
      </Button>
    </VStack>
  </Center>
);

export const NotFoundView: FC = ({ children }) => {
  const hasStore = useStore($hasStore);
  const paths = usePaths();

  const props = hasStore
    ? {
        href: paths.store(),
        linkTitle: "Back to Store",
      }
    : {
        href: paths.home(),
        linkTitle: "Go Home",
      };

  return (
    <Layout>
      <NotFountContent {...props}>{children}</NotFountContent>
    </Layout>
  );
};
