import React from "react";
import {
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CreateButton } from "components/buttons/CreateButton";
import { IFormData } from "components/forms/NftCreate/interface";

function checkValidation(
  key: string,
  value: string,
  ifValidFn: () => void
): boolean {
  if ((key === "" && value === "") || (key !== "" && value !== "")) {
    ifValidFn();

    return true;
  }

  return false;
}

export const NftProperties: React.FC = () => {
  const {
    register,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext<IFormData>();
  const { fields, append, remove } = useFieldArray({
    name: "properties", // unique name for your Field Array
  });

  const properties = getValues("properties");

  return (
    <FormControl id="supply">
      <FormLabel>PROPERTIES</FormLabel>
      <UnorderedList
        my={fields.length === 0 ? 0 : 4}
        ml={0}
        listStyleType={"none"}
        spacing={4}
      >
        {fields.map((field, index) => (
          <ListItem key={field.id}>
            <FormControl
              isInvalid={
                !!errors.properties?.[index]?.key ||
                !!errors.properties?.[index]?.value
              }
            >
              <HStack spacing={2}>
                <Input
                  {...register(`properties.${index}.key`, {
                    validate: (key) =>
                      checkValidation(key, properties[index].value, () =>
                        clearErrors(`properties.${index}.value`)
                      ),
                  })}
                  placeholder="e. g. Size"
                />
                <Input
                  {...register(`properties.${index}.value`, {
                    validate: (value) =>
                      checkValidation(value, properties[index].key, () =>
                        clearErrors(`properties.${index}.key`)
                      ),
                  })}
                  placeholder="e. g. Medium"
                />
                <CloseButton
                  size="lg"
                  variant="forms"
                  onClick={() => remove(index)}
                />
              </HStack>
              <FormErrorMessage>Field cannot be empty</FormErrorMessage>
            </FormControl>
          </ListItem>
        ))}
      </UnorderedList>

      <CreateButton
        onClick={() => append({ key: "", value: "" })}
        variant="tertiary"
        aria-label="add property"
        fontSize="md"
        lineHeight="base"
      >
        Add more
      </CreateButton>
    </FormControl>
  );
};
