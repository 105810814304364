import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FC, useState } from "react";

import { ITab } from "components/ArtworksList/ArtworksList";
import { MdChevronRight } from "react-icons/md";
import { fontSizes } from "theme/typography";

interface MobileTabsProps {
  tabs?: ITab[];
  currentTabIndex?: number;
  onTabChange: (index: number) => void;
}

export const MobileTabs: FC<MobileTabsProps> = ({
  tabs = [],
  currentTabIndex: defaultIndex = 0,
  onTabChange,
}) => {
  const [tabIndex, setTabIndex] = useState(
    defaultIndex === -1 ? 0 : defaultIndex
  );
  const [open, setOpen] = useState(false);

  const handleTabChange = (index: number) => {
    onTabChange(index);
    setTabIndex(index);
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const currentTab = tabs[tabIndex];

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="tertiary"
        leftIcon={
          <MdChevronRight
            size={fontSizes["2xl"]}
            style={{ transform: "rotate(90deg)" }}
          />
        }
        iconSpacing={4}
        justifyContent="start"
        flexGrow={1}
      >
        {currentTab?.title}
        {typeof currentTab.count === "number" && (
          <Text fontWeight={400} ml={3}>
            {currentTab.count}
          </Text>
        )}
      </Button>
      <Modal isOpen={open} onClose={handleClose} size="full">
        <ModalOverlay />
        <ModalContent bgColor="gray.700">
          <ModalHeader>Filter by</ModalHeader>
          <ModalCloseButton top={4} />
          <ModalBody
            bgColor="whiteAlpha.50"
            display="flex"
            p={6}
            flexDirection="column"
          >
            {tabs.map((tab, index) => (
              <Button
                key={tab.id}
                onClick={() => handleTabChange(index)}
                variant="tertiary"
                justifyContent="start"
                size="lg"
                mt={index === 0 ? 0 : 2}
              >
                <Flex>{tab.title}</Flex>
                <Text fontWeight={400} ml={3}>
                  {tab.count}
                </Text>
              </Button>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
