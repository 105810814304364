import { Circle, MenuItem, MenuItemProps } from "@chakra-ui/react";

import { Cluster } from "@solana/web3.js";
import { FC } from "react";
import { colors } from "theme/colors";

interface Props extends MenuItemProps {
  network: Cluster;
  isActive?: boolean;
  setNetwork?: (network: Cluster) => void;
}

export const NetworkSelectMenuItem: FC<Props> = ({
  isActive,
  network,
  setNetwork,
  ...props
}) => {
  const handleSelect = () => {
    setNetwork && setNetwork(network);
  };

  return (
    <MenuItem
      network={network}
      onClick={handleSelect}
      justifyContent="space-between"
      style={{ paddingLeft: 16, paddingRight: 16 }}
      h="48px"
      {...props}
    >
      {network}
      {isActive && (
        <Circle
          size={1.5}
          bgColor="green.500"
          boxShadow={`0 0 12px ${colors.green[500]}`}
        />
      )}
    </MenuItem>
  );
};
