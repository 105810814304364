import { AnyPublicKey } from "@metaplex-foundation/mpl-core";
import {
  FixedPriceSaleProgram,
  Store,
} from "@metaplex-foundation/mpl-fixed-price-sale";
import { Connection, PublicKey } from "@solana/web3.js";
import base58 from "bs58";
import {
  findAccountsAndDeserialize,
  loadAccountAndDeserialize,
} from "sdk/share";
import { trim } from "utils/trim";
import { toPubkey } from "../share/toPubkey";

const discriminator = base58.encode(
  Uint8Array.from([130, 48, 247, 244, 182, 191, 30, 26])
);

export const getStores = async (owner: PublicKey, connection: Connection) => {
  const storesAndKeys = await findAccountsAndDeserialize(
    connection,
    FixedPriceSaleProgram,
    Store,
    [
      { offset: 0, bytes: discriminator },
      { offset: 8, bytes: owner.toBase58() },
    ]
  );

  return Array.from(storesAndKeys).map(([pubkey, store]) =>
    combineStore(store, pubkey)
  );
};

const combineStore = (data: Store, pubkey: AnyPublicKey) => ({
  ...data,
  name: trim(data.name),
  description: trim(data.description),
  storeId: pubkey.toString(),
});

export const loadStores = async ({
  owner,
  connection,
}: {
  owner: PublicKey;
  connection: Connection;
}) => {
  try {
    return await getStores(owner, connection);
  } catch {
    return null;
  }
};

export const loadStore = async ({
  storeId,
  connection,
}: {
  storeId: AnyPublicKey;
  connection: Connection;
}) => {
  try {
    const pubkey = toPubkey(storeId);

    const storeData = await loadAccountAndDeserialize(
      connection,
      Store,
      pubkey
    );

    return combineStore(storeData, pubkey);
  } catch {
    return null;
  }
};
