import { DrawerCloseButton, DrawerHeader } from "@chakra-ui/react";

import { MetaplexIcon } from "components/Icons";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface Props {
  secondaryView?: boolean;
  secondaryViewTitle?: string;
  onExitSecondaryView?: () => void;
}

export const MobileSidebarHeader: React.FC<Props> = ({ secondaryView }) => {
  const { smDown } = useCustomBreakpoints();

  if (smDown) {
    return (
      <DrawerHeader
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bgColor="gray.700"
        position="sticky"
        px={secondaryView ? 3 : 6}
        top={0}
        zIndex="sticky"
      >
        <MetaplexIcon w={9} h={9} />
        <DrawerCloseButton position="unset" />
      </DrawerHeader>
    );
  }

  return null;
};
