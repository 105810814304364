import { HAS_BOUND_STORE_ID } from "global-const/env";
import { Outlet } from "react-router-dom";
import { AdminItems } from "views/AdminItems";
import { AdminLayout } from "views/AdminLayout";
import { AdminPage } from "views/AdminPage";
import { AdminStoreSetting } from "views/AdminStoreSetting";
import { CreateSaleView } from "views/CreateSaleView";
import {
  AdminStoreGuard,
  ConnectedWalletGuard,
  DeactivateStore,
  NotFoundView,
  ProfileStoreGuard,
  StoreExistsGuard,
} from "views/guards";
import { DetectStore } from "views/guards/DetectStore";
import { Home } from "views/Home";
import { ItemView } from "views/ItemView";
import { SalesView } from "views/ItemView/sales";
import { ArtistAlley } from "views/ArtistAlley";
import { NftCreationView } from "views/NftCreationView";
import { ProfileItems } from "views/ProfileItems";
import { ProfileSales } from "views/ProfileSales";
import { ProfilePage } from "views/ProfilePage";
import { StoreCreate } from "views/StoreCreate";
import { StorePage } from "views/StorePage";
import { Test2Page, TestPage } from "views/TestPage";
import { ROUTES } from "./routes";
import { ISchema } from "./types";

export const ADMIN_SCHEMA: ISchema[] = [
  {
    route: ROUTES.admin,
    element: (
      <ConnectedWalletGuard>
        <AdminStoreGuard>
          <AdminLayout />
        </AdminStoreGuard>
      </ConnectedWalletGuard>
    ),
    children: [
      { index: true, element: <AdminPage /> },
      { route: ROUTES.adminStorefront, element: <AdminStoreSetting /> },
      { route: ROUTES.adminArtworks, element: <AdminItems /> },
    ],
  },
];

export const PROFILE_SCHEMA: ISchema[] = [
  {
    route: ROUTES.profile,
    element: (
      <ConnectedWalletGuard>
        <ProfileStoreGuard>
          <Outlet />
        </ProfileStoreGuard>
      </ConnectedWalletGuard>
    ),
    children: [
      { index: true, element: <ProfilePage /> },
      { route: ROUTES.profileArtworks, element: <ProfileItems /> },
      { route: ROUTES.profileSales, element: <ProfileSales /> },
    ],
  },
];

export const STORE_SCHEMA: ISchema[] = [
  {
    route: ROUTES.store,
    element: (
      <DetectStore>
        <StoreExistsGuard>
          <Outlet />
        </StoreExistsGuard>
      </DetectStore>
    ),
    children: [
      { index: true, element: <StorePage /> },
      { route: ROUTES.createArtwork, element: <NftCreationView /> },
      { route: ROUTES.createSale, element: <CreateSaleView /> },
      { route: ROUTES.artwork, element: <ItemView /> },
      { route: ROUTES.sale, element: <SalesView /> },
      { route: ROUTES.pastSale, element: <ItemView /> },
      ...ADMIN_SCHEMA,
      ...PROFILE_SCHEMA,
    ],
  },
  { route: ROUTES.artistAlley, element: <ArtistAlley /> },
];

export const GLOBAL_SCHEMA: ISchema[] = [
  {
    route: ROUTES.home,
    element: (
      <DeactivateStore>
        <Outlet />
      </DeactivateStore>
    ),
    children: [
      { index: true, element: <Home /> },
      {
        path: "/test",
        element: (
          <ConnectedWalletGuard>
            <TestPage />
          </ConnectedWalletGuard>
        ),
      },
      {
        path: "/test2",
        element: (
          <ConnectedWalletGuard>
            <Test2Page />
          </ConnectedWalletGuard>
        ),
      },
      {
        route: ROUTES.createStore,
        element: (
          <ConnectedWalletGuard>
            <StoreCreate />
          </ConnectedWalletGuard>
        ),
      },
    ],
  },
];

export const SCHEMA: ISchema[] = [
  ...(HAS_BOUND_STORE_ID ? [] : GLOBAL_SCHEMA),
  ...STORE_SCHEMA,
  { path: "*", element: <NotFoundView /> },
];
