import {
  createClaimResourceInstruction,
  findVaultOwnerAddress,
} from "@metaplex-foundation/mpl-fixed-price-sale";
import { PROGRAM_ID } from "@metaplex-foundation/mpl-token-metadata";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import { PublicKey, SYSVAR_CLOCK_PUBKEY } from "@solana/web3.js";
import { toPubkey } from "sdk/share";
import { TransactionsBatch } from "sdk/transactions";
import { Wallet } from "wallet";

export interface ClaimResourceProps {
  wallet: Wallet;
  metadata: PublicKey;
  store: PublicKey;
  market: PublicKey;
  marketData: {
    sellingResource: string;
    treasuryHolder: string;
    treasuryMint: string;
    vault: string;
    resource: string;
  };
}

export const createClaimTransaction = async ({
  wallet,
  metadata,
  store,
  market,
  marketData,
}: ClaimResourceProps) => {
  const { treasuryHolder, sellingResource, vault, resource } = marketData;

  const [vaultOwner, vaultOwnerBump] = await findVaultOwnerAddress(
    toPubkey(resource),
    store
  );

  const claimToken = await getAssociatedTokenAddress(
    toPubkey(resource),
    wallet.publicKey
  );

  const ix = createClaimResourceInstruction(
    {
      market,
      treasuryHolder: toPubkey(treasuryHolder),
      sellingResource: toPubkey(sellingResource),
      sellingResourceOwner: wallet.publicKey,
      vault: toPubkey(vault),
      metadata,
      owner: vaultOwner,
      destination: claimToken,
      tokenMetadataProgram: PROGRAM_ID,
      clock: SYSVAR_CLOCK_PUBKEY,
    },
    {
      vaultOwnerBump,
    }
  );

  const claimResourceTx = new TransactionsBatch({ instructions: [ix] });

  return claimResourceTx;
};
