import { IArt } from "state/artworks";
import ReactPlayer from "react-player";

interface IArtVideoProps {
  artwork: IArt;
}

export const ArtVideo: React.FC<IArtVideoProps> = ({ artwork }) => {
  return (
    <ReactPlayer
      controls
      width="100%"
      height="100%"
      url={artwork.animationUrl}
      loop
      playing
      volume={0.1}
    />
  );
};
