import { Button, Input, Stack, StackProps } from "@chakra-ui/react";

import { forwardRef, useState } from "react";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";

interface Props extends StackProps {
  actionText: string;
  action: () => void;
  disabled?: boolean;
}

export const FileInput = forwardRef<HTMLInputElement, Props>(
  ({ actionText, action, disabled, ...props }, ref) => {
    const [value, setValue] = useState("");
    const { mdUp } = useCustomBreakpoints();

    const buttonCornerRadius = mdUp ? "xl" : undefined;

    return (
      <Stack direction={mdUp ? "row" : "column"} spacing={4} {...props}>
        <Input
          ref={ref}
          onChange={(e) => setValue(e.target.value)}
          disabled={disabled}
          bg="whiteAlpha.50"
          color="whiteAlpha.700"
          _placeholder={{ color: "whiteAlpha.700" }}
          placeholder="http://example.io"
        />
        <Button
          isDisabled={value === ""}
          onClick={action}
          variant="tertiary"
          h={mdUp ? "unset" : undefined}
          size={mdUp ? undefined : "lg"}
          borderRadius={buttonCornerRadius}
          px={5}
        >
          {actionText}
        </Button>
      </Stack>
    );
  }
);
