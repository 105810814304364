import { IArt } from "state/artworks";
import React, { useCallback } from "react";
import { Box, Divider, Flex, HStack } from "@chakra-ui/react";

import { ArtworkListItemHeader } from "./ArtworkListItemHeader";
import { EndedAuctionDetails } from "../shared/ArtworkDetails/EndedAuctionDetails";
import { InstantBuyDetails } from "../shared/ArtworkDetails/InstantBuyDetails";
import {
  IFixedPrice,
  isActiveSale,
  ISale,
  isEndedSale,
  SaleState,
} from "state/sales";
import { LiveAuctionDetails } from "../shared/ArtworkDetails/LiveAuctionDetails";
import { MintedDetails } from "../shared/ArtworkDetails/MintedDetails";
import { ParticipatedDetails } from "../shared/ArtworkDetails/ParticipatedDetails";
import { SecondarySaleDetails } from "../shared/ArtworkDetails/SecondarySaleDetails";
import { useCustomBreakpoints } from "hooks/useCustomBreakpoints";
import { AdminItemsDetails } from "../shared/ArtworkDetails/AdminItemsDetails";
import { Status } from "../shared/Status";

export type ArtworkCardVariant =
  | "live-auction"
  | "ended-auction"
  | "secondary-sale"
  | "instant-buy"
  | "participated"
  | "minted"
  | "select"
  | "admin-items";

interface Props {
  artwork?: IArt;
  sale?: ISale;
  variant?: ArtworkCardVariant;
  selected?: boolean;
  frozen?: boolean;
  onSelect?: (artwork: IArt) => void;
  onClick?: () => void;
}

export const ArtworkListItem: React.FC<Props> = ({
  artwork,
  sale,
  variant,
  selected,
  onClick,
  frozen,
  children,
  ...props
}) => {
  const { xlUp } = useCustomBreakpoints();

  const { state = SaleState.Uninitialized } = sale || {};

  const onSelect = useCallback(() => {
    artwork && props.onSelect?.(artwork);
    onClick ? onClick() : null;
  }, [props, artwork, onClick]);

  const updatedState =
    (!!sale &&
      (isEndedSale(sale)
        ? SaleState.Ended
        : isActiveSale(sale)
        ? SaleState.Active
        : state)) ||
    state;

  return (
    <Flex
      overflow="hidden"
      w="full"
      h="fit-content"
      onClick={onSelect}
      layerStyle={selected ? "active" : "base"}
      borderRadius="xl"
      align="center"
      justify="stretch"
      p={xlUp ? 4 : 0}
      transition={
        frozen
          ? undefined
          : "transform 100ms ease-in-out, background-color 100ms ease-in-out"
      }
      _hover={
        frozen
          ? undefined
          : { bgColor: "whiteAlpha.100", transform: "translateY(-2px)" }
      }
      cursor={frozen ? undefined : "pointer"}
      flexDirection={xlUp ? "row" : "column"}
    >
      <Flex
        width="calc(100% - 125px)"
        alignItems="center"
        flexDirection={xlUp ? "row" : "column"}
        w="full"
      >
        <ArtworkListItemHeader
          artwork={artwork || sale?.artwork}
          w={xlUp ? "320px" : "full"}
          minW={xlUp ? "320px" : "50%"}
        />

        {variant === "live-auction" && <LiveAuctionDetails sale={sale} />}

        {variant === "ended-auction" && <EndedAuctionDetails sale={sale} />}

        {variant === "secondary-sale" && <SecondarySaleDetails sale={sale} />}

        {variant === "instant-buy" && (
          <InstantBuyDetails sale={sale as IFixedPrice} />
        )}

        {variant === "participated" && <ParticipatedDetails sale={sale} />}

        {variant === "minted" && <MintedDetails artwork={artwork} />}

        {variant === "admin-items" && artwork && (
          <AdminItemsDetails artwork={artwork} sale={sale} />
        )}
      </Flex>

      {!xlUp && (
        <Box px={4} w="full">
          <Divider />
        </Box>
      )}

      <HStack
        p={xlUp ? 0 : 4}
        justifyContent={xlUp ? "unset" : "space-between"}
        w={xlUp ? "unset" : "full"}
        minW="125px"
      >
        {!xlUp && <Status state={updatedState} />}

        {children}
      </HStack>
    </Flex>
  );
};
