import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation";
import "swiper/modules/pagination/pagination";
import "components/Carousel/styles.css";
import React, { ReactElement } from "react";
import { Box, FlexProps } from "@chakra-ui/react";

interface ICarouselProps extends FlexProps {
  onSlideChange?: (slideIndex: number) => void;
  slides: ReactElement[];
}

export const Carousel: React.FC<ICarouselProps> = ({
  onSlideChange,
  slides,
  ...props
}: ICarouselProps) => {
  return (
    <Box {...props}>
      <Swiper
        navigation
        pagination
        loop
        modules={[Navigation, Pagination]}
        onSlideChange={(swiper: { realIndex: number }) =>
          onSlideChange ? onSlideChange(swiper.realIndex) : null
        }
      >
        {slides.map((item, index) => (
          <SwiperSlide key={index}>{item}</SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
